import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImageModel } from '@shared/models/shared/image.model';
import { AlertsService } from '@services/internal/alerts.service';
import { ShortenFileUrl } from '@shared/utils/shorten-file-url';
import { TrackById } from '@shared/utils/form-elements/track-by';
import { TranslationsKeys } from '@shared/type/i18n.type';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentsComponent implements OnInit {
  @Input() allowUpload: boolean = false;
  @Input() allowEdit: boolean = false;
  @Input() set attachments(data: ImageModel[]) {
    this.localAttachments = ShortenFileUrl(data);
  }
  @Input() title: string = '';
  @Input() tooltip: TranslationsKeys;
  @Input() resetPadding: boolean = false;
  @Input() resetMobilePadding: boolean = false;
  @Input() validateFormats: 'PDF' | 'ALL' = 'ALL';
  @Input() hideUrlPanel: boolean = false;
  @Input() customAcceptFormat: string = null;
  @Input() isMultipleFiles: boolean = true;

  @Output() deleteFileEmitter = new EventEmitter<string>();
  @Output() saveFileUrlEmitter = new EventEmitter<{ image: HTMLInputElement; type: string }>();
  @Output() uploadFilesEmitter = new EventEmitter<{ event: Event; file: File; type: string }>();

  localAttachments: ImageModel[] = [];
  accept: string;
  readonly trackBy = TrackById;

  constructor(private alertsService: AlertsService) {}

  ngOnInit() {
    this.accept = this.customAcceptFormat
      ? this.customAcceptFormat
      : this.validateFormats === 'PDF'
        ? '.pdf,.rtf'
        : '.pdf,.rtf,.doc,.docx,image/*,.csv';
  }

  deleteFile(file: ImageModel): void {
    this.deleteFileEmitter.emit(file.id ?? file.auditInventoryAttachmentId);
  }

  saveFileUrl(image: HTMLInputElement, type: string): void {
    this.saveFileUrlEmitter.emit({ image, type });
  }

  uploadFiles(params: { event: Event; file: File }, type: string): void {
    const file = params.file ? params.file : (params.event.target as HTMLInputElement).files[0];
    if (!file) {
      return;
    }
    const validFile: boolean = this.validateFormats === 'PDF' ? this.validatePDF(file) : this.validateFileAllFormats(file);
    if (!validFile) {
      const msg =
        this.validateFormats === 'PDF' ? 'shared.alerts.errorMessages.validatePDF' : 'shared.alerts.errorMessages.validateAllAttachments';
      this.alertsService.showError(msg);
      return;
    }
    // 15 Megabytes (MB) = 15,728,640 Bytes (B)
    if (file.size > 15728640) {
      this.alertsService.showError('shared.alerts.errorMessages.maxFileSize15mb');
      return;
    }
    this.uploadFilesEmitter.emit({ event: params.event, file: params.file, type });
  }

  private validatePDF(file: File): boolean {
    try {
      const type = file.type.toLocaleLowerCase();
      const name = file.name.toLocaleLowerCase();
      return type.includes('pdf') || type.includes('rtf') || name.endsWith('.pdf') || name.endsWith('.rtf');
    } catch (e) {
      return false;
    }
  }

  private validateFileAllFormats(file: File): boolean {
    try {
      const type = file.type.toLocaleLowerCase();
      const name = file.name.toLocaleLowerCase();
      return (
        type.includes('image/') ||
        this.validatePDF(file) ||
        type.includes('doc') ||
        type.includes('docx') ||
        name.endsWith('.doc') ||
        name.endsWith('.docx') ||
        name.endsWith('.csv')
      );
    } catch (e) {
      return false;
    }
  }
}
