import { IFilter } from '@shared/interfaces';
import { DefaultQueryParams } from '@shared/helpers/default-query-params';
import { ClearRequest } from '@shared/utils/filter-object-null-properties';
import { TState } from '@shared/type/index.type';

export class RecipesPageableParams {
  catalogId?: string;
  createdById?: string;
  manufacturerId?: string;
  name?: string;
  page: number;
  physicianId?: string;
  physicianIds?: string[];
  procedureId?: string;
  size: number;
  sort: string[];
  state?: TState;
  userId?: string;

  constructor(params: IFilter) {
    return RecipesPageableParams.build(params);
  }

  private static build(params: IFilter): RecipesPageableParams {
    const queryParams: RecipesPageableParams = DefaultQueryParams(params);

    queryParams.state = params?.state ?? 'ACTIVE';
    queryParams.name = params.search;
    queryParams.physicianId = params.physicianId;
    queryParams.procedureId = params.procedureId;
    queryParams.physicianIds = params.physicianIds;
    queryParams.catalogId = params.catalogId;
    queryParams.userId = params.userId;
    queryParams.createdById = params.createdById;
    queryParams.manufacturerId = params.manufacturer;

    return ClearRequest(queryParams);
  }
}
