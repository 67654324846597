export enum EDateFormat {
  MM_DD_YYYY = 'MM/DD/YYYY',
  DD_MM_YYYY = 'DD/MM/YYYY',
  YYYY_MM_DD = 'YYYY/MM/DD'
}

export enum EDateFormatPDF {
  MM_DD_YYYY = 'MM/dd/yy',
  DD_MM_YYYY = 'dd/MM/yy',
  YYYY_MM_DD = 'yy/MM/dd'
}
