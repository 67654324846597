<div class="full-width" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
  <app-input-with-chips-kit
    *ngIf="multiple; else single"
    fxFlex="100%"
    [dataCy]="'locationStateInput'"
    [chipDataCy]="'locationStateChip'"
    [chipRemoveData_cy]="'locationStateRemoveChipButton'"
    [selectedChips]="selectedChips"
    [label]="label"
    [showAddChipButton]="true"
    [disabled]="disabled"
    [invalidInput]="invalid"
    [forceBlurEvent]="forceBlurEvent"
    [parseChipByProperty]="'locationStateName'"
    [selectable]="true"
    [debounceEvents]="['keyup', 'focus']"
    [debounceTime]="1000"
    [required]="required ? !selectedChips.length : false"
    [requiredView]="required ? !!selectedChips.length : false"
    [removable]="removable"
    [autocompleteName]="autocomplete"
    (chipsRemovedEmitter)="removeChip($event.id)"
    (debounceTimeEndedEmitter)="searchLocationState($event)"
  ></app-input-with-chips-kit>

  <ng-template #single>
    <app-input-kit
      fxFlex="100%"
      [required]="required"
      [label]="label"
      [disabled]="disabled"
      [placeholder]="placeholder"
      [dataCy]="'locationStateInput'"
      [initialValue]="locationState"
      [debounceEvents]="['keyup', 'focus']"
      [debounceTime]="1000"
      [autocompleteName]="autocomplete"
      (debounceTimeEndedEmitter)="searchLocationState($event)"
    ></app-input-kit>
  </ng-template>

  <div *ngIf="showFilterByUserCountry && !disabled" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
    <app-checkbox-kit
      class="subtitleContainer"
      [disabled]="(loading$ | async) === true"
      [initialValue]="useFilterByCountry"
      [title]="'shared.labels.useFilterByCountry' | language"
      (checkEmitter)="useFilterByCountry = $event"
    ></app-checkbox-kit>
    <app-tooltip-kit [size]="16" [title]="'shared.tooltips.filterByCountry'" [position]="'after'"></app-tooltip-kit>
  </div>

  <mat-autocomplete #autocomplete="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngIf="(loading$ | async) === true || (initialLoading$ | async) === true" class="is-loading" disabled>
      <mat-progress-spinner value="30" class="spinner" mode="indeterminate" diameter="30"></mat-progress-spinner>
    </mat-option>
    <ng-container *ngIf="(loading$ | async) === false && (initialLoading$ | async) === false">
      <mat-option data-cy="locationStateOption" *ngIf="!searchedData.length; else options" disabled class="connectSxAutocompleteOption">
        <span>{{ 'shared.labels.noItemsFound' | language }}</span>
      </mat-option>
      <ng-template #options>
        <cdk-virtual-scroll-viewport
          itemSize="0"
          class="virtualAutocompleteScroll"
          [style.height.px]="searchedData.length < 3 ? searchedData.length * 50 : 150"
        >
          <mat-option
            data-cy="locationStateOption"
            *cdkVirtualFor="let locationState of searchedData"
            class="connectSxAutocompleteOption"
            [value]="locationState"
            (click)="selectOption(locationState)"
          >
            <span data-cy="locationStateOptionName">{{ locationState.locationStateName }}</span>
            <small>{{ locationState.country || '-' }}</small>
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </ng-template>
    </ng-container>
  </mat-autocomplete>
</div>
