import { Injectable } from '@angular/core';
import { ApiService } from '@shared/classes/api-service';
import { Observable, of, take, catchError, BehaviorSubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { PushNotifications } from '@capacitor/push-notifications';
import { GlobalStoreService } from '@services/internal/global-store.service';
import { GlobalErrorHandlerService } from '@services/internal/global-error-handler.service';
import { Router } from '@angular/router';
import { ActionPerformed } from '@capacitor/push-notifications/dist/esm/definitions';
import { SearchParamsShorter } from '@shared/utils/http/search-params-shorter';
import { OfflineStatusService } from '@services/offline/offline-status.service';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService extends ApiService {
  apnsToken$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  constructor(
    private globalErrorHandlerService: GlobalErrorHandlerService,
    private router: Router,
    private offlineStatusService: OfflineStatusService
  ) {
    super();
  }

  init(): void {
    if (GlobalStoreService.getPlatform() === 'ios') {
      this.initNotifications();
    }
  }

  subscribe(token: string): Observable<void> {
    if (!token?.length) {
      return of(null);
    }
    return this.put<void>(`mobile/notifications/subscribe?appName=VTRAKER`, token).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  //Use on logout, in case user logs a few accounts on the same device
  unSubscribe(): Observable<void> {
    if (!this.apnsToken$.value?.length) {
      return of(null);
    }
    return this.put<void>(`mobile/notifications/unsubscribe?appName=VTRAKER`, this.apnsToken$.value).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  private initNotifications(): void {
    PushNotifications.addListener('registration', token => {
      this.subscribe(token.value).pipe(take(1)).subscribe();
      this.apnsToken$.next(token.value);
    });

    PushNotifications.addListener('registrationError', err => {
      this.globalErrorHandlerService.logErrors(err, 'PUSH_NOTIFICATIONS', null, 'PushNotifications registration error');
    });

    // Notification appeared
    PushNotifications.addListener('pushNotificationReceived', notification => {
      console.log('Push notification received: ', notification);
    });

    // Click on push
    PushNotifications.addListener('pushNotificationActionPerformed', async (notification: ActionPerformed) => {
      const isOffline = this.offlineStatusService.isOffline$.value;
      if (isOffline) {
        return;
      }
      let url: string;
      let qParams: string = null;
      if (notification?.notification?.data?.transferKey) {
        url = '/movement/transfers/edit/' + notification.notification.data.transferKey;
        // Don't redirect if user already on the event page
      } else if (notification?.notification?.data?.eventIdKey && !this.router.url.includes('/events/edit/')) {
        url = '/events/edit/' + notification.notification.data.eventIdKey;
      } else if (notification?.notification?.data?.dueBackStatus?.length) {
        url = '/inventory/inventories/list';
        qParams = SearchParamsShorter('dueBackStatus') + '=' + notification?.notification?.data?.dueBackStatus;
      } else if (notification?.notification?.data?.inventoryExpirationStatus?.length) {
        const expParam: string = notification?.notification?.data?.inventoryExpirationStatus;
        const p =
          expParam === 'EXPIRED' ? 'EXPIRED' : expParam === 'WITHIN_30_DAYS' ? 'WITHIN_30' : expParam === 'IN_31_60_DAYS' ? '31-60' : null;
        if (p) {
          url = '/inventory/inventories/list';
          qParams = SearchParamsShorter('expirationDays') + '=' + p;
        }
      } else if (notification?.notification?.data?.inventoryIdKey) {
        url = '/inventory/inventories/edit/' + notification.notification.data.inventoryIdKey;
      } else if (notification?.notification?.data?.inventoryRequestKey) {
        url = '/movement/requests/edit/' + notification.notification.data.inventoryRequestKey;
      }
      if (url) {
        if (qParams !== null) {
          qParams = '?' + qParams;
          await this.router.navigateByUrl(url + qParams);
          // Reload the page to apply filters
          if (qParams) {
            setTimeout(() => {
              location.reload();
            }, 100);
          }
        } else {
          await this.router.navigateByUrl(url);
        }
      }
    });

    const registerNotifications = async () => {
      let permStatus = await PushNotifications.checkPermissions();
      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }
      if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!');
      }
      await PushNotifications.register();
    };

    registerNotifications();

    // Previously received notifications on app opened
    // PushNotifications.getDeliveredNotifications().then(() => {});
  }
}
