import { OrganizationModel } from '@shared/models/features/settings/organization.model';
import { InvitationExtendedModel } from '@shared/models/shared/invitation.model';
import { TColorThemes, TState, TRoles } from '@shared/type/index.type';
import { IUserSubordinationView } from '@shared/interfaces';
import { DateTimeString } from '@shared/models/shared/shared-kernel';
import { RegionModel } from '@shared/models';
import { UserLevelPermissions } from '@shared/models/permissions/permission.model';
import { ELanguages } from '@shared/enum/languages';

export class UserModel {
  alternateEmailAddress: string;
  colorTheme: TColorThemes;
  companyName: string;
  coordinators?: IUserSubordinationView[] | [];
  customPermissions: UserLevelPermissions;
  emailAddress: string;
  enableDueBackNotification: boolean;
  enableInventoryExpirationNotification: boolean;
  enableScanToIdentifyNotification: boolean;
  enableTransfersNotification: boolean;
  externalId: string;
  id: string;
  invitation: InvitationExtendedModel;
  isCustodianOnly: boolean;
  isOffline: boolean;
  isPrimaryAdmin: boolean;
  modifiedDatetime?: string;
  name: string = '';
  nextOnlineDate: DateTimeString;
  offlineHours: number;
  offset: number;
  //Organization added only for the current user at GET users/me
  organization?: OrganizationModel;
  phone: string = '';
  region: RegionModel;
  role: TRoles;
  showInventoriesToSales: boolean;
  state: TState;
  subordinatorUserSubordination: null;
  subordinators?: IUserSubordinationView[];
  timeZone: string | null;
  userIsLocked: boolean;
  language: keyof typeof ELanguages;
  lastLoggedDate: DateTimeString;

  constructor(org: OrganizationModel = null) {
    if (org) {
      this.name = org.company.name;
      this.id = 'ORGANIZATION';
    }
  }
}

export class UserTimeZone {
  offset: number;
  timeZone: string;
  constructor() {
    this.offset = new Date().getTimezoneOffset() / 60;
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
