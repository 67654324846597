import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TSelectedView } from '@shared/type/index.type';

@Component({
  selector: 'app-detail-page-view-selector',
  templateUrl: './detail-page-view-selector.component.html',
  styleUrls: ['./detail-page-view-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailPageViewSelectorComponent {
  @Input() selectedView: TSelectedView = 'DETAILS';

  @Output() viewChangedEmitter = new EventEmitter<'DETAILS' | 'INFO'>();
}
