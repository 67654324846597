export enum EImportStatus {
  CANCELED = 'shared.statuses.import.canceled',
  FAILURE = 'shared.statuses.import.failure',
  INTERNAL_ERROR = 'shared.statuses.import.internalError',
  PENDING = 'shared.statuses.import.pending',
  PREPROCESSING = 'shared.statuses.import.preprocessing',
  SAVING_DATA = 'shared.statuses.import.savingData',
  SUCCESS = 'shared.statuses.import.success',
  DELETED = 'shared.statuses.import.deleted'
}
