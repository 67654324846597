import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  // Force the value of maintenance mode here
  isMaintenance$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private router: Router) {}

  async checkMaintainMode(): Promise<void> {
    //Use fetch to avoid globalErrorHandler error interceptor
    const res = await window.fetch(`${environment.apiUrlCore}users/@/organizations`, {
      method: 'GET'
    });
    // @ts-ignore
    if (res.status === 500) {
      this.router.navigate(['/maintenance']);
      this.isMaintenance$.next(true);
    } else {
      this.isMaintenance$.next(false);
      if (this.router.url === '/maintenance') {
        this.router.navigate(['/dashboard']);
      }
    }
  }
}
