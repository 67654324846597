<div
  *ngIf="!skeleton"
  class="inputWithChipsKitContainer"
  [ngClass]="{
    valueExist: selectedChips?.length || showAddChipButton || focused,
    invalidInput:
      (error || inputRef.invalid || invalidInput || (required && !selectedChips?.length)) &&
      (inputRef.touched || (touched$ | async) === true),
    defaultHeight: focused && !selectedChips.length && !showAddChipButton,
    disabledInput: disabled,
    required: (required || requiredView) && !disabled,
    focused: focused,
    filterView: filterView
  }"
>
  <mat-form-field
    class="inputFormField"
    [ngClass]="{ valueExist: selectedChips?.length || value?.length || focused || showAddChipButton, disabledInput: disabled }"
  >
    <mat-label>{{ label }}</mat-label>
    <mat-chip-grid #chipList class="chipListKit" data-cy="chipListKit">
      <mat-chip-row
        [attr.data-cy]="chipDataCy"
        *ngFor="let chip of selectedChips; trackBy: trackBy"
        [disableRipple]="true"
        class="chipKit"
        [ngClass]="{ resetPadding: removable }"
        [removable]="removable && !chip.autoassignedContainer"
        (mouseenter)="chip.hover = true"
        (mouseleave)="chip.hover = false"
        (removed)="chipsRemovedEmitter.emit(chip); touched$.next(true)"
      >
        <ng-container *ngIf="detailPageLink && showLinkToDetail; else withoutLink">
          <a class="chipLink" (click)="$event.stopPropagation(); openLinkInBrowser(detailPageLink + chip.id)">
            <app-tooltip-on-ellipsis>{{ chip[parseChipByProperty] }}</app-tooltip-on-ellipsis>
          </a>
        </ng-container>
        <ng-template #withoutLink>
          <span class="chipLink"
            ><app-tooltip-on-ellipsis>{{ chip[parseChipByProperty] }}</app-tooltip-on-ellipsis></span
          >
        </ng-template>
        <app-icon
          [attr.data-cy]="chipRemoveData_cy"
          [type]="'outline'"
          [color]="chip.hover ? 'primary' : 'default'"
          [icon]="'close'"
          matChipRemove
          *ngIf="removable && !chip.autoassignedContainer"
          [size]="16"
        ></app-icon>
      </mat-chip-row>
      <mat-chip-row
        *ngIf="showAddChipButton && !showChipsInput && !disabled"
        class="chipKit addButton"
        data-cy="addChipButton"
        (click)="$event.stopPropagation(); showChipsInput = true; focusInput()"
      >
        <span class="chipLink">+ {{ 'shared.components.add' | language }}</span>
      </mat-chip-row>
      <input
        #input
        #inputRef="ngModel"
        matInput
        appDebounceEvent
        maxlength="255"
        autocomplete="off"
        [hidden]="showAddChipButton && !showChipsInput"
        [type]="type"
        [required]="required"
        [placeholder]="disabled ? null : placeholder"
        [disabled]="disabled"
        [attr.data-cy]="dataCy"
        [ngModel]="value"
        [matAutocomplete]="autocompleteName"
        [matAutocompleteDisabled]="!autocompleteName"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        [debounceEvents]="debounceEvents"
        [debounceTime]="debounceTime"
        (matChipInputTokenEnd)="chipInputEndEmitter.emit($event); input.value = ''"
        (focus)="focused = true; scrollTo(input)"
        (blur)="focused = false; showChipsInput = false; input.value = ''"
        (ngModelChange)="updateValue($event)"
        (debounceEventEmitter)="checkValueBeforeEmit(input.value)"
      />
    </mat-chip-grid>

    <div *ngIf="icons.length" fxLayout="row" fxLayoutGap="8px" class="iconsContainer" fxLayoutAlign="center center">
      <ng-container *ngFor="let icon of icons">
        <app-icon
          *ngIf="icon === 'plus'"
          matTooltip="Create new"
          matTooltipClass="connectSxTooltipMessage"
          matTooltipPosition="below"
          [icon]="'plus'"
          [type]="'outline'"
          [size]="20"
          [color]="'primary'"
          (click)="$event.stopPropagation(); iconClickEmitter.emit(icon)"
        ></app-icon>

        <app-icon
          *ngIf="icon === 'filter'"
          [icon]="'filter'"
          [type]="'outline'"
          [size]="20"
          #menuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="matMenuName"
          (click)="$event.stopPropagation()"
        ></app-icon>

        <app-icon
          *ngIf="icon === 'edit'"
          [icon]="'edit'"
          [type]="'outline'"
          [size]="20"
          [color]="'default'"
          (click)="$event.stopPropagation(); iconClickEmitter.emit(icon)"
        ></app-icon>

        <app-tooltip-kit *ngIf="icon === 'help'" [title]="tooltipText" (click)="$event.stopPropagation()"></app-tooltip-kit>
      </ng-container>
    </div>
  </mat-form-field>
</div>
<span *ngIf="hint" class="inputHint">{{ hint }}</span>
<span *ngIf="error" class="inputHint errorHint">{{ error }}</span>

<app-string-skeleton *ngIf="skeleton" [defaultInput]="true"></app-string-skeleton>
