import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { EventsStoreService } from '../../services/events-store.service';
import { Observable, take, map, takeUntil, BehaviorSubject } from 'rxjs';
import { DestroySubscriptions } from '@shared/classes/destroy-subscriptions';
import { EventTemplateBuilder } from '../../helpers/event-template-builder';
import { EventModel, InventoryModel, ManufacturerModel, ProductLineModel, UserModel } from '@shared/models';
import { TEventType } from '@shared/type/index.type';
import { MatAutocompleteRequireMath } from '@shared/validators/mat-autocomplete-require-math';
import { ConfirmComponent } from '@shared/components/modals/confirm/confirm.component';
import { LanguageService } from '@services/internal/language.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalConfig } from '@constants';
import { OfflineStatusService } from '@services/offline/offline-status.service';
import { PermissionService } from '@services/internal/permission.service';
import { UsersService } from '@services/users.service';

@Component({
  selector: 'app-form-step-second',
  templateUrl: './form-step-second.component.html',
  styleUrls: ['./form-step-second.component.scss']
})
export class FormStepSecondComponent extends DestroySubscriptions implements OnInit {
  @Input() set assignmentsValidator(validator: any) {
    if (!this.assignments || !validator) {
      return;
    }
    this.assignments.get('products').setValidators(validator.products);
    this.assignments.get('products').updateValueAndValidity();
    this.selectedEventType = validator.eventType;
    this.config = new EventTemplateBuilder(validator.eventType);
  }
  @Input() set event(data: EventModel) {
    this.setData(data);
    this.config = new EventTemplateBuilder(data.eventType);
  }
  @Input() allowEdit: boolean = false;
  @Input() filterByRepresentative: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() selectedProducts: ProductLineModel[] = [];
  @Input() selectedContainers: InventoryModel[] = [];
  @Input() showFilterProducts: boolean = false;
  @Input() createEntityFlow: boolean = false;
  @Input() valid: boolean = false;
  @Input() prevStepForm: UntypedFormGroup;
  @Input() selectedCustody: UserModel = new UserModel();
  @Input() allowCreateAndInNewIconInAutocompletes: boolean = true;

  @Output() addProductLineEmitter = new EventEmitter<void>();
  @Output() filterByRepresentativeEmitter = new EventEmitter<void>();
  @Output() formNameValueChangeEmitter = new EventEmitter<UntypedFormGroup>();
  @Output() removeManufacturerEmitter = new EventEmitter<string>();
  @Output() removeProductEmitter = new EventEmitter<string>();
  @Output() removeContainerEmitter = new EventEmitter<string>();
  @Output() selectManufacturerEmitter = new EventEmitter<ManufacturerModel[]>();
  @Output() selectProductEmitter = new EventEmitter<ProductLineModel>();
  @Output() selectContainerEmitter = new EventEmitter<InventoryModel>();

  config: EventTemplateBuilder = new EventTemplateBuilder();
  assignments: UntypedFormGroup;
  selectedEventType: TEventType;
  selectedManufacturers: Observable<ManufacturerModel[]> = this.eventsStoreService.store$.pipe(map(st => st.manufacturers));
  event$: Observable<EventModel> = this.eventsStoreService.store$.pipe(map(st => st.event));
  assignedDevices$: Observable<boolean> = this.eventsStoreService.store$.pipe(
    map(st => st.assignedDevicesOriginal),
    map(inv => Boolean(inv.length))
  );

  readonly currentUser: UserModel = UsersService.getUser();
  readonly isOffline$: BehaviorSubject<boolean> = this.offlineStatusService.isOffline$;

  constructor(
    public permissionService: PermissionService,
    private formBuilder: UntypedFormBuilder,
    private eventsStoreService: EventsStoreService,
    private dialog: MatDialog,
    private offlineStatusService: OfflineStatusService
  ) {
    super();
    this.createForm();
  }

  ngOnInit(): void {
    this.formChanges();
  }

  readyForUse(): void {
    const dialogRef = this.dialog.open(
      ConfirmComponent,
      ConfirmModalConfig({
        description: LanguageService.instant('shared.alerts.prompt.readyForUse'),
        acceptButtonStyle: 'primary'
      })
    );
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.eventsStoreService.setReadyForUse();
        }
      });
  }

  private createForm(): void {
    this.assignments = this.formBuilder.group({
      manufacturerIds: [null, [MatAutocompleteRequireMath]],
      products: [null, [MatAutocompleteRequireMath]],
      preferenceCard: [null, [MatAutocompleteRequireMath]]
    });
    this.assignments.get('products').disable();
  }

  private formChanges(): void {
    setTimeout(() => {
      this.formNameValueChangeEmitter.emit(this.assignments);
    });
    this.assignments.valueChanges.pipe(takeUntil(this.subscriptions)).subscribe(() => {
      this.formNameValueChangeEmitter.emit(this.assignments);
    });
  }

  private setData(data: EventModel): void {
    if (!data || !this.assignments) {
      return;
    }

    this.assignments.setValue({
      manufacturerIds: null,
      products: null,
      preferenceCard: data.preferenceCard ?? null
    });

    setTimeout(() => {
      this.formNameValueChangeEmitter.emit(this.assignments);

      if (this.allowEdit) {
        this.assignments.enable();
      } else {
        this.assignments.disable();
      }
    });
  }
}
