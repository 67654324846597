import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-multiselect-dropdown',
  templateUrl: './multiselect-dropdown.component.html',
  styleUrls: ['./multiselect-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MultiselectDropdownComponent<T> {
  @Input({ required: true }) options: T[] = [];
  @Input({ required: true }) selectedOptions: T[] = [];
  @Input({ required: true }) label: string = 'Label';
  @Input() disabled: boolean = false;
  @Input() hasBackground: boolean = false;
  @Input() dataCy: string = '';
  @Input('customSettings') set _customSettings(data: IDropdownSettings) {
    this.dropdownSettings = { ...this.dropdownSettings, ...data };
  }

  // Format { [dropdownSettings.idField]: value1, [dropdownSettings.textField]: value2 }
  @Output() optionSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() optionRemoved: EventEmitter<any> = new EventEmitter<any>();
  @Output() allOptionSelected: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() allOptionsRemoved: EventEmitter<any[]> = new EventEmitter<any[]>();

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    enableCheckAll: true,
    selectAllText: 'Select all',
    unSelectAllText: 'Unselect all',
    allowSearchFilter: true,
    clearSearchFilter: true,
    maxHeight: 200,
    itemsShowLimit: 5,
    searchPlaceholderText: 'Search item',
    noDataAvailablePlaceholderText: 'No items found',
    noFilteredDataAvailablePlaceholderText: 'No items found by search query',
    closeDropDownOnSelection: false,
    showSelectedItemsAtTop: false,
    defaultOpen: false,
    allowRemoteDataSearch: false
  };
}
