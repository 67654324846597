import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { TranslationsKeys } from '@shared/type/i18n.type';
import { take } from 'rxjs';

/** pure: false needs to update translations if translation file was loaded after application launched */
@Pipe({
  name: 'language',
  pure: false
})
export class LanguagePipe extends TranslatePipe implements PipeTransform {
  private translateCache: string;
  private keyCache: TranslationsKeys;
  constructor(
    protected translateService: TranslateService,
    protected ref: ChangeDetectorRef
  ) {
    super(translateService, ref);
    this.translateService.onLangChange.pipe(take(1)).subscribe(() => (this.translateCache = null));
  }

  transform(value: TranslationsKeys, ...args: unknown[]): string {
    // Save translations and return saved value each time pipe calls, to avoid calling service
    if (this.translateCache?.length && this.keyCache === value) {
      return this.translateCache;
    }
    this.translateCache = super.transform(value, args);
    this.keyCache = value;
    return this.translateCache;
  }
}
