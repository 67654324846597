import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { IErrorReports } from '@shared/interfaces';
import { Router } from '@angular/router';
import { AlertsService } from './alerts.service';
import { UserModel } from '@shared/models';
import { AuthenticationService } from '../auth.service';
import { take, throwError } from 'rxjs';
import { AppComponent } from '../../../app.component';
import { ConnectionStatus, Network } from '@capacitor/network';
import { UsersService } from '@services/users.service';
import { OfflineStatusService } from '@services/offline/offline-status.service';
import { MaintenanceService } from '@services/maintenance.service';
import { GetBrowser } from '@shared/utils/get-browser';
import { GlobalStoreService } from '@services/internal/global-store.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {
  private static readonly frontEndInternalErrors: string =
    'https://us-central1-connectsx-production.cloudfunctions.net/frontEndInternalErrors';
  private isReAuthenticating: boolean = false;
  private readonly errorReportUrl: string = 'https://us-central1-connectsx-production.cloudfunctions.net/errorReports';

  constructor(
    private router: Router,
    private alertsService: AlertsService,
    private httpClient: HttpClient,
    private injector: Injector,
    private maintenanceService: MaintenanceService,
    private ngZone: NgZone,
    private offlineStatusService: OfflineStatusService
  ) {
    Network.addListener('networkStatusChange', (status: ConnectionStatus) => {
      this.ngZone.run(() => {
        if (!status.connected && !this.offlineStatusService.areRequestsOffline$.value) {
          this.router.navigate(['/offline']);
        }
      });
    });
  }

  handleError(error: Error | HttpErrorResponse, req?: HttpRequest<any>): any {
    if (!AppComponent.isProd()) {
      console.log(error);
    }
    if (error instanceof HttpErrorResponse) {
      if (error.status === 504) {
        return;
      }
      // Server Error
      if (error.status > 404) {
        this.logErrors(error, 'BACKEND', req);
      }
      if (error.status === 401) {
        /* Auth is checking in auth.service, this is an additional error catcher */
        const authService: AuthenticationService = this.injector.get(AuthenticationService);
        if (UsersService.getUser().id) {
          if (!this.isReAuthenticating) {
            this.isReAuthenticating = true;
            authService
              .refreshToken()
              .then(() => {
                this.isReAuthenticating = false;
              })
              .catch(() => {
                this.isReAuthenticating = false;
              });
          }
        } else {
          if (location.pathname !== '/login') {
            this.alertsService.showSuccess('shared.alerts.successMessages.endSession', null, 10000);
          }
          authService.logout();
        }
      }
      if (error.status === 500) {
        if (!error?.url?.includes('core/alerts/pageable')) {
          this.alertsService.showError('shared.alerts.errorMessages.serverError');
        }
      }
      // Parse for responseType string
      const err = typeof error?.error === 'string' ? JSON.parse(error?.error) : error?.error;
      if (error.status === 503 && navigator.onLine === true) {
        this.alertsService.showError('shared.alerts.errorMessages.serverConnectionError');
        this.redirectToServerError();
        return throwError(() => new Error(error?.error?.message));
      }
      if (error.status >= 500 && navigator.onLine) {
        this.maintenanceService.checkMaintainMode();
      }
      return throwError(() => new Error(err?.message));
    } else {
      this.reportFrontEndInternalErrorsToSlack(error, req);
    }
  }

  logErrors(
    error: any,
    type: 'BACKEND' | 'FRONTEND' | 'PUSH_NOTIFICATIONS' | 'ANALYTICS',
    req?: HttpRequest<any>,
    message: string = '-'
  ): void {
    if (
      !AppComponent.isProd() ||
      req?.url?.includes(this.errorReportUrl) ||
      req?.url?.includes(GlobalErrorHandlerService.frontEndInternalErrors)
    ) {
      return;
    }
    // Don't send alerts error
    if (req?.url?.includes('alerts/pageable')) {
      return;
    }
    const priority = (): string => {
      let result: string;
      if (req?.url?.includes('alerts/pageable')) {
        result = 'PRIORITY: LOW';
      } else if (req?.url?.includes('/auth/refresh')) {
        result = 'PRIORITY: MEDIUM';
      } else {
        result = 'PRIORITY: HIGH';
      }
      return result + '; Type: ' + type + '; Message: ' + message;
    };

    const user: UserModel = UsersService.getUser();
    const params: IErrorReports = {
      host: location.origin,
      errorStatus: type === 'BACKEND' ? error?.status : 'n/a',
      user: {
        ...{ userName: user.name, emailAddress: user.emailAddress, state: user.state, timeZone: user.timeZone, role: user.role },
        ...GetBrowser(),
        ...{ platform: GlobalStoreService.getPlatform() }
      },
      organization: user.organization.company.name,
      page: location.href,
      backendEndpoint: type === 'BACKEND' ? error?.path : 'n/a',
      apiRequest: req || 'n/a',
      apiResponse: type === 'BACKEND' ? error : 'n/a',
      frontEndErrorInfo: priority()
    };
    this.httpClient.post<void>(this.errorReportUrl, params).pipe(take(1)).subscribe();
  }

  // Client Error
  reportFrontEndInternalErrorsToSlack(error: any, req: any): void {
    if (AppComponent.isProd()) {
      const user = UsersService.getUser();
      const reportError = {
        error: '' + error + '',
        page: location.href,
        request: req?.url,
        params: req?.params,
        reqBody: req?.body,
        userRole: user?.role,
        browser: GetBrowser(),
        platform: GlobalStoreService.getPlatform(),
        organization: user?.organization?.company?.name
      };
      this.httpClient.post<void>(GlobalErrorHandlerService.frontEndInternalErrors, reportError).pipe(take(1)).subscribe();
    }
  }

  private redirectToServerError(): void {
    this.router.navigate(['/server-error']);
  }
}
