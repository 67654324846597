export enum EPageablePageType {
  inventoryPageable,
  eventPageable,
  requestPageable,
  transferPageable,
  productLinePageable,
  billOfMaterialsPageable,
  catalogPageable,
  facilitiesPageable,
  physiciansPageable,
  proceduresPageable,
  manufacturersPageable,
  notificationsPageable,
  locationsPageable,
  deviceUsageTemplate,
  requestRecipes,
  users,
  inventoryImport,
  regionsPageable,
  priceSheetsPageable,
  auditsPageable,
  basePageable
}
