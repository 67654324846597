export enum EUserActions {
  EVENT_CREATED = 'shared.enums.userActions.EVENT_CREATED',
  EVENT_CLOSED = 'shared.enums.userActions.EVENT_CLOSED',
  EVENT_COMPLETED = 'shared.enums.userActions.EVENT_COMPLETED',
  EVENT_CANCELLED = 'shared.enums.userActions.EVENT_CANCELLED',
  INVENTORY_EDITED = 'shared.enums.userActions.INVENTORY_EDITED',
  EVENT_INVENTORY_MARKED = 'shared.enums.userActions.EVENT_INVENTORY_MARKED',
  TRANSFER_CREATED = 'shared.enums.userActions.TRANSFER_CREATED',
  TRANSFER_ACCEPTED = 'shared.enums.userActions.TRANSFER_ACCEPTED',
  TRANSFER_DECLINED = 'shared.enums.userActions.TRANSFER_DECLINED',
  INVENTORY_REQUEST_CREATED = 'shared.enums.userActions.INVENTORY_REQUEST_CREATED',
  EVENT_SOF_DOWNLOADED = 'shared.enums.userActions.EVENT_SOF_DOWNLOADED',
  EVENT_SOF_EMAILED = 'shared.enums.userActions.EVENT_SOF_EMAILED',
  EVENT_INVOICE_EMAILED = 'shared.enums.userActions.EVENT_INVOICE_EMAILED',
  EVENT_INVOICE_DOWNLOADED = 'shared.enums.userActions.EVENT_INVOICE_DOWNLOADED',
  FACILITY_CREATED = 'shared.enums.userActions.FACILITY_CREATED',
  PHYSICIAN_CREATED = 'shared.enums.userActions.PHYSICIAN_CREATED',
  PROCEDURE_CREATED = 'shared.enums.userActions.PROCEDURE_CREATED',
  CATALOG_CREATED = 'shared.enums.userActions.CATALOG_CREATED',
  PRODUCT_LINE_CREATED = 'shared.enums.userActions.PRODUCT_LINE_CREATED',
  EVENT_QUOTE_EMAILED = 'shared.enums.userActions.EVENT_QUOTE_EMAILED',
  TRANSFER_SHIPPED = 'shared.enums.userActions.TRANSFER_SHIPPED'
}
