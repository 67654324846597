import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@shared/classes/api-service';
import { PageableModel, PriceSheetItemModel, PriceSheetModel } from '@shared/models';
import { PriceSheetPageableParams } from '@shared/models/build-models';
import { Observable, catchError, map, of } from 'rxjs';
import { HttpHelperService } from './internal/http-helper.service';
import { IPriceSheetItemParams, IPriceSheetParams } from '@shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class PriceSheetsService extends ApiService {
  constructor() {
    super();
  }

  getPageable(params: PriceSheetPageableParams): Observable<PageableModel<PriceSheetModel>> {
    return this.get<PageableModel<PriceSheetModel>>(`facility-pricing-sheets/pageable`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of(new PageableModel()))
    );
  }

  createPriceSheet(params: IPriceSheetParams): Observable<string> {
    return this.post<string>(`facility-pricing-sheets`, params).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  updatePriceSheet(priceSheetId: string, params: IPriceSheetParams): Observable<boolean> {
    return this.put<void>(`facility-pricing-sheets/${priceSheetId}/assignments`, params).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.uploaded');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getPriceSheet(id: string, _params = {}, redirectToNotFoundPage: boolean = false): Observable<PriceSheetModel> {
    return this.get<PriceSheetModel>(`facility-pricing-sheets/${id}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        HttpHelperService.errorHandler(error, null, redirectToNotFoundPage);
        return of(null);
      })
    );
  }

  deletePriceSheet(id: string): Observable<boolean> {
    return this.delete<void>(`facility-pricing-sheets/${id}`).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.deleted');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  savePriceSheet(priceSheetId: string, params: Partial<IPriceSheetParams>): Observable<boolean> {
    return this.put<void>(`facility-pricing-sheets/${priceSheetId}`, params).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getPricesByManufacturer(facilityId: string, manufacturerId: string): Observable<PriceSheetItemModel[]> {
    return this.get<PriceSheetItemModel[]>(
      `facility-pricing-sheets/assignments/by-manufacturer?facilityId=${facilityId}&manufacturerId=${manufacturerId}`
    ).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  getPriceSheetItems(priceSheetId: string): Observable<PriceSheetItemModel[]> {
    return this.get<PriceSheetItemModel[]>(`facility-pricing-sheets/${priceSheetId}/assignments`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  deletePriceSheetItem(priceSheetId: string, priceSheetItemId: string): Observable<boolean> {
    return this.delete<void>(
      `facility-pricing-sheets/${priceSheetId}/assignments?facilityPricingSheetAssigmentId=${priceSheetItemId}`
    ).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.deleted');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  updatePriceSheetItem(priceSheetId: string, priceSheetItemId: string, params: IPriceSheetItemParams): Observable<boolean> {
    return this.put<void>(`facility-pricing-sheets/${priceSheetId}/assignment-row/${priceSheetItemId}`, params).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  createPriceSheetItem(priceSheetId: string, params: IPriceSheetItemParams): Observable<boolean> {
    return this.post<void>(`facility-pricing-sheets/${priceSheetId}/assignments`, params).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.created');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }
}
