import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EventModel, PageableModel } from '@shared/models';
import { EventsService } from '@services/events.service';
import { DisplayName } from '@shared/utils/form-elements/display-with-autocomplete';
import { IFilter } from '@shared/interfaces';
import { EventPageableParamsBuilder } from '@shared/models/build-models';
import { BehaviorSubject, finalize, map, Observable, take } from 'rxjs';
import { TIcons } from '@shared/type/icons.type';
import { TEventType, TInputType } from '@shared/type/index.type';
import { CreateEventComponent } from 'src/app/features/events/event-detail/partials/modals/create-event/create-event.component';
import { CreateMediumEntityConfig } from '@constants';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PermissionService } from '@services/internal/permission.service';
import { TranslationsKeys } from '@shared/type/i18n.type';

@Component({
  selector: 'app-events-autocomplete',
  templateUrl: './events-autocomplete.component.html',
  styleUrls: ['./events-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventsAutocompleteComponent {
  @Input() required: boolean = false;
  @Input() custodyId: string = null;
  @Input() useFilterCustody: boolean = true;
  @Input() showOpenInNewIcon: boolean = false;
  @Input() showOpenInNewIconOnCreateEvent: boolean = true;
  @Input() showClearIcon: boolean = false;
  @Input() initialValue: EventModel = null;
  @Input() disabled: boolean = false;
  @Input() showAddNew: boolean = false;
  @Input() selectedEventTypeOnCreate: TEventType = null;
  @Input() filterParams: IFilter = {};
  @Input() filterView: boolean = false;
  @Input() label: TranslationsKeys = 'shared.labels.event';

  @Output() selectedOptionEmitter = new EventEmitter<EventModel>();
  @Output() clearInputEmitter = new EventEmitter<void>();

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  initialLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  searchedData$: Observable<EventModel[]> = new Observable<EventModel[]>();
  inputType: TInputType = 'text';
  forceBlurEvent: boolean = false;

  readonly displayFn = DisplayName;

  constructor(
    public permissionService: PermissionService,
    private eventsService: EventsService,
    private dialog: MatDialog
  ) {}

  selectOption(event: EventModel): void {
    this.selectedOptionEmitter.emit(event);
  }

  searchEvents(value: string, skipSelectSingleOption: boolean = false): void {
    this.initialLoading$.next(false);
    this.forceBlurEvent = false;

    if (this.loading$.value) {
      return;
    }

    this.loading$.next(true);

    const params: IFilter = { ...{ state: 'ACTIVE' }, ...this.filterParams };

    if (this.useFilterCustody) {
      params.custodyId = this.custodyId || 'ORGANIZATION';
    }

    if (value) {
      params.eventName = value;
    }

    const queryParams: EventPageableParamsBuilder = new EventPageableParamsBuilder(params);

    this.searchedData$ = this.eventsService.getPageable(queryParams).pipe(
      take(1),
      map((data: PageableModel<EventModel>) => {
        if (skipSelectSingleOption && data.content.length === 1) {
          this.forceBlurEvent = true;
          return data.content;
        }

        if (!value && data.content.length === 1) {
          this.selectOption(data.content[0]);
          this.forceBlurEvent = true;
        } else {
          this.forceBlurEvent = false;
        }

        return data.content;
      }),
      finalize(() => this.loading$.next(false))
    );
  }

  doIconAction(icon: TIcons): void {
    if (icon === 'close') {
      this.clearInputEmitter.emit();
      this.searchEvents('', true);
    }
  }

  createEvent(): void {
    const dialogConfig: MatDialogConfig = { ...CreateMediumEntityConfig };
    const excludeEventTypes: TEventType[] = ['STOCK_ADJUSTMENT'];
    dialogConfig.data = {
      excludeEventTypes,
      selectedEventTypeOnCreate: this.selectedEventTypeOnCreate,
      showOpenInNewIconOnCreateEvent: this.showOpenInNewIconOnCreateEvent
    };

    const dialogRef = this.dialog.open(CreateEventComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(id => {
        if (id) {
          this.eventsService
            .getEvent(id)
            .pipe(take(1))
            .subscribe(event => this.selectOption(event));
        }
      });
  }
}
