import { TInventoryStatus, TItemType } from '@shared/type/index.type';
import { EExpirationStatus, EInventoryDeviceType } from '@shared/enum';
import { InventoryModel } from '@shared/models';

export class SimilarInventoryInfo {
  barcode: string;
  billable: boolean;
  catalogId: string;
  chosen?: boolean;
  count?: number;
  custodyId?: string;
  custodyName?: string;
  disabled?: boolean; // Front-end property
  locationId?: string;
  expirationDate?: string;
  expirationStatus: keyof typeof EExpirationStatus;
  expiredDays?: number; // Front-end property
  inventoryDeviceType?: keyof typeof EInventoryDeviceType;
  inventoryType: TItemType;
  lotNumber: string;
  name: string;
  parentCatalogId?: string;
  parentReferenceNumber?: string;
  referenceNumber?: string;
  selectedCount?: number;
  serialNumber: string;
  specificInventories?: string[];
  tags?: string;
  transferParentInventory?: InventoryModel;
  udi: string;
  inOrgCustody?: boolean;
  // Uses at mark used inventory, add bill sheet
  inventoryStatus?: TInventoryStatus;
  //front-end only, used to associate AI parsed items with similar inventory
  frontEndId?: number;
}
