import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { PageableModel, ProcedureModel, ProductLineModel } from '@shared/models';
import { HttpHelperService } from './internal/http-helper.service';
import { ProcedurePageableParams } from '@shared/models/build-models';
import { Observable, of, catchError, map } from 'rxjs';
import { ApiService } from '@shared/classes/api-service';
import { HeaderOptionsWithToken } from '@shared/utils/http/build-http-header-with-token';

@Injectable({
  providedIn: 'root'
})
export class ProcedureService extends ApiService {
  assignProducts(id: string, body: string[]): Observable<void> {
    return this.put<void>(`procedures/${id}/products`, body).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.assignProductLine');
        return of(null);
      })
    );
  }

  deactivate = (procedureId: string): Observable<boolean> =>
    this.put<void>(`procedures/${procedureId}/deactivate`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );

  deleteProductsFromProcedure(procedureId: string, productIds: string[]): Observable<void> {
    return this.delete<void>(`procedures/${procedureId}/products`, HeaderOptionsWithToken(productIds)).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.unAssignProductLine');
        return of(null);
      })
    );
  }

  getProcedure(id: string, _params = {}, redirectToNotFoundPage: boolean = false): Observable<ProcedureModel> {
    return this.get<ProcedureModel>(`procedures/${id}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        HttpHelperService.errorHandler(error, null, redirectToNotFoundPage);
        return of(null);
      })
    );
  }

  getPageable(params: ProcedurePageableParams): Observable<PageableModel<ProcedureModel>> {
    return this.get<PageableModel<ProcedureModel>>(`procedures/pageable`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of(new PageableModel()))
    );
  }

  getPageableByRepresentative(params: ProcedurePageableParams): Observable<PageableModel<ProcedureModel>> {
    return this.get<PageableModel<ProcedureModel>>(
      `procedures/pageable/byRepresentative`,
      HttpHelperService.addResponseTypeJSON(params)
    ).pipe(catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of(new PageableModel())));
  }

  getProducts(id: string): Observable<ProductLineModel[]> {
    return this.get<ProductLineModel[]>(`procedures/${id}/products`);
  }

  createProcedure(body: ProcedureModel): Observable<string> {
    return this.post<string>(`procedures`, body).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  updateProcedure(id: string, body: { name: string; modifiers: string[]; products?: ProductLineModel[]; type: string }): Observable<void> {
    return this.put<void>(`procedures/${id}`, body).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }
}
