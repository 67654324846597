import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { TIcons } from '@shared/type/icons.type';
import { endOfMonth, endOfToday, startOfMonth, startOfToday, startOfYear } from 'date-fns';
import { isDevice } from '@shared/utils/is-device';

@Component({
  selector: 'app-datepicker-kit',
  templateUrl: './datepicker-kit.component.html',
  styleUrls: ['./datepicker-kit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DatepickerKitComponent implements OnInit {
  @Input() initialDate = new UntypedFormControl(startOfMonth(startOfToday()));
  @Input() rangeStartDate = new UntypedFormControl(startOfMonth(startOfToday()));
  @Input() rangeEndDate = new UntypedFormControl(endOfMonth(endOfToday()));
  @Input() minDate = startOfYear(new Date(2018, 0));
  @Input() maxDate = endOfMonth(endOfToday());
  @Input() label: string = '';
  @Input() filterView: boolean = false;
  @Input() withBackground: boolean = false;
  @Input() set disabled(data: boolean) {
    if (data) {
      this.initialDate.disable();
      this.rangeStartDate.disable();
      this.rangeEndDate.disable();
    } else {
      this.initialDate.enable();
      this.rangeStartDate.enable();
      this.rangeEndDate.enable();
    }
  }
  @Input() set initialValue(data: Date | string) {
    this.initialDate.setValue(data);
  }
  @Input() set initialRangeStartValue(data: Date | string) {
    this.rangeStartDate.setValue(data);
  }
  @Input() set initialEndStartValue(data: Date | string) {
    this.rangeEndDate.setValue(data);
  }
  @Input() type: 'single' | 'range' = 'single';
  @Input() icon: TIcons = null;
  @Input() inputDataCy: string = '';
  @Input() togglerDataCy: string = '';
  @Input() includeWholeMonth: boolean = false;
  @Input() displayClearButton: boolean = false;

  @Output() dateEmitter = new EventEmitter<Date>();
  @Output() rangeEmitter = new EventEmitter<{ start: Date; end: Date }>();

  readonly touchDatePicker = isDevice('MOBILE') || isDevice('TABLET');

  ngOnInit(): void {
    this.rangeEmitter.emit({ start: this.rangeStartDate.value, end: this.rangeEndDate.value });
  }

  clear(): void {
    this.rangeStartDate.setValue(null);
    this.rangeEndDate.setValue(null);
    this.rangeEmitter.emit({ start: null, end: null });
  }

  chosenYearHandler(selectedDate: Date, control: UntypedFormControl): void {
    const ctrlValue = control.value;
    ctrlValue.setFullYear(selectedDate.getFullYear());
    control.setValue(ctrlValue);
  }

  chosenMonthHandler(selectedDate: Date, datepicker: MatDatepicker<any>): void {
    if (this.includeWholeMonth) {
      this.initialDate.setValue(endOfMonth(selectedDate));
    } else {
      this.initialDate.setValue(startOfMonth(selectedDate));
    }

    datepicker.close();
    this.dateEmitter.emit(this.initialDate.value);
  }

  rangePickerClosed(): void {
    if (!this.rangeEndDate.value) {
      this.rangeEndDate.setValue(this.rangeStartDate.value);
    }
    this.rangeEmitter.emit({ start: this.rangeStartDate.value, end: this.rangeEndDate.value });
  }
}
