import { TImportStatus, TState } from '@shared/type/index.type';
import { UserModel } from '@shared/models';
import { CreatedDatetime, DateTimeString } from '@shared/models/shared/shared-kernel';

export class InventoryImportJob {
  createdBy: UserModel;
  createdDatetime: CreatedDatetime;
  datetimeEnd: DateTimeString;
  datetimeStart: DateTimeString;
  fileName: string;
  id: string;
  importName: string;
  modifiedBy: UserModel;
  modifiedDatetime: string;
  organizationId: string;
  recordsCount: number | null;
  state: TState;
  status: TImportStatus;
}

export class InventoryImportLogs {
  id: string;
  message: string;
}
