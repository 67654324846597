import { IFilter } from '@shared/interfaces';
import { DefaultQueryParams } from '@shared/helpers/default-query-params';
import { TInventoryRequestStatus } from '@shared/type/index.type';
import { ClearRequest } from '@shared/utils/filter-object-null-properties';

export class RequestPageableSearchParams {
  inventoryRequestStatus?: TInventoryRequestStatus;
  search?: string;
  catalogId?: string;
  page: number;
  size: number;
  sort: string[];
  eventId?: string;
  manufacturerId?: string;
  requesterId?: string;
  organizationRequester?: boolean;
  shipByDateFrom?: string | Date;
  shipByDateTo?: string | Date;
  isRequestClaimed?: boolean;

  constructor(params: IFilter) {
    return RequestPageableSearchParams.build(params);
  }

  private static build(params: IFilter): RequestPageableSearchParams {
    const queryParams: RequestPageableSearchParams = DefaultQueryParams(params);
    queryParams.search = params.search;
    queryParams.inventoryRequestStatus = params.inventoryRequestStatus;
    queryParams.catalogId = params.catalogId;
    queryParams.eventId = params.eventId;
    queryParams.isRequestClaimed = params.isRequestClaimed;

    if (params?.manufacturer && typeof params.manufacturer !== 'boolean') {
      queryParams.manufacturerId = params.manufacturer;
    }
    if (params?.requesterId && params.requesterId !== 'ORGANIZATION') {
      queryParams.requesterId = params.requesterId;
    }
    if (params?.requesterId === 'ORGANIZATION') {
      queryParams.organizationRequester = true;
    }
    if (params?.shipByDateTo) {
      queryParams.shipByDateTo = params.shipByDateTo;
    }
    if (params?.shipByDateFrom) {
      queryParams.shipByDateFrom = params.shipByDateFrom;
    }
    return ClearRequest(queryParams);
  }
}
