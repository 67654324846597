<mat-expansion-panel class="mobileTableExpand" [expanded]="opened" (opened)="openedEmitter.emit(elementId)">
  <mat-expansion-panel-header class="mobileTableExpandHeader" [ngClass]="{ mobilePointer: selectableMode }">
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      [fxLayoutGap]="selectableMode ? '16px' : '0px'"
      class="mobileTableExpandTitleContainer"
    >
      <app-checkbox-kit
        *ngIf="selectableMode"
        [initialValue]="selected"
        (checkEmitter)="navigateDetailPage(); selected = $event"
        (click)="$event.stopPropagation()"
      ></app-checkbox-kit>
      <span class="title" (click)="navigateDetailPage($event)">{{ title }}</span>
      <span *ngIf="status" [ngClass]="statusClass">{{ status }}</span>
      <app-button-kit
        *ngIf="buttonTitle"
        class="mobileTableExpandButton"
        [type]="'secondary'"
        [title]="buttonTitle | language"
        [color]="buttonStyle"
        [icon]="buttonIcon"
        (clickEmitter)="$event.stopPropagation(); buttonClickedEmitter.emit()"
      ></app-button-kit>
    </div>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <ng-content></ng-content>
  </ng-template>
</mat-expansion-panel>
