import { TEventType } from '@shared/type/index.type';
import { EventTemplateBuilder } from 'src/app/features/events/event-detail/helpers/event-template-builder';

export const showTimeHelper = (eventType: TEventType, datetime?: string | Date): boolean => {
  const config = new EventTemplateBuilder(eventType);

  if (config.time) {
    if (datetime) {
      const date = new Date(datetime);
      if (date.getUTCHours() === 12 && date.getUTCMinutes() === 0 && date.getUTCMilliseconds() === 15) {
        return false;
      }
    }
    return true;
  }
  return false;
};

// Correct usage is showTimeHelper with parameter eventType, but in cases eventType is not available, we can use showOnlyDateHelper
// 12h 00m and 15ms in our internal convention to hide event time
export const showOnlyDateHelper = (datetime: string): boolean => {
  const date = new Date(datetime);
  return date.getUTCHours() === 12 && date.getUTCMinutes() === 0 && date.getUTCMilliseconds() === 15;
};
