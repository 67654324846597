import { CatalogModel } from '@shared/models';
import { EventModel } from './event.model';

export class EventQuoteModel {
  approved: boolean;
  catalogQuoteQuantities: EventQuoteCatalogModel[];
  event: EventModel;
  eventQuoteNote: string;
  id: string;
}

export class EventQuoteCatalogModel {
  catalog: CatalogModel;
  id: string;
  quantity: number;
  totalPrice: number;
  unitPrice: number;
}

export class EventQuoteParamsModel {
  catalogQuoteQuantities: {
    catalogId: string;
    quantity: number;
  }[];
  eventId?: string;
  isApproved?: boolean;
  note: string;
}
