import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UsersService } from '@services/users.service';
import { NoteModel } from '@shared/models';
import { ParseUserIds } from '../utils/parse-user-ids';
import { BehaviorSubject } from 'rxjs';
import { GlobalStoreService } from '@services/internal/global-store.service';

@Component({
  selector: 'app-comments-list',
  templateUrl: './comments-list.component.html',
  styleUrls: ['./comments-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CommentsListComponent {
  @Input() notes: NoteModel[] = [];
  @Input() allowEdit: boolean = true;
  @Input() set stopEdit(data: boolean) {
    if (data) {
      this.clear();
    }
  }
  @Input() showCommentMarker: boolean = false;

  @Output() editCommentEmitter = new EventEmitter<{ message: string; commentId: string; userIds: string[]; commentUserIds: string[] }>();
  @Output() editStartedEmitter = new EventEmitter<void>();
  @Output() markCommentEmitter = new EventEmitter<{ noteId: string; note: string; isPdfPrint: boolean }>();

  editedCommentId: string = '';
  editedComment: string = '';
  commentUserIds: string[] = [];
  readonly currentUserId: string = UsersService.getUser().id;
  readonly isMobile$: BehaviorSubject<boolean> = GlobalStoreService.isMobile$;

  constructor(private sanitizer: DomSanitizer) {}

  bypassSecurityTrustHtml(html: string): SafeHtml {
    setTimeout(() => {
      const currentUserMentioned = document.querySelectorAll(`[data-id="${UsersService.getUser().id}"]`);
      if (currentUserMentioned.length) {
        currentUserMentioned.forEach(m => m.classList.add('currentUserMentioned'));
      }
    });
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  saveComment(): void {
    const userIds: string[] = ParseUserIds(this.editedComment);
    const uniqueIds: string[] = [];
    const uniqueEditIds: string[] = [];

    userIds.forEach(id => {
      if (this.commentUserIds.indexOf(id) === -1) {
        uniqueIds.push(id);
      } else {
        uniqueEditIds.push(id);
      }
    });

    this.editCommentEmitter.emit({
      message: this.editedComment,
      commentId: this.editedCommentId,
      userIds: uniqueIds,
      commentUserIds: uniqueEditIds
    });
    this.clear();
  }

  editComment(note: NoteModel, wysiwygClass: string): void {
    this.editedCommentId = note.id ?? note.auditInventoryCommentId;
    this.editStartedEmitter.emit();
    this.commentUserIds = ParseUserIds(note.comment || note.note || note.text);
    setTimeout(() => {
      // @ts-ignore
      document.querySelector(wysiwygClass + ' .ql-container .ql-editor')?.focus();
    }, 50);
  }

  clear(): void {
    this.editedComment = '';
    this.editedCommentId = '';
    this.commentUserIds = [];
  }
}
