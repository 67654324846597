<div fxLayout="row" fxLayoutAlign="space-between center" class="titleContainer">
  <app-title [capitalize]="false" [type]="'h4'" [title]="'shared.labels.processing' | language"></app-title>
</div>

<div fxLayout="column" class="largeModalWindowContent" fxLayoutGap="16px">
  <span
    >Searching for <b class="searchedValue">{{ data.searchValue }}</b
    >...</span
  >

  <span *ngIf="(data.loading | async) === false && (data.matchedInventory | async) === null" class="colorRed"
    >{{ 'shared.components.noMatchFound' | language }}.</span
  >

  <span *ngIf="(data.loading | async) === false && (data.matchedInventory | async) !== null" class="colorGreen">
    <b>{{ (data.matchedInventory | async).name }}</b> {{ 'shared.components.successfullyAdded' | language }}.
  </span>
</div>

<div
  fxLayoutAlign="center"
  class="largeModalWindowButtonsContainer"
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutGap="16px"
  fxLayoutGap.xs="8px"
>
  <app-button-kit
    fxFlex="50%"
    fxFlex.xs="100%"
    [type]="'outline'"
    [color]="'primary'"
    [disabled]="(data.loading | async) === true"
    [title]="'shared.labels.tryAgain' | language"
    [fontWeight]="600"
    [size]="(isMobile$ | async) === true ? 'medium' : 'large'"
    (clickEmitter)="close(false)"
  ></app-button-kit>
  <app-button-kit
    fxFlex="50%"
    fxFlex.xs="100%"
    [type]="'primary'"
    [color]="'success'"
    [textColor]="'white'"
    [disabled]="(data.loading | async) === true"
    [title]="'shared.labels.finish' | language"
    [fontWeight]="600"
    [size]="(isMobile$ | async) === true ? 'medium' : 'large'"
    (clickEmitter)="close(true)"
  ></app-button-kit>
</div>
