import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MatDialog } from '@angular/material/dialog';
import { ICredentials, ISwitchOrganization, IToken } from '@shared/interfaces';
import { BehaviorSubject, firstValueFrom, Observable, of, catchError, finalize, map, take } from 'rxjs';
import { decryptionString, encryptionString } from '@shared/utils/encryption-string';
import { isJson } from '@shared/utils/check-is-json';
import { ApiService } from '@shared/classes/api-service';
import { LocalStorage } from '@services/internal/localstorage.service';
import { localStorageCleaner } from '@shared/utils/local-storage-cleaner';
import { OfflineStatusService } from '@services/offline/offline-status.service';
import { NativeBiometric } from 'capacitor-native-biometric';
import { environment } from '@environment';
import { UsersService } from '@services/users.service';
import { GlobalStoreService } from '@services/internal/global-store.service';
import { PushNotificationsService } from '@services/push-notifications.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Injectable({ providedIn: 'root' })
export class AuthenticationService extends ApiService {
  /** Uses for users who make actions from his sales user, uses organizationId & email works for SALES and DISTRIBUTORS roles only*/
  static isFakeAuth: boolean = false;
  private static encryptedFakeToken: string;
  private static encryptedToken: string;
  private httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      accept: '*/*'
    })
  };
  private isRefreshingToken$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private jwt: JwtHelperService = new JwtHelperService();
  constructor(
    private router: Router,
    private dialogRef: MatDialog,
    private offlineStatusService: OfflineStatusService,
    private pushNotificationsService: PushNotificationsService,
    private injector: Injector,
    private bottomSheet: MatBottomSheet
  ) {
    super();
    LocalStorage.getItem('token').then(token => (AuthenticationService.encryptedToken = token));
  }

  static getToken(): string {
    if (AuthenticationService.isFakeAuth) {
      return JSON.parse(decryptionString(AuthenticationService.encryptedFakeToken))?.accessToken;
    }
    return JSON.parse(decryptionString(AuthenticationService.encryptedToken))?.accessToken;
  }

  static async setToken(token: string): Promise<void> {
    AuthenticationService.encryptedToken = encryptionString(token);
    await LocalStorage.setItem('token', encryptionString(token));
  }

  async isAuthenticated(): Promise<boolean> {
    if (AuthenticationService.isFakeAuth) {
      return Boolean(AuthenticationService.encryptedFakeToken);
    }
    if (this.offlineStatusService.isOffline$.value) {
      return true;
    }

    let token: IToken;
    const t = await LocalStorage.getItem('token');
    const decryptedString = decryptionString(t);

    if (decryptedString) {
      if (isJson(decryptedString)) {
        token = JSON.parse(decryptedString);
      } else {
        this.logout();
        this.alertsService.showError('shared.alerts.errorMessages.authenticationRequired', null, 5000);
      }
    } else {
      return false;
    }

    return new Promise((resolve, reject): Promise<boolean> => {
      if (this.jwt.getTokenExpirationDate(token.accessToken)?.getTime() < new Date().getTime() + 180000) {
        if (this.isRefreshingToken$.value) {
          return this.waitRefreshingToken().then((): any => {
            resolve(true);
            return true;
          });
        } else {
          return this.refreshToken()
            .then(() => {
              resolve(true);
              return true;
            })
            .catch(() => {
              reject(false);
              return false;
            });
        }
      } else {
        resolve(true);
        return Promise.resolve(true);
      }
    });
  }

  login(credentials: ICredentials): Observable<IToken> {
    return this.postAuth<IToken>(`token`, credentials, this.httpOptions).pipe(
      map((tokens: IToken) => {
        AuthenticationService.setToken(JSON.stringify(tokens));
        AuthenticationService.isFakeAuth = false;
        this.alertsService.dismiss();
        return tokens;
      })
    );
  }

  /** Uses to create an auth based on email only for user who do not have assets in to the system, uses at CreateInventoryRequestComponent*/
  getInnerToken(username: string, organizationId: string): Observable<IToken> {
    return this.postAuth<IToken>(`inner/token`, { username, organizationId }, this.httpOptions).pipe(
      map((tokens: IToken) => {
        AuthenticationService.isFakeAuth = true;
        AuthenticationService.encryptedFakeToken = encryptionString(JSON.stringify(tokens));
        return tokens;
      })
    );
  }

  logout(returnUrl?: string): void {
    if (GlobalStoreService.getPlatform() !== 'web') {
      if (
        this.offlineStatusService.isOffline$.value ||
        this.offlineStatusService.preparingOffline$.value ||
        this.offlineStatusService.syncing$.value ||
        !this.offlineStatusService.isNetworkAvailable$.value
      ) {
        return;
      }
      // Remove FaceId,TouchId credentials
      NativeBiometric.deleteCredentials({ server: environment.frontUrl });
      LocalStorage.setItem('skipFaceIdAuth', 'true');
      this.pushNotificationsService.unSubscribe().pipe(take(1)).subscribe();
    }
    // Timeout needs to process autoSaving forms and send requests to save data before token be deleted
    setTimeout(() => {
      localStorageCleaner();
      AuthenticationService.isFakeAuth = false;
      AuthenticationService.encryptedFakeToken = null;
      this.dialogRef.closeAll();
      this.bottomSheet.dismiss();

      if (
        location.href.includes('claim') ||
        location.href.includes('reset') ||
        location.href.includes('forgot') ||
        location.href.includes('create-request') ||
        location.href.includes('ponumber') ||
        this.offlineStatusService.isOffline$.value
      ) {
        return;
      }
      // Remove items from Local Storage
      localStorage.removeItem('userId');
      // Remove items from Capacitor Storage
      LocalStorage.removeItem('token');
      LocalStorage.removeItem('dashboard');

      this.router.navigate(['/login'], { queryParams: { returnUrl } });
    }, 300);
  }

  async refreshToken(): Promise<boolean> {
    this.isRefreshingToken$.next(true);
    const t = await LocalStorage.getItem('token');

    const parsedToken: IToken = JSON.parse(decryptionString(t));
    const userId: string = this.jwt.decodeToken(parsedToken?.accessToken)?.sub;
    const organizationId: string = this.jwt.decodeToken(parsedToken?.accessToken)?.organizationId;
    const token = {
      userId,
      refreshToken: parsedToken?.refreshToken,
      organizationId
    };

    return firstValueFrom(
      this.postAuth<IToken>(`refresh`, token, this.httpOptions).pipe(
        map((tokens: IToken): boolean => {
          if (tokens?.accessToken) {
            AuthenticationService.setToken(JSON.stringify(tokens));
            const usersService = this.injector.get(UsersService);
            usersService.me().pipe(take(1)).subscribe();
            return true;
          } else {
            this.alertsService.showError('shared.alerts.errorMessages.reAuthenticationError', null, 3000);
            this.logout();
            return false;
          }
        }),
        catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
          this.alertsService.showError('shared.alerts.errorMessages.reAuthenticationError', null, 3000);
          this.logout();
          return of(error);
        }),
        finalize(() => this.isRefreshingToken$.next(false))
      )
    );
  }

  switchOrganization(orgSwitchTokens: ISwitchOrganization): Observable<boolean> {
    return this.postAuth<IToken>(`switchOrganization`, orgSwitchTokens, this.httpOptions).pipe(
      map(async (tokens: IToken) => {
        await AuthenticationService.setToken(JSON.stringify(tokens));
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  private async waitRefreshingToken(): Promise<boolean> {
    return await new Promise((req, _rej) => {
      const refreshWatcher = setInterval(() => {
        if (!this.isRefreshingToken$.value) {
          clearInterval(refreshWatcher);
          req(true);
        }
      }, 500);
    });
  }
}
