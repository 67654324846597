import { UntypedFormGroup } from '@angular/forms';
import { Directive, HostListener, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from '@shared/components/modals/confirm/confirm.component';
import { ConfirmModalConfig } from '@constants';
import { firstValueFrom } from 'rxjs';
import { LanguageService } from '@services/internal/language.service';
import { DestroySubscriptions } from '@shared/classes/destroy-subscriptions';

@Directive()
export abstract class CanAutoSaveDirective extends DestroySubscriptions {
  @HostListener('window:unload', ['$event'])
  unloadNotification(event: any) {
    if (!this.canDeactivate()) {
      event.returnValue = true;
    }
  }

  protected dialogService: MatDialog = inject(MatDialog);

  async canDeactivate(): Promise<boolean> {
    let declineRouterChange: boolean = false;
    let pristine: boolean = true;
    let valid: boolean = true;

    this.autoSaveFormsCheckValid().forEach((form: UntypedFormGroup) => {
      if (form.pristine === false) {
        pristine = false;
      }

      if (form.status === 'INVALID') {
        valid = false;
      }
    });
    if (valid && !pristine) {
      const dialogRef = this.dialogService.open(
        ConfirmComponent,
        ConfirmModalConfig({
          title: LanguageService.instant('confirmation.unsavedChanges'),
          description: LanguageService.instant('confirmation.leaveWithValidChanges'),
          acceptButtonText: LanguageService.instant('confirmation.saveAndLeave'),
          declineButtonText: LanguageService.instant('confirmation.discardChanges'),
          declineButtonStyle: 'warn',
          showCloseButton: true
        })
      );

      const result: boolean = await firstValueFrom(dialogRef.afterClosed());

      if (result) {
        this.autoSave();
      } else if (result === null) {
        declineRouterChange = true;
      }
    } else if (!valid && !pristine) {
      const dialogRef = this.dialogService.open(
        ConfirmComponent,
        ConfirmModalConfig({
          title: LanguageService.instant('confirmation.unsavedChanges'),
          description: LanguageService.instant('confirmation.leaveWithInvalidChanges'),
          acceptButtonText: LanguageService.instant('confirmation.discardChanges'),
          declineButtonText: LanguageService.instant('confirmation.continueEditing')
        })
      );

      const result: boolean = await firstValueFrom(dialogRef.afterClosed());

      if (!result) {
        declineRouterChange = true;
      } else {
        return true;
      }
    }

    return !(!valid && !pristine) && !declineRouterChange;
  }
  abstract autoSave(): void;
  abstract autoSaveFormsCheckValid(): UntypedFormGroup[];
}
