import { PageableModel, UserModel } from '@shared/models';
import { RolesStatistics } from '@shared/models/features/users/roles-statistics';
import { IFilter } from '@shared/interfaces';
import { TRoles } from '@shared/type/index.type';
import { UsersService } from '@services/users.service';

export class UsersFilterParams implements IFilter {
  search: '';
  roles: [];
  state: 'ACTIVE';
  isCustodianOnly: null;
  selectedMatSortHeaderDirection: 'desc';
  selectedMatSortHeaderActive;
  size;
  constructor() {
    this.search = '';
    this.roles = [];
    this.state = 'ACTIVE';
    this.isCustodianOnly = null;
    this.selectedMatSortHeaderDirection = 'desc';
    this.selectedMatSortHeaderActive = 'modifiedDatetime';
    this.size = UsersService.defaultPagination.value;
  }
}

export class UsersStore {
  rolesStatistics: RolesStatistics;
  roles: { role: TRoles; name: string }[];
  usersPageable: PageableModel<UserModel>;
  loadingUsers: boolean;
  loadingDistributorGroup: boolean;
  constructor() {
    this.rolesStatistics = new RolesStatistics();
    this.roles = [];
    this.usersPageable = new PageableModel();
  }
}
