<div
  *ngIf="totalPagesNumber > 1 && (isMobile$ | async) === false"
  class="connectSxCustomPagination"
  fxLayout="row"
  fxLayoutGap="8px"
  fxLayoutAlign="start center"
  [ngClass]="{ disabled: disabled }"
>
  <span class="paginationLabel" [ngClass]="{ disabled: currentPageNumber < 1 }" (click)="pageChanged(currentPageNumber)">
    <app-icon [type]="'outline'" [size]="20" [color]="'primary'" [icon]="'arrow_left_filled'" />
  </span>
  <div class="paginationPageContainer" fxLayout="row" fxLayoutGap="16px">
    @for (page of pagesArray; track page) {
      <span
        data-cy="paginationPage"
        class="paginationPage"
        fxLayoutAlign="center center"
        [ngClass]="{
          activePage: page === currentPageNumber + 1,
          disabled: disablePaginationItem(page)
        }"
        (click)="pageChanged(page)"
      >
        {{ page }}
      </span>
    }
  </div>
  <span
    class="paginationLabel"
    [ngClass]="{ disabled: currentPageNumber >= totalPagesNumber - 1 }"
    (click)="pageChanged(currentPageNumber + 2)"
  >
    <app-icon [type]="'outline'" [size]="20" [color]="'primary'" [icon]="'arrow_right_filled'" />
  </span>
</div>

<ng-container *ngIf="(isMobile$ | async) === true">
  <app-button-kit
    *ngIf="currentPageNumber < totalPagesNumber - 1 && (isOffline$ | async) === false"
    [color]="'primary'"
    [title]="'shared.labels.loadMore' | language"
    [size]="'large'"
    (clickEmitter)="pageChanged(currentPageNumber + 2)"
  />
  <p *ngIf="totalElements !== null" class="mobileResults">
    <ng-container *ngIf="(isOffline$ | async) === false; else offlinePagination">
      {{ 'shared.labels.results' | language }}: 1-
      {{
        (defaultMobilePagination$ | async) * (currentPageNumber + 1) > totalElements
          ? totalElements
          : (defaultMobilePagination$ | async) * (currentPageNumber + 1)
      }}
      {{ 'shared.labels.of' | language }}
      {{ totalElements }}
    </ng-container>
    <ng-template #offlinePagination> {{ totalElements }} {{ 'offline.paginationItems' | language }} </ng-template>
  </p>
</ng-container>
