import { MatTableDataSource } from '@angular/material/table';
import { DestroySubscriptions } from '@shared/classes/destroy-subscriptions';
import { PageableModel } from '@shared/models';
import { BehaviorSubject, pairwise } from 'rxjs';
import { GlobalStoreService } from '@services/internal/global-store.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export abstract class ResponsiveTableLayout<T> extends DestroySubscriptions {
  isMobile$: BehaviorSubject<boolean> = GlobalStoreService.isMobile$;
  dataSource: MatTableDataSource<T> = new MatTableDataSource<T>();
  totalElements: number = 0;
  totalPages: number = 0;
  pageNumber: number = 0;
  size: number = 20;
  refreshData: boolean = true;
  openedElementId: string = '';

  constructor() {
    super();

    this.isMobile$.pipe(pairwise(), takeUntilDestroyed()).subscribe((values: boolean[]) => {
      if (values[0] !== values[1]) {
        const data: PageableModel<T> = this.getTableData();
        this.dataSource = new MatTableDataSource();
        this.setData(data);
      }
    });
  }

  abstract setData(data: PageableModel<T>): void;

  private getTableData(): PageableModel<T> {
    const mockPageable: PageableModel<T> = new PageableModel<T>();
    mockPageable.size = this.size;
    mockPageable.totalElements = this.totalElements;
    mockPageable.totalPages = this.totalPages;
    mockPageable.pageable.pageNumber = this.pageNumber;
    mockPageable.content = [...this.dataSource.data];
    return mockPageable;
  }
}
