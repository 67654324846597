import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-checkbox-kit',
  templateUrl: './checkbox-kit.component.html',
  styleUrls: ['./checkbox-kit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CheckboxKitComponent {
  @Input() title: string = '';
  @Input() disabled: boolean = false;
  @Input() indeterminate: boolean = false;
  @Input() dataCy: string = '';
  @Input() set triggerImmediateSelect(data: boolean) {
    if (data) {
      this.checkEmitter.emit(this.value);
    }
  }
  @Input() set initialValue(data: boolean | string) {
    if (typeof data === 'string') {
      this.value = data === 'true';
    } else {
      this.value = data;
    }
  }
  @Input() forceFalseValue: boolean = false;
  @Input() wrapTitle: boolean = false;

  @Output() checkEmitter = new EventEmitter<boolean>();

  value: boolean = false;

  checkForcedValue(event: MatCheckboxChange): void {
    if (this.indeterminate) {
      this.value = false;
      event.source.checked = false;
    }

    setTimeout(() => {
      if (this.forceFalseValue) {
        this.value = false;
        event.source.checked = false;
      }
    });
  }
}
