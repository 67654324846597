<app-input-kit
  [initialValue]="initialValue"
  [required]="required"
  [label]="label | language"
  [dataCy]="'eventInput'"
  [debounceEvents]="['keyup', 'focus']"
  [debounceTime]="1000"
  [disabled]="disabled"
  [forceBlurEvent]="forceBlurEvent"
  [filterView]="filterView"
  [icons]="[
    showClearIcon && !disabled ? 'close' : null,
    (initialValue?.id?.length || initialValue?.id) && showOpenInNewIcon ? 'link' : null
  ]"
  [autocompleteName]="eventAutocomplete"
  [type]="inputType"
  [linkDetailPage]="'/events/edit/' + (initialValue?.id?.length ? initialValue?.id : initialValue?.id)"
  (debounceTimeEndedEmitter)="searchEvents($event)"
  (iconClickEmitter)="doIconAction($event)"
></app-input-kit>

<mat-autocomplete #eventAutocomplete="matAutocomplete" [displayWith]="displayFn">
  <mat-option *ngIf="(loading$ | async) === true || (initialLoading$ | async) === true" class="is-loading" disabled>
    <mat-progress-spinner value="30" class="spinner" mode="indeterminate" diameter="30"></mat-progress-spinner>
  </mat-option>
  <ng-container *ngIf="searchedData$ | async as searchedData">
    <mat-option *ngIf="!searchedData.length; else options" disabled class="connectSxAutocompleteOption">
      <span>{{ 'shared.labels.noItemsFound' | language }}</span>
    </mat-option>
    <ng-template #options>
      <cdk-virtual-scroll-viewport
        *ngIf="(loading$ | async) === false && (initialLoading$ | async) === false"
        itemSize="0"
        class="virtualAutocompleteScroll"
        [style.height.px]="searchedData.length < 3 ? searchedData.length * 50 : 150"
      >
        <mat-option
          *cdkVirtualFor="let event of searchedData"
          data-cy="eventOption"
          class="connectSxAutocompleteOption"
          [value]="event"
          (click)="selectOption(event)"
        >
          <p>{{ event.name }}</p>
        </mat-option>
      </cdk-virtual-scroll-viewport>
    </ng-template>
  </ng-container>
  <mat-option
    *ngIf="
      (loading$ | async) === false &&
      (initialLoading$ | async) === false &&
      showAddNew &&
      permissionService.isGranted('events', 'createEvent')
    "
    data-cy="addNewOption"
    class="connectSxAutocompleteOption addNewOption"
    (click)="createEvent()"
  >
    <app-icon [type]="'outline'" [size]="20" [color]="'primary'" [icon]="'plus'"></app-icon>
    <span class="addNewLabel">{{ 'shared.labels.addNew' | language }}</span>
  </mat-option>
</mat-autocomplete>
