<app-default-modal
  [size]="'small'"
  [title]="data?.title ?? 'events.detail.sendAsAttachmentsLabel' | language"
  [disableSubmitButton]="!subject || !email || !emailPattern.test(email) || !replyTo || !emailPattern.test(replyTo) || !body"
  [buttonTitle]="'events.detail.sendLabel' | language"
  [scrollableContent]="true"
  (clickEmitter)="send()"
>
  <div fxLayout="column" fxLayoutGap="16px" fxFlex="100%">
    <app-input-kit [required]="true" [label]="'Subject'" [initialValue]="subject" (valueChangedEmitter)="subject = $event"></app-input-kit>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="16px" fxFlex.xs="none" fxFlex="100%">
      <app-input-kit
        fxFlex="50%"
        fxFlex.xs="100%"
        [required]="true"
        [label]="'events.detail.toEmailLabel' | language"
        [pattern]="emailPattern"
        [initialValue]="email"
        (valueChangedEmitter)="email = $event"
      ></app-input-kit>

      <app-select-kit
        *ngIf="data?.event.facility"
        fxFlex="50%"
        fxFlex.xs="100%"
        class="selectContainer"
        [label]="'events.detail.selectFromFacilityLabel' | language"
        [disabled]="noEmailOption"
        [overflowHidden]="true"
        [resetValueAfterSelect]="true"
        [parseKeyByProperty]="'email'"
        [parseValueByProperty]="'email'"
        [showNoneOption]="noEmailOption"
        [noneOptionLabel]="'events.detail.noEmailsOption' | language"
        [selectData]="contacts"
        [customOptionTemplate]="customOptionTemplate"
        (selectEmitter)="email = $event"
      >
      </app-select-kit>

      <ng-template #customOptionTemplate let-item>
        <div fxLayout="column">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
            <app-icon *ngIf="item.isDefault" [size]="16" [icon]="'star'" [type]="'filled'" [color]="'primary'" />
            <span class="defaultBodyText">{{ getContactTypeName(item) }}</span>
          </div>
          <span class="caption">{{ item.email }}</span>
        </div>
      </ng-template>
    </div>

    <app-input-with-chips-kit
      [selectedChips]="CCEmails"
      [label]="'CC (email)'"
      [showAddChipButton]="true"
      [removable]="true"
      [addOnBlur]="true"
      [parseChipByProperty]="'email'"
      [separatorKeysCodes]="separatorKeysCodes"
      (chipsRemovedEmitter)="removeCCEmail($event)"
      (chipInputEndEmitter)="selectCCEmail($event?.input?.value)"
    ></app-input-with-chips-kit>

    <app-input-kit
      [required]="true"
      [label]="'events.detail.replyToEmailLabel' | language"
      [pattern]="emailPattern"
      [initialValue]="replyTo"
      (valueChangedEmitter)="replyTo = $event"
    ></app-input-kit>

    <app-textarea-kit
      [required]="true"
      [label]="'events.detail.messageBodyLabel' | language"
      [initialValue]="body"
      (valueChangedEmitter)="body = $event"
    ></app-textarea-kit>

    <small>
      <i>link to PDF file</i><br /><br />
      If you have any questions, please feel free to contact us. <br /><br />
      Thank you
    </small>
  </div>
</app-default-modal>
