/**
 * By default, enums sort in alphabetical order, but if it needs to change default order, use this function
 *
 * @param enm Enum
 * @constructor
 */
export const SortEnumsOriginalOrder = (enm: Record<string, string>): { key: string; value: string }[] => {
  const sorted: { key: string; value: string }[] = Object.keys(enm).map((k: string) => ({
    key: k,
    value: enm[k]
  }));
  return sorted;
};
