import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductsStoreService {
  modifiers$: BehaviorSubject<{ modifierName: string }[]> = new BehaviorSubject<{ modifierName: string }[]>([]);

  selectModifier(value: string = ''): void {
    const modifier: string = value.trim();
    this.modifiers$.value.push({ modifierName: modifier });
  }

  removeModifier(item: { modifierName: string }): void {
    const index: number = this.modifiers$.value.indexOf(item);

    if (index >= 0) {
      this.modifiers$.value.splice(index, 1);
    }
  }
}
