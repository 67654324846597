export const GetStatisticLink = (message: string): string => {
  if (message.includes('TOTAL_SALES_V2')) {
    return '/statistics/total-sales';
  } else if (message.includes('CUSTOM_SALES_V2')) {
    return '/statistics/custom-sales';
  } else if (message.includes('CASE_REPORTS_V2')) {
    return '/statistics/case';
  } else if (message.includes('FACILITY_PAR')) {
    return '/statistics/par-levels';
  } else if (message.includes('CONTAINER_TURN')) {
    return '/statistics/container-turn';
  } else if (message.includes('TRANSFER')) {
    return '/statistics/transfer';
  } else {
    return '/statistics';
  }
};
