import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { AlertsService } from '@services/internal/alerts.service';

export class ApiService {
  protected httpClient: HttpClient = inject(HttpClient);
  protected alertsService: AlertsService = inject(AlertsService);
  protected readonly apiUrlCore: string = environment.apiUrlCore;
  protected readonly apiUrlAuth: string = environment.apiUrlAuth;
  protected readonly apiUrlGudid: string = environment.apiUrlGudid;
  protected readonly apiUrlJob: string = environment.apiUrlJob;
  protected readonly apiUrlAI: string = environment.apiUrlAI;
  protected readonly apiUrlUDIdentify: string = environment.apiUrlUDIdentify;

  // Core service
  get<T>(path: string, options: { [param: string]: any } = {}): Observable<T> {
    return this.httpClient.get<T>(`${this.apiUrlCore}${path}`, options);
  }

  post<T>(path: string, body: any, options: { [param: string]: any } = {}): Observable<T> {
    return this.httpClient.post<T>(`${this.apiUrlCore}${path}`, body, options);
  }

  patch<T>(path: string, body: any, options: { [param: string]: any } = {}): Observable<T> {
    return this.httpClient.patch<T>(`${this.apiUrlCore}${path}`, body, options);
  }

  put<T>(path: string, body: any, options: { [param: string]: any } = {}): Observable<T> {
    return this.httpClient.put<T>(`${this.apiUrlCore}${path}`, body, options);
  }

  delete<T>(path: string, options: { [param: string]: any } = {}): Observable<T> {
    return this.httpClient.delete<T>(`${this.apiUrlCore}${path}`, options);
  }

  // Auth service
  postAuth<T>(path: string, body: any, options: { [param: string]: any } = {}): Observable<T> {
    return this.httpClient.post<T>(`${this.apiUrlAuth}${path}`, body, options);
  }

  getAuth<T>(path: string, options: { [param: string]: any } = {}): Observable<T> {
    return this.httpClient.get<T>(`${this.apiUrlAuth}${path}`, options);
  }

  // Job service
  postJob<T>(path: string, body: any, options: { [param: string]: any } = {}): Observable<T> {
    return this.httpClient.post<T>(`${this.apiUrlJob}${path}`, body, options);
  }

  // Gudid service
  getGudid<T>(path: string, options: { [param: string]: any } = {}): Observable<T> {
    return this.httpClient.get<T>(`${this.apiUrlGudid}${path}`, options);
  }

  // AI service
  getAI<T>(path: string, options: { [param: string]: any } = {}): Observable<T> {
    return this.httpClient.get<T>(`${this.apiUrlAI}${path}`, options);
  }

  postAi<T>(path: string, body: any, options: { [param: string]: any } = {}): Observable<T> {
    return this.httpClient.post<T>(`${this.apiUrlAI}${path}`, body, options);
  }

  // UDIdentify service
  getUDIdentify<T>(path: string, options: { [param: string]: any } = {}): Observable<T> {
    return this.httpClient.get<T>(`${this.apiUrlUDIdentify}${path}`, options);
  }

  postUDIdentify<T>(path: string, body: any, options: { [param: string]: any } = {}): Observable<T> {
    return this.httpClient.post<T>(`${this.apiUrlUDIdentify}${path}`, body, options);
  }

  putUDIdentify<T>(path: string, body: any, options: { [param: string]: any } = {}): Observable<T> {
    return this.httpClient.put<T>(`${this.apiUrlUDIdentify}${path}`, body, options);
  }

  deleteUDIdentify<T>(path: string, options: { [param: string]: any } = {}): Observable<T> {
    return this.httpClient.delete<T>(`${this.apiUrlUDIdentify}${path}`, options);
  }
}
