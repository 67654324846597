import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { filter, lastValueFrom, Observable, pairwise, takeUntil, catchError, from } from 'rxjs';
import { AuthenticationService } from '@services/auth.service';
import { GlobalErrorHandlerService } from '@services/internal/global-error-handler.service';
import { Router, RoutesRecognized } from '@angular/router';
import { HttpCancelService } from '@services/internal/http-cancel.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  static previousUrl: string = null;
  constructor(
    private authenticationService: AuthenticationService,
    private globalErrorHandlerService: GlobalErrorHandlerService,
    private httpCancelService: HttpCancelService,
    private router: Router
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: any[]) => {
        AuthInterceptor.previousUrl = events[0]?.urlAfterRedirects;
        // Cancel pending requests
        if (AuthInterceptor.detectPagePathWasChanged(events[0]?.urlAfterRedirects, events[1]?.urlAfterRedirects)) {
          this.httpCancelService.cancelPendingRequests();
        }
      });
  }

  /**
   * Ignore page query params change
   *
   * @return TRUE to cancel pending requests
   */
  private static detectPagePathWasChanged(startUrl: string = '', redirectUrl: string = ''): boolean {
    const startPath: string = startUrl.split('?')?.length > 1 ? startUrl.split('?')[0] : null;
    const redirectPath: string = redirectUrl.split('?')?.length > 1 ? redirectUrl.split('?')[0] : null;

    /** Case when user clicks clear filter*/
    if (startUrl?.includes(redirectUrl)) {
      return false;
    }
    if (!startUrl || !redirectUrl) {
      return true;
    }

    return startPath !== null && startPath !== redirectPath;
  }

  private static createHeaders(_req: HttpRequest<any>, token: string): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleHeaders(req, next));
  }

  private handleError(req: HttpRequest<any>, next: HttpHandler): any {
    return next
      .handle(req)
      .pipe(takeUntil(this.httpCancelService.onCancelPendingRequests(req)))
      .pipe(catchError((error: any) => this.globalErrorHandlerService.handleError(error, req)));
  }

  private async handleHeaders(req: HttpRequest<any>, next: HttpHandler): Promise<any> {
    if (req.url.includes('auth/refresh') || req.url.includes('auth/token')) {
      return lastValueFrom(this.handleError(req, next));
    }
    const auth: boolean = await this.authenticationService.isAuthenticated();
    if (auth) {
      const token: string = AuthenticationService.getToken();
      // Do not add headers to jsonp requests, it throws an error if there are a header
      if (token && !req.url.includes('maps.googleapis.com')) {
        const header: HttpHeaders = AuthInterceptor.createHeaders(req, token);
        // clone http to custom request and send it to the server
        const AuthRequest = req.clone({ headers: header });
        return lastValueFrom(this.handleError(AuthRequest, next));
      } else {
        return lastValueFrom(this.handleError(req, next));
      }
    } else {
      return lastValueFrom(this.handleError(req, next));
    }
  }
}
