<div
  [attr.data-cy]="dataCy"
  class="expandedSectionContainer"
  fxLayoutGap="8px"
  [ngClass]="{ expandedSectionItem: expanded }"
  (click)="expandEmitter.emit(!expanded)"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
    <div *ngIf="icon" fxHide.xs class="expandedSectionIconContainer" fxLayoutAlign="center center">
      <app-icon [type]="'outline'" [icon]="icon" [size]="24"></app-icon>
    </div>

    <div fxLayout="column" fxLayoutGap="2px">
      <span class="expandedSectionTitle">{{ title }}</span>
      <span *ngIf="description" class="caption">{{ description }}</span>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
    <span *ngIf="chipText" [ngClass]="chipClass + ' large'">{{ chipText }}</span>

    <app-icon [type]="'outline'" [icon]="expanded ? 'arrow_up_filled' : 'arrow_down_filled'" [size]="24"></app-icon>
  </div>
</div>
