<app-create-entity-modal
  [title]="'locations.createLocationTitle' | language"
  [showCreateButton]="true"
  [size]="'small'"
  [disableCreateButton]="!name.length || !locations || (loading$ | async) === true"
  [confirmationRequired]="true"
  [showCloseConfirmation]="!!name || touched"
  (createEmitter)="create()"
>
  <div class="full-width" fxLayoutGap="24px" fxLayout="column">
    <div fxLayout="column" fxLayoutGap="16px" class="modalViewContainer">
      <app-input-kit
        [dataCy]="'createLocationNameInput'"
        [initialValue]="name"
        [label]="'locations.locationNameInputLabel' | language"
        [required]="true"
        (valueChangedEmitter)="name = $event"
      ></app-input-kit>
      <div ngClass.xs="full-width" fxLayoutGap="8px" fxLayout="column" fxLayoutAlign="start start">
        <div
          fxLayout="column"
          fxLayoutAlign="start start"
          class="googleAutocompleteContainer required full-width"
          [ngClass]="{ invalid: !locations && touched, focused: searchInput.focused }"
        >
          <span class="googleAutocompleteLabel">{{ 'locations.searchLocationInputLabel' | language }}</span>
          <input
            data-cy="createLocationPlaceInput"
            matInput
            class="googleAutocompleteInput"
            required
            autocomplete="off"
            (blur)="touched = true"
            #locationInput
            #searchInput="matInput"
            (keyup)="locationInput.value?.length === 0 ? (locations = null) : {}"
            [value]="locations?.locationName"
          />
        </div>

        <app-checkbox-kit
          class="currentLocationCheckbox"
          [dataCy]="'useCurrentLocation'"
          [initialValue]="useCurrentLocation"
          [disabled]="disableCheckbox"
          [title]="'locations.filterByCurrentLocationLabel' | language"
          (checkEmitter)="useCurrentLocation = $event; changeAutocompleteOptions()"
        ></app-checkbox-kit>
      </div>
    </div>
  </div>
</app-create-entity-modal>
