import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ImageModel, InventoryModel, NoteModel, PageableModel, TrackingModel, TransferModel } from '@shared/models';
import { HttpHelperService } from './internal/http-helper.service';
import { AuthenticationService } from './auth.service';
import { CreateTransferModel } from '@shared/models/request-models';
import { InventoryPageableParams, TransferPageableParams } from '@shared/models/build-models';
import { IDefaultParams, ITrackingNumber } from '@shared/interfaces';
import { catchError, map, Observable, of } from 'rxjs';
import { ApiService } from '@shared/classes/api-service';
import { environment } from '@environment';
import { ITrack } from '@shared/interfaces/tracking';
import { TItemType, TTransferShipmentType } from '@shared/type/index.type';
import { TransferFacilityNotificationDto } from '@shared/interfaces/transfers/transfer-facility-notification-dto';
import { ITransferAdditionalFields } from '@shared/interfaces/transfers/transfer-additional-fields-dto';
import { IAuditTransferDto } from '@shared/interfaces/transfers/audit-transfer-dto';

@Injectable({
  providedIn: 'root'
})
export class TransfersService extends ApiService {
  setShippingAddressId(transferId: string, id: string): Observable<TransferModel> {
    return this.post<TransferModel>(`transfers/${transferId}/shippingAddress`, `"${id}"`).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.saved')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  addAttachmentFile(id: string, formData: FormData): Promise<void> {
    const token: string = AuthenticationService.getToken();
    return window
      .fetch(`${environment.apiUrlCore}transfers/${id}/attachment`, {
        method: 'PUT',
        body: formData,
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(
        res => {
          if (res.status >= 400 && res.status < 600) {
            throw new Error('Error upload file');
          }
          Promise.resolve(res).then();
        },
        err => {
          Promise.reject(Error(err)).then();
          throw new Error(err);
        }
      );
  }

  addAttachmentUrl(id: string, attachmentUrl: string): Observable<void> {
    return this.put<void>(`transfers/${id}/attachment/url`, attachmentUrl);
  }

  addComment(id: string, message: string, userIds: string[]): Observable<boolean> {
    return this.post<string>(`transfers/${id}/comments?mentionUserIds=${userIds}`, message).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  editComment(id: string, commentId: string, message: string, userIds: string[], commentUserIds: string[]): Observable<boolean> {
    return this.put<string>(
      `transfers/${id}/comment/${commentId}?newMentionUserIds=${userIds}&existMentionUserIds=${commentUserIds}`,
      message
    ).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  addImageFile(id: string, formData: FormData, isDeletable: boolean = true): Promise<void> {
    const token: string = AuthenticationService.getToken();
    return window
      .fetch(`${environment.apiUrlCore}transfers/${id}/image?isDeletable=${isDeletable}`, {
        method: 'PUT',
        body: formData,
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(
        res => {
          if (res.status >= 400 && res.status < 600) {
            throw new Error('Error upload file');
          }
          Promise.resolve(res).then();
        },
        err => {
          Promise.reject(Error(err)).then();
          throw new Error(err);
        }
      );
  }

  addImageUrl(id: string, url: string, isDeletable: boolean = true): Observable<void> {
    return this.put<void>(`transfers/${id}/image/url?isDeletable=${isDeletable}`, url);
  }

  create(body: CreateTransferModel): Observable<string> {
    return this.post<string>(`transfers`, body).pipe(
      map(id => {
        this.alertsService.showSuccess('shared.alerts.successMessages.created');
        return id;
      }),
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.createTransfer');
        return of(null);
      })
    );
  }

  modifyTransfer(id: string, body: CreateTransferModel, showSuccessMessage: boolean = true): Observable<boolean> {
    return this.put<void>(`transfers/${id}/addInventoriesToPendingTransfer`, body).pipe(
      map(() => {
        if (showSuccessMessage) {
          this.alertsService.showSuccess('shared.alerts.successMessages.itemsAdded', null, 10000);
        }
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  deleteAttachment(id: string, transferAttachmentId: string): Observable<void> {
    return this.delete(`transfers/${id}/attachment?transferAttachmentId=${transferAttachmentId}`).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.fileWasDeleted')),
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.deleteAttachment');
        return of(null);
      })
    );
  }

  deleteImage(id: string, transferImageId: string): Observable<void> {
    return this.delete(`transfers/${id}/image?transferImageId=${transferImageId}`).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.imageWasDeleted')),
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.deleteImage');
        return of(null);
      })
    );
  }

  exportPackingSlip(id: string, type: TItemType, isUseQRCodes: boolean, isUsePartialInvRequestAssignment: boolean): Observable<string> {
    return this.post<string>(
      `transfers/${id}/pdf?type=${type}&isUseQRCodes=${isUseQRCodes}&isUsePartialInvRequestAssignment=${isUsePartialInvRequestAssignment}`,
      {},
      HttpHelperService.addResponseTypeText({})
    ).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getTransfer(id: string, redirectToNotFoundPage: boolean = false): Observable<TransferModel> {
    return this.get<TransferModel>(`transfers/${id}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        HttpHelperService.errorHandler(error, null, redirectToNotFoundPage);
        return of(null);
      })
    );
  }

  getAttachments(id: string): Observable<ImageModel[]> {
    return this.get<ImageModel[]>(`transfers/${id}/attachments`).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.loadAttachments');
        return of([]);
      })
    );
  }

  getAvailableContainers(queryParams: InventoryPageableParams): Observable<PageableModel<InventoryModel>> {
    return this.get<PageableModel<InventoryModel>>(`transfers/containers`, HttpHelperService.addResponseTypeJSON(queryParams));
  }

  getComments(id: string): Observable<NoteModel[]> {
    return this.get<NoteModel[]>(`transfers/${id}/comments`).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.loadNotes');
        return of([]);
      })
    );
  }

  getImages(id: string): Observable<ImageModel[]> {
    return this.get<ImageModel[]>(`transfers/${id}/images`).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.loadImages');
        return of([]);
      })
    );
  }

  getInventories(id: string, params: IDefaultParams | InventoryPageableParams): Observable<PageableModel<InventoryModel>> {
    return this.get<PageableModel<InventoryModel>>(`transfers/${id}/inventories`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.loadInventoriesInTransfer');
        return of(null);
      })
    );
  }

  getPageable(params: TransferPageableParams): Observable<PageableModel<TransferModel>> {
    return this.get<PageableModel<TransferModel>>(`transfers`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of(new PageableModel()))
    );
  }

  getTrackInfo(transferTrackingDTO: TrackingModel): Observable<ITrack> {
    const param: string = HttpHelperService.getQueryParams(transferTrackingDTO);
    return this.get<ITrack>(`transfers/trackInfo?${param}`).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of(null))
    );
  }

  releaseItem(id: string, inventoryId: string): Observable<boolean> {
    return this.delete<void>(`transfers/${id}/inventory?inventoryId=${inventoryId}`).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.released');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  updateTransfer(id: string, body: string): Observable<boolean> {
    return this.put<void>(`transfers/${id}`, body).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  updateAdditionalFields(id: string, params: ITransferAdditionalFields): Observable<boolean> {
    return this.put<void>(`transfers/${id}/additional-fields`, params).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  tracking(id: string, body: ITrackingNumber): Observable<void> {
    return this.post<void>(`transfers/${id}/tracking`, body).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.saved')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  updateNote(transferId: string, note: string): Observable<void> {
    return this.put<void>(`transfers/${transferId}/note?note=${note}`, {}).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.saved')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  changeShippedStatus(transferId: string, transferShipmentStatus: TTransferShipmentType[]): Observable<void> {
    return this.put<void>(`transfers/${transferId}/shipment`, JSON.stringify(transferShipmentStatus)).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.saved')),
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, _error.message);
        return of(null);
      })
    );
  }

  notifyFacility(transferId: string, body: TransferFacilityNotificationDto): Observable<boolean> {
    return this.post<void>(`transfers/${transferId}/facility-notification`, body).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  createTransferFromAudit(auditInventoryId: string, body: IAuditTransferDto): Observable<boolean> {
    return this.post<void>(`transfers/audit-inventory/${auditInventoryId}`, body).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }
}
