export enum EPageDataRoutes {
  facility = '/directory/facilities/edit/:id',
  manufacturer = '/directory/manufacturers/edit/:id',
  physician = '/directory/physicians/edit/:id',
  preferenceCard = '/directory/preference-cards/edit/:id',
  procedure = '/directory/procedures/edit/:id',
  region = '/directory/regions/edit/:id',
  inventory = '/inventory/inventories/edit/:id',
  event = '/events/edit/:id',
  catalog = '/inventory/catalog/edit/:id',
  import = '/inventory/import/detail/:id',
  product = '/inventory/products/edit/:id',
  request = '/movement/requests/edit/:id',
  transfer = '/movement/transfers/edit/:id',
  user = '/users/:id',
  bom = '/inventory/bill-of-materials/edit/:id',
  priceSheet = '/directory/price-lists/:id',
  audit = '/inventory/audits/edit/:id'
}
