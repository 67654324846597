/**
 *
 * @param enm
 * @param inputKey
 * @constructor
 */
export const GetEnumKeyByValue = (enm: Record<string, string>, inputKey: string): any => {
  if (!enm || !inputKey) {
    return null;
  }
  let keyByValue: string;
  Object.entries(enm).every(e => {
    if (e[1] === inputKey) {
      keyByValue = e[0];
      return false;
    } else {
      return true;
    }
  });
  return keyByValue;
};
