/**
 * @description The purpose is to filter Http request from null, undefined, '' before send the backend
 * @param obj (any http params)
 */
export const ClearRequest = (obj: any): any => {
  const newObj: any = {};
  Object.keys(obj).forEach(key => {
    if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};
