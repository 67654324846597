<app-default-modal
  [size]="'small'"
  [title]="'notifications.notificationsLabel' | language"
  [showPrevStepButton]="!!(notifications$ | async).length"
  [prevButtonTitle]="'notifications.markAllAsRead' | language"
  [buttonTitle]="isActive('/notifications') ? null : ('shared.labels.viewAll' | language)"
  [disablePrevButton]="loading$ | async"
  [disableSubmitButton]="loading$ | async"
  (clickEmitter)="view()"
  (backEmitter)="markAllAsRead()"
>
  <div fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="start stretch" fxFlex="100%">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="connectSxTabContainer base full-width">
      <div
        fxFlex="50%"
        [class.connectSxTabActive]="selectedView === 'NOTIFICATIONS'"
        class="connectSxTab"
        (click)="selectedView = 'NOTIFICATIONS'; getNotifications()"
      >
        {{ 'notifications.notificationsLabel' | language }}
      </div>
      <div
        fxFlex="50%"
        class="connectSxTab"
        [class.connectSxTabActive]="selectedView === 'MENTIONS'"
        (click)="selectedView = 'MENTIONS'; getNotifications()"
      >
        {{ 'notifications.mentions' | language }}
      </div>
    </div>
    <app-list-notifications
      [loading]="loading$ | async"
      [notifications]="notifications$ | async"
      (viewEmitter)="close()"
    ></app-list-notifications>
  </div>
</app-default-modal>
