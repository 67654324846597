import { TNotificationsEntityType } from '@shared/type/index.type';
import { UserModel } from '@shared/models';

export class NotificationModel {
  createdBy: UserModel;
  createdDatetime: string;
  entityId: string;
  entityType: TNotificationsEntityType;
  id: string;
  isRead: boolean;
  message: string;
  recipient: UserModel;
  subject: string;
}
