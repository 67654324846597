import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TagsService } from '@services/tags.service';
import { BehaviorSubject, finalize, take } from 'rxjs';
import { TTagType, TIcons } from '@shared/type/index.type';
import { ITag } from '@shared/interfaces/tag';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { TranslationsKeys } from '@shared/type/i18n.type';
import { AlertsService } from '@services/internal/alerts.service';

@Component({
  selector: 'app-tags-autocomplete',
  templateUrl: './tags-autocomplete.component.html',
  styleUrls: ['./tags-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagsAutocompleteComponent {
  @Input() selectedChips: { tagText: string }[];
  @Input() tagEntityType: TTagType;
  @Input() disabled: boolean = false;
  @Input() removable: boolean = true;
  @Input() tooltipText: TranslationsKeys;
  @Input() icons: TIcons[] = [];
  @Input() label: TranslationsKeys = 'shared.labels.searchTagsLabel';

  @Output() removeChipEmitter = new EventEmitter<any>();
  @Output() selectedOptionEmitter = new EventEmitter<string>();

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  searchedData$: BehaviorSubject<ITag[]> = new BehaviorSubject<ITag[]>([]);
  clearInput: boolean = true;
  waitToBeAdded: boolean = false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(private tagsService: TagsService, private alertsService: AlertsService) {}

  searchTags(value: string): void {
    this.loading$.next(true);
    this.searchedData$.next([]);

    this.tagsService
      .searchTags({ entityType: this.tagEntityType, tagName: value })
      .pipe(
        take(1),
        finalize(() => this.loading$.next(false))
      )
      .subscribe(pageable => {
        this.searchedData$.next(pageable.content);
      });
  }

  selectTag(tagText: string, preventDoubleSelect: boolean = false): void {
    if (this.selectedChips.some(t => t.tagText === tagText)) {
      this.alertsService.showError('shared.alerts.errorMessages.tagDuplication', null, 5000);
      return;
    }

    /** Do not emmit en event if it triggers on blur and the option was selected from autocomplete*/
    this.waitToBeAdded = false;
    if (preventDoubleSelect) {
      setTimeout(() => {
        if (!this.waitToBeAdded) {
          if (tagText?.length) {
            this.selectedOptionEmitter.emit(tagText);
          }
          this.waitToBeAdded = false;
        }
      }, 500);
    } else {
      this.waitToBeAdded = true;
      this.selectedOptionEmitter.emit(tagText);
    }
  }

  removeChip(tagText: { tagText: string }): void {
    this.removeChipEmitter.emit(tagText);
  }
}
