import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DestroySubscriptions } from '@shared/classes/destroy-subscriptions';
import { ProcedureModel, ProductLineModel } from '@shared/models';
import { EProcedureTypes } from '@shared/enum';
import { MatAutocompleteRequireMath } from '@shared/validators/mat-autocomplete-require-math';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreateSmallEntityConfig } from '@constants';
import { CreateProductComponent } from 'src/app/features/inventory/products/partials/modals/create-product-line/create-product.component';
import { firstValueFrom, takeUntil } from 'rxjs';
import { ProductsService } from '@services/products.service';
import { PermissionService } from '@services/internal/permission.service';

@Component({
  selector: 'app-procedure-form',
  templateUrl: './procedure-form.component.html',
  styleUrls: ['./procedure-form.component.scss']
})
export class ProcedureFormComponent extends DestroySubscriptions implements OnInit, OnChanges {
  @Input() set procedure(value: ProcedureModel) {
    this.setValue(value);
  }
  @Input() createEntityFlow: boolean = false;
  @Input() productLineLoading: boolean = false;
  @Input() productsSelected: ProductLineModel[] = [];
  @Input() removable: boolean = false;
  @Input() loading: boolean = false;
  @Input() modifiers: { modifierName: string }[] = [];

  @Output() formValueChangeEmitter = new EventEmitter<UntypedFormGroup>();
  @Output() removeProductEmitter = new EventEmitter<string>();
  @Output() selectProductEmitter = new EventEmitter<ProductLineModel>();
  @Output() selectModifierEmitter = new EventEmitter<string>();
  @Output() removeModifierEmitter = new EventEmitter<{ modifierName: string }>();

  form: UntypedFormGroup;
  procedureTypes: Record<string, string> = EProcedureTypes;
  initialLoading: boolean = true;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public permissionService: PermissionService,
    private dialog: MatDialog,
    private productsService: ProductsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();
    this.formChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.productsSelected?.currentValue && this.form) {
      this.form.patchValue({ products: changes.productsSelected.currentValue });
    }
  }

  selectModifier(value: string): void {
    this.form.markAsTouched();
    this.form.markAsDirty();
    this.selectModifierEmitter.emit(value);
  }

  removeModifier(item: { modifierName: string }): void {
    this.form.markAsTouched();
    this.form.markAsDirty();
    this.removeModifierEmitter.emit(item);
  }

  async createProduct(): Promise<void> {
    const dialogConfig: MatDialogConfig = { ...CreateSmallEntityConfig };
    dialogConfig.data = {
      redirect: false
    };
    const dialogRef = this.dialog.open(CreateProductComponent, dialogConfig);
    const params = await firstValueFrom(dialogRef.afterClosed());

    if (params?.productLineId) {
      const res: ProductLineModel = await firstValueFrom(this.productsService.getProduct(params.productLineId));
      if (res) {
        this.selectProductEmitter.emit(res);
      }
    }
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
      type: ['', [Validators.required, Validators.maxLength(255)]],
      products: [[], [MatAutocompleteRequireMath, Validators.maxLength(255)]]
    });
  }

  private formChanges(): void {
    setTimeout(() => {
      this.formValueChangeEmitter.emit(this.form);
    });
    this.form.valueChanges.pipe(takeUntil(this.subscriptions)).subscribe(() => {
      this.formValueChangeEmitter.emit(this.form);
    });
  }

  private setValue(procedure: ProcedureModel): void {
    if (!procedure.name) {
      return;
    }
    this.initialLoading = false;
    this.form.setValue({
      name: procedure.name,
      type: procedure.type,
      products: []
    });
  }
}
