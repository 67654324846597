import { Injectable } from '@angular/core';
import { ApiService } from '@shared/classes/api-service';
import { Observable } from 'rxjs/internal/Observable';
import { HttpErrorResponse } from '@angular/common/http';
import { of, catchError } from 'rxjs';
import { ITag } from '@shared/interfaces/tag';
import { TTagType } from '@shared/type/index.type';
import { PageableModel } from '@shared/models';

@Injectable({
  providedIn: 'root'
})
export class TagsService extends ApiService {
  constructor() {
    super();
  }

  searchTags(params: { entityType: TTagType; tagName: string }): Observable<PageableModel<ITag>> {
    return this.get<PageableModel<ITag>>(`tags`, { params }).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of(new PageableModel()))
    );
  }
}
