import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@shared/classes/api-service';
import { FacilityModel, ManufacturerModel, PhysicianModel, PreferenceCardModel } from '@shared/models';
import { HeaderOptionsWithToken } from '@shared/utils/http/build-http-header-with-token';
import { Observable, map, catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserAssignmentsService extends ApiService {
  constructor() {
    super();
  }

  assignFacility(userId: string, facilityIds: string[]): Observable<boolean> {
    return this.post<void>(`users/${userId}/facilities`, { facilityIds }).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.assignFacility', error.message);
        return of(null);
      })
    );
  }

  assignLocation(userId: string, locationIds: string[]): Observable<boolean> {
    return this.post<void>(`users/${userId}/locations`, { locationIds }).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.assignLocation', error.message);
        return of(null);
      })
    );
  }

  assignManufacturer(userId: string, manufacturerId: string): Observable<boolean> {
    return this.put<void>(`users/${userId}/manufacturer?manufacturerId=${manufacturerId}`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.assignManufacturer', error.message);
        return of(null);
      })
    );
  }

  assignPhysician(userId: string, physicianIds: string[]): Observable<boolean> {
    return this.post<void>(`users/${userId}/physicians`, { physicianIds }).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.assignPhysician', error.message);
        return of(null);
      })
    );
  }

  assignProduct(userId: string, productIds: string[]): Observable<boolean> {
    return this.post<void>(`users/${userId}/products`, { productIds }).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  assignRecipe(userId: string, requestRecipesIds: string[]): Observable<boolean> {
    return this.post<void>(`users/${userId}/requestRecipes`, { requestRecipesIds }).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getAssignedFacilities(userId: string): Observable<FacilityModel[]> {
    return this.get<FacilityModel[]>(`users/${userId}/facilities`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  getAssignedManufacturers(userId: string, name: string = ''): Observable<ManufacturerModel[]> {
    return this.get<ManufacturerModel[]>(`users/${userId}/manufacturers?name=${name}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  getAssignedPhysicians(userId: string): Observable<PhysicianModel[]> {
    return this.get<PhysicianModel[]>(`users/${userId}/physicians`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  getAssignedRecipes(userId: string): Observable<PreferenceCardModel[]> {
    return this.get<PreferenceCardModel[]>(`users/${userId}/requestRecipes`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  unassignFacility(userId: string, facilityIds: string[]): Observable<boolean> {
    return this.delete<void>(`users/${userId}/facilities`, HeaderOptionsWithToken(facilityIds)).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  unassignLocation(userId: string, locationIds: string[]): Observable<boolean> {
    return this.delete<void>(`users/${userId}/locations`, HeaderOptionsWithToken(locationIds)).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  unassignManufacturer(userId: string, manufacturerId: string): Observable<boolean> {
    return this.delete<void>(`users/${userId}/manufacturer?manufacturerId=${manufacturerId}`).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  unassignPhysician(userId: string, physicianIds: string[]): Observable<boolean> {
    return this.delete<void>(`users/${userId}/physicians`, HeaderOptionsWithToken(physicianIds)).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  unassignProduct(userId: string, productIds: string[]): Observable<boolean> {
    return this.delete<void>(`users/${userId}/products`, HeaderOptionsWithToken(productIds)).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  unassignRecipe(userId: string, requestRecipeIds: string[]): Observable<boolean> {
    return this.delete<void>(`users/${userId}/requestRecipes`, HeaderOptionsWithToken(requestRecipeIds)).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }
}
