import { IFilter } from '@shared/interfaces';
import { DefaultQueryParams } from '@shared/helpers/default-query-params';
import { ClearRequest } from '@shared/utils/filter-object-null-properties';
import { TState } from '@shared/type/index.type';
import { EBOMType } from '@shared/enum';

export class BillOfMaterialsPageableParams {
  name?: string;
  page: number;
  size: number;
  sort: string[];
  state?: TState;
  userId?: string;
  billOfMaterialType?: keyof typeof EBOMType;

  constructor(params: IFilter) {
    return BillOfMaterialsPageableParams.build(params);
  }

  private static build(params: IFilter): BillOfMaterialsPageableParams {
    const queryParams: BillOfMaterialsPageableParams = DefaultQueryParams(params);

    queryParams.state = params?.state ?? 'ACTIVE';
    queryParams.name = params.search;

    if (params.userId) {
      queryParams.userId = params.userId;
    }
    if (params.bomType) {
      queryParams.billOfMaterialType = params.bomType;
    }

    return ClearRequest(queryParams);
  }
}
