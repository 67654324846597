import { AfterViewInit, Component, NgZone, signal } from '@angular/core';
import { environment } from '@environment';
import { SwUpdate } from '@angular/service-worker';
import { AlertsService } from '@services/internal/alerts.service';
import { DestroySubscriptions } from '@shared/classes/destroy-subscriptions';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { GlobalStoreService } from '@services/internal/global-store.service';
import { LanguageService } from '@services/internal/language.service';
import { AnalyticsService } from '@services/internal/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends DestroySubscriptions implements AfterViewInit {
  /** Allow render UI for users that added correct password */
  allowRender = signal<boolean>(false);
  /** Password that users added for non-production environments */
  password: string = '';
  constructor(
    private swUpdate: SwUpdate,
    private alertsService: AlertsService,
    private router: Router,
    private zone: NgZone,
    private analyticsService: AnalyticsService,
    /** inject globalStoreService to set page title on non auth pages */
    //@ts-ignore
    private globalStoreService: GlobalStoreService,
    /** inject LanguageService to make multilanguage work */
    //@ts-ignore
    private languageService: LanguageService
  ) {
    super();
    /** Show input to enter password for non-production environments */ // @ts-ignore
    if (AppComponent.isProd() || location.port === '4200' || GlobalStoreService.getPlatform() !== 'web' || window.Cypress) {
      this.allowRender.set(true);
    } else {
      if (AppComponent.getCookie('dev_password') === environment.password) {
        this.allowRender.set(true);
      }
    }
    if (GlobalStoreService.getPlatform() !== 'web') {
      this.initializeDeepUrlsWatcher();
    }
  }

  static isProd = (): boolean => environment.production && location.host === 'beacon.connectsx.com';

  /** CheckPassword in cookies for non-production environments */
  private static getCookie(name: string): string {
    const matches: string[] = document.cookie.match(
      new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
    );
    return matches ? decodeURIComponent(matches[1]) : null;
  }

  /** Default TrustedType */
  private static initDefaultTrustedType(): void {
    // @ts-ignore
    if (window.trustedTypes?.createPolicy) {
      // @ts-ignore
      window.trustedTypes.createPolicy('default', {
        createHTML: (str: string) => str.replace(/\</g, '&lt;'),
        createScriptURL: (str: string) => str, // warning: this is unsafe!
        createScript: (str: string) => str
      });
    }
  }

  ngAfterViewInit(): void {
    if (AppComponent.isProd()) {
      this.analyticsService.initGA();
      console.log('%cSTOP!', 'color:red; font-size: 36px; font-weight: bold; text-shadow: 1px 1px 1px black; background: yellow;');
      console.log(
        '%cUsing this console may allow attackers to impersonate you and steal your information using an attack called Self-XSS. ' +
          'Do not enter or paste code that you do not understand.',
        'font-size: 18px;'
      );
    }

    if (GlobalStoreService.getPlatform() === 'web') {
      AppComponent.initDefaultTrustedType();
      /** Check Service Worker updates to reload the page and apply changes */
      if (this.swUpdate.isEnabled) {
        this.swUpdate.versionUpdates.pipe(takeUntil(this.subscriptions)).subscribe(evt => {
          if (evt.type === 'VERSION_READY') {
            this.swUpdate.activateUpdate().then(() => {
              this.alertsService.showWarning('shared.alerts.successMessages.newVersionAvailable');
              document.location.reload();
            });
          }
        });
      }
    }
  }

  /** Check password user added for non-production environments */
  checkPassword(): void {
    if (environment.password === this.password) {
      this.allowRender.set(true);
      const expiryDate: Date = new Date();
      expiryDate.setMonth(expiryDate.getMonth() + 1);
      document.cookie = `dev_password=${this.password};expires=${expiryDate.toUTCString()}`;
    }
  }

  private initializeDeepUrlsWatcher() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const slug = event.url.split('connectsx.com').pop();
        if (slug?.length) {
          this.router.navigateByUrl(slug);
        }
      });
    });
  }
}
