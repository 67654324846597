export class EventVolumesModel {
  day: number;
  eventCount: number;
  representativeCount: number;
}

export class DashboardModel {
  closedEventsCount: number;
  containerInventoryCount: number;
  inventoryTotalValue: string;
  looseInventoryCount: number;
  noLotInventoryCount: number;
  openEventsCount: number;
  pendingRequestsCount: number;
  pendingTransfersCount: number;
  totalExpiredItems: number;
  totalInventoryCount: number;
  totalOverdueItems: number;
  //Date property was saved in localstorege
  createdDateTime?: number;
}
