<div fxLayout="row" fxLayoutAlign="space-between center" class="titleContainer">
  <app-title [type]="'h3'" [title]="data.name"></app-title>
  <app-button-kit
    [icon]="'close'"
    [defaultIconButton]="true"
    [title]="''"
    [textColor]="'primary_text'"
    (clickEmitter)="dialogRef.close()"
  ></app-button-kit>
</div>
<div class="locationsMapContainer" *ngIf="apiLoaded | async">
  <google-map [options]="options" width="100%" [center]="markerPosition">
    <map-marker [position]="markerPosition" [options]="markerOptions"></map-marker>
  </google-map>
</div>
