import { IFilter } from '@shared/interfaces';
import { DefaultQueryParams } from '@shared/helpers/default-query-params';
import { TState } from '@shared/type/index.type';
import { ClearRequest } from '@shared/utils/filter-object-null-properties';

export class PhysiciansPageableParams {
  fullName?: string;
  npi?: string;
  page: number;
  procedureId?: string;
  representativeId?: string;
  size: number;
  sort: string[];
  state?: TState;
  userId?: string;

  constructor(params: IFilter) {
    return PhysiciansPageableParams.build(params);
  }

  private static build(params: IFilter): PhysiciansPageableParams {
    const queryParams: PhysiciansPageableParams = DefaultQueryParams(params);
    queryParams.state = params?.state ?? 'ACTIVE';
    if (params.fullName && params.fullName !== 'fullName') {
      queryParams.fullName = params.fullName;
    }
    queryParams.npi = params.npi;
    queryParams.representativeId = params.representativeId;
    queryParams.userId = params.custodyId;
    queryParams.procedureId = params.procedureId;
    return ClearRequest(queryParams);
  }
}
