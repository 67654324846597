<mat-paginator
  class="connectSxTablePagination"
  [selectConfig]="{ panelClass: 'itemsPerPageSelectPanel' }"
  [ngClass]="{ withTooltip: withTooltip }"
  [pageSizeOptions]="pageSizeOptions"
  [length]="totalElements"
  [pageIndex]="pageNumber"
  [pageSize]="size"
  [disabled]="disabled"
  [hidePageSize]="totalElements < 20"
  (page)="selectPagination($event)"
></mat-paginator>
