import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ProductsService } from '@services/products.service';
import { ManufacturerService } from '@services/manufacturer.service';
import { AlertsService } from '@services/internal/alerts.service';
import { CanAutoSaveDirective } from '@guards/autosave/can-auto-save';
import { BehaviorSubject, finalize, Observable, take } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ManufacturerModel, ProductLineModel } from '@shared/models';
import { ProductsStoreService } from '../../../services/products-store.service';

@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateProductComponent extends CanAutoSaveDirective implements OnInit {
  @ViewChild('stepper') stepperRef: MatStepper;

  assets: UntypedFormGroup;
  formsValid: boolean = false;
  requiredFields: UntypedFormGroup;
  assignToCustody: boolean = true;
  showAssignToCustodyCheckbox: boolean;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  modifiers$: BehaviorSubject<{ modifierName: string }[]> = this.productsStoreService.modifiers$;

  constructor(
    public router: Router,
    public manufacturerService: ManufacturerService,
    public productsService: ProductsService,
    public alertsService: AlertsService,
    public ref: ChangeDetectorRef,
    @Optional() public dialogRef: MatDialogRef<CreateProductComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: { manufacturer: ManufacturerModel; showAssignToCustodyCheckbox: boolean; redirect: boolean },
    private productsStoreService: ProductsStoreService
  ) {
    super();
    this.productsStoreService.modifiers$.next([]);
  }

  autoSave(): void {
    this.createProduct(false);
  }

  autoSaveFormsCheckValid(): UntypedFormGroup[] {
    return [this.requiredFields, this.assets];
  }

  checkValidation(): void {
    setTimeout(() => {
      this.formsValid = this.requiredFields.valid && this.assets.valid;
      this.ref.markForCheck();
    }, 50);
  }

  createProduct(redirect = true): void {
    this.submit(redirect).pipe(take(1)).subscribe();
  }

  ngOnInit(): void {
    if (this.data?.manufacturer) {
      setTimeout(() => {
        this.requiredFields.get('manufacturerId').disable();
        this.requiredFields.patchValue({
          manufacturerId: this.data.manufacturer
        });
        this.showAssignToCustodyCheckbox = this.data.showAssignToCustodyCheckbox;
      }, 200);
    }
  }

  submit(redirectToDetailPage: boolean = true): Observable<string> {
    this.loading$.next(true);

    return new Observable(observer => {
      this.requiredFields.value.manufacturerId = this.requiredFields.get('manufacturerId').value?.id;

      const result: ProductLineModel = { ...this.requiredFields.value, ...this.assets.value };

      result.modifiers = this.modifiers$.value.map(m => m.modifierName);

      this.productsService
        .createProduct(result)
        .pipe(
          take(1),
          finalize(() => this.loading$.next(false))
        )
        .subscribe((id: string) => {
          if (!id) {
            return;
          }

          this.dialogRef.close();
          this.requiredFields.markAsPristine();
          this.assets.markAsPristine();

          if (redirectToDetailPage) {
            this.router.navigate([`/inventory/products/edit/${id}`]).then();
          }

          this.alertsService.showSuccess('shared.alerts.successMessages.created');
          observer.next(id);
        });
    });
  }

  createWithoutRedirect(): void {
    this.submit(false)
      .pipe(take(1))
      .subscribe((productLineId: string) => {
        this.dialogRef.close({ productLineId, assign: this.assignToCustody });
      });
  }
}
