import { PermissionModel } from '@shared/models';
import { PermissionConfigModel } from '@shared/models/permissions/permission.model';
import { MergeModels } from '@guards/permissions/merge-models';

export class OfflinePermissions {
  model: PermissionModel = {
    config: new PermissionConfigModel(),
    events: {
      canCloseEvent: true,
      canAddPONumber: false,
      canChangeCommissionAndInvoicePaid: false,
      canChangeEventStatus: false,
      canChangeEventStatusOnlyFromClosedToCompleted: false,
      canCreateEventForSelfOnly: false,
      canEditEvent: false,
      canGenerateInvoice: false,
      canMarkAsPaid: false,
      canScheduleEvent: false,
      canSignEvent: false,
      filterByRepresentativeInHisGroup: false,
      canExportSalesOrderFormToQuickBooks: false,
      canReadUsers: false,
      canReadDUTPriceHasChangedByNonAdmin: false,
      canReadStockAdjustment: false,

      toggleCalendarView: false,
      filterEvents: false,
      createEvent: false,
      seeImages: false,
      signEventUsingCanvas: false
    },
    forbiddenRoutes: [],
    directory: {
      createFacility: false,
      createManufacturer: false,
      createPhysician: false,
      createProcedure: false
    },
    report: {
      readReport: false,
      exportInventory: false
    },
    inventory: {
      acceptSelfTransferOnly: false,
      approveAnyTransfer: false,
      canAdd_IFY_ToProductLine: false,
      canCreateCatalog: false,
      canCreateInventoryImport: false,
      canCreateProductLine: false,
      canEditCatalog: false,
      canEditInventoryAdditionalInfo: false,
      canEditInventoryPriceInfo: false,
      canEditInventoryPriceInfoForSelfCreatedOnly: false,
      canEditPriceOfInventoryInDUT: false,
      canEditProductLine: false,
      canFilterDevicesByCustody: false,
      canFilterInventoriesByCustody: false,
      canFilterInventoriesByHoldStatus: false,
      canFulfillInventoryRequest: false,
      canInitiateInventoryRequest: false,
      canMarkInventoryAsUsedInEvent: true,
      canReadCOG: false,
      canReadInventoryImport: false,
      canReadParLevel: false,
      createInventory: false,
      createInventoryTransfer: false,
      manageHoldStatus: false,
      assignEventToTransfer: false,
      setDueBackDateInTransfer: false,
      readFieldTransferFlag: false,
      readInventoryValue: false,
      filterInventories: false
    },
    custom: {
      addAndDeactivateInventoriesEnabled: false,
      initiateTransfersEnabled: false,
      reopenEventsEnabled: false,
      requireAssociateRequestsWithEvents: false,
      requireEventNotesEnabled: false
    }
  };

  constructor() {
    this.model = MergeModels(this.model);
  }
}
