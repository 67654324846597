export enum EConsignmentStatus {
  'POSSESSION' = 'shared.enums.consignmentStatus.possession',
  'CONSIGNED' = 'shared.enums.consignmentStatus.consigned',
  'LOANED' = 'shared.enums.consignmentStatus.loaned',
  'OTHER' = 'shared.enums.consignmentStatus.other'
}

export enum ETransferConsignmentStatus {
  'RESET' = 'shared.enums.consignmentStatus.reset',
  'POSSESSION' = 'shared.enums.consignmentStatus.possession',
  'CONSIGNED' = 'shared.enums.consignmentStatus.consigned',
  'LOANED' = 'shared.enums.consignmentStatus.loaned',
  'OTHER' = 'shared.enums.consignmentStatus.other'
}
