export enum EInventoryStatus {
  UNUSED = 'shared.enums.inventoryStatus.unused',
  USED = 'shared.enums.inventoryStatus.used',
  IMPLANTED = 'shared.enums.inventoryStatus.implanted',
  IN_OUT = 'shared.enums.inventoryStatus.inOut',
  DAMAGED = 'shared.enums.inventoryStatus.damaged',
  MISSING = 'shared.enums.inventoryStatus.missing',
  OTHER = 'shared.enums.inventoryStatus.other',
  FACILITY_STOCK = 'shared.enums.inventoryStatus.facilityStock',
  EXPIRED = 'shared.enums.inventoryStatus.expired',
  DUPLICATE = 'shared.enums.inventoryStatus.duplicate'
}
