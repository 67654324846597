import { Inject, Optional, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UsersService } from '@services/users.service';
import { UserTimeZone } from '@shared/models';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
import { TDateFormat } from '@shared/type/index.type';

@Pipe({
  name: 'userDate'
})
export class UserDatePipe extends DatePipe implements PipeTransform {
  private readonly userTimeZone: UserTimeZone = new UserTimeZone();
  private readonly orgDateFormat: TDateFormat;

  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
    super(dateLocale);
    this.userTimeZone = UsersService.userTimeZone ?? new UserTimeZone();
    this.orgDateFormat = UsersService.dateFormat;
  }

  // @ts-ignore
  transform(value: string | Date, dateFormat?: string): string {
    const timeFormat: string = 'hh:mm a';

    if (!value) {
      return null;
    }

    switch (this.orgDateFormat) {
      case 'DD_MM_YYYY':
        return format(utcToZonedTime(value, this.userTimeZone.timeZone), `d MMM, yyyy${dateFormat ? '' : ' ' + timeFormat}`);
      case 'MM_DD_YYYY':
        return format(utcToZonedTime(value, this.userTimeZone.timeZone), `MMM d, yyyy${dateFormat ? '' : ' ' + timeFormat}`);
      case 'YYYY_MM_DD':
        return format(utcToZonedTime(value, this.userTimeZone.timeZone), `yyyy MMM d${dateFormat ? '' : ' ' + timeFormat}`);
      default:
        return format(utcToZonedTime(value, this.userTimeZone.timeZone), `d MMM, yyyy${dateFormat ? '' : ' ' + timeFormat}`);
    }
  }
}
