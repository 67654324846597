import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, forkJoin, Observable, retry, take } from 'rxjs';
import { InventoriesService } from '@services/inventories.service';
import { IFilter } from '@shared/interfaces';
import { InventoryPageableParams } from '@shared/models/build-models';
import { EventsService } from '@services/events.service';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { LocalDatabaseService } from '@services/offline/local-database.service';
import { AlertsService } from '@services/internal/alerts.service';
import {
  EventContainerModel,
  EventModel,
  InventoryModel,
  ManufacturerModel,
  NoteModel,
  PageableModel,
  PONumberModel,
  PriceAdjustmentModel,
  ProductLineModel,
  SimilarInventoryInfo,
  UserModel
} from '@shared/models';
import { ISubmitForBilling } from '@shared/interfaces';
import { SyncService } from '@services/offline/sync.service';
import { LanguageService } from '@services/internal/language.service';
import { Network } from '@capacitor/network';
import { LocalStorage } from '@services/internal/localstorage.service';
import { BackgroundTasksService } from '@services/offline/background-tasks.service';
import { UserPromptsService } from '@services/offline/user-prompts.service';
import { ICloseEvent, IDUTChanges, IEventAssignmentsDB, ILoadedData, IUserChanges } from '@shared/interfaces/offline';
import { OfflineStatusService } from '@services/offline/offline-status.service';
import { UsersService } from '@services/users.service';
import { Overlay } from '@angular/cdk/overlay';
import { MobileOfflineProgressModalComponent } from '@shared/components/mobile-offline-progress-modal/mobile-offline-progress-modal.component';
import { SearchParamsShorterObject } from '@shared/utils/http/search-params-shorter';
import { Wait } from '@shared/utils/wait';
import { IEventAssignedInventoryResponse } from '@shared/interfaces/events/event-assigned-inventory-responce';

export const storeName: string = 'offlinedb';

@Injectable({
  providedIn: 'root'
})
export class OfflineService {
  selectedOfflineEvents$: BehaviorSubject<EventModel[]> = new BehaviorSubject<EventModel[]>([]);
  offlineInventories: Map<string, InventoryModel> = new Map<string, InventoryModel>();
  offlineEventAssignments: Map<string, IEventAssignmentsDB> = new Map<string, IEventAssignmentsDB>();
  offlineUser: UserModel;
  userChanges: {
    markUsedInventory?: IDUTChanges[];
    removeDeviceFromEvent?: IDUTChanges[];
    closeEvent?: ICloseEvent[];
  } = {
    markUsedInventory: [],
    removeDeviceFromEvent: [],
    closeEvent: []
  };

  loaderRef: MatBottomSheetRef;
  isOfflineReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private inventoriesLoaded: ILoadedData;
  private eventsLoaded: ILoadedData;
  private offlineHours: number;

  constructor(
    private inventoriesService: InventoriesService,
    private eventsService: EventsService,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private localDatabaseService: LocalDatabaseService,
    private alertsService: AlertsService,
    private syncService: SyncService,
    private backgroundTasksService: BackgroundTasksService,
    private userPromptsService: UserPromptsService,
    private offlineStatusService: OfflineStatusService,
    private usersService: UsersService,
    private overlay: Overlay
  ) {
    LocalStorage.getItem('offline').then(async isOffline => {
      if (isOffline === 'true') {
        this.offlineStatusService.areRequestsOffline$.next(true);
        this.offlineStatusService.isOffline$.next(true);
        this.localDatabaseService.init();
        await this.localDatabaseService.isStoreExists(storeName);
        await this.localDatabaseService.openStore(storeName);
        await this.getDataFromDB();
        this.backgroundTasksService.startOfflineTimer();
        this.backgroundTasksService.setNetworkStatusWatcher();
        document.body.classList.add('offline');
        UsersService.defaultPagination.next(500);
        this.isOfflineReady$.next(true);
      }
    });
  }

  setEvents(event: EventModel, add: boolean): void {
    if (add) {
      this.selectedOfflineEvents$.value.push(event);
    } else {
      const ind: number = this.selectedOfflineEvents$.value.findIndex(e => e.id === event.id);
      this.selectedOfflineEvents$.value.splice(ind, 1);
    }
    this.selectedOfflineEvents$.next([...[], ...this.selectedOfflineEvents$.value]);
  }

  async enableOfflineMode(hours: number): Promise<void> {
    const isEnoughMemory = await this.backgroundTasksService.isEnoughMemory();
    if (!isEnoughMemory) {
      return;
    }
    await this.localDatabaseService.init();
    await this.localDatabaseService.openStore(storeName);
    await this.clearOfflineChanges();
    const networkType = await Network.getStatus().then(n => n.connectionType);
    if (networkType === 'cellular') {
      const isUseOfflineModeCellular = await this.userPromptsService.offlineModeUsingCellularPrompt();
      if (!isUseOfflineModeCellular) {
        return;
      }
    }

    this.offlineStatusService.preparingOffline$.next(true);
    this.offlineHours = hours;
    const filterParam: IFilter = { custodyId: UsersService.getUser().id, eventStatus: 'OPEN' };
    if (this.router?.url?.includes('events/list')) {
      await this.router.navigate([`/events/list`], { queryParams: { reloadEventsForCurrentCustody: true } });
    } else {
      await this.router.navigate([`/events/list`], { queryParams: SearchParamsShorterObject(filterParam) });
    }

    this.userPromptsService.selectEventsInfo();

    this.bottomSheet.open(MobileOfflineProgressModalComponent, {
      hasBackdrop: false,
      closeOnNavigation: false,
      disableClose: true,
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: {
        type: 'START_PRELOADING',
        title: '',
        description: '',
        disableAcceptButton: this.selectedOfflineEvents$,
        acceptButtonText: LanguageService.instant('shared.alerts.prompt.buttons.yes'),
        declineButtonText: LanguageService.instant('shared.alerts.prompt.buttons.no')
      }
    });

    await this.localDatabaseService.init();
    await this.localDatabaseService.openStore(storeName);
    LocalStorage.setItem('offlineHours', JSON.stringify({ createdDateTime: new Date().toISOString(), hours }));
    //Set the padding bottom while the BottomSheetConfirmComponent is showing at the bottom, to let choose the last item
    const container = document.querySelector('.contentContainer');
    if (container) {
      container.classList.add('bottomPadding');
    }
  }

  async offlineEventsWasSet(): Promise<void> {
    this.offlineStatusService.preparingOffline$.next(false);
    this.offlineStatusService.loadedPercentage$.next(0);
    this.inventoriesLoaded = null;
    this.eventsLoaded = null;
    LocalStorage.setItem('offline', 'true');
    this.offlineUser = UsersService.getUser();
    await this.localDatabaseService.setTable('user');
    this.localDatabaseService.setItem('user', JSON.stringify(this.offlineUser));
    this.loaderRef = this.bottomSheet.open(MobileOfflineProgressModalComponent, {
      data: {
        type: 'PROGRESS'
      },
      closeOnNavigation: false,
      disableClose: true
    });
    this.backgroundTasksService.startOfflineTimer();
    this.backgroundTasksService.setNetworkStatusWatcher();
    document.querySelector('.contentContainer').classList.remove('bottomPadding');
    await this.loadEventsData();
    this.loadInventories();
  }

  async cancelOfflineMode() {
    this.offlineStatusService.preparingOffline$.next(false);
    this.usersService.me().pipe(take(1)).subscribe();
    this.selectedOfflineEvents$.next([]);
    await LocalStorage.removeItem('offline');
    document.querySelector('.contentContainer')?.classList?.remove('bottomPadding');
    this.clearOfflineChanges();
  }

  async disableOfflineMode(): Promise<void> {
    let isUserChooseAction: boolean = false;
    // Ask user if sync changes
    const isUserChanges = this.isChanges();
    if (isUserChanges) {
      const confirmSyncChanges = await this.userPromptsService.syncChangesQuestionPrompt();
      if (confirmSyncChanges) {
        this.offlineStatusService.areRequestsOffline$.next(false);
        await this.syncService.syncData();
        await this.clearOfflineChanges();
        isUserChooseAction = true;
      } else {
        const clearChanges = await this.userPromptsService.clearChangesPrompt();
        if (clearChanges) {
          await this.clearOfflineChanges();
          isUserChooseAction = true;
        } else {
          return;
        }
      }
    }
    if (isUserChooseAction || !isUserChanges) {
      if (!isUserChanges) {
        await this.clearOfflineChanges();
      }
      await firstValueFrom(this.usersService.changeOfflineMOde(UsersService.getUser().id, { makeOffline: false, offlineHours: null }));
      this.usersService.me().pipe(take(1)).subscribe();
    }
    if (isUserChooseAction || !isUserChanges) {
      this.offlineStatusService.areRequestsOffline$.next(false);
      this.alertsService.showSuccess('shared.alerts.successMessages.online');
      document.body.classList.remove('offline');
    }
  }

  getEventInventoriesByIds(inventoryIds: string[] = []): InventoryModel[] {
    const result: InventoryModel[] = [];
    inventoryIds.forEach(id => {
      if (this.offlineInventories.has(id)) {
        result.push(this.offlineInventories.get(id));
      }
    });
    return result;
  }

  /** Track changes */
  /**
   * 1. Mark to event
   * 2. UnAssign from event
   * 3. Close (sign)
   */
  async trackChanges(
    actionType: keyof IUserChanges,
    eventId: string,
    params: { markUsed?: SimilarInventoryInfo[]; unAssignInventoryIds?: string[]; closeEvent?: ISubmitForBilling }
  ): Promise<void> {
    if (actionType === 'markUsedInventory') {
      const currentChanges = this.userChanges.markUsedInventory;
      let inventoryIds: string[] = [];
      params.markUsed.forEach(i => {
        const specificInventories = i.specificInventories.splice(0, i.count);
        inventoryIds = [...inventoryIds, ...specificInventories];
      });
      const currentEventChangesIndex = currentChanges.findIndex(e => e.eventId === eventId);
      if (currentEventChangesIndex !== -1) {
        this.userChanges.markUsedInventory[currentEventChangesIndex].inventoryIds = [
          ...this.userChanges.markUsedInventory[currentEventChangesIndex].inventoryIds,
          ...inventoryIds
        ];
      } else {
        this.userChanges.markUsedInventory.push({ eventId, inventoryIds });
      }
      this.markAddedToEvent(inventoryIds);
      await this.setUserChangesToDB('markUsedInventory');
    } else if (actionType === 'removeDeviceFromEvent') {
      let devicesToRemove = params.unAssignInventoryIds;
      const removeDevicesChanges = this.userChanges.removeDeviceFromEvent;
      const removeDevicesIndex = removeDevicesChanges.findIndex(e => e.eventId === eventId);

      const markUsedInventoryChanges = this.userChanges.markUsedInventory;
      const markUsedInventoryChangesIndex = markUsedInventoryChanges.findIndex(e => e.eventId === eventId);
      // If device was removed from the devices that were added during offline mode
      // If devices were added to current event in offline mode
      if (markUsedInventoryChangesIndex !== -1) {
        // If current devices were added to current event in offline mode, remove them
        const addedDevices = this.userChanges.markUsedInventory[markUsedInventoryChangesIndex].inventoryIds;
        this.userChanges.markUsedInventory[markUsedInventoryChangesIndex].inventoryIds = addedDevices.filter(
          id => !devicesToRemove.includes(id)
        );
        //Remove deviceIds form common array if they were added and remove in offline mode
        devicesToRemove = devicesToRemove.filter(id => !addedDevices.includes(id));
        await this.setUserChangesToDB('markUsedInventory');
      }
      // Changes for current event
      if (devicesToRemove.length) {
        if (removeDevicesIndex !== -1) {
          this.userChanges.removeDeviceFromEvent[removeDevicesIndex].inventoryIds = [
            ...removeDevicesChanges[removeDevicesIndex].inventoryIds,
            ...devicesToRemove
          ];
        } else {
          this.userChanges.removeDeviceFromEvent.push({ eventId, inventoryIds: devicesToRemove });
        }
        await this.setUserChangesToDB('removeDeviceFromEvent');
        // Let this device be available to mark again
        devicesToRemove.forEach(deviceId => {
          if (this.offlineInventories.has(deviceId)) {
            const device: InventoryModel = this.offlineInventories.get(deviceId);
            device.event = null;
            device.markedInEvent = null;
            this.offlineInventories.set(deviceId, device);
          }
        });
      }
    } else if (actionType === 'closeEvent') {
      this.userChanges.closeEvent.push({ eventId, submit: params.closeEvent });
      await this.setUserChangesToDB('closeEvent');
    }
  }

  isChanges(): boolean {
    return (
      Boolean(this.userChanges.markUsedInventory?.length) ||
      Boolean(this.userChanges.removeDeviceFromEvent?.length) ||
      Boolean(this.userChanges.closeEvent?.length)
    );
  }

  async clearOfflineChanges(): Promise<void> {
    LocalStorage.removeItem('offline');
    LocalStorage.removeItem('offlineHours');

    this.offlineStatusService.isOffline$.next(false);
    this.offlineStatusService.areRequestsOffline$.next(false);
    this.offlineStatusService.preparingOffline$.next(false);
    this.offlineStatusService.syncing$.next(false);
    this.offlineStatusService.loadedPercentage$.next(0);
    this.isOfflineReady$.next(false);
    // Clear all tables manually, in case sometimes method deleteStore does not work
    await this.localDatabaseService.setTable('userChanges');
    await this.localDatabaseService.setItem('markUsedInventory', JSON.stringify([]));
    await this.localDatabaseService.setItem('removeDeviceFromEvent', JSON.stringify([]));
    await this.localDatabaseService.setItem('closeEvent', JSON.stringify([]));

    const tables = await this.localDatabaseService.getAllTables();
    for (const t of tables) {
      await this.localDatabaseService.setTable(t);
      await this.localDatabaseService.clear();
      await this.localDatabaseService.deleteTable(t);
    }
    await this.localDatabaseService.deleteStore(storeName);
    this.usersService.me().pipe(take(1)).subscribe();
    document.body.classList.remove('offline');
    this.offlineUser = null;
    this.selectedOfflineEvents$.next([]);
    this.offlineInventories.clear();
    this.offlineEventAssignments.clear();

    this.userChanges = {
      markUsedInventory: [],
      removeDeviceFromEvent: [],
      closeEvent: []
    };
    this.offlineInventories = new Map<string, InventoryModel>();
    this.offlineEventAssignments = new Map<string, IEventAssignmentsDB>();
    //Unsubscribe offline tasks
    this.backgroundTasksService.subscriptions$.next();
    this.backgroundTasksService.subscriptions$.complete();
  }

  /** For each event detail load all assigned data and save it to localDB
   * 1. DUT inventories, packs
   * 2. Manufacturers
   * 4. Product Lines
   * 5. eventContainers
   * 6. notes
   * 7. PO Number
   * 7. price Adjustments
   */
  getSingleEventAssignments(eventId: string): Observable<Array<any>> {
    const inventories: Promise<IEventAssignedInventoryResponse> = firstValueFrom(this.eventsService.getAssignedInventories(eventId)).then(
      data => {
        const { inventories, packs } = data;
        const inventoriesCut: InventoryModel[] = [];
        const packsCut: InventoryModel[] = [];

        inventories.forEach(i => inventoriesCut.push(this.cutUnUsedInventoryProperties(i)));
        packs.forEach(i => packsCut.push(this.cutUnUsedInventoryProperties(i)));

        return {
          inventories: inventoriesCut,
          packs: packsCut
        };
      }
    );
    const manufacturers: Observable<ManufacturerModel[]> = this.eventsService.getManufacturers(eventId);
    const products: Observable<ProductLineModel[]> = this.eventsService.getProducts(eventId);
    const eventContainers: Promise<EventContainerModel[]> = firstValueFrom(this.eventsService.getContainers(eventId));
    const notes: Observable<NoteModel[]> = this.eventsService.getNotes(eventId);
    const poNumbers: Observable<PONumberModel[]> = this.eventsService.getPoNumbers(eventId);
    const priceAdjustments: Observable<PriceAdjustmentModel[]> = this.eventsService.getPriceAdjustment(eventId);
    return forkJoin([inventories, manufacturers, products, eventContainers, notes, poNumbers, priceAdjustments]);
  }

  /** Mark inventory that it was added to event */
  markAddedToEvent(inventoryIds: string[]): void {
    const inv = this.getEventInventoriesByIds(inventoryIds);
    inv.forEach(i => this.offlineInventories.set(i.id, { ...i, ...{ markedInEvent: true } }));
  }

  async getDataFromDB(): Promise<void> {
    await this.localDatabaseService.setTable('user');
    const usr = await this.localDatabaseService.getItem('user');
    //Fail get data from store
    if (!usr?.length && !this.offlineUser) {
      return this.clearOfflineChanges();
    }
    this.offlineUser = JSON.parse(usr);
    await Wait();
    await this.localDatabaseService.setTable('inventories');
    const allInventories = await this.localDatabaseService.getAllValues();
    allInventories.forEach(i => {
      try {
        const invParsed = JSON.parse(i);
        this.offlineInventories.set(invParsed.id, invParsed);
      } catch (e) {
        alert('Inv ' + e);
      }
    });
    await Wait();

    await this.localDatabaseService.setTable('events');
    const allEventAssignments = await this.localDatabaseService.getAllValues();
    for (const e of allEventAssignments) {
      try {
        const ev = JSON.parse(e);
        this.offlineEventAssignments.set(ev.event.id, ev);
      } catch (er: any) {
        alert(er);
      }
    }
    await Wait();
    await this.localDatabaseService.setTable('userChanges');
    const markUsedInv = await this.localDatabaseService.getItem('markUsedInventory');
    if (markUsedInv.length) {
      try {
        this.userChanges.markUsedInventory = JSON.parse(markUsedInv);
      } catch (e) {}
    }
    const removeInv = await this.localDatabaseService.getItem('removeDeviceFromEvent');
    if (removeInv.length) {
      try {
        this.userChanges.removeDeviceFromEvent = JSON.parse(removeInv);
        // Set isClosedOffline to all offline events to show the correct status on the event list
        this.userChanges.closeEvent.forEach(e => {
          const event = this.offlineEventAssignments.get(e.eventId);
          event.event = { ...event.event, ...{ isClosedOffline: true } };
          this.offlineEventAssignments.set(e.eventId, event);
        });
      } catch (e) {}
    }
    const closeEvents = await this.localDatabaseService.getItem('closeEvent');
    if (closeEvents.length) {
      try {
        this.userChanges.closeEvent = JSON.parse(closeEvents);
      } catch (e) {}
    }
  }

  async setUserChangesToDB(actionType: keyof IUserChanges): Promise<void> {
    await this.localDatabaseService.setTable('userChanges');
    if (actionType === 'markUsedInventory') {
      await this.localDatabaseService.setItem('markUsedInventory', JSON.stringify(this.userChanges.markUsedInventory));
    } else if (actionType === 'removeDeviceFromEvent') {
      await this.localDatabaseService.setItem('removeDeviceFromEvent', JSON.stringify(this.userChanges.removeDeviceFromEvent));
    } else if (actionType === 'closeEvent') {
      await this.localDatabaseService.setItem('closeEvent', JSON.stringify(this.userChanges.closeEvent));
      // Set isClosedOffline to all offline events to show the correct status on the event list
      this.userChanges.closeEvent.forEach(e => {
        const event = this.offlineEventAssignments.get(e.eventId);
        event.event = { ...event.event, ...{ isClosedOffline: true } };
        this.offlineEventAssignments.set(e.eventId, event);
      });
    }
  }

  private loadInventories(page: number = 0): void {
    const p: IFilter = { size: 1000, page, custodyId: UsersService.getUser().id };

    const invP = new InventoryPageableParams(p);
    this.inventoriesService
      .getPageable(invP)
      .pipe(take(1), retry(3))
      .subscribe(async (pageable: PageableModel<InventoryModel>) => {
        pageable.content.forEach((i: InventoryModel) => this.offlineInventories.set(i.id, this.cutUnUsedInventoryProperties(i)));
        await this.saveToDB('inventories', pageable.content, null);

        this.inventoriesLoaded = { page: pageable.number, totalPages: pageable.totalPages };
        await this.checkPercentage();
        if (page < this.inventoriesLoaded.totalPages - 1) {
          this.loadInventories(page + 1);
        }
      });
  }

  private async loadEventsData(index: number = 0): Promise<void> {
    return await firstValueFrom(this.getSingleEventAssignments(this.selectedOfflineEvents$.value[index].id)).then(async (result: any) => {
      const eventAssignments: IEventAssignmentsDB = {
        event: this.selectedOfflineEvents$.value[index],
        devices: result[0]?.inventories || [],
        packs: result[0]?.packs || [],
        manufacturers: result[1],
        products: result[2],
        eventContainers: result[3],
        notes: result[4],
        poNumbers: result[5],
        priceAdjustments: result[6]
      };

      this.offlineEventAssignments.set(eventAssignments.event.id, eventAssignments);
      this.eventsLoaded = { page: index, totalPages: this.selectedOfflineEvents$.value.length };
      await this.saveToDB('events', null, eventAssignments);
      await this.checkPercentage();
      if (index < this.selectedOfflineEvents$.value.length - 1) {
        await this.loadEventsData(index + 1);
      }
    });
  }

  private async checkPercentage(): Promise<void> {
    if (!this.inventoriesLoaded || !this.eventsLoaded) {
      return;
    }
    const all: number = this.inventoriesLoaded.totalPages + this.eventsLoaded.totalPages;
    const loaded: number = this.inventoriesLoaded.page + 1 + this.eventsLoaded.page + 1;

    this.offlineStatusService.loadedPercentage$.next(Math.round((loaded * 100) / all));
    const lp = this.offlineStatusService.loadedPercentage$.value;
    // Reduce checking memory
    if ((lp > 20 && lp < 30) || (lp > 80 && lp < 95)) {
      await this.backgroundTasksService.isEnoughMemory();
    }
    if (this.offlineStatusService.loadedPercentage$.value >= 100) {
      // Save from variables to DB when al completed
      this.usersService
        .changeOfflineMOde(UsersService.getUser().id, { makeOffline: true, offlineHours: this.offlineHours })
        .pipe(take(1))
        .subscribe();
      document.body.classList.add('offline');
      this.loaderRef.dismiss();
      this.alertsService.showSuccess('shared.alerts.successMessages.offlineMode');
      this.offlineStatusService.isOffline$.next(true);
      this.offlineStatusService.areRequestsOffline$.next(true);
      this.isOfflineReady$.next(true);
      //Set offline permissions
      this.usersService.me().pipe(take(1)).subscribe();
    }
  }

  private async saveToDB(table: 'inventories' | 'events', inventories: InventoryModel[], event: IEventAssignmentsDB): Promise<void> {
    await Wait();
    if (table === 'inventories') {
      await this.localDatabaseService.setTable('inventories');
      for (const i of inventories) {
        await this.localDatabaseService.setTable('inventories');
        await this.localDatabaseService.setItem(i.id, JSON.stringify(i));
      }
    } else if (table === 'events') {
      await this.localDatabaseService.setTable('events');
      await this.localDatabaseService.setItem(event.event.id, JSON.stringify(event));
    }
    await Wait();
  }

  // Remove unused data to reduce the size that DB keeps on the client side
  private cutUnUsedInventoryProperties(inv: InventoryModel): InventoryModel {
    const invShorten = inv;
    invShorten.catalog.modifiedBy = null;
    invShorten.catalog.manufacturer = null;
    if (invShorten.createdBy) {
      invShorten.createdBy.organization = null;
    }
    if (invShorten.modifiedBy) {
      invShorten.modifiedBy.organization = null;
    }
    if (invShorten.custody) {
      invShorten.custody.organization = null;
    }
    if (invShorten.event) {
      invShorten.event.facility = null;
      invShorten.event.createdBy = null;
      invShorten.event.representative = null;
      invShorten.event.modifiedBy = null;
    }
    if (invShorten.inventoryImportJob) {
      invShorten.inventoryImportJob.createdBy = null;
      invShorten.inventoryImportJob.modifiedBy = null;
    }
    if (invShorten.location) {
      invShorten.location.createdBy = null;
      invShorten.location.modifiedBy = null;
    }
    if (invShorten.billOfMaterial) {
      invShorten.billOfMaterial.createdBy = null;
      invShorten.billOfMaterial.modifiedBy = null;
    }
    if (invShorten.parentInventory) {
      invShorten.parentInventory.createdBy = null;
      invShorten.parentInventory.modifiedBy = null;
    }
    if (invShorten.transfer) {
      invShorten.transfer.createdBy = null;
      invShorten.transfer.modifiedBy = null;
    }
    if (invShorten.lotNumberChangedBy) {
      invShorten.lotNumberChangedBy.organization = null;
    }
    return invShorten;
  }
}
