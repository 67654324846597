import { PermissionModel } from '@shared/models';

export const MergeModels = (modelConfig: PermissionModel): PermissionModel => {
  const model = modelConfig;
  const modelPattern = new PermissionModel();
  model.config = { ...model.config, ...modelConfig.config };
  model.common = { ...model.common, ...modelConfig.common };
  model.dashboard = { ...model.dashboard, ...modelConfig.dashboard };
  model.forbiddenRoutes = [...(model.forbiddenRoutes || [])];
  model.inventory = { ...modelPattern.inventory, ...model.inventory };
  model.events = { ...modelPattern.events, ...model.events };
  model.report = { ...modelPattern.report, ...model.report };
  model.settings = { ...modelPattern.settings, ...model.settings };
  model.exportTask = { ...modelPattern.exportTask, ...model.exportTask };
  model.user = { ...modelPattern.user, ...model.user };
  model.custom = { ...modelPattern.custom, ...model.custom };
  model.userLevel = { ...modelPattern.userLevel, ...model.userLevel };
  model.notifications = { ...modelPattern.notifications, ...model.notifications };
  return model;
};
