import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpCancelService {
  private exceptionsList: string[] = ['dashboard/summary', '/organizations', 'quickbooks/account', 'alerts/pageable', 'requestRecipes'];
  private pendingHTTPRequests$ = new Subject<void>();

  // Cancel Pending HTTP calls
  cancelPendingRequests() {
    this.pendingHTTPRequests$.next();
  }

  onCancelPendingRequests(req: HttpRequest<any>): Observable<any> {
    /**
     * Cancel requests only for GET methods
     */
    if (req.method !== 'GET') {
      return new Observable();
    }
    /**
     * Check url exception
     */
    const exception: boolean = this.exceptionsList.some(u => req.url.includes(u));
    if (exception) {
      return new Observable();
    } else {
      return this.pendingHTTPRequests$.asObservable();
    }
  }
}
