import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-string-skeleton',
  templateUrl: './string-skeleton.component.html',
  styleUrls: ['./string-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StringSkeletonComponent {
  @Input() count: number = 1;
  @Input() set theme(data: { [key: string]: string }) {
    this.skeletonTheme = { ...this.skeletonTheme, ...data };
  }
  @Input() appearance: 'circle' | 'line' = 'line';
  @Input() set defaultBackground(data: boolean) {
    if (data) {
      this.skeletonTheme = { ...this.skeletonTheme, ...{ 'background-color': 'var(--overlay-light)' } };
    }
  }
  @Input() set defaultInput(data: boolean) {
    if (data) {
      this.skeletonTheme = { ...this.skeletonTheme, ...{ height: '48px', 'border-radius': '8px' } };
    }
  }

  skeletonTheme: { [key: string]: string } = { 'background-color': 'var(--base)', margin: '0' };
}
