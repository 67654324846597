import { TState } from '@shared/type/index.type';
import { EProcedureTypes } from '@shared/enum';
import { CreatedDatetime, ModifiedDatetime } from '@shared/models/shared/shared-kernel';
import { UserModel } from '@shared/models';

export class ProcedureModel {
  createdBy: UserModel;
  createdDatetime: CreatedDatetime;
  id: string;
  modifiedBy: UserModel;
  modifiedDatetime: ModifiedDatetime;
  modifiers: string[];
  name: string;
  organizationId: string;
  state: TState;
  type: keyof typeof EProcedureTypes;
}
