import { CatalogModel } from '@shared/models';
import { TState } from '@shared/type/index.type';
import { UserModel } from '@shared/models';
import { EBOMType } from '@shared/enum';

export class BomDetails {
  catalog: CatalogModel;
  createdBy: UserModel;
  createdDatetime: string | Date;
  existedQuantity?: number;
  id: string;
  modifiedBy: UserModel;
  modifiedDatetime: string | Date;
  quantity: number;
  minQuantity?: number;
  maxQuantity?: number;
  numberOfInventoriesForCatalog: number;
  state: TState;
}

export class BillOfMaterials {
  catalogCount: number;
  createdBy: UserModel;
  createdDatetime: Date | string;
  id: string;
  inventoryCount: number;
  modifiedBy: UserModel;
  modifiedDatetime: Date | string;
  name: string;
  quantity: number;
  state: TState;
  bomType: keyof typeof EBOMType;
  //exist in type PACK
  packCatalog?: CatalogModel;
}
