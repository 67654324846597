import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { PermissionsGuard } from '@guards/permissions/permissions.guard';
import { Observable } from 'rxjs/internal/Observable';
import { AuthenticationService } from '../auth.service';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpHelperService {
  static httpOptionsTextResponse: any = {
    headers: new HttpHeaders({
      Accept: 'text/html, application/xhtml+xml, */*',
      'Content-Type': 'application/x-www-form-urlencoded'
    }),
    responseType: 'text'
  };

  static addResponseTypeJSON(params: any): { params: any } {
    return { params: { ...params, ...{ responseType: 'json' as 'json' } } };
  }

  static addResponseTypeText(params: any): any {
    return { ...params, ...{ responseType: 'text' } };
  }

  static errorHandler(error: HttpErrorResponse, _caught: Observable<any>, redirectToNotFoundPage: boolean = false) {
    if ((error.status === 404 || error.toString().includes('not found')) && redirectToNotFoundPage) {
      PermissionsGuard.redirectToNotFound();
    }
    return throwError(error.error);
  }

  static getParams(query: any): HttpParams {
    let params: HttpParams = new HttpParams();
    for (const [key, value] of Object.entries(query)) {
      if (value) {
        // @ts-ignore
        params = params.append(key.toString(), value);
      }
    }
    return params;
  }

  static getQueryParams(params: any): string {
    return Object.keys(params)
      .map(key => `${key}=${encodeURIComponent(params[key])}`)
      .join('&');
  }

  static setImageParams(method: 'PUT' = 'PUT', image: FormData): any {
    const token = AuthenticationService.getToken();
    return {
      method,
      body: image,
      headers: { Authorization: `Bearer ${token}` }
    };
  }
}
