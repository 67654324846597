<div class="mobileScreen">
  <app-back-prev-page class="mobileScreenBackButton"></app-back-prev-page>

  <div class="mobileTitle" [style.maxWidth]="maxWidth$ | async">
    <span>{{ title$ | async }}</span>
  </div>

  <span
    *ngIf="(statusData$ | async)?.status && !showMobileNavbar"
    #statusTemplate
    class="mobileStatusContainer"
    [ngClass]="(statusData$ | async).class"
    >{{ (statusData$ | async).status }}</span
  >
</div>

<div
  *ngIf="showMobileNavbar && (isPreparingOffline$ | async) === false"
  class="mobileIconContainer burger"
  fxLayoutAlign="center center"
  (click)="menuTogglerEmitter.emit()"
>
  <app-icon [size]="24" [icon]="'menu'" [color]="'primary'" [type]="'outline'"></app-icon>
</div>
<ng-container *appHasPermission="{ scope: 'notifications', permissionName: 'readNotifications' }">
  <div
    *ngIf="showMobileNavbar && (isOffline$ | async) === false && (isPreparingOffline$ | async) === false"
    class="mobileIconContainer notifications"
    fxLayoutAlign="center center"
  >
    <app-icon [size]="24" [icon]="'notification'" [color]="'primary'" [type]="'outline'" routerLink="/notifications"></app-icon>
    <span
      fxLayoutAlign="center center"
      *ngIf="totalElements > 0"
      class="badge"
      [ngClass]="{ single: totalElements < 10, double: totalElements >= 10 && totalElements <= 99 }"
      >{{ totalElements > 99 ? '99+' : totalElements }}</span
    >
  </div>
</ng-container>
