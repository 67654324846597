import { HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '@services/auth.service';

export const HeaderOptionsWithToken = (bodyParams: any) => {
  const token = AuthenticationService.getToken();
  return {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }),
    body: bodyParams
  };
};
