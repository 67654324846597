import { Injectable } from '@angular/core';
import { ApiService } from '@shared/classes/api-service';
import { catchError, Observable, of } from 'rxjs';
import { HttpHelperService } from '@services/internal/http-helper.service';
import { TEventType } from '@shared/type/index.type';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from '@services/auth.service';
import { environment } from '@environment';
import { ISupposedInventories } from '@shared/interfaces/events/hand-written-inventories-info';
import { IAIBasicEventCreationResponse, IAIEventCreation, IBasicEventInfoParams } from '@shared/interfaces/events/ai-event-creation';
import { TAiParseAcceptedImagesFormats } from '@shared/type/ai-parse-image-formats';

@Injectable({
  providedIn: 'root'
})
export class AiPredictionsService extends ApiService {
  getEventType(): Observable<TEventType> {
    return this.getAI<TEventType>(`most-used-event-type`, HttpHelperService.addResponseTypeText({})).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of('CASE'))
    );
  }

  async convertImageToJson(formData: FormData, format: TAiParseAcceptedImagesFormats): Promise<ISupposedInventories[]> {
    const token: string = AuthenticationService.getToken();
    const res = await window.fetch(`${environment.apiUrlAI}convert-to-json?format=${format}`, {
      method: 'POST',
      body: formData,
      headers: { Authorization: `Bearer ${token}` }
    });
    const result = await res.json();
    if (!res.ok) {
      this.alertsService.showError(null, result?.message, 10000);
      return [];
    }
    Promise.resolve(result);
    return result;
  }

  async aiCreateEventInfo(formData: FormData, format: TAiParseAcceptedImagesFormats): Promise<IAIEventCreation> {
    const token: string = AuthenticationService.getToken();
    const res = await window.fetch(`${environment.apiUrlCore}events/ai-create-event-info?format=${format}`, {
      method: 'POST',
      body: formData,
      headers: { Authorization: `Bearer ${token}` }
    });
    const result = await res.json();
    if (!res.ok) {
      this.alertsService.showError(null, result?.message, 10000);
      return null;
    }
    Promise.resolve(result);
    return result;
  }

  aiBasicCreateEventInfo(params: IBasicEventInfoParams): Observable<IAIBasicEventCreationResponse> {
    return this.post<IAIBasicEventCreationResponse>(
      `events/ai-basic-create-event-info`,
      HttpHelperService.addResponseTypeText(params)
    ).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error?.message, 10000);
        return null;
      })
    );
  }
}
