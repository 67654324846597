import { PermissionModel } from '@shared/models';
import { ErrorRole } from '../roles/error.role';
import { DistributorAdminRole } from '../roles/distributor-company/distributor.admin.role';
import { DistributorSalesRole } from '../roles/distributor-company/distributor.sales.role';
import { DistributorOperationsRole } from '../roles/distributor-company/distributor.operations.role';
import { DistributorFinanceRole } from '../roles/distributor-company/distributor.finance.role';
import { DistributorDistributorRole } from '../roles/distributor-company/distributor.distributor.role';
import { TRoles } from '@shared/type/index.type';
import { DistributorRegionalManagerRole } from '../roles/distributor-company/distributor.regional-manager.role';

export class DistributorCompanyTypeFactory {
  constructor() {}

  createRoles(role: TRoles): PermissionModel {
    switch (role) {
      case 'ADMIN':
        return new DistributorAdminRole().model;
      case 'DISTRIBUTOR':
        return new DistributorDistributorRole().model;
      case 'SALES':
        return new DistributorSalesRole().model;
      case 'FINANCE':
        return new DistributorFinanceRole().model;
      case 'OPERATIONS':
        return new DistributorOperationsRole().model;
      case 'REGIONAL_MANAGER':
        return new DistributorRegionalManagerRole().model;
      default:
        return new ErrorRole().model;
    }
  }
}
