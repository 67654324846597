import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TransfersService } from '@services/transfers.service';
import { RequestsService } from '@services/requests.service';
import { BehaviorSubject, firstValueFrom, map, take } from 'rxjs';
import { NoteModel } from '@shared/models';
import { TCommentsType } from '@shared/type/index.type';
import { InventoriesService } from '@services/inventories.service';
import { ParseUserIds } from '../utils/parse-user-ids';
import { CommentsComponent } from '@shared/modules/comments/comments/comments.component';
import { AlertsService } from '@services/internal/alerts.service';
import { PermissionService } from '@services/internal/permission.service';
import { EventsService } from '@services/events.service';
import { Router } from '@angular/router';
import { UsersService } from '@services/users.service';
import { CreateSmallEntityConfig } from '@constants';

@Component({
  selector: 'app-comments-modal',
  templateUrl: './comments-modal.component.html',
  styleUrls: ['./comments-modal.component.scss']
})
export class CommentsModalComponent implements OnInit {
  comment: string = '';
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  notes: NoteModel[] = [];
  readonly isAdmin: boolean = UsersService.getUser().role === 'ADMIN';

  constructor(
    public dialogRef: MatDialogRef<CommentsModalComponent>,
    public permissionService: PermissionService,
    @Inject(MAT_DIALOG_DATA)
    public data: { id: string; type: TCommentsType; comments: NoteModel[]; additionalNote?: string; eventId: string },
    private transfersService: TransfersService,
    private requestsService: RequestsService,
    private inventoriesService: InventoriesService,
    private alertsService: AlertsService,
    private eventsService: EventsService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  close(res?: { message: string; userIds: string[] }): void {
    this.dialogRef.close(res);
  }

  async ngOnInit(): Promise<void> {
    this.loading$.next(true);
    if (this.data.type === 'TRANSFER') {
      this.notes = await firstValueFrom(
        this.transfersService.getComments(this.data.id).pipe(map(c => CommentsComponent.replaceBrComments(c)))
      );
    }
    if (this.data.type === 'REQUEST') {
      this.notes = await firstValueFrom(this.requestsService.getNotes(this.data.id).pipe(map(c => CommentsComponent.replaceBrComments(c))));
    }
    if (this.data.type === 'INSPECTION') {
      this.notes = await firstValueFrom(
        this.inventoriesService.getInspectionComments(this.data.id).pipe(map(c => CommentsComponent.replaceBrComments(c)))
      );
    }
    if (this.data.type === 'EVENT') {
      this.notes = await firstValueFrom(this.eventsService.getNotes(this.data.id).pipe(map(c => CommentsComponent.replaceBrComments(c))));
    }
    if (this.data.type === 'CHECKIN') {
      this.notes = [...this.data.comments];
    }
    this.loading$.next(false);
  }

  viewDetails(): void {
    this.dialog.closeAll();
    this.router.navigate(['/events/edit/' + this.data.eventId]);
  }

  save(): void {
    if (this.comment.length) {
      const message: string = CommentsComponent.replaceUnSupportedSymbols(this.comment);
      if (message?.length > 9999) {
        this.alertsService.showError('shared.alerts.errorMessages.maxLengthComment');
        return;
      }
      this.close({ message, userIds: ParseUserIds(this.comment) });
    }
  }

  async viewEventInfo(): Promise<void> {
    const dialogConfig: MatDialogConfig = { ...CreateSmallEntityConfig };
    dialogConfig.data = { eventId: this.data.eventId };
    dialogConfig.height = '350px';
    const eventsInfoMessagingComponent = await import(
      '../../../../features/events/shared/standalone/modals/events-info-messaging/events-info-messaging.component'
    ).then(c => c.EventsInfoMessagingComponent);
    this.dialog.open(eventsInfoMessagingComponent, dialogConfig).afterClosed().pipe(take(1)).subscribe();
  }
}
