import { NgModule } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SkeletonComponent } from './partials/skeleton/skeleton.component';
import { CommonModule } from '@angular/common';
import { StringSkeletonComponent } from './partials/string-skeleton/string-skeleton.component';

@NgModule({
  declarations: [SkeletonComponent, StringSkeletonComponent],
  imports: [NgxSkeletonLoaderModule, CommonModule],
  exports: [SkeletonComponent, StringSkeletonComponent]
})
export class SkeletonModule {}
