<div class="full-width" fxLayout="row" fxLayoutAlign="start center">
  <app-input-with-chips-kit
    *ngIf="multiple; else single"
    class="full-width"
    [dataCy]="'modifiersInput'"
    [chipDataCy]="'modifiersChip'"
    [chipRemoveData_cy]="'modifiersRemoveChipButton'"
    [selectedChips]="selectedChips"
    [label]="label | language"
    [showAddChipButton]="true"
    [clearInput]="clearInput"
    [disabled]="disabled"
    [selectable]="true"
    [skeleton]="skeleton"
    [debounceEvents]="['keyup', 'focus']"
    [debounceTime]="1000"
    [required]="false"
    [requiredView]="false"
    [removable]="removable"
    [addOnBlur]="true"
    [autocompleteName]="autocomplete"
    [parseChipByProperty]="'modifierName'"
    [separatorKeysCodes]="separatorKeysCodes"
    (chipsRemovedEmitter)="removeChip($event)"
    (debounceTimeEndedEmitter)="getModifiers($event)"
    (chipInputEndEmitter)="selectModifier($event?.input?.value, true); clearInput = true"
    (inputCleared)="clearInput = false"
  ></app-input-with-chips-kit>

  <ng-template #single>
    <app-input-kit
      fxFlex="100%"
      [label]="label | language"
      [disabled]="disabled"
      [skeleton]="skeleton"
      [dataCy]="'modifiersInput'"
      [initialValue]="modifier"
      [debounceEvents]="['keyup', 'focus']"
      [debounceTime]="1000"
      [icons]="[showClearIcon ? 'close' : null]"
      [autocompleteName]="autocomplete"
      (debounceTimeEndedEmitter)="getModifiers($event)"
      (iconClickEmitter)="doIconAction($event)"
    ></app-input-kit>
  </ng-template>

  <mat-autocomplete
    #autocomplete="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="selectModifier($event.option?.value?.modifierName); clearInput = true"
  >
    <mat-option *ngIf="(loading$ | async) === true || (initialLoading$ | async) === true" class="is-loading" disabled>
      <mat-progress-spinner value="30" class="spinner" mode="indeterminate" diameter="30"></mat-progress-spinner>
    </mat-option>
    <ng-container *ngIf="(loading$ | async) === false && (initialLoading$ | async) === false">
      <mat-option *ngIf="(searchedData$ | async)?.length === 0; else data" disabled class="connectSxAutocompleteOption"
        ><span>{{ 'shared.labels.noItemsFound' | language }}</span></mat-option
      >
      <ng-template #data>
        <cdk-virtual-scroll-viewport
          itemSize="0"
          class="virtualAutocompleteScroll"
          [style.height.px]="(searchedData$ | async).length < 3 ? (searchedData$ | async).length * 50 : 150"
        >
          <mat-option
            data-cy="modifierOption"
            *cdkVirtualFor="let modifier of searchedData$ | async"
            class="connectSxAutocompleteOption"
            [value]="modifier"
          >
            <span>{{ modifier.modifierName }}</span>
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </ng-template>
    </ng-container>
  </mat-autocomplete>
</div>
