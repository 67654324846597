import { UntypedFormGroup, Validators } from '@angular/forms';
import { TPhoneFormat } from '@shared/type/index.type';

export const checkPhoneFormat = (form: UntypedFormGroup, format: TPhoneFormat, control: string = 'phone') => {
  if (format === 'INTERNATIONAL') {
    form.get(control).setValidators([Validators.minLength(11), Validators.pattern('^[0-9]*$')]);
    if (form.get(control).value?.length < 11) {
      form.get(control).setErrors({ format: true });
    } else {
      form.get(control).setErrors({ format: null });
    }
    form.get(control).updateValueAndValidity();
  } else {
    form.get(control).setValidators([Validators.minLength(10), Validators.pattern('^[0-9]*$')]);
    let phoneValue: string = form.get(control).value || '';
    phoneValue = phoneValue.slice(0, 10);
    form.get(control).patchValue(phoneValue);
    form.get(control).setErrors({ format: null });
    form.get(control).updateValueAndValidity();
  }
};

export const IsNumbers = (str: string): boolean => new RegExp('^[0-9]*$').test(str);
