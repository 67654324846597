import { IFilter } from '@shared/interfaces';
import { DefaultQueryParams } from '@shared/helpers/default-query-params';
import { ClearRequest } from '@shared/utils/filter-object-null-properties';

export class LocationStatePageableParams {
  countryName?: string;
  ignoreUsedLocation?: boolean;
  locationStateName?: string;
  page: number;
  size: number;
  sort: string[];

  constructor(params: IFilter) {
    return LocationStatePageableParams.build(params);
  }

  private static build(params: IFilter): LocationStatePageableParams {
    const queryParams: LocationStatePageableParams = DefaultQueryParams(params);

    queryParams.locationStateName = params.search;
    queryParams.countryName = params.countryName;
    queryParams.ignoreUsedLocation = params.ignoreUsedLocation;

    return ClearRequest(queryParams);
  }
}
