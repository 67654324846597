import { IFilter } from '@shared/interfaces';
import { DefaultQueryParams } from '@shared/helpers/default-query-params';
import { ClearRequest } from '@shared/utils/filter-object-null-properties';
import { TImportStatus } from '@shared/type/index.type';

export class InventoryImportPageable {
  importName?: string;
  importStatus?: TImportStatus;
  page: number;
  size: number;
  sort: string[];

  constructor(params: IFilter) {
    return InventoryImportPageable.build(params);
  }

  private static build(params: IFilter): InventoryImportPageable {
    const queryParams: InventoryImportPageable = DefaultQueryParams(params);

    queryParams.importName = params.search;

    if (params.importStatus) {
      queryParams.importStatus = params.importStatus;
    }

    return ClearRequest(queryParams);
  }
}
