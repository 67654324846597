import { ManufacturerModel } from '@shared/models/features/directory/manufacturers/manufacturer.model';
import { ProductLineModel } from '@shared/models/features/inventory/product-line/productline.model';
import { ImageModel } from '@shared/models/shared/image.model';
import { TItemType, TState } from '@shared/type/index.type';
import { BillOfMaterials, UserModel } from '@shared/models';

export class CatalogModel {
  catalogType: TItemType;
  assignedProductLines?: ProductLineModel[];
  backOrder: boolean;
  belowParLevel: boolean;
  billOfMaterialsQuantity?: number;
  billOfMaterialsMinQuantity?: number;
  billOfMaterialsMaxQuantity?: number;
  colorBand: string;
  costOfGoods: string;
  createdBy: UserModel;
  createdDatetime: string;
  disabledRemoveInBOM?: boolean;
  id: string;
  inventoryCount: number;
  name: string;
  images?: ImageModel[];
  inventoryRequestProductId?: string;
  inventoryRequestQuantity?: number;
  leadTimeNumber: number;
  lowStock: boolean;
  manufacturer: ManufacturerModel;
  modifiedBy: UserModel;
  modifiedDatetime: string;
  numberOfInventoriesForCatalog?: number;
  organizationId: string;
  parLevel: number;
  products?: ProductLineModel[];
  referenceNumber: string;
  state: TState;
  tags: string[];
  warehouse: string;
  unitPrice?: number; // Front-end property
  billOfMaterial: BillOfMaterials;
}
