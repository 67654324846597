import { Injectable } from '@angular/core';
import { IMobileActions } from '@shared/interfaces/mobile-actions';
import { BreadcrumbService } from 'xng-breadcrumb';
import { EPageDataRoutes } from '../enums/page-data-routes.enum';
import { GlobalStoreService } from '@services/internal/global-store.service';

@Injectable()
export class PageDataService {
  private static breadcrumbService: BreadcrumbService | undefined = undefined;

  constructor(private breadcrumbService: BreadcrumbService) {
    PageDataService.breadcrumbService = this.breadcrumbService;
  }

  static setPageData(route: EPageDataRoutes, name: string, statusData?: IMobileActions): void {
    const breadcrumbService = PageDataService.getBreadcrumbService();
    breadcrumbService.set(route, name);
    GlobalStoreService.screenTitle$.next(name);
    GlobalStoreService.mobileActions$.next(statusData);
  }

  static getBreadcrumbService(): BreadcrumbService {
    return PageDataService.breadcrumbService;
  }
}
