export class RolesStatisticsRole {
  count: number;
  remaining: number;
  constructor() {
    this.count = 0;
    this.remaining = null;
  }
}
export class RolesStatistics {
  totalUsers?: RolesStatisticsRole;
  operationUsersCount?: RolesStatisticsRole;
  regionalManagersCount?: RolesStatisticsRole;
  distributorsCount?: RolesStatisticsRole;
  salesCount?: RolesStatisticsRole;
}
