import { KeyValue, KeyValuePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { TranslationsKeys } from '@shared/type/i18n.type';

@Component({
  selector: 'app-select-kit',
  templateUrl: './select-kit.component.html',
  styleUrls: ['./select-kit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [KeyValuePipe]
})
export class SelectKitComponent implements AfterViewInit {
  @Input() set selectData(data: any[] | Record<string, string>) {
    if (Array.isArray(data)) {
      this.items = data;
    } else {
      this.items = this.keyValuePipe.transform(data);
    }
  }
  @Input() useDisabledInOptions: boolean = false;
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() hint: TranslationsKeys;
  @Input() error: string = '';
  @Input() parseValueByProperty: string = '';
  @Input() parseKeyByProperty: string = '';
  @Input() parseKeyByAdditionalProperty: string = '';
  @Input() dataCy: string = '';
  @Input() optionDataCy: string = '';
  @Input() optionDataCyValueShowString: boolean = false;
  @Input() filterView: boolean = false;
  @Input() touched: boolean = false;
  @Input() borderTransparent: boolean = false;
  @Input() parseValueByIndex: boolean = false;
  @Input() parseValueByItem: boolean = false;
  @Input() parseKeyByItem: boolean = false;
  @Input() compareOptionWith: number | string = null;
  @Input() showNoneOption: boolean = false;
  @Input() noneOptionLabel: string = 'None';
  @Input() showAnyOption: boolean = false;
  @Input() disableNoneOption: boolean = false;
  @Input() hiddenOption: string[] = [];
  @Input() resetValueAfterSelect: boolean = false;
  @Input() overflowHidden: boolean = false;
  @Input() translateValue: boolean = false;
  @Input() displayColorPalette: boolean = false;
  @Input() skeleton: boolean = false;
  @Input() set initialValue(data: any) {
    this.value = data;
  }
  @Input() customOptionTemplate: TemplateRef<any>;
  @Input() showHoverForLongOptions: boolean = false;

  @Output() selectEmitter = new EventEmitter<any>();
  @Output() addNewEmitter = new EventEmitter<void>();

  value: string = '';
  focused: boolean = false;
  items: any[] | KeyValue<string, string>[] = [];

  constructor(
    private keyValuePipe: KeyValuePipe,
    private ref: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.ref.markForCheck();
  }

  updateValue(value: string) {
    this.selectEmitter.emit(value);

    if (this.resetValueAfterSelect) {
      setTimeout(() => (this.value = ''));
    }
  }
}
