import { Injectable } from '@angular/core';
import { ApiService } from '@shared/classes/api-service';
import { HttpErrorResponse } from '@angular/common/http';
import { of, catchError, Observable } from 'rxjs';
import { PageableModel } from '@shared/models';
import { IModifier } from '@shared/interfaces';
import { TModifier } from '@shared/type/index.type';

@Injectable({
  providedIn: 'root'
})
export class ModifiersService extends ApiService {
  constructor() {
    super();
  }

  getModifiers(params: { modifierName: string; entityType: TModifier }): Observable<PageableModel<IModifier>> {
    return this.get<PageableModel<IModifier>>(`modifiers`, { params }).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of(new PageableModel()))
    );
  }
}
