export enum ETransferStatus {
  'PENDING' = 'shared.enums.transferStatus.pending',
  'ACCEPTED' = 'shared.enums.transferStatus.accepted',
  'DECLINED' = 'shared.enums.transferStatus.declined',
  'HOLD' = 'shared.enums.transferStatus.hold',
  'INITIALIZING' = 'shared.enums.transferStatus.initializing',
  'ERROR' = 'shared.enums.transferStatus.error'
}
export enum ETransferStatusFilter {
  'PENDING' = 'shared.enums.transferStatus.pending',
  'ACCEPTED' = 'shared.enums.transferStatus.accepted',
  'DECLINED' = 'shared.enums.transferStatus.declined',
  'HOLD' = 'shared.enums.transferStatus.hold'
}
