import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  InventoryRequestAssignment,
  InventoryRequestModel,
  SimilarInventoryInfo,
  NoteModel,
  PageableModel,
  SimilarInventoryFilter
} from '@shared/models';
import { InventoryReceiveModel, InventoryRequestRequestModel, UpdateInventoryRequestModel } from '@shared/models/request-models';
import { HttpHelperService } from './internal/http-helper.service';
import { RequestPageableSearchParams } from '@shared/models/build-models';
import { ITrackingNumber } from '@shared/interfaces';
import { catchError, map, Observable, of } from 'rxjs';
import { ApiService } from '@shared/classes/api-service';
import { EInventoryRequestStatus } from '@shared/enum';
import { IAssignmentsShippingDto } from '@shared/interfaces/inventory-requests/assignment-shipping-dto';
import { UsersService } from '@services/users.service';

@Injectable({
  providedIn: 'root'
})
export class RequestsService extends ApiService {
  addComment(id: string, message: string, userIds: string[]): Observable<boolean> {
    return this.post<void>(`inventoryRequests/${id}/notes?mentionUserIds=${userIds}`, message).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  editComment(id: string, commentId: string, message: string, userIds: string[], commentUserIds: string[]): Observable<boolean> {
    return this.put<void>(
      `inventoryRequests/${id}/note/${commentId}?newMentionUserIds=${userIds}&existMentionUserIds=${commentUserIds}`,
      message
    ).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  setShippingAddressId(requestId: string, id: string): Observable<InventoryRequestModel> {
    return this.post<InventoryRequestModel>(`inventoryRequests/${requestId}/shippingAddress`, `"${id}"`).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.saved')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getRequest(id: string, redirectToNotFoundPage: boolean = false): Observable<InventoryRequestModel> {
    return this.get<InventoryRequestModel>(`inventoryRequests/${id}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        HttpHelperService.errorHandler(error, null, redirectToNotFoundPage);
        return of(null);
      })
    );
  }

  getAssignments(id: string): Observable<InventoryRequestAssignment[]> {
    return this.get<InventoryRequestAssignment[]>(`inventoryRequests/${id}/assignment`);
  }

  getInventoriesToTransferGrouped(requestId: string, params: SimilarInventoryFilter, size: number): Observable<SimilarInventoryInfo[]> {
    return this.post<SimilarInventoryInfo[]>(`inventoryRequests/${requestId}/search/grouped?size=${size}`, params).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of([]))
    );
  }

  getNotes(id: string): Observable<NoteModel[]> {
    return this.get<NoteModel[]>(`inventoryRequests/${id}/notes`).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.loadNotes');
        return of([]);
      })
    );
  }

  getPageable(params: RequestPageableSearchParams): Observable<PageableModel<InventoryRequestModel>> {
    return this.get<PageableModel<InventoryRequestModel>>(`inventoryRequests`, HttpHelperService.addResponseTypeJSON(params));
  }

  receive(id: string, body: InventoryReceiveModel): Observable<void> {
    return this.put<void>(`inventoryRequests/${id}/receive`, body).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  assignTransfers(id: string, transferIds: string[], status: keyof typeof EInventoryRequestStatus): Observable<boolean> {
    return this.put<void>(`inventoryRequests/${id}/transfer?transferIds=${transferIds}&inventoryRequestStatus=${status}`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.assigned');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  sendInventoryRequest(params: InventoryRequestRequestModel): Observable<string> {
    return this.post<string>(`inventoryRequests`, params).pipe(
      map((id: string) => {
        this.alertsService.showSuccess('shared.alerts.successMessages.requestWasCreated');
        return id;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  update(id: string, body: UpdateInventoryRequestModel, recipientId: string): Observable<boolean> {
    const param = recipientId ? `?recipientId=${recipientId}` : '';
    return this.post<void>(`inventoryRequests/${id}` + param, body).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  updateTrackingNumber(id: string, body: ITrackingNumber): Observable<void> {
    return this.put<void>(`inventoryRequests/${id}/tracking`, body).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.saved')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  shipByDate(id: string, shipByDate: string): Observable<InventoryRequestModel> {
    return this.post<InventoryRequestModel>(`inventoryRequests/${id}/shipByDate`, shipByDate).pipe(
      map(request => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return request;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  saveAssignmentsShipping(inventoryRequestId: string, params: IAssignmentsShippingDto[]): Observable<boolean> {
    return this.put<void>(`inventoryRequests/${inventoryRequestId}/assignment`, params).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  claimInProgress(inventoryRequestId: string): Observable<boolean> {
    const claimedById = UsersService.getUser().id;
    return this.put<void>(`inventoryRequests/${inventoryRequestId}/claim`, { claimedById }).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  unClaimInProgress(inventoryRequestId: string): Observable<boolean> {
    return this.put<void>(`inventoryRequests/${inventoryRequestId}/unclaim`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }
}
