<app-create-entity-modal
  [title]="'products.createProductTitle' | language"
  [showCreateButton]="stepperRef?.selectedIndex === 1"
  [showPrevStepButton]="stepperRef?.selectedIndex === 1"
  [showNextStepButton]="stepperRef?.selectedIndex === 0"
  [disableNextStepButton]="requiredFields?.invalid"
  [size]="'small'"
  [confirmationRequired]="true"
  [showCloseConfirmation]="requiredFields?.touched"
  [disableCreateButton]="!formsValid || (loading$ | async) === true"
  (prevStepEmitter)="stepperRef.previous()"
  (nextStepEmitter)="stepperRef.next()"
  (createEmitter)="!data?.redirect ? createWithoutRedirect() : createProduct()"
>
  <mat-stepper #stepper [linear]="true" [disableRipple]="true">
    <ng-template matStepperIcon="edit">
      <mat-icon>check</mat-icon>
    </ng-template>
    <mat-step [label]="'products.productDetailsLabel' | language" [stepControl]="requiredFields">
      <div *ngIf="showAssignToCustodyCheckbox" class="assignToCustodyCheckbox">
        <app-checkbox-kit
          [initialValue]="assignToCustody"
          (checkEmitter)="assignToCustody = $event"
          [title]="'products.assignProductToCustodyCheckbox' | language"
        ></app-checkbox-kit>
      </div>

      <app-form-product-line-step-first
        [modifiers]="modifiers$ | async"
        [createEntityFlow]="true"
        (formValueChangeEmitter)="requiredFields = $event; checkValidation()"
      ></app-form-product-line-step-first>
    </mat-step>
    <mat-step [label]="'products.productAssetsLabel' | language" [stepControl]="assets">
      <app-form-product-line-step-second
        [createEntityFlow]="true"
        [formsValid]="formsValid"
        (formValueChangeEmitter)="assets = $event; checkValidation()"
      ></app-form-product-line-step-second>
    </mat-step>
  </mat-stepper>
</app-create-entity-modal>
