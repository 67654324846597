<div fxLayout="column" fxLayoutGap="8px" *ngIf="anchors.length" fxHide.lt-lg class="infoDataBlockAnchorsContainer">
  <div
    *ngFor="let item of anchors"
    class="infoBlockAnchorItem"
    fxLayout="row"
    fxLayoutGap="8px"
    fxLayoutAlign="start center"
    (mouseenter)="item.hover = true"
    (mouseleave)="item.hover = false"
    (click)="scrollToAnchor(item.selector)"
  >
    <app-icon [type]="'outline'" [color]="item.hover ? 'primary' : 'default'" [icon]="item.icon" [size]="16"></app-icon>
    <span class="label">{{ item.title | language }}</span>
  </div>
</div>
