export const ParseUserIds = (message: string = ''): string[] => {
  const userIds: string[] = [];
  const array: string[] = message?.split('data-id="') || [];

  if (array.length > 1) {
    array.splice(0, 1);
    array.forEach(row => {
      const endIndex: number = row.indexOf('"');
      const userId: string = row.slice(0, endIndex);
      userIds.push(userId);
    });
  }

  return userIds;
};
