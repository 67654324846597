<div
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="16px"
  class="noItemsContainer"
  [ngClass]="{ resetHeight: resetHeight, resetPadding: resetPadding }"
>
  <img src="/assets/img/no_items_logo.svg" alt="No Items" title="No items" draggable="false" />
  <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="start stretch">
    <span class="imageLabel" [attr.data-cy]="dataCy">{{ label | language }}</span>
    <span *ngIf="additionalLabel" class="imageLabel">{{ additionalLabel | language }}</span>
  </div>
</div>
