import { Component, ChangeDetectionStrategy, Input, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreateSmallEntityConfig } from '@constants';
import { EventsService } from '@services/events.service';
import { GlobalStoreService } from '@services/internal/global-store.service';
import { NotificationsService } from '@services/notifications.service';
import { MessagingNotificationModel } from '@shared/models';
import { UserDatePipe } from '@shared/pipes/user-date.pipe';
import { inOutAnimation } from '@shared/utils/animations/in-out-animation';
import { GetTextContentFromHTML } from '@shared/utils/get-text-content-from-html';
import { BehaviorSubject, finalize, take } from 'rxjs';
import { LayoutsComponent } from '../layouts/layouts.component';
import { OpenLinkInBrowser } from '@shared/utils/cross-platform/open-link-in-browser';
import { LanguageService } from '@services/internal/language.service';
import { CommentsModalComponent } from '@shared/modules/comments/comments-modal/comments-modal.component';

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [inOutAnimation],
  providers: [UserDatePipe]
})
export class MessagingComponent {
  @Input() messages: MessagingNotificationModel[] = [];
  @Input() loading: boolean = false;
  @Input() totalMessagingNotifications: number = 0;

  expanded: boolean = false;
  disabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly isMobile$: BehaviorSubject<boolean> = GlobalStoreService.isMobile$;
  readonly getTextContentFromHTML = GetTextContentFromHTML;
  readonly openInBrowser = OpenLinkInBrowser;

  constructor(
    private dialog: MatDialog,
    private eventsService: EventsService,
    private userDatePipe: UserDatePipe,
    private notificationsService: NotificationsService,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: MessagingNotificationModel[]
  ) {
    if (this.data?.length) {
      this.messages = data;
    }
  }

  async viewComments(message: MessagingNotificationModel): Promise<void> {
    this.disabled$.next(true);

    const dialogConfig: MatDialogConfig = { ...CreateSmallEntityConfig };

    dialogConfig.data = {
      type: 'EVENT',
      id: message.eventId,
      additionalNote:
        LanguageService.instant('shared.components.commentsListNote') +
        ' <b>' +
        message.eventName +
        (message.eventDate ? ' (' + this.userDatePipe.transform(message.eventDate, 'MM/dd/yyyy') + ')</b>.' : '</b>.'),
      eventId: message.eventId
    };

    if (message.unreadCount) {
      this.markMessageAsRead(message.eventId);

      if (this.isMobile$.value) {
        message.unreadCount = 0;
      }
    }
    // Loading CommentsModalComponent async because it has heavy app-wysiwyg component inside
    await import('../../../modules/comments/comments.module');
    this.dialog
      .open(CommentsModalComponent, dialogConfig)
      .afterClosed()
      .pipe(
        take(1),
        finalize(() => this.disabled$.next(false))
      )
      .subscribe(result => {
        if (result?.message) {
          this.addComment(message.eventId, result.message, result.userIds);
        }
      });
  }

  private addComment(id: string, message: string, mentionUserIds: string[]): void {
    this.eventsService.addComment(id, message, mentionUserIds).pipe(take(1)).subscribe();
  }

  private markMessageAsRead(id: string): void {
    this.notificationsService
      .markMessagingAlertAsRead(id)
      .pipe(take(1))
      .subscribe(() => LayoutsComponent.forceGetMessagingNotifications());
  }
}
