import { PermissionModel } from '@shared/models';
import { MergeModels } from '../../merge-models';
import { ECompanyTypes } from '@shared/enum';

export class ManufacturerFinanceRole {
  model: PermissionModel = {
    config: {
      companyType: ECompanyTypes.MANUFACTURER,
      roleName: 'FINANCE',
      validRole: true,
      permissionsWasSet: true
    },
    common: {
      visitDetailPageAssignedEntity: true,
      addComment: true
    },
    events: {
      canMarkAsPaid: true,
      canChangeCommissionAndInvoicePaid: true,
      toggleCalendarView: true,
      filterEvents: true,
      createEvent: true,
      seeImages: true,
      editPriceAdjustments: true,
      readTotalValue: true,
      signEventUsingCanvas: true,
      submitForBillingRequestReplenishment: true,
      useAdditionalFiltersOnMUI: true,
      copyEvent: true,
      editPONUmber: true
    },
    forbiddenRoutes: [
      '/export',
      '/users',
      '/users/list',
      '/inventory/import',
      '/inventory/import/list',
      '/inventory/import/detail',
      '/statistics',
      '/directory/regions',
      '/directory/price-lists',
      '/organization-tree'
    ],
    inventory: {
      canEditPriceOfInventoryInDUT: true,
      canFilterInventoriesByCustody: true,
      filterInventories: true,
      readCatalogDetail: true,
      readProductLineDetail: true,
      readImportDetail: true,
      readEventDetail: true,
      markDeviceInEvent: true
    },
    directory: {
      readFacilityDetail: true,
      readPhysicianDetail: true,
      readProcedureDetail: true,
      readManufacturerDetail: true
    }
  };

  constructor() {
    this.model = MergeModels(this.model);
  }
}
