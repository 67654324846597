import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslationsKeys } from '@shared/type/i18n.type';
import { TIcons } from '@shared/type/icons.type';
import { inOutAnimation } from '@shared/utils/animations/in-out-animation';
import { Router } from '@angular/router';
import { TStatusClass } from '@shared/type/index.type';

@Component({
  selector: 'app-mobile-table-view',
  templateUrl: './mobile-table-view.component.html',
  styleUrls: ['./mobile-table-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [inOutAnimation]
})
export class MobileTableViewComponent {
  @Input() title: string = '';
  @Input() status: string = '';
  @Input() statusClass: TStatusClass = null;
  @Input() elementId: string = '';
  @Input() opened: boolean = false;
  @Input() buttonTitle: TranslationsKeys = null;
  @Input() buttonIcon: TIcons = null;
  @Input() buttonStyle: 'warn' | 'primary' | 'success' = 'primary';
  @Input() detailsPageUrl: string = '';
  @Input() selectableMode: boolean = false;
  @Input() selected: boolean = false;

  @Output() openedEmitter = new EventEmitter<string>();
  @Output() buttonClickedEmitter = new EventEmitter<void>();
  @Output() selectItemEmitter = new EventEmitter<boolean>();

  toggle: boolean = false;

  constructor(private router: Router) {}

  navigateDetailPage(event?: Event): void {
    if (!this.selectableMode && this.detailsPageUrl) {
      this.router.navigate([this.detailsPageUrl]);
    } else if (this.selectableMode) {
      event?.stopPropagation();
      this.selectItemEmitter.emit(!this.selected);
    }
  }
}
