<div class="full-width">
  <app-input-with-chips-kit
    *ngIf="multiple; else single"
    class="full-width"
    [dataCy]="'userInput'"
    [chipDataCy]="'userChip'"
    [chipRemoveData_cy]="'userChipRemoveButton'"
    [selectedChips]="selectedChips"
    [showAddChipButton]="maxChipsCount > selectedChips?.length"
    [label]="label"
    [disabled]="disabled"
    [forceBlurEvent]="forceBlurEvent"
    [debounceEvents]="['keyup', 'focus']"
    [required]="required"
    [requiredView]="!!selectedChips.length && required"
    [debounceTime]="1000"
    [invalidInput]="invalid"
    [parseChipByProperty]="'name'"
    [removable]="removable"
    [detailPageLink]="allowViewDetails ? '/users/' : null"
    [autocompleteName]="userAutocomplete"
    [filterView]="filterView"
    (chipsRemovedEmitter)="removeChip($event.id)"
    (debounceTimeEndedEmitter)="searchUser($event)"
    (iconClickEmitter)="doIconAction($event)"
  />

  <ng-template #single>
    <app-input-kit
      class="full-width"
      [required]="required"
      [label]="label"
      [filterView]="filterView"
      [dataCy]="'userInput'"
      [initialValue]="user"
      [disabled]="disabled"
      [debounceEvents]="['keyup', 'focus']"
      [debounceTime]="1000"
      [icons]="[showClearIcon && !disabled ? 'close' : null, user?.id && showOpenInNewIcon ? 'link' : null, tooltip ? 'help' : null]"
      [tooltipText]="tooltip"
      [forceBlurEvent]="forceBlurEvent"
      [size]="inputSize"
      [autocompleteName]="userAutocomplete"
      [linkDetailPage]="'/users/' + user?.id"
      [invalidInput]="invalid"
      [showInvalidStateOnInit]="showInvalidStateOnInit"
      (valueChangedEmitter)="valueChangedEmitter.emit($event)"
      (debounceTimeEndedEmitter)="searchUser($event)"
      (iconClickEmitter)="doIconAction($event)"
    />
  </ng-template>

  <mat-autocomplete #userAutocomplete="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngIf="(loading$ | async) === true || (initialLoading$ | async) === true; else options" class="is-loading" disabled>
      <mat-progress-spinner value="30" class="spinner" mode="indeterminate" diameter="30" />
    </mat-option>
    <ng-template #options>
      <mat-option *ngIf="!searchedData.length && !showFromOrgOption; else data" disabled class="connectSxAutocompleteOption"
        ><span>{{ 'shared.labels.noItemsFound' | language }}</span></mat-option
      >
      <ng-template #data>
        <cdk-virtual-scroll-viewport
          itemSize="0"
          class="virtualAutocompleteScroll"
          [style.height.px]="
            (showFromOrgOption ? 1 : 0) + searchedData.length < 3 ? ((showFromOrgOption ? 1 : 0) + searchedData.length) * 50 : 150
          "
        >
          <mat-option
            *ngIf="showFromOrgOption"
            [value]="{ name: 'shared.labels.fromOrg' | language, id: null }"
            (click)="selectOrg()"
            data-cy="organizationOption"
            class="connectSxAutocompleteOption"
            ><b>{{ 'transfers.fromMyOrganization' | language }}</b></mat-option
          >
          <mat-option
            *cdkVirtualFor="let user of searchedData"
            data-cy="userOption"
            class="connectSxAutocompleteOption"
            [value]="user"
            (click)="selectOption(user)"
          >
            <span data-cy="userName"
              >{{ user.name
              }}<ng-container *ngIf="showUserRole">
                - <b>{{ eRoleTypes[user.role] | language }}</b></ng-container
              ></span
            >
            <small>{{ user.emailAddress }}</small>
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </ng-template>
    </ng-template>
  </mat-autocomplete>
</div>
