import { UserModel } from '@shared/models/features/users/user.model';
import { TState } from '@shared/type/index.type';

export class LocationModel {
  containers: number;
  country: string;
  createdBy: UserModel;
  createdDatetime: Date;
  id: string;
  items: number;
  latitude: string;
  locationName: string;
  locationState: string;
  longitude: string;
  modifiedBy: UserModel;
  modifiedDatetime: Date;
  name: string;
  placeId: string;
  postalCode: string;
  removable?: boolean; // Front-end property
  state: TState;
  street: string;
}

export class LocationCoordsModel {
  containers: number;
  country: string;
  createdBy: UserModel;
  createdDatetime: Date;
  id: string;
  items: number;
  latitude: number;
  locationName: string;
  locationState: string;
  longitude: number;
  modifiedBy: UserModel;
  modifiedDatetime: Date;
  name: string;
  placeId: string;
  postalCode: string;
  state: TState;
  street: string;
}
