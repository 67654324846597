import { CatalogModel, UserModel } from '@shared/models';
import { ImageModel } from '@shared/models/shared/image.model';
import { TState } from '@shared/type/index.type';
import { ManufacturerModel } from '@shared/models/features/directory/manufacturers/manufacturer.model';

export class ProductLineModel {
  assetUrl: string;
  attachments?: ImageModel[];
  catalogs: CatalogModel[];
  checked?: boolean;
  countOfAssociatedDevices: number;
  createdBy: UserModel;
  createdDatetime: Date;
  description: string;
  id: string;
  images?: ImageModel[];
  inventoryRequestQuantity?: number;
  manufacturer: ManufacturerModel;
  modifiedBy: UserModel;
  modifiedDatetime: Date;
  //Front-end only
  modifier?: string;
  modifiers: string[];
  name: string;
  organizationId: string;
  state: TState;
  isInstrumentsOnly?: boolean;
  isImplantsOnly?: boolean;
}
export class RequestProductLineModel {
  catalogId: string;
  createdDatetime: Date;
  id: string;
  inventoryRequestId: string;
  modifiedDatetime: Date;
  productId: string;
  productName: string;
  quantity: number;
  referenceNumber: string;
}
