import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from '@services/internal/language.service';
import { TranslationsKeys } from '@shared/type/i18n.type';
import { TIcons } from '@shared/type/icons.type';
import { GlobalStoreService } from '@services/internal/global-store.service';

@Component({
  selector: 'app-default-modal',
  templateUrl: './default-modal.component.html',
  styleUrls: ['./default-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultModalComponent implements AfterViewInit {
  @ViewChild('defaultModalTitleContainer') titleContainer: ElementRef<HTMLDivElement>;
  @ViewChild('defaultModalButtonsContainer') buttonsContainer: ElementRef<HTMLDivElement>;

  @Input() title: string = '';
  @Input('buttonTitle') set _buttonTitle(data: string) {
    this.buttonTitle = data;
    setTimeout(() => this.calculateContentHeight());
  }
  @Input() buttonType: 'primary' | 'secondary' | 'outline' = 'primary';
  @Input() size: 'small' | 'medium' = 'medium';
  @Input() buttonIcon: TIcons = null;
  @Input() buttonColor: 'primary' | 'success' | 'warn' = 'primary';
  @Input() tooltip: TranslationsKeys;
  @Input() disableSubmitButton: boolean = false;
  @Input() disablePrevButton: boolean = false;
  @Input() showPrevStepButton: boolean = false;
  @Input() prevButtonTitle: string = LanguageService.instant('shared.labels.previous');
  @Input() prevButtonType: 'primary' | 'secondary' | 'outline' = 'secondary';
  @Input() prevButtonColor: 'primary' | 'success' | 'warn' = 'primary';
  @Input() preventDefaultClose: boolean = false;
  @Input() disableCloseButton: boolean = false;
  @Input() hideSubmitButton: boolean = false;
  @Input() capitalize: boolean = true;
  @Input() scrollableContent: boolean = false;
  @Input() titleIconTemplate: TemplateRef<any>;

  @Output() clickEmitter = new EventEmitter<void>();
  @Output() backEmitter = new EventEmitter<void>();
  @Output() closeEmitter = new EventEmitter<void>();

  buttonTitle: string = '';
  contentHeight: number = null;

  /** Close modal on click link */
  @HostListener('click', ['$event'])
  clickInside(event: any) {
    if (!event || typeof event.composedPath !== 'function') {
      return;
    }
    const isClickToLink = event
      .composedPath()
      .findIndex((e: HTMLElement) => e.tagName === 'A' && e.getAttribute('data-link') !== 'skipClosing');
    if (isClickToLink !== -1) {
      this.dialogRef.close();
    }
  }

  constructor(
    public dialogRef: MatDialogRef<DefaultModalComponent>,
    private ref: ChangeDetectorRef
  ) {}

  static getSafeArea(area: 'afe-area-inset-top' | 'safe-area-inset-bottom'): number {
    if (GlobalStoreService.getPlatform() === 'web') {
      return 0;
    }
    const div = document.createElement('div');
    div.style.paddingTop = 'env(' + area + ')';
    document.body.appendChild(div);
    const safeAreaInsetTop = getComputedStyle(div).paddingTop;
    document.body.removeChild(div);
    return Number(safeAreaInsetTop);
  }

  ngAfterViewInit(): void {
    this.calculateContentHeight();
  }

  click(): void {
    this.clickEmitter.emit();
  }

  back(): void {
    this.backEmitter.emit();
  }

  private calculateContentHeight(): void {
    const dialogWindows: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName(
      'mat-mdc-dialog-container'
    ) as HTMLCollectionOf<HTMLElement>;
    const dialogWindowHeight: number = dialogWindows[dialogWindows.length - 1].offsetHeight;
    const titleContainerHeight: number = this.titleContainer.nativeElement.offsetHeight || 0;
    const buttonsContainerHeight: number = this.buttonsContainer?.nativeElement.offsetHeight || 0;
    const dialogWindowPadding: number = 24 * 2;
    const titleContainerMargin: number = 12;
    const saveArea = DefaultModalComponent.getSafeArea('afe-area-inset-top') + DefaultModalComponent.getSafeArea('safe-area-inset-bottom');

    this.contentHeight =
      dialogWindowHeight - titleContainerHeight - buttonsContainerHeight - dialogWindowPadding - titleContainerMargin - saveArea;
    this.ref.markForCheck();
  }
}
