<div class="notificationsWidgetContainer" fxLayout="column" fxLayoutGap="8px">
  <div
    fxLayoutAlign="space-between end"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign.xs="start stretch"
    fxLayoutGap="8px"
    class="widgetTitleContainer"
  >
    <app-title
      [title]="
        (isMobile$ | async) === true
          ? selectedView === 'NOTIFICATIONS'
            ? ('notifications.notificationsLabel' | language)
            : ('notifications.mentions' | language)
          : ('notifications.notificationsAndMentions' | language)
      "
      [type]="'h4'"
    ></app-title>
    <div fxFlex="220px" fxFlex.xs="none" fxLayout="row" class="connectSxTabContainer base">
      <app-tab-ui
        fxFlex="50%"
        [title]="'notifications.notificationsLabel' | language"
        [selected]="selectedView === 'NOTIFICATIONS'"
        (clickEmitter)="selectedView = 'NOTIFICATIONS'; getNotifications()"
      ></app-tab-ui>
      <app-tab-ui
        fxFlex="50%"
        [title]="'notifications.mentions' | language"
        [selected]="selectedView === 'MENTIONS'"
        (clickEmitter)="selectedView = 'MENTIONS'; getNotifications()"
      ></app-tab-ui>
    </div>
  </div>

  <div class="listNotificationsContainer">
    <app-list-notifications [loading]="loading$ | async" [notifications]="notifications$ | async"></app-list-notifications>
  </div>

  <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="end center">
    <app-button-kit
      fxFlex.xs="50%"
      [type]="'secondary'"
      [title]="'notifications.markAllAsRead' | language"
      [size]="'small'"
      (clickEmitter)="markAllAsRead()"
    ></app-button-kit>
    <app-button-kit
      fxFlex.xs="50%"
      [type]="'primary'"
      [textColor]="'white'"
      [title]="'shared.labels.viewAll' | language"
      [size]="'small'"
      (clickEmitter)="view()"
    ></app-button-kit>
  </div>
</div>
