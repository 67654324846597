<div
  class="datepickerKitFieldContainer"
  [ngClass]="{
    valueExist: initialDate.value,
    invalidInput: initialDate.invalid && initialDate.touched,
    required: required && !initialDate.disabled,
    disabledInput: initialDate.disabled,
    filterView: filterView,
    focused: datePicker.opened
  }"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="4px"
>
  <mat-form-field
    class="datepickerFieldFormField"
    [ngClass]="{
      valueExist: initialDate.value,
      invalidInput: initialDate.invalid && initialDate.touched,
      disabledInput: initialDate.disabled
    }"
  >
    <mat-label>{{ label }}</mat-label>
    <input
      matInput
      #input
      readonly
      [min]="minDate"
      [attr.data-cy]="inputDataCy"
      [matDatepicker]="datePicker"
      [formControl]="initialDate"
      (click)="datePicker.open()"
      (focus)="input.blur()"
      (dateChange)="dateEmitter.emit($event)"
    />
    <mat-datepicker #datePicker [touchUi]="touchDatePicker"></mat-datepicker>
  </mat-form-field>
  <div fxLayout="row" fxLayoutGap="8px" class="iconsContainer" fxLayoutAlign="center center">
    <app-icon
      *ngIf="input.value && clearIcon && !_disabled"
      [icon]="'close'"
      [type]="'outline'"
      [size]="16"
      (click)="$event.stopPropagation(); clearInput(input)"
    ></app-icon>
    <app-tooltip-kit
      *ngIf="tooltip"
      [color]="'default'"
      [isHtml]="true"
      [position]="'below'"
      [icon]="'help'"
      (click)="$event.stopPropagation()"
      >{{ tooltip }}</app-tooltip-kit
    >
  </div>
  <mat-datepicker-toggle
    [disableRipple]="true"
    class="datepickerFieldToggle"
    [attr.data-cy]="togglerDataCy"
    [for]="datePicker"
    *ngIf="!initialDate.disabled"
  >
    <app-icon matDatepickerToggleIcon *ngIf="icon" [icon]="icon" [type]="'outline'" [size]="20"></app-icon>
  </mat-datepicker-toggle>
</div>
