import { Injectable } from '@angular/core';
import { EventModel, InventoryModel, InventoryRequestModel, TransferModel } from '@shared/models';
import { IUserSubordinationView } from '@shared/interfaces';
import { UsersService } from '../users.service';
import { BehaviorSubject, firstValueFrom, map, Observable, Subscription } from 'rxjs';
import { PermissionService } from './permission.service';

@Injectable({
  providedIn: 'root'
})
export class DataPassService {
  canViewDetail$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  callOpenCreateEventFromBillSheet$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  callOpenImportInventoryCSVModal$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private disableExportQueryParams: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private exportQueryParamsSubscriptions: Subscription;
  private param: string;
  private inventoryToTransfer: BehaviorSubject<InventoryModel[]> = new BehaviorSubject<InventoryModel[]>([]);
  private canDistributorEdit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private subordinators = UsersService.user$.pipe(map(u => u.subordinators));

  constructor(private permissionService: PermissionService) {}

  clearExportQueryParamsSubscriptions(): void {
    this.exportQueryParamsSubscriptions?.unsubscribe();
  }

  getDisableExportQueryParams(): Observable<boolean> {
    return new Observable<boolean>(
      observer =>
        (this.exportQueryParamsSubscriptions = this.disableExportQueryParams.subscribe(value => {
          observer.next(value);
        }))
    );
  }

  getParams = (): string => this.param;

  setDisableExportQueryParams(value: boolean): void {
    this.disableExportQueryParams.next(value);
  }

  setParams(value: string): void {
    this.param = value;
  }

  setInventoryToTransfer(inventory: InventoryModel[] = []): void {
    this.inventoryToTransfer.next(inventory);
  }

  getInventoryToTransfer(): Observable<InventoryModel[]> {
    return this.inventoryToTransfer;
  }

  getCanDistributorEdit(): Observable<boolean> {
    return this.canDistributorEdit.asObservable();
  }

  async getSubordinators(event: EventModel, request?: InventoryRequestModel, transfer?: TransferModel): Promise<void | boolean> {
    // Checking role, need only distributor
    if (!this.permissionService.isRole('DISTRIBUTOR')) {
      this.canDistributorEdit.next(true);
      return;
    }
    // Getting array of subordinators from the store
    const subordinatorsArray: IUserSubordinationView[] = await firstValueFrom(this.subordinators);
    // Getting current user id
    const currentUserId: string = UsersService.getUser().id;
    /* We need to check if the sales user is in the distributor's group, and if so, the distributor can edit this event/request/transfer
    After comparing we will return the value and send value to the next method of our Behavior Subject */

    // If we have a request param we will compare subordinator id with requester id and id of the current user with requester id
    if (request) {
      if (subordinatorsArray?.length) {
        const value =
          subordinatorsArray?.findIndex(
            subordinator => subordinator.user.id === request.requester.id || currentUserId === request.requester.id
          ) !== -1;
        this.canDistributorEdit.next(value);
        return value;
      } else {
        const value = currentUserId === request.requester.id;
        this.canDistributorEdit.next(value);
        return value;
      }
    } else if (transfer) {
      /* If we have a transfer param we will compare subordinator id with sender/recipient id
      and id of the current user with sender/recipient id */
      if (subordinatorsArray?.length) {
        const value =
          subordinatorsArray?.findIndex(
            subordinator =>
              subordinator.user.id === transfer.sender?.id ||
              subordinator.user.id === transfer.recipient?.id ||
              currentUserId === transfer.sender.id ||
              currentUserId === transfer.recipient.id
          ) !== -1;
        this.canDistributorEdit.next(value);
        return value;
      } else {
        const value = currentUserId === transfer.sender.id || currentUserId === transfer.recipient.id;
        this.canDistributorEdit.next(value);
        return value;
      }
    } else {
      /* If we don't have request/transfer params we will compare subordinator id with representative id
      and id of the current user with representative id */
      if (subordinatorsArray?.length) {
        const value =
          subordinatorsArray?.findIndex(
            subordinator => subordinator.user.id === event.representative.id || currentUserId === event.representative.id
          ) !== -1;
        this.canDistributorEdit.next(value);
        return value;
      } else {
        const value = currentUserId === event.representative.id;
        this.canDistributorEdit.next(value);
        return value;
      }
    }
  }

  async canViewDetail(event: EventModel): Promise<void> {
    if (event) {
      // Getting current user id
      const currentUserId: string = UsersService.getUser().id;
      // Checking role, need only distributor
      if (this.permissionService.isRole('ADMIN') || this.permissionService.isRole('REGIONAL_MANAGER')) {
        this.canViewDetail$.next(true);
        return;
      } else if (this.permissionService.isRole('SALES')) {
        const canSaleView = event.representative?.id === currentUserId;
        this.canViewDetail$.next(canSaleView);
        return;
      } else {
        // Getting array of subordinators from the store
        const subordinatorsArray: IUserSubordinationView[] = await firstValueFrom(this.subordinators);

        if (subordinatorsArray?.length) {
          const value =
            subordinatorsArray?.findIndex(
              subordinator => subordinator.user.id === event.representative.id || currentUserId === event.representative.id
            ) !== -1;
          this.canViewDetail$.next(value);
        } else {
          const value = currentUserId === event.representative.id;
          this.canViewDetail$.next(value);
        }
      }
    }
  }
}
