import { ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { EventQuotesService } from '@services/event-quotes.service';
import { CatalogModel, EventModel, ManufacturerModel, ProductLineModel } from '@shared/models';
import { EventQuoteParamsModel } from '@shared/models/features/events/event-quote.model';
import { BehaviorSubject, finalize, take } from 'rxjs';

@Component({
  selector: 'app-create-quote',
  templateUrl: './create-quote.component.html',
  styleUrls: ['./create-quote.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateQuoteComponent {
  @ViewChild('stepper') stepperRef: MatStepper;

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  selectedItems: CatalogModel[] = [];
  searchedItems: CatalogModel[] = [];
  note: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { event: EventModel; manufacturers: ManufacturerModel[]; products: ProductLineModel[] },
    public dialogRef: MatDialogRef<CreateQuoteComponent>,
    public eventQuotesService: EventQuotesService,
    public router: Router
  ) {}

  selectCatalog(catalog: CatalogModel): void {
    if (!catalog.inventoryRequestQuantity) {
      catalog.inventoryRequestQuantity = 1;
    }

    this.selectedItems.push(catalog);
    this.selectedItems = [...[], ...this.selectedItems];
    this.searchedItems.splice(
      this.searchedItems.findIndex(c => c.id === catalog.id),
      1
    );
  }

  removeCatalog(catalog: CatalogModel): void {
    this.selectedItems.splice(
      this.selectedItems.findIndex(c => c.id === catalog.id),
      1
    );
    catalog.inventoryRequestQuantity = 1;
    this.searchedItems.unshift(catalog);
  }

  createQuote(): void {
    this.loading$.next(true);

    const quoteCatalogs: { catalogId: string; quantity: number }[] = this.selectedItems.map(c => ({
      catalogId: c.id,
      quantity: c.inventoryRequestQuantity
    }));
    const params: EventQuoteParamsModel = {
      eventId: this.data.event.id,
      note: this.note,
      catalogQuoteQuantities: quoteCatalogs
    };

    this.eventQuotesService
      .createQuote(params)
      .pipe(
        take(1),
        finalize(() => this.loading$.next(false))
      )
      .subscribe(id => {
        if (id) {
          this.dialogRef.close(id);
          this.router.navigate([`/events/edit/${this.data.event.id}/quote`]);
        }
      });
  }
}
