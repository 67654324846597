<div fxLayout="column" fxLayoutGap="16px">
  <app-title [type]="'h5'" class="subtitleContainer" [title]="('events.detail.yourQuoteIncludes' | language) + ':'"></app-title>

  <div class="requestReviewItemsContainer" fxLayout="column" fxLayoutGap="8px">
    <app-catalog-item
      *ngFor="let catalog of selectedCatalogs"
      [view]="'SELECTED'"
      [disableControls]="disableControls"
      [catalog]="catalog"
      (itemRemoved)="removeCatalogEmitter.emit($event)"
    ></app-catalog-item>
  </div>
</div>
