import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CatalogService } from '@services/catalog.service';
import { IFilter } from '@shared/interfaces';
import { CatalogModel, EventModel, ManufacturerModel, ProductLineModel } from '@shared/models';
import { CatalogsPageableParams } from '@shared/models/build-models';
import { TIcons } from '@shared/type/icons.type';
import { FilterUniqueArray } from '@shared/utils/arrays/filter-unique-array';
import { BehaviorSubject, finalize, take } from 'rxjs';

@Component({
  selector: 'app-create-quote-filters',
  templateUrl: './create-quote-filters.component.html',
  styleUrls: ['./create-quote-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateQuoteFiltersComponent implements OnInit {
  @Input() manufacturers: ManufacturerModel[] = [];
  @Input() products: ProductLineModel[] = [];
  @Input() selectedCatalogs: CatalogModel[] = [];
  @Input() event: EventModel = new EventModel();

  @Output() catalogsSearchCompleted = new EventEmitter<CatalogModel[]>();
  @Output() loadingEmitter = new EventEmitter<boolean>();

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  searchValue: string = '';
  product: ProductLineModel = new ProductLineModel();

  constructor(private catalogService: CatalogService) {}

  ngOnInit(): void {
    this.searchCatalogs('');
  }

  clear(entity: 'PRODUCT_LINE'): void {
    if (entity === 'PRODUCT_LINE') {
      this.product = new ProductLineModel();
    }
  }

  doIconAction(icon: TIcons): void {
    if (icon === 'close') {
      this.searchValue = '';
      this.searchCatalogs('');
    }
  }

  searchCatalogs(value: string): void {
    this.loading$.next(true);
    this.loadingEmitter.emit(true);

    const params: IFilter = {
      size: 20,
      search: value,
      state: 'ACTIVE'
    };

    if (this.product.id) {
      params.productId = this.product.id;
    }

    if (this.manufacturers.length) {
      const selectedManufacturers: ManufacturerModel[] = this.manufacturers.filter(m => m.checked);
      params.manufacturerIds = selectedManufacturers.map(m => m.id);
    }

    if (this.products.length) {
      const selectedProducts: ProductLineModel[] = this.products.filter(p => p.checked);
      params.productIds = selectedProducts.map(p => p.id);
    }

    const queryParams: CatalogsPageableParams = new CatalogsPageableParams(params);

    this.catalogService
      .getPageable(queryParams)
      .pipe(
        take(1),
        finalize(() => {
          this.loading$.next(false);
          this.loadingEmitter.emit(false);
        })
      )
      .subscribe(data => {
        const catalogs: CatalogModel[] = FilterUniqueArray(data.content, this.selectedCatalogs);
        this.catalogsSearchCompleted.emit(catalogs);
      });
  }
}
