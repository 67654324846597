import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PermissionService } from '@services/internal/permission.service';
import { UsersService } from '@services/users.service';
import { take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PermissionsGuard {
  constructor(private permissionService: PermissionService, private usersService: UsersService) {}

  static redirectToNotFound(): void {
    location.href = location.origin + '/not-found-404-page';
  }

  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.usersService.me().pipe(take(1)).subscribe();
    const permissionsWasSet: boolean = await this.permissionService.permissionsWasSet();
    if (permissionsWasSet) {
      const permissionAllowed: boolean = this.permissionService.isAllowedRoute(state.url);
      if (!permissionAllowed) {
        PermissionsGuard.redirectToNotFound();
      }
      return permissionAllowed;
    } else {
      PermissionsGuard.redirectToNotFound();
      return false;
    }
  }
}
