export enum ECarrier {
  ups = 'shared.carrier.ups',
  usps = 'shared.carrier.usps',
  fedex = 'shared.carrier.fedex',
  dhl_express = 'shared.carrier.dhl_express',
  aramex = 'shared.carrier.aramex',
  asendia_us = 'shared.carrier.asendia_us',
  borderguru = 'shared.carrier.borderguru',
  boxberry = 'shared.carrier.boxberry',
  bring = 'shared.carrier.bring',
  canada_post = 'shared.carrier.canada_post',
  correios_br = 'shared.carrier.correios_br',
  correos_espana = 'shared.carrier.correos_espana',
  collect_plus = 'shared.carrier.collect_plus',
  couriersplease = 'shared.carrier.couriersplease',
  deutsche_post = 'shared.carrier.deutsche_post',
  dhl_benelux = 'shared.carrier.dhl_benelux',
  dhl_germany = 'shared.carrier.dhl_germany',
  dhl_ecommerce = 'shared.carrier.dhl_ecommerce',
  dpd_germany = 'shared.carrier.dpd_germany',
  dpd_uk = 'shared.carrier.dpd_uk',
  estafeta = 'shared.carrier.estafeta',
  fastway_australia = 'shared.carrier.fastway_australia',
  gls_de = 'shared.carrier.gls_de',
  gls_fr = 'shared.carrier.gls_fr',
  globegistics = 'shared.carrier.globegistics',
  gophr = 'shared.carrier.gophr',
  gso = 'shared.carrier.gso',
  hermes_uk = 'shared.carrier.hermes_uk',
  hongkong_post = 'shared.carrier.hongkong_post',
  lasership = 'shared.carrier.lasership',
  mondial_relay = 'shared.carrier.mondial_relay',
  new_zealand_post = 'shared.carrier.new_zealand_post',
  newgistics = 'shared.carrier.newgistics',
  nippon_express = 'shared.carrier.nippon_express',
  ontrac = 'shared.carrier.ontrac',
  orangeds = 'shared.carrier.orangeds',
  parcel = 'shared.carrier.parcel',
  posti = 'shared.carrier.posti',
  rr_donnelley = 'shared.carrier.rr_donnelley',
  sendle = 'shared.carrier.sendle',
  skypostal = 'shared.carrier.skypostal',
  stuart = 'shared.carrier.stuart',
  yodel = 'shared.carrier.yodel'
}
