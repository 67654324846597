<span
  class="connectSxIcon"
  fxLayoutAlign="center center"
  [ngClass]="
    'icon-' +
    icon +
    ' size-' +
    size +
    ' ' +
    type +
    ' ' +
    color +
    (defaultCursor ? ' defaultCursor' : '') +
    (changeColorOnHover ? ' coloredHover' : '') +
    (shakeAnimation ? ' shakeAnimation' : '') +
    (disabled ? ' disabled' : '') +
    (background ? ' ' + getBackgroundColor() : '')
  "
  [attr.data-cy]="dataCy"
  (click)="clickEmitter.emit()"
></span>
