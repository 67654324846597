import { IFilter } from '@shared/interfaces';
import { TInventoryExpirationStatus, TInventoryStatus, TItemType, TState } from '@shared/type/index.type';
import { DefaultQueryParams } from '@shared/helpers/default-query-params';
import { EExpirationStatus, EHoldStatus, EInspectionDue, EInventoryDeviceType } from '@shared/enum';
import { ClearRequest } from '@shared/utils/filter-object-null-properties';
import { GetEnumKeyByValue } from '@shared/utils/get-enum-key-by-value';

export class InventoryPageableParams {
  barcode?: string;
  barcodeOrSerialNumber?: string;
  billable?: boolean | string;
  bomId?: string;
  catalogId?: string;
  catalogIds?: string[];
  consignmentStatus?: string;
  consignmentStatusIsNull?: boolean;
  containerId?: string;
  custodyId?: string;
  custodyIds?: string[];
  deviceId?: string;
  dueBackStatus?: string;
  eventDatetimeFrom?: string;
  eventDatetimeTo?: string;
  eventId?: string;
  eventInventoryState?: TItemType;
  expirationStatus?: TInventoryExpirationStatus;
  expirationStatuses?: (keyof typeof EExpirationStatus)[];
  expiredDaysFrom?: number;
  expiredDaysTo?: number;
  facilityId?: string;
  holdStatuses?: (keyof typeof EHoldStatus)[];
  ids?: string[];
  importJobId?: string;
  inOrgCustody?: boolean;
  inspectionDaysFrom?: number;
  inspectionDaysTo?: number;
  inventoryDeviceType?: keyof typeof EInventoryDeviceType;
  inventoryStatus?: TInventoryStatus;
  inventoryType?: TItemType;
  inventoryTypes?: TItemType[];
  isFacilityStock?: boolean;
  locationId?: string;
  lotNumber?: string;
  manufacturerId?: string;
  name?: string;
  notInPendingTransfer?: boolean | string;
  page?: number;
  parentInventoryId?: string;
  productLineId?: string;
  productLineIds?: string[];
  referenceNumber?: string;
  searchEqualText?: string;
  searchText?: string;
  searchWithoutBom?: boolean;
  searchWithoutContainer?: boolean | string;
  searchWithoutEvent?: boolean | string;
  searchWithoutLocation?: boolean;
  searchWithoutLot?: boolean | string;
  searchWithoutUDI?: boolean | string;
  serialNumber?: string;
  showReservedContainers?: boolean;
  size?: number;
  sort?: string[];
  state?: TState;
  tag?: string;
  transferId?: string;
  type?: 'STOCK' | 'DEFAULT';
  isExtensionRequested?: boolean;
  isInPack?: boolean;

  constructor(params: IFilter) {
    return InventoryPageableParams.build(params);
  }

  private static build(params: IFilter): InventoryPageableParams {
    const queryParams: InventoryPageableParams = DefaultQueryParams(params, params.size);

    // Catalog #, tags, UDI, lot, serial, name (includes)
    queryParams.searchText = params.search;

    // Catalog #, tags, UDI, lot, serial, name (equals)
    queryParams.searchEqualText = params.searchEqual;

    queryParams.inventoryStatus = params.inventoryStatus;
    queryParams.expirationStatus = params.expirationStatus;
    queryParams.dueBackStatus = params.dueBackStatus;
    queryParams.searchWithoutLot = params.searchWithoutLot;
    queryParams.searchWithoutUDI = params.searchWithoutUDI;
    queryParams.facilityId = params.facilityId;
    queryParams.catalogId = params.catalogId;
    queryParams.catalogIds = params.catalogIds;
    queryParams.custodyIds = params.custodyIds;
    queryParams.parentInventoryId = params.containerId;
    queryParams.holdStatuses = params.holdStatuses;
    queryParams.notInPendingTransfer = params.notInPendingTransfer;
    queryParams.barcode = params.barcode;
    queryParams.bomId = params.bomId;
    queryParams.locationId = params.locationId;
    queryParams.productLineId = params.productId;
    queryParams.expiredDaysFrom = params.expiredDaysFrom;
    queryParams.expiredDaysTo = params.expiredDaysTo;
    queryParams.searchWithoutLocation = params.searchWithoutLocation;
    queryParams.importJobId = params.importJobId;
    queryParams.isExtensionRequested = params.isExtensionRequested;
    queryParams.eventDatetimeFrom = params.eventDatetimeFrom;
    queryParams.eventDatetimeTo = params.eventDatetimeTo;
    queryParams.showReservedContainers = params.showReservedContainers;
    queryParams.isInPack = params.isInPack;

    if (params.isFacilityStock !== null && !params.includeFacilityStock) {
      queryParams.isFacilityStock = false;
    }

    if (params.includeFacilityStock) {
      queryParams.isFacilityStock = null;
    }

    if (params.searchWithoutEvent) {
      queryParams.searchWithoutEvent = params.searchWithoutEvent;
    }

    if (params.billable) {
      queryParams.billable = params.billable;
    }

    if (!params.withoutState) {
      queryParams.state = params?.eventInventoryState ? 'INACTIVE' : 'ACTIVE';
    }

    if (params?.selectedMatSortHeaderActive === 'lotNumber') {
      const param = queryParams['sort'][0];
      queryParams['sort'] = [
        param,
        `modifiedDatetime,${params.selectedMatSortHeaderDirection ? params.selectedMatSortHeaderDirection : 'desc'}`
      ];
    }

    if (params?.selectedMatSortHeaderActive === 'manufacturerDate') {
      // Uses for autoRefill BOM using FIFO/LIFO methods
      // manufacturerDate should be always first parameter to work sort properly at this endpoint
      queryParams['sort'] = [
        `manufacturerDate,${params.selectedMatSortHeaderDirection}`,
        `createdDatetime,${params.selectedMatSortHeaderDirection}`
      ];
    }

    if (params.inventoryTypes?.length) {
      queryParams.inventoryTypes = params.inventoryTypes;
    } else if (params.itemType) {
      queryParams.inventoryType = params.itemType;
    }

    if (params.manufacturer && typeof params.manufacturer !== 'boolean') {
      queryParams.manufacturerId = params.manufacturer;
    }

    if (params.custodyId) {
      if (params.custodyId === 'ORGANIZATION') {
        queryParams.inOrgCustody = true;
      } else {
        queryParams.custodyId = params.custodyId;
      }
    }

    if (params.inOrgCustody) {
      queryParams.inOrgCustody = params.inOrgCustody;
    }

    if (params.consignmentStatus !== 'true' && params.consignmentStatus !== 'false' && params.consignmentStatus?.length) {
      queryParams.consignmentStatus = params.consignmentStatus;
    }

    if (typeof params.consignmentStatusIsNull === 'boolean') {
      queryParams.consignmentStatusIsNull = params.consignmentStatusIsNull;
    }

    if (params.consignmentStatus === 'true' || params.consignmentStatus === 'false') {
      queryParams.consignmentStatusIsNull = params.consignmentStatus === 'true';
    }

    if (params.expirationStatuses) {
      queryParams.expirationStatuses = params.expirationStatuses;
    }

    if (params.inventoryDeviceType && params.inventoryDeviceType?.length) {
      queryParams.inventoryDeviceType = params.inventoryDeviceType;
    }

    if (params.inventoryStatus === 'FACILITY_STOCK') {
      queryParams.inventoryStatus = null;
      queryParams.isFacilityStock = true;
    }

    if (String(params.searchWithoutBom) === 'true') {
      queryParams.searchWithoutBom = true;
    }

    if (params.isExtensionRequested) {
      queryParams['sort'] = [`modifiedDatetime,inventoryRequestExtension,asc`];
    }

    if (String(params.searchWithoutContainer) === 'true') {
      queryParams.searchWithoutContainer = params.searchWithoutContainer;
    }

    if (params.inspectionDays?.length) {
      if (params.inspectionDays === GetEnumKeyByValue(EInspectionDue, EInspectionDue['0_07'])) {
        queryParams.inspectionDaysFrom = 0;
        queryParams.inspectionDaysTo = 7;
      } else if (params.inspectionDays === GetEnumKeyByValue(EInspectionDue, EInspectionDue['0_30'])) {
        queryParams.inspectionDaysFrom = 0;
        queryParams.inspectionDaysTo = 30;
      } else if (params.inspectionDays === GetEnumKeyByValue(EInspectionDue, EInspectionDue['31_60'])) {
        queryParams.inspectionDaysFrom = 31;
        queryParams.inspectionDaysTo = 60;
      } else if (params.inspectionDays === GetEnumKeyByValue(EInspectionDue, EInspectionDue['61_90'])) {
        queryParams.inspectionDaysFrom = 61;
        queryParams.inspectionDaysTo = 90;
      } else if (params.inspectionDays === GetEnumKeyByValue(EInspectionDue, EInspectionDue['90_MORE'])) {
        queryParams.inspectionDaysFrom = 91;
      } else if (params.inspectionDays === GetEnumKeyByValue(EInspectionDue, EInspectionDue.OVERDUE)) {
        queryParams.inspectionDaysTo = 0;
      }
    } else {
      // Filter by Inspection due flag
      if (+params.inspectionDaysFrom === 7 && +params.inspectionDaysTo === 7) {
        queryParams.inspectionDaysFrom = 7;
        queryParams.inspectionDaysTo = 7;
      }
    }

    return ClearRequest(queryParams);
  }
}
