export class StatusesCount {
  USED: number;
  IMPLANTED: number;
  IN_OUT: number;
  DAMAGED: number;
  MISSING: number;
  OTHER: number;
  UNUSED?: number;
  FACILITY_STOCK?: number;
  EXPIRED?: number;
  DUPLICATE?: number;
  constructor() {
    return {
      USED: 0,
      IMPLANTED: 0,
      IN_OUT: 0,
      DAMAGED: 0,
      MISSING: 0,
      OTHER: 0,
      FACILITY_STOCK: 0,
      EXPIRED: 0,
      DUPLICATE: 0
    };
  }
}
