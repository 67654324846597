import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UsersService } from '@services/users.service';
import { ECurrency } from '@shared/enum';
import { DefaultCurrency } from '@constants';

@Component({
  selector: 'app-currency-symbol',
  templateUrl: './currency-symbol.component.html',
  styleUrls: ['./currency-symbol.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurrencySymbolComponent {
  /**
   * Get value from other components
   */
  static get currency(): string {
    return ECurrency[UsersService.currency || DefaultCurrency];
  }

  /**
   * Show in template
   */
  get getCurrency(): string {
    return ECurrency[UsersService.currency || DefaultCurrency];
  }
}
