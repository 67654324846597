import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-toggle-kit',
  templateUrl: './toggle-kit.component.html',
  styleUrls: ['./toggle-kit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ToggleKitComponent {
  @Input() title: string = '';
  @Input() disabled: boolean = false;
  @Input() dataCy: string = '';
  @Input() set initialValue(data: boolean | string) {
    if (typeof data === 'string') {
      this.value = Boolean(data);
    } else {
      this.value = data;
    }
  }

  @Output() toggleEmitter = new EventEmitter<boolean>();

  value: boolean = false;
}
