import { UsersService } from '@services/users.service';

export const GetShortLogoURL = (): string => {
  if (UsersService.colorTheme$.value === 'DARK_BLUE') {
    return '/assets/img/logo-icon-dark-blue.svg';
  } else {
    return '/assets/img/logo-icon.svg';
  }
};

export const GetLabelURL = (): string => {
  if (UsersService.colorTheme$.value === 'DARK_BLUE') {
    return '/assets/img/logo-label-dark-blue.svg';
  } else {
    return '/assets/img/logo-label.svg';
  }
};

export const ThemeBasedUrls = (): string => {
  if (UsersService.colorTheme$.value === 'DARK_BLUE') {
    return '/assets/img/logo-full-dark-blue.svg';
  } else {
    return '/assets/img/logo-full.svg';
  }
};

export const GetAiIconURL = (): string => {
  if (UsersService.colorTheme$.value === 'LIGHT') {
    return '/assets/img/icons/ai_sparks_color-light-theme.svg';
  } else {
    return '/assets/img/icons/ai_sparks_color.svg';
  }
};
