import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NoteModel, UserModel } from '@shared/models';
import { TranslationsKeys } from '@shared/type/i18n.type';
import { BehaviorSubject } from 'rxjs';
import { ParseUserIds } from '../utils/parse-user-ids';
import { AlertsService } from '@services/internal/alerts.service';
import { GlobalStoreService } from '@services/internal/global-store.service';
import { UsersService } from '@services/users.service';
import { PermissionService } from '@services/internal/permission.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentsComponent {
  @Input() commentsTitle: string = '';
  @Input() set notes(data: NoteModel[]) {
    this.comments = CommentsComponent.replaceBrComments(data);
  }
  @Input() tooltipText: TranslationsKeys;
  @Input() lastChild: boolean = false;
  @Input() allowEdit: boolean = true;
  @Input() titleType: 'h5' | 'caption' = 'h5';
  @Input() expandedSection: boolean = false;
  @Input() recipients: UserModel[] = [];
  @Input() displayCommentRecipients: boolean = false;
  @Input() showCommentMarker: boolean = false;

  @Output() addCommentEmitter = new EventEmitter<{ message: string; userIds: string[] }>();
  @Output() editCommentEmitter = new EventEmitter<{ message: string; commentId: string; userIds: string[]; commentUserIds: string[] }>();
  @Output() recipientAdded = new EventEmitter<UserModel>();
  @Output() recipientRemoved = new EventEmitter<string>();
  @Output() markCommentEmitter = new EventEmitter<{ noteId: string; note: string; isPdfPrint: boolean }>();

  newComment: string = '';
  showNewCommentField$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  comments: NoteModel[] = [];

  readonly currentUser: UserModel = UsersService.getUser();
  readonly isMobile$: BehaviorSubject<boolean> = GlobalStoreService.isMobile$;

  constructor(
    public permissionService: PermissionService,
    private alertsService: AlertsService
  ) {}

  static replaceUnSupportedSymbols(message: string): string {
    // Close IMG tag to support images in the transfer PDF
    let imgInd: number = message.indexOf('<img src="');
    if (imgInd !== -1) {
      const closeImg = (): void => {
        const closeInd: number = message.indexOf('">', imgInd);
        message = message.slice(0, closeInd) + '"/>' + message.slice(closeInd + 2);
        imgInd = message.indexOf('<img src="', closeInd);
        if (imgInd !== -1) {
          closeImg();
        }
      };
      closeImg();
    }

    // Delete unsupported in the transfer PDF emojis
    const msg: string = message.replaceAll(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      (e: string, i) => {
        if (message.slice(i - 2, i) !== '">') {
          return '';
        } else {
          return e;
        }
      }
    );
    // Replace other characters
    return msg.replaceAll('&nbsp;', ' ').replaceAll('&nbsp;', ' ').replaceAll('<br>', '<br></br>');
  }

  static replaceBrComments(notes: NoteModel[] = []): NoteModel[] {
    return notes.map(n => {
      n.comment = n.comment?.replaceAll('<br></br>', '<br>');
      n.note = n.note?.replaceAll('<br></br>', '<br>');
      n.text = n.text?.replaceAll('<br></br>', '<br>');
      return n;
    });
  }

  openAddCommentField(): void {
    this.showNewCommentField$.next(true);
    setTimeout(() => {
      // @ts-ignore
      document.querySelector('.ql-container .ql-editor')?.focus();
    }, 50);
  }

  saveComment(): void {
    const message: string = CommentsComponent.replaceUnSupportedSymbols(this.newComment);
    const mentionedUserIds: string[] = ParseUserIds(this.newComment);

    if (message?.length > 9999) {
      this.alertsService.showError('shared.alerts.errorMessages.maxLengthComment');
      this.showNewCommentField$.next(true);
      return;
    }

    this.addCommentEmitter.emit({ message, userIds: mentionedUserIds });
    this.newComment = '';
    this.showNewCommentField$.next(false);

    // Add mentioned user to the comment recipients
    mentionedUserIds.forEach(id => {
      if (this.recipients.findIndex(r => r.id === id) === -1) {
        const mockUser: UserModel = new UserModel();
        mockUser.id = id;
        this.recipientAdded.emit(mockUser);
      }
    });
  }

  editComment(event: { message: string; commentId: string; userIds: string[]; commentUserIds: string[] }): void {
    const params = event;
    if (params?.message?.length > 9999) {
      this.alertsService.showError('shared.alerts.errorMessages.maxLengthComment');
      return;
    }
    params.message = CommentsComponent.replaceUnSupportedSymbols(event.message);
    this.editCommentEmitter.emit(params);
  }
}
