/**
 * Converting large strings numbers
 *
 * @example 12315024687.770002 => '12,315,024,687.77'
 */
export const convertStringNumbers = (value: string = '0'): string => {
  if (!isNaN(+value)) {
    return +value ? parseFloat((+value).toFixed(2)).toLocaleString() : '0';
  } else {
    return value;
  }
};
