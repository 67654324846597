import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UsersService } from '@services/users.service';
import { LocalStorage } from '@services/internal/localstorage.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-custom-pagination-selector',
  templateUrl: './custom-pagination-selector.component.html',
  styleUrls: ['./custom-pagination-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomPaginationSelectorComponent implements OnInit {
  @Input() totalElements: number;
  @Input() pageNumber: number;
  @Input() size: number;
  @Input() disabled: boolean;
  @Input() withTooltip: boolean;
  @Input() maxSize: 100 | 500 = 500;

  @Output() getPageableEmitter = new EventEmitter<PageEvent>();

  pageSizeOptions: number[] = [20, 50, 100, 250];

  ngOnInit() {
    this.pageSizeOptions = this.pageSizeOptions.filter(i => i <= this.maxSize);
  }

  selectPagination(event: PageEvent): void {
    this.getPageableEmitter.emit(event);
    UsersService.defaultPagination.next(event.pageSize);
    LocalStorage.setItem('defaultPagination', String(event.pageSize));
  }
}
