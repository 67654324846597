import { IFilter } from '@shared/interfaces';
import { DefaultQueryParams } from '@shared/helpers/default-query-params';
import { ClearRequest } from '@shared/utils/filter-object-null-properties';

export class DeviceUsageTemplatesPageableParams {
  name?: string;
  page: number;
  size: number;
  sort: string[];

  constructor(params: IFilter) {
    return DeviceUsageTemplatesPageableParams.build(params);
  }

  private static build(params: IFilter): DeviceUsageTemplatesPageableParams {
    const queryParams: DeviceUsageTemplatesPageableParams = DefaultQueryParams(params);
    queryParams.name = params.search;
    return ClearRequest(queryParams);
  }
}
