<div class="multiselectDropdownContainer">
  <span *ngIf="selectedOptions.length" class="caption">{{ label }}</span>
  <ng-multiselect-dropdown
    [ngClass]="{ hasBackground }"
    [placeholder]="label"
    [settings]="dropdownSettings"
    [data]="options"
    [disabled]="disabled"
    [attr.data-cy]="dataCy"
    [(ngModel)]="selectedOptions"
    (onSelect)="optionSelected.emit($event)"
    (onSelectAll)="allOptionSelected.emit($event)"
    (onDeSelect)="optionRemoved.emit($event)"
    (onDeSelectAll)="allOptionsRemoved.emit($event)"
  ></ng-multiselect-dropdown>
</div>
