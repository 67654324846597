export const GetTextContentFromHTML = (html: string): string => {
  if (!html) {
    return '';
  }

  const parser: DOMParser = new DOMParser();
  const doc: Document = parser.parseFromString(html, 'text/html');

  return getTextContent(doc.body);
};

const getTextContent = (element: HTMLElement): string => {
  let textContent = '';

  if (element.nodeType === Node.TEXT_NODE) {
    textContent += element.textContent || '';
  } else {
    // Convert NodeListOf<ChildNode> to an array for iteration
    const childNodesArray = Array.from(element.childNodes);

    for (const childNode of childNodesArray) {
      textContent += getTextContent(childNode as HTMLElement);
    }
  }

  return textContent || ':emoji:';
};
