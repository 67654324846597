import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PhysicianModel, PreferenceCardAssignment, PreferenceCardModel } from '@shared/models';
import { RecipesStoreService } from '../../../services/recipes-store.service';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-preference-card-details',
  templateUrl: './preference-card-details.component.html',
  styleUrls: ['./preference-card-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreferenceCardDetailsComponent {
  assignments$: Observable<PreferenceCardAssignment[]> = this.preferenceCardStore.store$.pipe(map(st => st.assignments));
  assignedPhysicians$: Observable<PhysicianModel[]> = this.preferenceCardStore.store$.pipe(map(st => st.physicians));

  constructor(@Inject(MAT_DIALOG_DATA) public preferenceCard: PreferenceCardModel, private preferenceCardStore: RecipesStoreService) {
    this.preferenceCardStore.store$.next({
      ...this.preferenceCardStore.store$.value,
      recipe: this.preferenceCard
    });
    this.preferenceCardStore.loadAssignedPhysicians(this.preferenceCard.id);
    this.preferenceCardStore.loadAssignments(this.preferenceCard.id);
  }
}
