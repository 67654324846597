import { PhysicianModel, PreferenceCardModel, PreferenceCardAssignment } from '@shared/models';
import { UntypedFormGroup } from '@angular/forms';

export class PreferenceCardsState {
  id: string;
  recipe: PreferenceCardModel;
  duplicateRecipe: PreferenceCardModel;
  loading: boolean;
  recipeForm: UntypedFormGroup;
  validForm: boolean;
  formsTouched: boolean;
  physicians: PhysicianModel[];
  editable: boolean;
  assignments: PreferenceCardAssignment[];
  newAssignments: PreferenceCardAssignment[];

  constructor() {
    this.recipe = new PreferenceCardModel();
    this.duplicateRecipe = new PreferenceCardModel();
    this.physicians = [];
    this.assignments = [];
    this.newAssignments = [];
    this.validForm = false;
    this.formsTouched = false;
    this.editable = true;
  }
}
