import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductsService } from '@services/products.service';
import { BehaviorSubject, finalize, take } from 'rxjs';
import { ManufacturerModel, ProductLineModel, UserModel } from '@shared/models';
import { IFilter } from '@shared/interfaces';
import { ProductLineParams } from '@shared/models/build-models';
import { FilterUniqueArray, FilterUniqueArrayByModifier } from '@shared/utils/arrays/filter-unique-array';
import { PermissionService } from '@services/internal/permission.service';
import { DisplayName } from '@shared/utils/form-elements/display-with-autocomplete';
import { TIcons } from '@shared/type/icons.type';
import { LanguageService } from '@services/internal/language.service';

@Component({
  selector: 'app-product-autocomplete',
  templateUrl: './product-autocomplete.component.html',
  styleUrls: ['./product-autocomplete.component.scss']
})
export class ProductAutocompleteComponent {
  @Input() product: ProductLineModel = new ProductLineModel();
  @Input() multiple: boolean = false;
  @Input() showAddNewButton: boolean = false;
  @Input() showClearIcon: boolean = false;
  @Input() selectedChips: ProductLineModel[] = [];
  @Input() manufacturers: ManufacturerModel[] = [];
  @Input() manufacturer: ManufacturerModel = new ManufacturerModel();
  @Input() required: boolean = false;
  @Input() removable: boolean = false;
  @Input() disabled: boolean = false;
  @Input() showLinkToDetail: boolean = true;
  @Input() showOnlyAssignedOption: boolean = false;
  @Input() custody: UserModel = new UserModel();
  @Input() invalid: boolean = false;
  @Input() label: string = LanguageService.instant('shared.labels.singleProductAutocomplete');
  @Input() displayManufacturer: boolean = false;
  @Input() skipEmptyProducts: boolean = false;
  @Input() filterView: boolean = false;
  @Input() clearAfterSelection: boolean = false;
  @Input() hideProducts: ProductLineModel[] = [];
  @Input() userType: 'REPRESENTATIVE' | 'CUSTODY' = 'CUSTODY';
  @Input() skeleton: boolean = false;
  @Input() includeProductTypes: boolean = false;

  @Output() removeChipEmitter = new EventEmitter<string>();
  @Output() optionSelectedEmitter = new EventEmitter<ProductLineModel>();
  @Output() addProductEmitter = new EventEmitter<void>();
  @Output() clearInputEmitter = new EventEmitter<void>();
  @Output() valueChangedEmitter = new EventEmitter<string>();

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  initialLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  searchedData$: BehaviorSubject<ProductLineModel[]> = new BehaviorSubject<ProductLineModel[]>([]);
  onlyAssignedProducts: boolean = true;
  forceBlurEvent: boolean = false;

  readonly displayFn = DisplayName;

  constructor(
    public permissionService: PermissionService,
    private productsService: ProductsService
  ) {}

  doIconAction(icon: TIcons): void {
    if (icon === 'close') {
      this.clearInputEmitter.emit();
      this.searchProduct('', true);
    }
  }

  removeChip(productId: string): void {
    this.removeChipEmitter.emit(productId);
  }

  selectOption(product: ProductLineModel): void {
    if (this.clearAfterSelection) {
      this.product = new ProductLineModel();
    }

    this.optionSelectedEmitter.emit(product);
  }

  addProductLine(): void {
    this.addProductEmitter.emit();
  }

  searchProduct(value: string, skipSelectSingleOption: boolean = false): void {
    this.initialLoading$.next(false);
    this.forceBlurEvent = false;

    if (this.loading$.value) {
      return;
    }

    this.loading$.next(true);

    const params: IFilter = {
      search: value,
      size: 20,
      state: 'ACTIVE'
    };

    if (this.manufacturers.length) {
      params.manufacturerIds = this.manufacturers.map(m => m.id);
    }

    if (this.manufacturer?.id) {
      params.manufacturer = this.manufacturer.id;
    }

    if (this.onlyAssignedProducts) {
      if (this.permissionService.isRole('SALES')) {
        params.assignedProductLines = true;
      } else {
        params.custodyId = this.custody?.id;
      }
    }

    if (!this.showOnlyAssignedOption && this.custody?.id) {
      params.custodyId = this.custody.id;
    }

    const queryParams: ProductLineParams = new ProductLineParams(params);

    this.productsService
      .getPageable(queryParams)
      .pipe(
        take(1),
        finalize(() => this.loading$.next(false))
      )
      .subscribe(data => {
        const productsByModifiers: ProductLineModel[] = [...data.content];

        if (this.includeProductTypes) {
          data.content.forEach(product => {
            if (!product.modifiers) {
              return;
            }

            product.modifiers.forEach(modifier => {
              const productWithModifier: ProductLineModel = { ...product };
              productWithModifier.modifier = modifier;

              const index: number = productsByModifiers.indexOf(product) + 1;
              productsByModifiers.splice(index, 0, productWithModifier);
            });
          });
          this.searchedData$.next(FilterUniqueArrayByModifier(productsByModifiers, this.selectedChips));
        } else {
          this.searchedData$.next(FilterUniqueArray(data.content, this.selectedChips));
        }

        if (this.hideProducts.length) {
          if (this.includeProductTypes) {
            this.searchedData$.next(FilterUniqueArrayByModifier(this.searchedData$.value, this.hideProducts));
          } else {
            this.searchedData$.next(FilterUniqueArray(this.searchedData$.value, this.hideProducts));
          }
        }

        if (this.skipEmptyProducts) {
          this.searchedData$.next(this.searchedData$.value.filter(product => Boolean(product.countOfAssociatedDevices)));
        }

        if (skipSelectSingleOption && this.searchedData$.value.length === 1) {
          this.forceBlurEvent = true;
          return;
        }

        if (!value && this.searchedData$.value.length === 1) {
          this.selectOption(this.searchedData$.value[0]);
          this.forceBlurEvent = true;
        } else {
          this.forceBlurEvent = false;
        }
      });
  }
}
