import { ContactModel } from '@shared/models/shared/contact.model';

export class ManufacturerModel {
  additionalContacts: ContactModel[];
  assignedUserId: string;
  billAsOrg: boolean;
  checked?: boolean; // Front-end property
  cityAddress: string;
  createdDatetime?: string | Date;
  email: string;
  fax: string;
  id: string;
  imageUrl: string;
  modifiedDatetime?: string | Date;
  name: string;
  organizationId: string;
  organizationName: string;
  paymentTerm?: string;
  phone: string;
  poNumber?: string;
  removable?: boolean; // Front-end property
  requireSendSalesOrderFormEnabled: boolean;
  state: string;
  stateAddress: string;
  streetAddress: string;
  type: string;
  zipCode: string;
}
