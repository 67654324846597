import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IFilter } from '@shared/interfaces';
import { SearchParamsShorter } from '@shared/utils/http/search-params-shorter';
import { SearchParamsRequestBuilder } from '@shared/helpers/managaer-search-params/search-params-request-builder';
import { BehaviorSubject } from 'rxjs';
import { EPageablePageType } from '@shared/enum';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';

@Injectable({ providedIn: 'root' })
export class ManagerSearchParamsService {
  filterParams: BehaviorSubject<IFilter> = new BehaviorSubject<IFilter>({});
  private componentType: EPageablePageType = null;
  private copyParams: IFilter = {};
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private searchParamsRequestBuilder: SearchParamsRequestBuilder
  ) {}

  componentInit(type: EPageablePageType, initialParams: IFilter): void {
    this.componentType = type;
    const finalParams: IFilter = this.matchUrlsParamsWithInitialParams(initialParams);
    this.saveParams(finalParams);
  }

  getPageable(pagination: PageEvent, sort?: Sort): void {
    if (pagination) {
      this.paginationChange(pagination);
    } else {
      this.sortColumnHeaderChange(sort);
    }
  }

  saveParams(params: IFilter): void {
    this.copyParams = { ...this.copyParams, ...params };
    this.searchParamsRequestBuilder.setComponentParams(this.componentType, this.copyParams);
    this.filterParams.next(this.copyParams);
    this.setURLSearchParams(this.copyParams);
  }

  private getUrlSearchParams = (): any => this.activatedRoute.snapshot.queryParams;

  private matchUrlsParamsWithInitialParams(initialParams: { [k: string]: any }): IFilter {
    const queryParams: { [k: string]: any } = this.getUrlSearchParams();
    const result: { [k: string]: any } = {};
    this.copyParams = {};
    for (const k in initialParams) {
      if (initialParams.hasOwnProperty(k)) {
        const urlShort: string = SearchParamsShorter(k);
        result[k] = queryParams[urlShort]?.length ? queryParams[urlShort] : initialParams[k];
      }
    }
    return result;
  }

  private paginationChange(pagination: PageEvent): void {
    const paginationParams: IFilter = {
      page: pagination.pageIndex,
      size: pagination.pageSize
    };
    this.saveParams(paginationParams);
  }

  private setURLSearchParams(urlSearchParams: { [k: string]: any }): void {
    const params: { [k: string]: any } = {};
    for (const i in urlSearchParams) {
      if (urlSearchParams.hasOwnProperty(i) && (urlSearchParams[i] || urlSearchParams[i] === 0 || urlSearchParams[i] === false)) {
        params[SearchParamsShorter(i)] = urlSearchParams[i];
      }
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: params,
      replaceUrl: true
    });
  }

  private sortColumnHeaderChange(sort: Sort): void {
    const selectedMatSortHeader: IFilter = {
      selectedMatSortHeaderDirection: sort.direction,
      selectedMatSortHeaderActive: sort.active
    };
    this.saveParams(selectedMatSortHeader);
  }
}
