export const SearchParamsShorter = (param: string): string => {
  // get first 2 letters + uppercase letters and last letter f.e inventoryType = inTe, expirationStatus = exSs
  let result: string = param[0] + param[1];
  const isUpperCase = (letter: string) => /^[A-Z]*$/.test(letter);
  const length: number = param.length - 1;
  for (let i = 2; i < length; i++) {
    if (param[i] && isUpperCase(param[i])) {
      result += param[i];
    }
  }
  result += param[param.length - 1];
  return result;
};

export const SearchParamsShorterObject = (params: { [k: string]: any }): { [k: string]: any } => {
  const result: { [k: string]: any } = {};
  for (const k in params) {
    if (params.hasOwnProperty(k)) {
      result[SearchParamsShorter(k)] = params[k];
    }
  }
  return result;
};
