import { TLocalStorageKey } from '@shared/type/index.type';
import { Preferences } from '@capacitor/preferences';

export class LocalStorage {
  static async getItem(key: TLocalStorageKey): Promise<string> {
    await LocalStorage.configureStorage();
    const { value } = await Preferences.get({ key });
    return value;
  }

  static async setItem(key: TLocalStorageKey, value: string): Promise<void> {
    await LocalStorage.configureStorage();
    await Preferences.set({
      key,
      value
    });
  }

  static async removeItem(key: TLocalStorageKey): Promise<void> {
    await LocalStorage.configureStorage();
    await Preferences.remove({ key });
  }

  static getWebStorageItem(key: TLocalStorageKey): string {
    return localStorage.getItem(key);
  }

  static setWebStorageItem(key: TLocalStorageKey, value: string): void {
    localStorage.setItem(key, value);
  }

  private static async configureStorage() {
    await Preferences.configure({ group: 'csx' });
  }
}
