import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OfflineStatusService {
  isOffline$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isNetworkAvailable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  areRequestsOffline$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  preparingOffline$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadedPercentage$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  syncing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
}
