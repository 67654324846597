export enum EExpirationStatus {
  'UNEXPIRED' = 'shared.enums.expirationStatus.unexpired',
  'EXPIRED' = 'shared.enums.expirationStatus.expired',
  'EXPIRING_SOON' = 'shared.enums.expirationStatus.expiringSoon'
}

export enum EExpirationStatusWithDays {
  'EXPIRED' = 'shared.enums.expirationStatusWithDays.expired',
  'WITHIN_30' = 'shared.enums.expirationStatusWithDays.within_30',
  '31-60' = 'shared.enums.expirationStatusWithDays.31-60',
  '61-120' = 'shared.enums.expirationStatusWithDays.61-120',
  '121-180' = 'shared.enums.expirationStatusWithDays.121-180',
  '181-365' = 'shared.enums.expirationStatusWithDays.181-365'
}
