<div fxLayout="column" fxLayoutGap="12px">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <app-title [title]="data.title | language" [type]="'h5'"></app-title>
    <span class="cancelButton" (click)="cancel()">{{ 'shared.labels.cancel' | language }}</span>
  </div>
  <div fxLayout="column" fxLayoutGap="8px">
    <span *ngFor="let item of data.items" class="bottomSheetItem" (click)="cancel(item)">
      {{ item.name }}
    </span>
  </div>
</div>
