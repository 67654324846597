import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@shared/classes/api-service';
import { EventQuoteModel } from '@shared/models';
import { EventQuoteCatalogModel, EventQuoteParamsModel } from '@shared/models/features/events/event-quote.model';
import { Observable, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventQuotesService extends ApiService {
  constructor() {
    super();
  }

  getQuote(id: string): Observable<EventQuoteModel> {
    return this.get<EventQuoteModel>(`event-quotes/event/${id}`).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of(new EventQuoteModel()))
    );
  }

  createQuote(body: EventQuoteParamsModel): Observable<string> {
    return this.post<string>(`event-quotes`, body).pipe(
      map(id => {
        this.alertsService.showSuccess('shared.alerts.successMessages.created');
        return id;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  updateQuote(quoteId: string, body: EventQuoteParamsModel): Observable<boolean> {
    return this.put<void>(`event-quotes/${quoteId}`, body).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  updateQuoteItem(itemId: string, body: EventQuoteCatalogModel): Observable<boolean> {
    return this.put<void>(`event-quotes/catalog-quote/${itemId}`, body).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  deleteQuoteItem(itemId: string): Observable<boolean> {
    return this.delete<void>(`event-quotes/catalog-quote/${itemId}`).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.deleted');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  deleteQuote(quoteId: string): Observable<boolean> {
    return this.delete<void>(`event-quotes/${quoteId}`).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.deleted');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }
}
