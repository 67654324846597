import { KeyValue, KeyValuePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-radio-kit',
  templateUrl: './radio-kit.component.html',
  styleUrls: ['./radio-kit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [KeyValuePipe]
})
export class RadioKitComponent {
  @Input() set radioButtons(data: any[] | Record<string, string>) {
    if (Array.isArray(data)) {
      this.items = data;
    } else {
      this.items = this.keyValuePipe.transform(data);
    }
  }
  @Input() disabled: boolean = false;
  @Input() layout: 'row' | 'column' = 'row';
  @Input() value: any = '';
  @Input() gap: string = '';
  @Input() parseValueByProperty: string = '';
  @Input() parseKeyByProperty: string = '';
  @Input() label: string = '';
  @Input() translateValue: boolean = false;
  @Input() customClass: string = '';

  @Output() radioEmitter = new EventEmitter<any>();

  items: any[] | KeyValue<string, string>[] = [];

  constructor(private keyValuePipe: KeyValuePipe) {}
}
