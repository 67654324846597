<app-default-modal
  [size]="'small'"
  [title]="'events.detail.purchaseOrderNumberLabel' | language"
  [disableSubmitButton]="!poNumbersFulFilled || !poDate"
  [buttonTitle]="'shared.labels.submit' | language"
  [scrollableContent]="true"
  (clickEmitter)="savePONumber()"
>
  <div fxLayout="column" fxLayoutGap="16px" fxFlex="100%">
    <span class="poNumberHint"> {{ 'events.detail.fillPOCaption' | language }} </span>

    <app-datepicker-field-kit
      [label]="'events.detail.poDateLabel' | language"
      [initialValue]="poDate"
      [inputDataCy]="'eventPODateInput'"
      [icon]="'calendar'"
      [required]="true"
      (dateEmitter)="poDate = $event.value"
    ></app-datepicker-field-kit>

    <div fxLayout="column" fxLayoutGap="8px">
      <span class="poNumberHint">{{ 'events.detail.addPOForAllLabel' | language }} </span>

      <app-input-kit
        [dataCy]="'eventPONumberInput'"
        [label]="('events.detail.poLabel' | language) + ' #'"
        [initialValue]="commonPONumber"
        (valueChangedEmitter)="commonPONumber = $event; setPoNumbersForAllManufactures(); checkEmpty()"
      ></app-input-kit>
    </div>

    <div
      *ngFor="let manufacturePO of data.poNumbers; trackBy: trackBy"
      fxLayout.xs="column"
      fxLayout="row"
      fxLayoutGap="16px"
      fxLayoutAlign.xs="start stretch"
      fxLayoutAlign="space-between center"
    >
      <span fxFlex.xs="none" fxFlex="33%" class="poNumberManufacturerName">{{ manufacturePO.manufacturer.name }}</span>
      <app-input-kit
        fxFlex="66%"
        fxFlex.xs="none"
        [dataCy]="'eventPONumberInput'"
        [label]="('events.detail.poLabel' | language) + ' #'"
        [initialValue]="manufacturePO.poNumber"
        (valueChangedEmitter)="manufacturePO.poNumber = $event; checkEmpty()"
      ></app-input-kit>
    </div>
  </div>
</app-default-modal>
