import { IFilter } from '@shared/interfaces';
import { DefaultQueryParams } from '@shared/helpers/default-query-params';
import { TTransferStatus } from '@shared/type/index.type';
import { ClearRequest } from '@shared/utils/filter-object-null-properties';

export class TransferPageableParams {
  createdDatetime?: string;
  datetimeFrom?: string | Date;
  datetimeTo?: string | Date;
  eventId?: string;
  inventoryId?: string;
  organizationRecipient?: boolean;
  organizationSender?: boolean;
  page: number;
  recipientId?: string;
  returnedAuthorization?: string;
  search?: string;
  searchWithoutInventoryRequest?: boolean;
  senderId?: string;
  senderRecipientNonAdmin?: boolean;
  size: number;
  sort: string[];
  status?: TTransferStatus;
  trackingNumber?: string;
  transferStatuses?: TTransferStatus[];

  constructor(params: IFilter) {
    return TransferPageableParams.build(params);
  }

  private static build(params: IFilter): TransferPageableParams {
    const queryParams: TransferPageableParams = DefaultQueryParams(params);
    queryParams.search = params.search;
    queryParams.status = params.transferStatus;
    queryParams.inventoryId = params.inventoryId;
    queryParams.createdDatetime = params.transferDate;
    queryParams.trackingNumber = params.trackingNumber;
    queryParams.eventId = params.eventId;
    queryParams.returnedAuthorization = params.returnedAuthorization;

    if (params?.transferDatetimeFrom) {
      queryParams.datetimeFrom = params.transferDatetimeFrom;
    }

    if (params?.transferDatetimeTo) {
      queryParams.datetimeTo = params.transferDatetimeTo;
    }

    if (params?.transferSenderId && params?.transferSenderId !== 'ORGANIZATION') {
      queryParams.senderId = params.transferSenderId;
    }

    if (params?.transferSenderId === 'ORGANIZATION') {
      queryParams.organizationSender = true;
    }

    if (params?.transferRecipientId && params?.transferRecipientId !== 'ORGANIZATION') {
      queryParams.recipientId = params.transferRecipientId;
    }

    if (params?.transferRecipientId === 'ORGANIZATION') {
      queryParams.organizationRecipient = true;
    }

    if (params?.transferStatuses?.length) {
      queryParams.transferStatuses = params.transferStatuses;
    }

    if (params?.searchWithoutInventoryRequest) {
      queryParams.searchWithoutInventoryRequest = true;
    }

    if (String(params?.senderRecipientNonAdmin) === 'true') {
      queryParams.senderRecipientNonAdmin = true;
    }

    return ClearRequest(queryParams);
  }
}
