import { Injectable } from '@angular/core';
import { IEventPDFSendRequest, IEventExportPdf } from '@shared/interfaces';
import { HttpHelperService } from './internal/http-helper.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of, catchError, map } from 'rxjs';
import { ApiService } from '@shared/classes/api-service';

@Injectable({
  providedIn: 'root'
})
export class EventPdfService extends ApiService {
  constructor() {
    super();
  }

  invoice(params: IEventExportPdf): Observable<string> {
    return this.post<string>(`events/export/eventInvoice`, params, HttpHelperService.addResponseTypeText({})).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  salesOrderForm(params: IEventExportPdf, isPdfWithoutPrices: boolean, isPdfWithoutReplenishment: boolean): Observable<string> {
    return this.post<string>(
      `events/export/pdf?isPdfWithoutPrices=${isPdfWithoutPrices}&isPdfWithoutReplenishment=${isPdfWithoutReplenishment}`,
      params,
      HttpHelperService.addResponseTypeText({})
    ).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  sendEmailInvoice(params: IEventPDFSendRequest): Observable<void> {
    return this.post<void>(`events/send/eventInvoicePdf`, params).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.pdfWasSendToEmail')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(false);
      })
    );
  }

  sendSalesOrderForm(params: IEventPDFSendRequest): Observable<void> {
    return this.post<void>(`events/send/pdf`, params).pipe(
      map(data => {
        this.alertsService.showSuccess('shared.alerts.successMessages.pdfWasSendToEmail');
        return data;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(false);
      })
    );
  }

  eventInvoiceSCV(eventId: string): Observable<string> {
    return this.post<string>(`events/export/eventInvoice/csv`, {}, HttpHelperService.addResponseTypeText({ params: { eventId } })).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  generateQuotePDF(params: IEventExportPdf): Observable<string> {
    return this.post<string>(`events/export/event-quote-pdf`, params, HttpHelperService.addResponseTypeText({})).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  sendQuote(params: IEventPDFSendRequest): Observable<void> {
    return this.post<void>(`events/send/event-quote-pdf`, params).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.pdfWasSendToEmail')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(false);
      })
    );
  }
}
