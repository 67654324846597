import { CatalogModel, ProductLineModel } from '@shared/models';

export class PreferenceCardAssignment {
  catalog: CatalogModel;
  createdDatetime?: string;
  id?: string;
  modifier: string;
  modifiedDatetime?: string;
  product: ProductLineModel;
  quantity: number;
  totalElements?: number;
}

export class PreferenceCardAssignmentDTO {
  catalogId: string;
  productId: string;
  quantity: number;
  modifier?: string;
}
