type deviceType = 'MOBILE' | 'TABLET' | 'DESKTOP';

/**
 * This method is for sync requests, it not observes for the breakpoints changes
 * For the future we can extend is as Android/IOS/Tablet, etc
 */

export const isDevice = (type: deviceType): boolean => {
  const width: number = window.innerWidth;
  if (type === 'MOBILE') {
    return width < 767;
  } else if (type === 'TABLET') {
    return width >= 767 && width < 1025;
  } else if (type === 'DESKTOP') {
    return width > 767;
  }
  return true;
};

// Detecting the mobile devices (includes tablets with landscape orientation)
//TODO probably remove and replace or modify this function in the future, not relevant logic, it does not cover all phones/tablets
export const isMobileDevice = (): boolean =>
  typeof screen.orientation === 'undefined' ||
  (/Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent) && window.innerWidth <= 1279) ||
  (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2);
