import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { LocalDatabaseService } from './local-database.service';
import { EventsService } from '@services/events.service';
import { ICloseEvent, IDUTChanges } from '@shared/interfaces/offline';
import { OfflineStatusService } from '@services/offline/offline-status.service';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MobileOfflineProgressModalComponent } from '@shared/components/mobile-offline-progress-modal/mobile-offline-progress-modal.component';

@Injectable({
  providedIn: 'root'
})
export class SyncService {
  private loaderRef: MatBottomSheetRef;
  constructor(
    private localDatabaseService: LocalDatabaseService,
    private eventsService: EventsService,
    private offlineStatusService: OfflineStatusService,
    private bottomSheet: MatBottomSheet
  ) {}

  async syncData(): Promise<void> {
    this.offlineStatusService.syncing$.next(true);
    this.loaderRef = this.bottomSheet.open(MobileOfflineProgressModalComponent, {
      data: {
        type: 'SYNCING'
      },
      closeOnNavigation: false,
      disableClose: true
    });
    //Get all changes
    await this.localDatabaseService.setTable('userChanges');
    const unAssignDevices = await this.localDatabaseService.getItem('removeDeviceFromEvent');
    if (unAssignDevices?.length) {
      await this.unAssignDevices(JSON.parse(unAssignDevices));
      await this.localDatabaseService.removeItem('removeDeviceFromEvent');
    }
    const markUsedInventoryChanges = await this.localDatabaseService.getItem('markUsedInventory');
    if (markUsedInventoryChanges?.length) {
      await this.markDevices(JSON.parse(markUsedInventoryChanges));
      await this.localDatabaseService.removeItem('markUsedInventory');
    }
    const closeEvent = await this.localDatabaseService.getItem('closeEvent');
    if (closeEvent?.length) {
      await this.closeEvent(JSON.parse(closeEvent));
      await this.localDatabaseService.removeItem('closeEvent');
    }
    this.offlineStatusService.syncing$.next(false);
    this.loaderRef.dismiss();
  }

  private async markDevices(markDevices: IDUTChanges[] = []): Promise<void> {
    const markDevicesRecursion = async (index = 0) => {
      if (!markDevices[index] || !markDevices[index]?.inventoryIds?.length) {
        return;
      }
      const c: IDUTChanges = markDevices[index];
      await firstValueFrom(this.eventsService.updateDutStatus(c.eventId, { inventoryIds: c.inventoryIds, inventoryStatus: 'USED' }));
      await markDevicesRecursion(index + 1);
    };
    await markDevicesRecursion(0);
  }

  private async unAssignDevices(removeDevices: IDUTChanges[] = []): Promise<void> {
    const removeDevicesRecursion = async (index = 0) => {
      if (!removeDevices[index] || !removeDevices[index]?.inventoryIds?.length) {
        return;
      }
      const c: IDUTChanges = removeDevices[index];
      await firstValueFrom(this.eventsService.unAssignDevicesFromEvent(c.eventId, c.inventoryIds));
      await removeDevicesRecursion(index + 1);
    };
    return await removeDevicesRecursion(0);
  }

  private async closeEvent(signs: ICloseEvent[]): Promise<void> {
    const submitEventRecursion = async (index: number) => {
      if (!signs[index]) {
        return;
      }
      const c: ICloseEvent = signs[index];
      await firstValueFrom(this.eventsService.submit(c.eventId, c.submit));
      await submitEventRecursion(index + 1);
    };
    return await submitEventRecursion(0);
  }
}
