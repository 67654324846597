import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { GlobalStoreService } from '@services/internal/global-store.service';
import { EventEmailPdfService } from 'src/app/features/events/event-detail/services/event-email-pdf.service';

@Component({
  selector: 'app-global-loader',
  templateUrl: './global-loader.component.html',
  styleUrls: ['./global-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalLoaderComponent {
  loading$: Observable<boolean> = GlobalStoreService.loading$;
  sending$: Observable<boolean> = EventEmailPdfService.sending$;
}
