export const FilterUniqueArray = (array: { id: string }[], remove: { id: string }[]): any[] =>
  array.filter(r => !remove.some(s => s.id === r.id));

export const FilterUniqueArrayIdsString = (array: { id: string }[], removeIds: string[]): any[] =>
  array.filter(r => !removeIds.some(s => s === r.id));

// This function filters the array1 and leaves there only unique values
export const FilterUniqueArrayByProperty = (array1: any[], array2: any[], property: string): any[] =>
  array1.filter(obj => !array2.some(obj2 => obj[property] === obj2[property]));

export const FilterUniqueArrayByModifier = (
  array: { id: string; modifier?: string }[],
  remove: { id: string; modifier?: string }[]
): any[] => array.filter(r => !remove.some(s => s.id === r.id && s.modifier === r.modifier));
