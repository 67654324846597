import { AbstractControl } from '@angular/forms';

export const MatAutocompleteRequireMath = (control: AbstractControl) => {
  const selection: any = control.value;
  if (typeof selection === 'string' && selection.length) {
    return { incorrect: true };
  }
  return null;
};

export const MatAutocompleteRequireID = (control: AbstractControl) => {
  const selection: any = control.value;
  if (typeof selection === 'object' && (!selection?.hasOwnProperty('id') || selection?.id === false)) {
    return { incorrect: true };
  }
  return null;
};
