<div fxHide fxShow.lt-lg class="backgroundContainer">
  <div fxLayout="row" fxLayoutAlign="start center" class="connectSxTabContainer">
    <div
      class="connectSxTab"
      fxFlex="50%"
      [ngClass]="{ connectSxTabActive: selectedView === 'DETAILS' }"
      (click)="viewChangedEmitter.emit('DETAILS')"
    >
      {{ 'shared.labels.details' | language }}
    </div>

    <div
      class="connectSxTab"
      fxFlex="50%"
      [ngClass]="{ connectSxTabActive: selectedView === 'INFO' }"
      (click)="viewChangedEmitter.emit('INFO')"
    >
      {{ 'shared.labels.info' | language }}
    </div>
  </div>
</div>
