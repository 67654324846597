import { IFilter } from '@shared/interfaces/filter';
import { DefaultQueryParams } from '@shared/helpers/default-query-params';
import { TNotificationSubject } from '@shared/type/index.type';

export class NotificationsPageableParams {
  alertSubject?: TNotificationSubject;
  excludeAlertSubjects?: TNotificationSubject[];
  isRead?: boolean;
  page: number;
  size: number;
  sort: string[];

  constructor(params: IFilter) {
    return NotificationsPageableParams.build(params);
  }

  private static build(params: IFilter): NotificationsPageableParams {
    const queryParams: NotificationsPageableParams = DefaultQueryParams(params);

    if (params.hasOwnProperty('isRead')) {
      queryParams.isRead = params.isRead;
    }

    if (params.alertSubject) {
      queryParams.alertSubject = params.alertSubject;
    }

    if (params.excludeAlertSubjects) {
      queryParams.excludeAlertSubjects = params.excludeAlertSubjects;
    }

    return queryParams;
  }
}
