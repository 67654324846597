<div [ngClass]="{ 'form-group-emphasized': !expandedSection, 'last-child': lastChild }" fxLayout="column" fxLayoutGap="16px">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">
    <div fxLayout="row" class="form-group-emphasized-wrap" fxLayoutGap="8px" fxLayoutAlign="start center">
      <app-title [title]="commentsTitle || ('shared.labels.comments' | language)" [type]="titleType"></app-title>
      <app-tooltip-kit *ngIf="tooltipText" [title]="tooltipText" [position]="'above'"></app-tooltip-kit>
    </div>
    <ng-container *appHasPermission="{ scope: 'common', permissionName: 'addComment' }">
      <app-button-kit
        *ngIf="(showNewCommentField$ | async) === false"
        fxHide.lt-lg
        [dataCy]="'leaveCommentButton'"
        [type]="'secondary'"
        [title]="'shared.labels.leaveCommentButton' | language"
        (clickEmitter)="openAddCommentField()"
      ></app-button-kit>
      <app-button-kit
        *ngIf="(showNewCommentField$ | async) === false"
        fxHide
        fxShow.lt-lg
        [iconSize]="24"
        [icon]="'plus'"
        (clickEmitter)="openAddCommentField()"
      ></app-button-kit>
    </ng-container>
  </div>

  <app-user-autocomplete
    *ngIf="displayCommentRecipients"
    [required]="false"
    [showOpenInNewIcon]="true"
    [showClearIcon]="false"
    [multiple]="true"
    [usersForMentions]="true"
    [label]="'shared.labels.commentRecipients' | language"
    [removable]="allowEdit && permissionService.isGranted('common', 'addComment')"
    [disabled]="!allowEdit || !permissionService.isGranted('common', 'addComment')"
    [allowViewDetails]="currentUser.role === 'ADMIN'"
    [selectedChips]="recipients"
    (optionSelectedEmitter)="recipientAdded.emit($event)"
    (removeChipEmitter)="recipientRemoved.emit($event)"
  ></app-user-autocomplete>

  <div [hidden]="(showNewCommentField$ | async) === false" fxLayout="column" fxLayoutGap="16px">
    <app-wysiwyg id="commentInput" fxFlex="100%" [maxLength]="1000" [value]="newComment" (valueChanged)="newComment = $event"></app-wysiwyg>

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
      <app-button-kit
        fxFlex="100px"
        fxFlex.xs="50%"
        data-cy="addCommentSaveButton"
        [color]="'success'"
        [textColor]="'white'"
        [size]="(isMobile$ | async) === true ? 'medium' : 'small'"
        [disabled]="!newComment?.length"
        [type]="'primary'"
        [title]="'shared.labels.save' | language"
        (clickEmitter)="saveComment()"
      ></app-button-kit>

      <app-button-kit
        fxFlex="100px"
        fxFlex.xs="50%"
        data-cy="addCommentCancelButton"
        [color]="'warn'"
        [size]="(isMobile$ | async) === true ? 'medium' : 'small'"
        [type]="'secondary'"
        [title]="'shared.labels.cancel' | language"
        (clickEmitter)="newComment = ''; showNewCommentField$.next(false)"
      ></app-button-kit>
    </div>
  </div>

  <app-comments-list
    [stopEdit]="showNewCommentField$ | async"
    [notes]="comments"
    [allowEdit]="allowEdit"
    [showCommentMarker]="showCommentMarker"
    (editStartedEmitter)="showNewCommentField$.next(false)"
    (editCommentEmitter)="editComment($event)"
    (markCommentEmitter)="markCommentEmitter.emit($event)"
  ></app-comments-list>
</div>
