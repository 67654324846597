import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';
import { FacilityModel, NoteModel, PageableModel, PhysicianModel, ProcedureModel, ProductLineModel } from '@shared/models';
import { PhysicianRequestModel } from '@shared/models/request-models/physician-request-model';
import { HttpHelperService } from './internal/http-helper.service';
import { PhysiciansPageableParams } from '@shared/models/build-models';
import { ApiService } from '@shared/classes/api-service';

@Injectable({ providedIn: 'root' })
export class PhysiciansService extends ApiService {
  constructor() {
    super();
  }

  addComment(id: string, message: string, userIds: string[]): Observable<boolean> {
    return this.post<void>(`physicians/${id}/notes?mentionUserIds=${userIds}`, { text: message }).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  editComment(id: string, commentId: string, message: string, userIds: string[], commentUserIds: string[]): Observable<boolean> {
    return this.put<void>(
      `physicians/${id}/note/${commentId}?newMentionUserIds=${userIds}&existMentionUserIds=${commentUserIds}`,
      message
    ).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  deactivate(physicianId: string): Observable<void> {
    return this.put<void>(`physicians/${physicianId}/deactivate`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.deactivated');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getPhysician(id: string, redirectToNotFoundPage: boolean = false): Observable<PhysicianModel> {
    return this.get<PhysicianModel>(`physicians/${id}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        HttpHelperService.errorHandler(error, null, redirectToNotFoundPage);
        return of(null);
      })
    );
  }

  getFacilities(id: string): Observable<FacilityModel[]> {
    return this.get<FacilityModel[]>(`physicians/${id}/facilities`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  getNotes(id: string): Observable<NoteModel[]> {
    return this.get<NoteModel[]>(`physicians/${id}/notes`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  getPageable(params: PhysiciansPageableParams): Observable<PageableModel<PhysicianModel>> {
    return this.get<PageableModel<PhysicianModel>>(`physicians/pageable`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(new PageableModel());
      })
    );
  }

  getPageableByRepresentative(params: PhysiciansPageableParams): Observable<PageableModel<PhysicianModel>> {
    return this.get<PageableModel<PhysicianModel>>(
      `physicians/pageable/byRepresentative`,
      HttpHelperService.addResponseTypeJSON(params)
    ).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(new PageableModel());
      })
    );
  }

  getProcedures(id: string): Observable<ProcedureModel[]> {
    return this.get<ProcedureModel[]>(`physicians/${id}/procedures`);
  }

  getProducts(id: string): Observable<ProductLineModel[]> {
    return this.get<ProductLineModel[]>(`physicians/${id}/products`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  createPhysician(body: PhysicianRequestModel): Observable<string> {
    return this.post<string>(`physicians/mobile`, body).pipe(
      map(id => {
        this.alertsService.showSuccess('shared.alerts.successMessages.created');
        return id;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  updatePhysician(id: string, body: PhysicianModel): Observable<void> {
    return this.put<void>(`physicians/${id}`, body);
  }

  putMobile(id: string, body: PhysicianRequestModel): Observable<void> {
    return this.put<void>(`physicians/mobile/${id}`, body).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }
}
