<div class="listTableContainer withoutFilters">
  <table
    mat-table
    matSort
    matSortDisableClear
    [dataSource]="dataSource"
    (matSortChange)="getPageable(null, $event)"
    [matSortActive]="matSortHeaderInFilter.active"
    [matSortDirection]="matSortHeaderInFilter.direction"
    class="connectSxTable fixedTitles"
    [ngClass]="{ hideCells: loading === true && (isMobile$ | async) === false, expandedView: selectedView === 'EXPORTS' }"
  >
    <!-- subject Column -->
    <ng-container matColumnDef="subject">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="loading">
        {{ 'notifications.notificationColumnTitle' | language }}
      </th>
      <td mat-cell *matCellDef="let element" fxHide.lt-lg>
        {{ element.subject }}
      </td>
    </ng-container>

    <!-- Message -->
    <ng-container matColumnDef="message">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="loading">
        {{ 'notifications.messageColumnTitle' | language }}
      </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.isRead" fxHide.lt-lg class="emphasizedPrimary">{{ 'notifications.newLabel' | language }}</span>
        <span [innerHTML]="bypassSecurityTrustHtml(replaceStatistics(element.entityType, element.message))" class="messageText"></span>
      </td>
    </ng-container>

    <!-- createdBy Column -->
    <ng-container matColumnDef="createdBy">
      <th [fxHide]="selectedView !== 'EXPORTS'" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="loading">
        {{ 'notifications.createdByColumnTitle' | language }}
      </th>
      <td [fxHide]="selectedView !== 'EXPORTS'" mat-cell *matCellDef="let element">
        {{ element.recipient?.name }}
      </td>
    </ng-container>

    <!-- modifiedDatetime Column -->
    <ng-container matColumnDef="modifiedDatetime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="loading">
        {{ 'notifications.createdColumnTitle' | language }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.modifiedDatetime | userDate }}
      </td>
    </ng-container>

    <!-- action Column -->
    <ng-container matColumnDef="entityType">
      <th mat-header-cell *matHeaderCellDef>{{ 'notifications.actionColumnTitle' | language }}</th>
      <td mat-cell *matCellDef="let element">
        <app-icon
          *ngIf="element.entityType === 'REQUEST' && element.subject !== 'User mentioned'"
          [color]="'primary'"
          [type]="'outline'"
          [size]="20"
          [icon]="'show'"
          [routerLink]="'/movement/requests/edit/' + element.entityId"
        ></app-icon>

        <app-icon
          *ngIf="element.entityType === 'INVENTORY_IMPORT'"
          [color]="'primary'"
          [type]="'outline'"
          [size]="20"
          [icon]="'show'"
          [routerLink]="'/inventory/import/detail/' + element.entityId"
        ></app-icon>

        <app-icon
          *ngIf="element.entityType === 'EVENT' && element.subject !== 'User mentioned' && isAdmin"
          [color]="'primary'"
          [type]="'outline'"
          [routerLink]="'/events/edit/' + element.entityId"
          [size]="20"
          [icon]="'show'"
        ></app-icon>

        <app-icon
          *ngIf="element.entityType === 'TRANSFER' && element.subject !== 'User mentioned'"
          [color]="'primary'"
          [type]="'outline'"
          [size]="20"
          [icon]="'show'"
          [routerLink]="'/movement/transfers/edit/' + element.entityId"
        ></app-icon>

        <app-icon
          *ngIf="element.entityType === 'INVENTORY' && element.subject === 'Device movement logged'"
          [color]="'primary'"
          [type]="'outline'"
          [size]="20"
          [icon]="'show'"
          [routerLink]="'/inventory/inventories/edit/' + element.entityId"
        ></app-icon>

        <app-icon
          *ngIf="element.entityType === 'STATISTICS' && element.subject === 'Overdue inventory'"
          [color]="'primary'"
          [type]="'outline'"
          [size]="20"
          [icon]="'show'"
          (click)="defineDueBackStatus(element.message)"
        ></app-icon>

        <app-icon
          *ngIf="element.entityType === 'STATISTICS' && element.subject === 'Reserved event for container'"
          [color]="'primary'"
          [type]="'outline'"
          [size]="20"
          [icon]="'show'"
          (click)="defineReservationDate(element.message, element.createdDatetime)"
        ></app-icon>

        <app-icon
          *ngIf="element.entityType === 'STATISTICS' && element.subject === 'Expired inventory'"
          [color]="'primary'"
          [type]="'outline'"
          [size]="20"
          [icon]="'show'"
          (click)="defineExpirationStatus(element.message)"
        ></app-icon>

        <app-icon
          *ngIf="element.entityType === 'STATISTICS' && element.subject === 'Expired inspection'"
          [color]="'primary'"
          [type]="'outline'"
          [size]="20"
          [icon]="'show'"
          (click)="defineInspectionDue(element.message)"
        ></app-icon>

        <app-icon
          *ngIf="element.subject === 'User mentioned'"
          [color]="'primary'"
          [type]="'outline'"
          [size]="20"
          [icon]="'show'"
          [routerLink]="getMentionedPage(element)"
        ></app-icon>

        <app-icon
          *ngIf="
            element.entityType === 'INVENTORY' &&
            !element.subject.toLowerCase().includes('overdue inventory') &&
            !element.subject.toLowerCase().includes('expired inventory') &&
            !element.subject.toLowerCase().includes('reserved event for container') &&
            !element.subject.toLocaleLowerCase().includes('report run') &&
            element.subject !== 'Device movement logged' &&
            element.subject !== 'User mentioned' &&
            element.subject !== 'Inventory due back extension requested'
          "
          [color]="'primary'"
          [type]="'outline'"
          [size]="20"
          [icon]="'show'"
          [routerLink]="'/inventory/inventories/edit/' + element.entityId"
        ></app-icon>

        <app-icon
          *ngIf="element.entityType === 'AUDIT_INVENTORY'"
          [color]="'primary'"
          [type]="'outline'"
          [size]="20"
          [icon]="'show'"
          [routerLink]="'/inventory/audits/edit/' + element.entityId"
        ></app-icon>

        <app-icon
          *ngIf="element.entityType === 'INVENTORY' && element.subject === 'Inventory due back extension requested'"
          [color]="'primary'"
          [type]="'outline'"
          [size]="20"
          [icon]="'show'"
          (clickEmitter)="navigateToDueBackExtension()"
        ></app-icon>

        <ng-container *ngIf="isStatistic(element.entityType, element.message)">
          <app-icon
            *appHasPermission="{ scope: 'report', permissionName: 'readReport' }"
            [color]="'primary'"
            [type]="'outline'"
            [size]="20"
            [icon]="'show'"
            (click)="openStatisticLink(element.message)"
          ></app-icon>
        </ng-container>

        <app-icon
          *ngIf="element.entityType === 'INVITATION'"
          [color]="'primary'"
          [type]="'outline'"
          [size]="20"
          [icon]="'show'"
          [routerLink]="'/users/' + element.entityId"
        ></app-icon>

        <app-icon
          *ngIf="element.entityType === 'INVENTORY_EXPORT'"
          [color]="'primary'"
          [type]="'outline'"
          [size]="20"
          [icon]="'download'"
          (click)="downloadFile(element.message)"
        ></app-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
    <ng-container *ngIf="(isMobile$ | async) === true; else desktop">
      <app-mobile-table-view
        *matRowDef="let row; columns: columnsToDisplay"
        [title]="row.subject"
        [status]="!row.isRead ? ('notifications.newLabel' | language) : null"
        [elementId]="row.id"
        [statusClass]="'emphasizedPrimary'"
        [opened]="row.id === openedElementId"
        (openedEmitter)="openedElementId = $event"
      >
        <tr mat-row></tr>
      </app-mobile-table-view>
    </ng-container>
    <ng-template #desktop>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
    </ng-template>
  </table>

  <app-empty-state *ngIf="!dataSource.data.length && !loading"></app-empty-state>

  <app-skeleton
    *ngIf="loading && ((!dataSource.data.length && (isMobile$ | async) === true) || (isMobile$ | async) === false)"
    [count]="20"
  ></app-skeleton>
</div>

<div
  *ngIf="dataSource.data.length"
  fxLayout="row"
  fxLayout.lt-lg="column"
  fxLayoutAlign.lt-lg="start stretch"
  fxLayoutAlign="space-between center"
  class="connectSxTablePaginationContainer"
>
  <app-custom-pagination
    [totalPages]="totalPages"
    [pageNumber]="pageNumber"
    [disabled]="loading"
    (pageChangedEmitter)="getPageable({ pageIndex: $event, pageSize: size, length: totalElements, previousPageIndex: pageNumber }, null)"
  ></app-custom-pagination>
  <app-custom-pagination-selector
    [totalElements]="totalElements"
    [pageNumber]="pageNumber"
    [size]="size"
    [disabled]="loading"
    (getPageableEmitter)="getPageable($event)"
  ></app-custom-pagination-selector>
</div>
