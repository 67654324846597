<div
  class="textareaKitContainer"
  [ngClass]="{
    valueExist: focused,
    defaultHeight: focused && !textarea.value,
    invalidInput: error,
    disabledInput: disabled,
    required: required && !disabled,
    focused: focused
  }"
>
  <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="8px">
    <mat-form-field
      class="textareaFormField"
      [ngClass]="{ valueExist: textarea.value || focused, invalidInput: error || textareaRef.invalid, disabledInput: disabled }"
    >
      <mat-label>{{ label }}</mat-label>
      <textarea
        #textarea
        #textareaRef="ngModel"
        matInput
        cdkTextareaAutosize
        autocomplete="off"
        [minlength]="minLength"
        [maxlength]="maxLength"
        name="textareaKit"
        [required]="required"
        [placeholder]="placeholder"
        [disabled]="disabled"
        [attr.data-cy]="dataCy"
        [ngModel]="value"
        [ngModelOptions]="{ standalone: true }"
        (focus)="focused = true"
        (blur)="focused = false; textarea.value = textarea.value.trim()"
        (ngModelChange)="updateValue($event)"
      ></textarea>
    </mat-form-field>

    <div class="hintContainer" *ngIf="hint">
      <app-tooltip-kit [title]="hint" [position]="hintPosition" (click)="$event.stopPropagation()"></app-tooltip-kit>
    </div>
  </div>
  <span *ngIf="error" class="inputHint errorHint">{{ error }}</span>
</div>
