import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Base class to destroy subscriptions
 */
@Injectable()
export class DestroySubscriptions implements OnDestroy {
  subscriptions: Subject<void> = new Subject<void>();

  /**
   * If a child components uses his own OnDestroy, then it overwrites current ngOnDestroy, then it needs to call this method
   */
  unSubscribe(): void {
    this.subscriptions.next();
    this.subscriptions.complete();
  }

  ngOnDestroy(): void {
    this.unSubscribe();
  }
}
