import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { DestroySubscriptions } from '@shared/classes/destroy-subscriptions';

@Component({
  selector: 'app-form-product-line-step-second',
  templateUrl: './form-product-line-step-second.component.html',
  styleUrls: ['./form-product-line-step-second.component.scss']
})
export class FormProductLineStepSecondComponent extends DestroySubscriptions implements OnInit {
  @Input() formsValid: boolean = false;
  @Input() createEntityFlow: boolean = false;

  @Output() formValueChangeEmitter = new EventEmitter<UntypedFormGroup>();

  assets: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.formChanges();
  }

  private formChanges(): void {
    this.formValueChangeEmitter.emit(this.assets);
    this.assets.valueChanges.pipe(takeUntil(this.subscriptions)).subscribe(() => {
      this.formValueChangeEmitter.emit(this.assets);
    });
  }

  private initForm(): void {
    this.assets = this.formBuilder.group({
      assetUrl: [''],
      description: ['']
    });
  }
}
