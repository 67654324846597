import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertsService } from '@services/internal/alerts.service';
import { NotificationsService } from '@services/notifications.service';
import { LayoutsComponent } from '@shared/components/global/layouts/layouts.component';
import { IFilter } from '@shared/interfaces';
import { NotificationModel, PageableModel } from '@shared/models';
import { NotificationsPageableParams } from '@shared/models/build-models';
import { TNotificationsView } from '@shared/type/common-types.type';
import { SearchParamsShorterObject } from '@shared/utils/http/search-params-shorter';
import { take, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsStoreService {
  constructor(
    private notificationsService: NotificationsService,
    private router: Router,
    private alertsService: AlertsService
  ) {}

  markAllAsRead(type: TNotificationsView, quantity: number): void {
    this.getNotifications(type, quantity).subscribe();
    this.alertsService.showSuccess('shared.alerts.successMessages.markAllAsRead');
  }

  viewAll(type: TNotificationsView): void {
    const params: Partial<NotificationsPageableParams> = {};

    if (type === 'MENTIONS') {
      params.alertSubject = 'USER_MENTIONED';
    } else {
      params.excludeAlertSubjects = ['USER_MENTIONED'];
    }

    this.router.navigate(['/notifications'], { queryParams: SearchParamsShorterObject(params) });
  }

  getNotifications(
    type: TNotificationsView,
    size?: number,
    markAsReadAfterView: boolean = true
  ): Observable<PageableModel<NotificationModel>> {
    const params: IFilter = {
      isRead: false,
      page: 0,
      size: size || 20
    };

    if (type === 'MENTIONS') {
      params.alertSubject = 'USER_MENTIONED';
      params.excludeAlertSubjects = null;
    } else {
      params.excludeAlertSubjects = ['USER_MENTIONED'];
      params.alertSubject = null;
    }

    const queryParams: NotificationsPageableParams = new NotificationsPageableParams(params);

    return this.notificationsService.getPageable(queryParams).pipe(
      take(1),
      tap(data => {
        if (markAsReadAfterView) {
          this.markReadNotification(data.content);
        }
      })
    );
  }

  private markReadNotification(data: NotificationModel[] = []): void {
    const unreadIds: string[] = data.flatMap(notification => (!notification.isRead ? notification.id : []));

    if (!unreadIds.length) {
      return;
    }

    this.notificationsService
      .markAsRead(unreadIds)
      .pipe(take(1))
      .subscribe(() => LayoutsComponent.forceGetNotifications());
  }
}
