import { IICalEvent } from '@shared/interfaces';
import { LanguageService } from '@services/internal/language.service';
import { utcToZonedTime } from 'date-fns-tz';
import { UsersService } from '@services/users.service';
import { addMinutes } from 'date-fns';
import { GlobalStoreService } from '@services/internal/global-store.service';

export const downloadICalEvent = (filename: string, text: string, iCal: IICalEvent): void => {
  const isWeb = GlobalStoreService.getPlatform() === 'web';
  if (!isWeb) {
    const success = (_eventId: string) => {
      alert(LanguageService.instant('shared.alerts.successMessages.addedToCalendar'));
    };
    const error = (message: any) => {
      alert('Error: ' + message);
    };

    // @ts-ignore
    const calOptions = window.plugins.calendar.getCalendarOptions(); // grab the defaults
    calOptions.calendarName = 'MyCreatedCalendar'; // iOS only
    calOptions.calendarId = 1;
    calOptions.url = iCal.url;
    calOptions.isAllDay = false;
    calOptions.allday = false;
    const endDate = addMinutes(utcToZonedTime(iCal.start, UsersService.userTimeZone.timeZone), 1);
    // @ts-ignore
    window.plugins.calendar.createEventWithOptions(
      iCal.summary,
      iCal.location,
      iCal.description,
      iCal.start,
      endDate,
      calOptions,
      success,
      error
    );
  } else {
    const element: HTMLAnchorElement = document.createElement('a');
    element.setAttribute('href', 'data:text/calendar;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.click();
    element.remove();
  }
};
