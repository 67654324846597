import { IFilter } from '@shared/interfaces';
import { DefaultQueryParams } from '@shared/helpers/default-query-params';
import { UsersService } from '../../../../core/services/users.service';
import { TState } from '@shared/type/index.type';
import { ClearRequest } from '@shared/utils/filter-object-null-properties';

export class ProductLineParams {
  catalogId?: string;
  manufacturerId?: string;
  manufacturerIds?: string[];
  modifier?: string;
  name?: string;
  page: number;
  size: number;
  sort: string[];
  state?: TState;
  userId?: string;

  constructor(params: IFilter) {
    return ProductLineParams.build(params);
  }

  private static build(params: IFilter): ProductLineParams {
    const queryParams: ProductLineParams = DefaultQueryParams(params);
    queryParams.state = params?.state ?? 'ACTIVE';
    queryParams.name = params.search;
    queryParams.catalogId = params.catalogId;
    queryParams.manufacturerIds = params.manufacturerIds;
    queryParams.userId = params.custodyId;
    queryParams.modifier = params.modifier;
    if (params?.assignedProductLines) {
      queryParams.userId = UsersService.getUser().id;
    }
    if (params && params.manufacturer && typeof params.manufacturer !== 'boolean') {
      queryParams.manufacturerId = params.manufacturer;
    }
    return ClearRequest(queryParams);
  }
}
