<div fxLayout="column" fxLayoutGap="12px">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <app-title [title]="'auth.partials.selectOrganization' | language" [type]="'h5'"></app-title>
    <span class="cancelButton" (click)="cancel()">{{ 'shared.labels.cancel' | language }}</span>
  </div>
  <div fxLayout="column" fxLayoutGap="8px">
    <span
      *ngFor="let organization of data.organizations"
      class="bottomSheetItem"
      [ngClass]="{ activeItem: organization.organizationId === data.currentOrgId }"
      (click)="switchOrg(organization.organizationId)"
    >
      {{ organization.name }}
    </span>
  </div>
</div>
