import { IICalEvent } from '@shared/interfaces';

export const createICalEvent = (events: IICalEvent[]): string => {
  const formatDate = (date: Date): string => {
    if (!date) {
      return '';
    }
    const day: string | number = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month: string | number = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const year: number = date.getFullYear();
    const hour: string | number = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const minutes: string | number = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    const seconds: string | number = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return `${year}${month}${day}T${hour}${minutes}${seconds}`;
  };
  let VCALENDAR: string = `BEGIN:VCALENDAR
PRODID:-//Google Inc//Google Calendar 70.9054//EN
VERSION:2.0
`;

  for (const event of events) {
    const eventDate = formatDate(event.start).split('T')[0].trim();
    const eventTime = event.isAllDay ? `VALUE=DATE:${eventDate}` : `TZID=${event.timeZone}:${formatDate(event.start)}`;
    const timeStamp: string = formatDate(new Date());
    const uuid: string = `${timeStamp}uid@connectsx.com`;
    const EVENT: string = `BEGIN:VEVENT
DTSTAMP:${timeStamp}Z
DTSTART;${eventTime}${event.isAllDay ? 'T000000' : ''}
DTEND;${eventTime}${event.isAllDay ? 'T240000' : ''}
SUMMARY:${event.summary}
DESCRIPTION:${event.description}
LOCATION:${event.location}
URL:${event.url}
UID:${uuid}
END:VEVENT`;
    VCALENDAR += `${EVENT}
`;
  }
  VCALENDAR += `END:VCALENDAR`;

  return VCALENDAR;
};
