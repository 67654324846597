<div fxLayout="row" fxLayoutAlign="start center">
  <app-input-with-chips-kit
    *ngIf="multiple; else single"
    class="full-width"
    [showAddChipButton]="maxChipsCount > selectedItems?.length"
    [dataCy]="'catalogInput'"
    [chipDataCy]="'catalogChip'"
    [chipRemoveData_cy]="'catalogRemoveChipButton'"
    [selectedChips]="selectedItems"
    [label]="label"
    [forceBlurEvent]="forceBlurEvent"
    [debounceEvents]="['keyup', 'focus']"
    [required]="!selectedItems.length && required"
    [requiredView]="!!selectedItems.length && required"
    [debounceTime]="1000"
    [disabled]="disabled"
    [invalidInput]="invalid"
    [filterView]="filterView"
    [parseChipByProperty]="'referenceNumber'"
    [removable]="removable"
    [detailPageLink]="'/inventory/catalog/edit/'"
    [autocompleteName]="catalogAutocomplete"
    (chipsRemovedEmitter)="removeChip($event.id)"
    (debounceTimeEndedEmitter)="searchCatalogs($event)"
    (iconClickEmitter)="doIconAction($event)"
  ></app-input-with-chips-kit>

  <ng-template #single>
    <app-input-kit
      class="full-width"
      [required]="required"
      [label]="label"
      [dataCy]="'catalogInput'"
      [initialValue]="catalog"
      [debounceEvents]="['keyup', 'focus']"
      [borderTransparent]="borderTransparent"
      [debounceTime]="searchValue !== successfulSearchParams?.searchText ? 1000 : 0"
      [forceBlurEvent]="forceBlurEvent"
      [disabled]="disabled"
      [icons]="[showClearIcon && !disabled ? 'close' : null]"
      [filterView]="filterView"
      [autocompleteName]="catalogAutocomplete"
      [tooltipText]="tooltip"
      [icons]="icons"
      (debounceTimeEndedEmitter)="searchCatalogs($event)"
      (iconClickEmitter)="doIconAction($event)"
      (valueChangedEmitter)="valueChangedEmitter.emit($event); setSearchValue($event)"
    ></app-input-kit>
  </ng-template>

  <mat-autocomplete #catalogAutocomplete="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngIf="(loading$ | async) === true || (initialLoading$ | async) === true" class="is-loading" disabled>
      <mat-progress-spinner value="30" class="spinner" mode="indeterminate" diameter="30"></mat-progress-spinner>
    </mat-option>
    <ng-container *ngIf="(loading$ | async) === false && (initialLoading$ | async) === false">
      <mat-option
        *ngIf="!searchedData.length && !searchedAuditCatalogsData.length; else options"
        disabled
        data-cy="noCatalogOption"
        class="connectSxAutocompleteOption"
      >
        <span>{{ 'shared.labels.noItemsFound' | language }}</span>
      </mat-option>

      <ng-template #options>
        <cdk-virtual-scroll-viewport
          *ngIf="isSearchAuditCatalogs; else catalogsOptions"
          itemSize="0"
          class="virtualAutocompleteScroll"
          [style.height.px]="searchedAuditCatalogsData.length < 3 ? searchedAuditCatalogsData.length * 50 : 150"
        >
          <mat-option
            *cdkVirtualFor="let catalog of searchedAuditCatalogsData"
            data-cy="catalogOption"
            class="connectSxAutocompleteOption"
            [value]="catalog"
            (click)="selectAuditCatalog(catalog)"
          >
            <p data-cy="referenceNumber">{{ catalog.referenceNumber }}</p>
            <small>{{ 'shared.components.description' | language }}: {{ catalog.name || '-' }}</small>
          </mat-option>
        </cdk-virtual-scroll-viewport>

        <ng-template #catalogsOptions>
          <cdk-virtual-scroll-viewport
            itemSize="20"
            class="virtualAutocompleteScroll"
            [style.height.px]="searchedData.length < 3 ? searchedData.length * 50 : 150"
          >
            <mat-option
              *cdkVirtualFor="let catalog of searchedData"
              data-cy="catalogOption"
              class="connectSxAutocompleteOption"
              [value]="catalog"
              (click)="selectCatalog(catalog)"
            >
              <p data-cy="referenceNumber">{{ catalog.referenceNumber }}</p>
              <small>{{ 'shared.components.description' | language }}: {{ catalog.name || '-' }}</small>
              <small *ngIf="showManufacturerName">{{ 'shared.components.manufacturer' | language }}: {{ catalog.manufacturer.name }}</small>
            </mat-option>
          </cdk-virtual-scroll-viewport>
        </ng-template>
      </ng-template>
    </ng-container>

    <mat-option
      *ngIf="
        (loading$ | async) === false &&
        (initialLoading$ | async) === false &&
        showAddNewButton &&
        permissionService.isGranted('inventory', 'canCreateProductLine')
      "
      data-cy="addNewOption"
      class="connectSxAutocompleteOption addNewOption"
      (click)="addCatalog()"
    >
      <app-icon [type]="'outline'" [size]="20" [color]="'primary'" [icon]="'plus'"></app-icon>
      <span class="addNewLabel">{{ 'shared.labels.addNew' | language }}</span>
    </mat-option>
  </mat-autocomplete>
</div>
