<app-create-entity-modal
  [title]="'recipes.createRecipeTitle' | language"
  [showCreateButton]="stepperRef?.selectedIndex === 1"
  [showPrevStepButton]="stepperRef?.selectedIndex === 1"
  [showNextStepButton]="stepperRef?.selectedIndex === 0"
  [disableNextStepButton]="
    recipesStoreService.getRecipeForm.invalid || (stepperRef?.selectedIndex === 0 && !(newPhysicians$ | async).length)
  "
  [disableCreateButton]="(validForm$ | async) === false"
  [confirmationRequired]="true"
  [showCloseConfirmation]="recipesStoreService.store$.value.recipeForm.touched"
  (prevStepEmitter)="stepperRef.previous()"
  (nextStepEmitter)="stepperRef.next()"
  (createEmitter)="recipesStoreService.create()"
>
  <mat-stepper #stepper [linear]="true" [disableRipple]="true">
    <ng-template matStepperIcon="edit">
      <mat-icon>check</mat-icon>
    </ng-template>
    <mat-step [label]="'recipes.recipeDetailsLabel' | language" [stepControl]="recipesStoreService.getRecipeForm">
      <app-preference-card-form
        [createEntityFlow]="true"
        [physicians]="newPhysicians$ | async"
        (assignPhysicianEmitter)="recipesStoreService.setNewPhysicians($event)"
        (unAssignPhysicianEmitter)="recipesStoreService.removePhysicians($event)"
      />
    </mat-step>
    <mat-step [label]="'recipes.associatedInventoryLabel' | language">
      <app-recipe-assignments
        [createEntityFlow]="true"
        [requestRecipeAssignments]="assignments | async"
        (addAttachments)="recipesStoreService.setNewAssignments($event)"
        (delete)="recipesStoreService.deleteNewAssignment($event)"
        (save)="recipesStoreService.create()"
        (checkValidation)="recipesStoreService.checkValidation()"
        (getAssignmentsFromBOM)="getAssignmentsFromBOM()"
      ></app-recipe-assignments>
    </mat-step>
  </mat-stepper>
</app-create-entity-modal>
