export enum ESterilization {
  STERILIZATION_RECEIVED_PRE = 'shared.enums.sterilization.receivedPre',
  STERILIZATION_STERILIZED = 'shared.enums.sterilization.sterilized',
  STERILIZATION_RECEIVED_POST = 'shared.enums.sterilization.receivedPost',
  STERILIZATION_CLEANED = 'shared.enums.sterilization.cleaned',
  STERILIZATION_RESET = 'shared.enums.sterilization.reset'
}

export enum EDelivery {
  DELIVERY_READY = 'shared.enums.delivery.ready',
  DELIVERY_PICKED = 'shared.enums.delivery.picked',
  DELIVERY_DELIVERED = 'shared.enums.delivery.delivered',
  DELIVERY_RECEIVED = 'shared.enums.delivery.received',
  DELIVERY_RESET = 'shared.enums.delivery.reset'
}

export enum EShipment {
  SHIPMENT_PREPARED = 'shared.enums.shipment.prepared',
  SHIPMENT_READY = 'shared.enums.shipment.ready',
  SHIPMENT_SHIPPED = 'shared.enums.shipment.shipped',
  SHIPMENT_RESET = 'shared.enums.shipment.reset'
}
