import { EventModel } from '@shared/models/features/events/event.model';
import { TransferModel } from '@shared/models/features/movement/transfers/transfer.model';
import { CatalogModel } from '@shared/models/features/inventory/catalog/catalog.model';
import { TItemType, TState } from '@shared/type/index.type';
import { BillOfMaterials } from '@shared/models/features/inventory/bill-of-materials/bill-of-materials';
import { UserModel } from '@shared/models/features/users/user.model';
import {
  EConsignmentStatus,
  EDueBackStatus,
  EExpirationStatus,
  EHoldStatus,
  EInventoryDeviceType,
  EInventoryStatus,
  ESetFlag
} from '@shared/enum';
import { FacilityModel, InventoryImportJob, LocationModel } from '@shared/models';
import { DateTimeString, UniqueId } from '@shared/models/shared/shared-kernel';
import { IPackBOMDetails, IPackDeviceInfo } from '@shared/interfaces/inventory/pack-bom-details';

export class InventoryModel {
  barcode: string;
  billable: boolean;
  billOfMaterial: BillOfMaterials;
  catalog: CatalogModel;
  //front-end only
  catalogNumber?: string;
  checkinFlag: keyof typeof ESetFlag;
  children?: InventoryModel[];
  childrenCount?: number;
  chosen?: boolean;
  consignmentStatus: keyof typeof EConsignmentStatus;
  costOfGoods: string;
  count?: number;
  createdBy: UserModel;
  createdDatetime: DateTimeString;
  custody: UserModel;
  deviceId: string;
  devices?: InventoryModel[];
  dueBackDate: DateTimeString;
  dueBackStatus: keyof typeof EDueBackStatus;
  dutAmount?: number;
  event: EventModel;
  eventPrice?: number;
  expirationDate: Date;
  expirationStatus: keyof typeof EExpirationStatus;
  existContainer?: InventoryModel;
  facility?: FacilityModel;
  futureContainer?: InventoryModel;
  holdStatus: keyof typeof EHoldStatus;
  id: string;
  inventoryImportJob: InventoryImportJob;
  inventoryStatus: keyof typeof EInventoryStatus;
  inventoryType: TItemType;
  invoiceUdi?: string;
  isCheckinStarted: boolean;
  isInspectionRequired: boolean;
  lotNumber: string;
  matExpansionPanelUniqueId?: string;
  modifiedBy: UserModel;
  modifiedDatetime: DateTimeString;
  name: string;
  nextInspectionOn: string;
  notes: string;
  parentInventory: InventoryModel;
  price: number;
  quantityTransferExport?: number;
  relatedInventories?: any;
  reservedEvent: EventModel;
  reusable: boolean;
  selected?: boolean;
  selectedItems?: number;
  serialNumber: string;
  state: TState;
  tags: string[];
  totalElements?: number;
  transfer?: TransferModel;
  transferParentInventory?: InventoryModel;
  udi: string;
  expiredDays: number;
  location: LocationModel;
  noCharge?: boolean;
  exContainerName: string;
  inventoryDeviceType: keyof typeof EInventoryDeviceType;
  lotNumberChangedBy: UserModel;
  lotNumberChangedOn: DateTimeString;
  manufacturerDate: DateTimeString;
  inventoryRequestExtensionId: UniqueId;
  // Used at eventInventory, assigned devices to event
  priceChangedBy?: UserModel;
  priceChangedOn?: DateTimeString;
  // Front-end property
  autoassignedContainer?: boolean;
  inspectionDue?: boolean;
  // Uses at Device used tab on the event page
  isFacilityPrice?: boolean;
  //Front-end only, UI visible items, for intersection observer
  visible?: boolean;
  //Front-end only, need in offline mode
  markedInEvent?: boolean;
  checked?: boolean;
  //For inventoryType Pack
  packBOMDetails: IPackBOMDetails[];
  // mark used pack items
  packDeviceInfos: IPackDeviceInfo[];
  isInPack: boolean;

  constructor() {
    this.catalog = new CatalogModel();
  }
}
