<div fxLayout="column" fxLayoutGap="8px">
  <div fxLayout="row" fxLayoutAlign="space-between flex-end" class="full-width" fxLayoutGap="16px">
    <div fxLayout="column" fxLayoutGap="16px" fxFlex="100%">
      <app-events-autocomplete
        [label]="label"
        [showOpenInNewIcon]="showOpenInNewIcon"
        [showClearIcon]="showClearIcon"
        [useFilterCustody]="false"
        [disabled]="disabled"
        [initialValue]="initialValue"
        [filterParams]="getFilterParams()"
        (selectedOptionEmitter)="setEventEmitter.emit($event)"
        (clearInputEmitter)="setEventEmitter.emit(null)"
      ></app-events-autocomplete>
    </div>
    <span *ngIf="!disabled" [matMenuTriggerFor]="menu" class="additionalFilter" data-cy="searchByMenu">
      <app-icon
        [icon]="'filter'"
        [color]="eventNumber || datetimeFrom || datetimeTo || (displayFilterByRepresentative && representative) ? 'primary' : 'default'"
        [type]="'outline'"
        [size]="20"
      ></app-icon>
    </span>

    <mat-menu (click)="$event.stopPropagation()" #menu="matMenu" xPosition="before" class="eventsFilterMenu">
      <ng-template matMenuContent>
        <div fxLayout="column" fxLayoutGap="8px" (click)="$event.stopPropagation()">
          <app-input-kit
            [initialValue]="eventNumber"
            [label]="'shared.filter.searchByTitleEventNUmber' | language"
            [icons]="['close']"
            [filterView]="true"
            [type]="'number'"
            (valueChangedEmitter)="eventNumber = +$event"
            (iconClickEmitter)="eventNumber = null"
          ></app-input-kit>

          <ng-container *appHasPermission="{ scope: 'inventory', permissionName: 'canFilterInventoriesByCustody' }">
            <app-user-autocomplete
              *ngIf="displayFilterByRepresentative"
              [type]="'CUSTODY'"
              [user]="representative"
              [label]="'shared.components.representative' | language"
              [showClearIcon]="true"
              [filterView]="true"
              (optionSelectedEmitter)="representative = $event"
              (clearInputEmitter)="representative = null"
            ></app-user-autocomplete>
          </ng-container>

          <app-datepicker-kit
            [type]="'range'"
            [label]="'shared.filter.eventDate' | language"
            [filterView]="true"
            [maxDate]="null"
            [displayClearButton]="true"
            [inputDataCy]="'filterByEventDateInput'"
            [togglerDataCy]="'filterByEventDateToggler'"
            [initialRangeStartValue]="datetimeFrom"
            [initialEndStartValue]="datetimeTo"
            [icon]="'calendar'"
            (rangeEmitter)="datetimeFrom = $event.start; datetimeTo = $event.end"
          ></app-datepicker-kit>
        </div>
      </ng-template>
    </mat-menu>
  </div>
</div>
