import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@shared/classes/api-service';
import { LocationStateModel, PageableModel } from '@shared/models';
import { LocationStatePageableParams } from '@shared/models/build-models';
import { Observable, catchError, of } from 'rxjs';
import { HttpHelperService } from './internal/http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class LocationStateService extends ApiService {
  constructor() {
    super();
  }

  getPageable(params: LocationStatePageableParams): Observable<PageableModel<LocationStateModel>> {
    return this.get<PageableModel<LocationStateModel>>(`locationStates/pageable`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of(new PageableModel()))
    );
  }
}
