import { ProductLineModel } from '@shared/models';
import { UserModel } from './user.model';
import { TState } from '@shared/type/index.type';

export class UserProductCommissionModel {
  createdBy: UserModel;
  createdDatetime: Date | string;
  id: string;
  modifiedBy: UserModel;
  modifiedDatetime: Date | string;
  product: ProductLineModel;
  rate: number | string;
  state: TState;
  totalSum: number;
  user: UserModel;
}
