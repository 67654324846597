import { Injectable, Injector } from '@angular/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { OfflineStatusService } from '@services/offline/offline-status.service';
import { UserPromptsService } from '@services/offline/user-prompts.service';
import { OfflineService } from '@services/offline/offline.service';
import { Subject, takeUntil, timer } from 'rxjs';
import { IOfflineTimer } from '@shared/interfaces/offline';
import { LocalStorage } from '@services/internal/localstorage.service';
import { GetDiffHours } from '@shared/utils/date/get-diff-hours';
import { Device } from '@capacitor/device';

@Injectable({
  providedIn: 'root'
})
export class BackgroundTasksService {
  subscriptions$: Subject<void> = new Subject<void>();
  constructor(
    private offlineStatusService: OfflineStatusService,
    private userPromptsService: UserPromptsService,
    private injector: Injector
  ) {}

  setNetworkStatusWatcher(): void {
    Network.getStatus().then(status => this.offlineStatusService.isNetworkAvailable$.next(status.connected));
    Network.addListener('networkStatusChange', async (status: ConnectionStatus) => {
      this.offlineStatusService.isNetworkAvailable$.next(status.connected);
    });
  }

  async isEnoughMemory(): Promise<boolean> {
    const { realDiskFree } = await Device.getInfo();
    const distFreeInMib = Math.round(realDiskFree) / (1024 * 1024);
    if (distFreeInMib < 512) {
      this.userPromptsService.notEnoughMemoryInfo();
      return false;
    }
    return true;
  }

  async startOfflineTimer(): Promise<void> {
    timer(1000, 600000)
      .pipe(takeUntil(this.subscriptions$))
      .subscribe(async () => {
        if (this.offlineStatusService.isOffline$.value) {
          const timerData: IOfflineTimer = await LocalStorage.getItem('offlineHours').then(t => JSON.parse(t));
          const diffHours = GetDiffHours(new Date(timerData.createdDateTime), new Date());
          const offlineService = this.injector.get(OfflineService);
          if (diffHours >= timerData.hours) {
            const networkConnected: boolean = await Network.getStatus().then(status => status.connected);
            const isChanges = offlineService.isChanges();
            if (networkConnected && isChanges) {
              const confirmSyncChanges: boolean = await this.userPromptsService.syncChangesPrompt(timerData.hours);
              if (confirmSyncChanges && isChanges) {
                await offlineService.disableOfflineMode();
              } else {
                const clearChanges: boolean = await this.userPromptsService.clearChangesPrompt();
                if (clearChanges) {
                  await offlineService.clearOfflineChanges();
                }
              }
            } else {
              await this.userPromptsService.offlineTimeOverPrompt();
              const clearChanges: boolean = await this.userPromptsService.clearChangesPrompt();
              if (clearChanges) {
                await offlineService.clearOfflineChanges();
              }
            }
          }
        }
      });
  }
}
