import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationsKeys } from '@shared/type/i18n.type';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  static service: TranslateService;
  constructor(private translateService: TranslateService) {
    LanguageService.service = translateService;
  }

  static instant(key: TranslationsKeys): string {
    return LanguageService?.service?.instant(key);
  }

  translate(key: TranslationsKeys): string {
    return this.translateService.instant(key);
  }
}
