export const isJson = (str: string): boolean => {
  if (!str?.length) {
    return false;
  }
  str = typeof str !== 'string' ? JSON.stringify(str) : str;
  try {
    str = JSON.parse(str);
  } catch (err) {
    return false;
  }

  return typeof str === 'object' && str !== null;
};
