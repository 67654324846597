import { CreatedDatetime, ModifiedDatetime } from '@shared/models/shared/shared-kernel';
import { TState } from '@shared/type/index.type';
import { UserModel } from '../../users/user.model';
import { LocationStateModel } from './location-state.model';

export class RegionModel {
  createdBy: UserModel;
  createdDatetime: CreatedDatetime;
  id: string;
  locationTotal: number;
  modifiedBy: UserModel;
  modifiedDatetime: ModifiedDatetime;
  regionName: string;
  regionalManager: UserModel;
  state: TState;
}

export class RegionParamsModel {
  regionName: string;
  regionalManagerId?: string;
  locationStates?: LocationStateModel[];
}
