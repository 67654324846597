<div #createEntityModalTitleContainer fxLayout="row" fxLayoutAlign="space-between center" class="titleContainer">
  <app-title [type]="'h4'" [title]="title"></app-title>
  <app-button-kit
    [icon]="'close'"
    [defaultIconButton]="true"
    [title]="''"
    [dataCy]="'closeModalButton'"
    [textColor]="'primary_text'"
    (clickEmitter)="close()"
  ></app-button-kit>
</div>

<div fxLayout="row" class="largeModalWindowContent" [style.height]="contentHeight + 'px'">
  <ng-content></ng-content>
</div>

<div
  #createEntityModalButtonsContainer
  fxLayoutAlign.lt-lg="center"
  [fxLayoutAlign]="!showPrevStepButton && (showCreateButton || showNextStepButton) && size === 'small' ? 'center' : 'end'"
  class="largeModalWindowButtonsContainer"
  fxLayout="row"
  fxLayoutGap="16px"
>
  <app-button-kit
    *ngIf="showPrevStepButton"
    fxFlex.xs="100%"
    [fxFlex]="size === 'small' ? '50%' : '250px'"
    [dataCy]="'prevStepButton'"
    [type]="'secondary'"
    [color]="'primary'"
    [title]="'shared.labels.previous' | language"
    [fontWeight]="600"
    [size]="'large'"
    (clickEmitter)="prevStep()"
  ></app-button-kit>
  <app-button-kit
    *ngIf="showNextStepButton"
    fxFlex.xs="100%"
    [fxFlex]="size === 'small' ? '50%' : '250px'"
    [type]="'primary'"
    [dataCy]="'nextStepButton'"
    [disabled]="disableNextStepButton"
    [color]="'primary'"
    [textColor]="'white'"
    [title]="'shared.labels.next' | language"
    [fontWeight]="600"
    [size]="'large'"
    (clickEmitter)="nextStep()"
  ></app-button-kit>
  <app-button-kit
    *ngIf="showCreateButton"
    fxFlex.xs="100%"
    [fxFlex]="size === 'small' ? '50%' : '250px'"
    [dataCy]="'createButton'"
    [type]="'primary'"
    [disabled]="disableCreateButton"
    [color]="'success'"
    [textColor]="'white'"
    [title]="createButtonTitle | language"
    [fontWeight]="600"
    [size]="'large'"
    (clickEmitter)="create()"
  ></app-button-kit>
</div>
