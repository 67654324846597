export enum EProcedureTypes {
  ORTHOPEDIC = 'shared.enums.procedureType.orthopedic',
  TRAUMA = 'shared.enums.procedureType.trauma',
  SPINE = 'shared.enums.procedureType.spine',
  CARDIAC = 'shared.enums.procedureType.cardiac',
  VASCULAR = 'shared.enums.procedureType.vascular',
  UROLOGIC = 'shared.enums.procedureType.urologic',
  THORACIC = 'shared.enums.procedureType.thoracic',
  PLASTIC = 'shared.enums.procedureType.plastic',
  PEDIATRIC = 'shared.enums.procedureType.pediatric',
  OPHTHALMOLOGICAL = 'shared.enums.procedureType.ophthalmological',
  NEURO = 'shared.enums.procedureType.neuro',
  HEAD = 'shared.enums.procedureType.head',
  HAND = 'shared.enums.procedureType.hand',
  GYNECOLOGICAL = 'shared.enums.procedureType.gynecological',
  ENDOCRINE = 'shared.enums.procedureType.endocrine',
  COLORECTAL = 'shared.enums.procedureType.colorectal',
  OTHER = 'shared.enums.procedureType.other'
}
