import { EInventoryDeviceType } from '@shared/enum';
import { TItemType, TState } from '@shared/type/entity-type.type';
import { TInventoryExpirationStatus, TInventoryStatus } from '@shared/type/statuses.type';
import { SimilarInventoryInfo } from '@shared/models';

export class SimilarInventoryFilter {
  barcode?: string;
  billable?: boolean;
  catalogId?: string;
  catalogIds?: string[];
  custodyId?: string;
  expirationStatus?: TInventoryExpirationStatus;
  facilityId?: string;
  groupOfNumbers?: string;
  ignoreCustodyId?: string;
  importId?: string;
  inOrgCustody?: boolean;
  inventoryDeviceType?: keyof typeof EInventoryDeviceType;
  inventoryStatus?: TInventoryStatus;
  inventoryType?: TItemType;
  isCheckInStarted?: boolean;
  isFacilityStock?: boolean;
  locationId?: string;
  lotNumber?: string;
  manufacturerIds?: string[];
  name?: string;
  parentInventoryId?: string;
  parentInventoryIds?: string[];
  productLineIds?: string[];
  searchText?: string;
  serialNumber?: string;
  showReservedContainers?: boolean;
  state?: TState;
  toExclude?: SimilarInventoryInfo[];
  udi?: string;
  inventoryTypes?: TItemType[];
  isInPack?: boolean;

  constructor() {
    this.searchText = '';
    this.inventoryTypes = ['DEVICE', 'PACK'];
    this.billable = null;
    this.expirationStatus = null;
    this.locationId = null;
    this.isInPack = false;
  }
}
