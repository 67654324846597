import { Injectable } from '@angular/core';
import { ApiService } from '@shared/classes/api-service';
import { catchError, map, Observable, of, take, BehaviorSubject } from 'rxjs';
import { ImageModel, NoteModel, PageableModel } from '@shared/models';
import { HttpHelperService } from '@services/internal/http-helper.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
  IAudit,
  IAuditCatalogInfo,
  IAuditCatalogInfoCreationDTO,
  IAuditContainerCatalogInfo,
  IAuditInventoryCreationDTO,
  IAuditInventoryInfosCreationDTO
} from '@shared/interfaces/audits';
import { AuditsPageableParams } from '@shared/models/build-models/pageable/audits-pageable-params';
import { UniqueId } from '@shared/models/shared/shared-kernel';
import { EAuditStatus } from '@shared/enum/audit-status';
import { AuthenticationService } from '@services/auth.service';
import { environment } from '@environment';
import { BasePageableParams } from '@shared/models/build-models/pageable/base-pageable-params';
import { UsersService } from '@services/users.service';

@Injectable({ providedIn: 'root' })
export class AuditsService extends ApiService {
  //CC-5088 Show indicator audits with certain statuses exist
  static doAuditsMatchingStatusExist: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  getAuditMatchingStatus(): void {
    const user = UsersService.getUser();
    if (user.organization.isAuditEnabled) {
      const params: AuditsPageableParams = {
        page: 0,
        sort: [],
        size: 1
      };
      if (user.role === 'ADMIN') {
        params.statuses = ['PENDING', 'IN_PROGRESS', 'SUBMITTED'];
      } else {
        params.statuses = ['PENDING', 'IN_PROGRESS'];
      }
      this.getPageable(params)
        .pipe(take(1))
        .subscribe(pageable => {
          AuditsService.doAuditsMatchingStatusExist.next(Boolean(pageable.content.length));
        });
    }
  }

  getPageable(params: AuditsPageableParams, checkAuditState: boolean = false): Observable<PageableModel<IAudit>> {
    return this.get<PageableModel<IAudit>>(`audit/pageable`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      map(data => {
        if (checkAuditState) {
          //CC-5088
          const user = UsersService.getUser();
          if (user.organization.isAuditEnabled && Number(params.size) >= data.totalElements) {
            let statuses: (keyof typeof EAuditStatus)[];
            if (user.role === 'ADMIN') {
              statuses = ['PENDING', 'IN_PROGRESS', 'SUBMITTED'];
            } else {
              statuses = ['PENDING', 'IN_PROGRESS'];
            }
            const hasMatchingStatus = data.content.some(a => statuses.includes(a.status));
            AuditsService.doAuditsMatchingStatusExist.next(hasMatchingStatus);
          }
        }
        return data;
      })
    );
  }

  deleteAudit(auditInventoryId: string): Observable<void> {
    return this.delete<void>(`audit/${auditInventoryId}`).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.deleted')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  create(params: IAuditInventoryCreationDTO): Observable<UniqueId> {
    return this.post<UniqueId>(`audit`, params).pipe(
      map(id => {
        this.alertsService.showSuccess('shared.alerts.successMessages.created');
        return id;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  sendReminder(auditInventoryId: UniqueId): Observable<void> {
    return this.post<void>(`audit/${auditInventoryId}/send-reminder`, {}).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.sent')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getAudit(auditInventoryId: UniqueId): Observable<IAudit> {
    return this.get<IAudit>(`audit/${auditInventoryId}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getCatalogsInfo(auditInventoryId: UniqueId, params: BasePageableParams): Observable<PageableModel<IAuditCatalogInfo>> {
    return this.get<PageableModel<IAuditCatalogInfo>>(
      `audit/${auditInventoryId}/audit-catalog-info`,
      HttpHelperService.addResponseTypeJSON(params)
    ).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(new PageableModel());
      })
    );
  }

  getContainersInfo(auditInventoryId: UniqueId, params: BasePageableParams): Observable<PageableModel<IAuditContainerCatalogInfo>> {
    return this.get<PageableModel<IAuditContainerCatalogInfo>>(
      `audit/${auditInventoryId}/audit-container-catalog-info`,
      HttpHelperService.addResponseTypeJSON(params)
    ).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(new PageableModel());
      })
    );
  }

  updateStatus(auditInventoryId: UniqueId, status: keyof typeof EAuditStatus): Observable<void> {
    return this.put<void>(`audit/${auditInventoryId}/status?auditInventoryStatus=${status}`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        this.getAuditMatchingStatus();
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getComments(auditInventoryId: UniqueId): Observable<NoteModel[]> {
    return this.get<NoteModel[]>(`audit/${auditInventoryId}/comments`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  editComment(
    auditInventoryId: UniqueId,
    commentId: string,
    message: string,
    userIds: string[],
    commentUserIds: string[]
  ): Observable<string> {
    return this.put<string>(
      `audit/${auditInventoryId}/comment/${commentId}?newMentionUserIds=${userIds}&existMentionUserIds=${commentUserIds}`,
      message
    ).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  addComment(auditInventoryId: UniqueId, message: string, userIds: string[]): Observable<string> {
    return this.post<string>(`audit/${auditInventoryId}/comments?mentionUserIds=${userIds}`, message).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  async uploadAttachments(auditInventoryId: string, attachments: FormData): Promise<boolean> {
    const token: string = AuthenticationService.getToken();
    const res = await window.fetch(`${environment.apiUrlCore}audit/${auditInventoryId}/attachment`, {
      method: 'PUT',
      body: attachments,
      headers: { Authorization: `Bearer ${token}` }
    });
    if (res.status >= 400 && res.status < 600) {
      this.alertsService.showError('shared.alerts.errorMessages.loadAttachments');
      return Promise.reject();
    } else {
      return true;
    }
  }

  getAttachments(auditInventoryId: string): Observable<ImageModel[]> {
    return this.get<ImageModel[]>(`audit/${auditInventoryId}/attachment`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  uploadAttachmentsByUrl(auditInventoryId: string, url: string): Observable<boolean> {
    return this.put<string>(`audit/${auditInventoryId}/attachment/url`, url).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  deleteAttachments(attachmentId: string): Observable<boolean> {
    return this.delete<void>(`audit/attachment?auditAttachmentId=${attachmentId}`).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.deleted');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(false);
      })
    );
  }

  downloadCSV(auditInventoryId: string): Observable<string> {
    return this.get<string>(`audit/${auditInventoryId}/export/csv`, HttpHelperService.addResponseTypeText({})).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  downloadCSVMissedItems(auditInventoryId: string): Observable<string> {
    return this.get<string>(`audit/${auditInventoryId}/export/missed-items/csv`, HttpHelperService.addResponseTypeText({})).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  createCatalogsInfo(auditInventoryId: UniqueId, params: IAuditCatalogInfoCreationDTO): Observable<IAuditCatalogInfo> {
    return this.post<IAuditCatalogInfo>(`audit/${auditInventoryId}/audit-catalog-info`, params).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  createInventoryInfo(auditInventoryId: UniqueId, params: IAuditInventoryInfosCreationDTO): Observable<void> {
    return this.post<void>(`audit/${auditInventoryId}/audit-inventory-info`, params).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        throw new Error();
      })
    );
  }

  // Backend create either catalog with loose items or container
  createCalculatedCatalogInfo(auditInventoryId: UniqueId, params: IAuditCatalogInfoCreationDTO): Observable<boolean> {
    return this.post<boolean>(`audit/${auditInventoryId}/calculated-audit-catalog-info`, params).pipe(
      map(isContainer => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return isContainer;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  inPossession(auditInventoryId: UniqueId, auditCatalogInfoId: UniqueId, isInPossession: boolean): Observable<void> {
    return this.put<void>(`audit/${auditInventoryId}/audit-catalog-info/${auditCatalogInfoId}?isInPossession=${isInPossession}`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  deleteAuditInventory(auditInventoryId: UniqueId, auditInventoryInfoId: UniqueId): Observable<void> {
    return this.delete<void>(`audit/${auditInventoryId}/audit-inventory-info/${auditInventoryInfoId}`).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.deleted')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  deleteAuditContainer(auditInventoryId: UniqueId, auditContainerCatalogInfoId: UniqueId): Observable<void> {
    return this.delete<void>(`audit/${auditInventoryId}/audit-container-catalog-info/${auditContainerCatalogInfoId}`).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.deleted')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  deleteAuditCatalogInfo(auditInventoryId: UniqueId, auditCatalogInfoId: UniqueId): Observable<void> {
    return this.delete<void>(`audit/${auditInventoryId}/audit-catalog-info/${auditCatalogInfoId}`).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.deleted')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }
}
