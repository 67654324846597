import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IConfirmParams } from '@shared/interfaces';
import { BehaviorSubject } from 'rxjs';
import { GlobalStoreService } from '@services/internal/global-store.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {
  readonly isMobile$: BehaviorSubject<boolean> = GlobalStoreService.isMobile$;

  constructor(private dialogRef: MatDialogRef<ConfirmComponent>, @Inject(MAT_DIALOG_DATA) public data: IConfirmParams) {}

  close(confirm: boolean): void {
    this.dialogRef.close(confirm);
  }
}
