import { TState } from '@shared/type/index.type';
import { CatalogModel } from '../../inventory/catalog/catalog.model';
import { UserModel } from '../../users/user.model';
import { ManufacturerModel } from '../manufacturers/manufacturer.model';

export class PriceSheetModel {
  createdBy: UserModel;
  createdDatetime: string;
  facilityPricingSheetAssignmentQuantity: number;
  facilityPricingSheetAssignments: PriceSheetItemModel[];
  facilityQuantity: number;
  id: string;
  modifiedBy: UserModel;
  modifiedDatetime: string;
  name: string;
  state: TState;
}

export class PriceSheetItemModel {
  alternateCatalog: string;
  catalog: CatalogModel;
  createdDatetime: string;
  editMode?: boolean; // Front-end property
  id: string;
  manufacturer: ManufacturerModel;
  modifiedDatetime: string;
  price: number;

  constructor() {
    this.catalog = new CatalogModel();
    this.manufacturer = new ManufacturerModel();
    this.price = null;
  }
}
