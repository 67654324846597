import { IFilter } from '@shared/interfaces';
import { DefaultQueryParams } from '@shared/helpers/default-query-params';
import { ClearRequest } from '@shared/utils/filter-object-null-properties';
import { TState } from '@shared/type/index.type';

export class UsersPageableParams {
  hasDistributorGroup?: boolean;
  isCustodianOnly?: boolean;
  name?: string;
  page: number;
  regionIds?: string[];
  roles?: string[];
  size: number;
  sort: string[];
  state?: TState;

  constructor(params: IFilter) {
    return UsersPageableParams.build(params);
  }

  private static build(params: IFilter): UsersPageableParams {
    const queryParams: UsersPageableParams = DefaultQueryParams(params);

    queryParams.name = params.search;
    queryParams.roles = params.roles;
    queryParams.state = params.state;
    queryParams.regionIds = params.regionIds;
    queryParams.hasDistributorGroup = params.hasDistributorGroup;

    if (params.isCustodianOnly) {
      queryParams.isCustodianOnly = true;
    }

    return ClearRequest(queryParams);
  }
}
