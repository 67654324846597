import { TFacilityContactsType } from '@shared/type/index.type';

export class ContactModel {
  additionalEmail: string;
  createdDatetime?: string;
  email: string;
  id: string;
  modifiedDatetime?: string;
  name: string;
  phone: string;
  phoneExtension: string;
  role: string;
  type: TFacilityContactsType;
  isSalesOrderDefault: boolean;
  isInvoiceDefault: boolean;
  //front-end only
  isPrimaryEmail?: boolean;

  constructor() {
    this.additionalEmail = '';
    this.email = '';
    this.name = '';
    this.phone = '';
    this.phoneExtension = '';
    this.role = '';
  }
}
