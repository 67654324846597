import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Optional } from '@angular/core';
import { ProcedureService } from '@services/procedure.service';
import { Router } from '@angular/router';
import { AlertsService } from '@services/internal/alerts.service';
import { ProductsService } from '@services/products.service';
import { ProcedureModel, ProductLineModel } from '@shared/models';
import { UntypedFormGroup } from '@angular/forms';
import { CanAutoSaveDirective } from '@guards/autosave/can-auto-save';
import { BehaviorSubject, finalize, Observable, take } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-create-procedure',
  templateUrl: './create-procedure.component.html',
  styleUrls: ['./create-procedure.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateProcedureComponent extends CanAutoSaveDirective {
  form: UntypedFormGroup;
  formsValid: boolean = false;
  procedure: ProcedureModel = new ProcedureModel();
  productLineLoading: boolean = false;
  productsSelected$: BehaviorSubject<ProductLineModel[]> = new BehaviorSubject<ProductLineModel[]>([]);
  removable: boolean = true;
  selectable: boolean = true;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  modifiers: { modifierName: string }[] = [];

  constructor(
    public router: Router,
    public procedureService: ProcedureService,
    public alertsService: AlertsService,
    public productsService: ProductsService,
    public ref: ChangeDetectorRef,
    @Optional() public dialogRef: MatDialogRef<CreateProcedureComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public redirect: boolean
  ) {
    super();
  }

  autoSave(): void {
    this.submit(false);
  }

  autoSaveFormsCheckValid(): UntypedFormGroup[] {
    return [this.form];
  }

  checkValidation(): void {
    setTimeout(() => {
      this.formsValid = this.form.valid;
      this.ref.markForCheck();
    }, 50);
  }

  removeProduct(id: string): void {
    this.productsSelected$.next(this.productsSelected$.value.filter(prod => prod.id !== id));
  }

  selectProduct(product: ProductLineModel): void {
    const currentProducts = this.productsSelected$.value;
    currentProducts.push(product);
    this.productsSelected$.next(currentProducts);
  }

  selectModifier(value: string): void {
    if ((value || '').trim()) {
      this.modifiers.push({ modifierName: value.trim() });
    }
    this.ref.markForCheck();
  }

  removeModifier(item: { modifierName: string }): void {
    const index: number = this.modifiers.indexOf(item);
    if (index >= 0) {
      this.modifiers.splice(index, 1);
    }
  }

  submit(redirectToDetailPage: boolean = true): Observable<string> {
    this.loading$.next(true);

    return new Observable(observer => {
      const result: ProcedureModel = Object.assign({}, this.form.value);
      result.modifiers = this.modifiers.map(modifier => modifier.modifierName) || [];
      const products = this.productsSelected$.value.map(prod => prod.id);

      this.procedureService
        .createProcedure(result)
        .pipe(
          take(1),
          finalize(() => this.loading$.next(false))
        )
        .subscribe(res => {
          if (products.length) {
            this.procedureService
              .assignProducts(res, products)
              .pipe(take(1))
              .subscribe(() => {
                if (redirectToDetailPage) {
                  this.router.navigate([`/directory/procedures/edit/${res}`]);
                }
              });
          } else {
            if (redirectToDetailPage) {
              this.router.navigate([`/directory/procedures/edit/${res}`]);
            }
          }

          this.modifiers = [];
          this.alertsService.showSuccess('shared.alerts.successMessages.created');
          observer.next(res);
        });
    });
  }

  submitWithSubscription(redirect = true): void {
    this.submit(redirect).pipe(take(1)).subscribe();
  }

  submitWithoutRedirect(): void {
    this.submit(false)
      .pipe(take(1))
      .subscribe((res: string) => {
        this.dialogRef.close(res);
      });
  }
}
