import { IFilter } from '@shared/interfaces';
import { DefaultQueryParams } from '@shared/helpers/default-query-params';
import { TState } from '@shared/type/index.type';
import { ClearRequest } from '@shared/utils/filter-object-null-properties';

export class PriceSheetPageableParams {
  name?: string;
  page: number;
  size: number;
  sort: string[];
  state?: TState;

  constructor(params: IFilter) {
    return PriceSheetPageableParams.build(params);
  }

  private static build(params: IFilter): PriceSheetPageableParams {
    const queryParams: PriceSheetPageableParams = DefaultQueryParams(params);

    queryParams.state = params?.state ?? 'ACTIVE';
    queryParams.name = params.search;

    return ClearRequest(queryParams);
  }
}
