import { EventModel } from '@shared/models/features/events/event.model';
import { FacilityModel, ProcedureModel, ProductLineModel, UserModel } from '@shared/models';
import { NoteModel } from '@shared/models/shared/note.model';
import { TState } from '@shared/type/index.type';

export class PhysicianModel {
  addressState: string;
  city: string;
  createdBy: UserModel;
  createdDatetime: Date;
  email: string;
  events?: (null | EventModel)[];
  externalId: string;
  facilities?: FacilityModel[];
  firstName: string;
  fullName: string;
  id: string;
  lastName: string;
  middleName: string;
  modifiedBy: UserModel;
  modifiedDatetime: Date;
  notes: NoteModel[];
  npi: string;
  organizationId: string;
  primaryLocation: string;
  primaryPhone: string;
  procedures?: ProcedureModel[];
  products?: ProductLineModel[];
  secondaryPhone: string;
  state: TState;
  zipCode: string;
  removable?: boolean;
  editMode?: boolean;
  commissionRate?: number;
  tags: string[];
}
