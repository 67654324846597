import { animate, style, transition, trigger } from '@angular/animations';

export const inOutAnimation = [
  trigger('inOutAnimation', [
    transition(':enter', [style({ height: 0, opacity: 0 }), animate('0.3s ease-in-out')]),
    transition(':leave', [animate('0.3s ease-in-out', style({ height: 0, opacity: 0 }))])
  ])
];

export const inOutHorizontalAnimation = [
  trigger('inOutHorizontalAnimation', [
    transition(':enter', [style({ width: 0, opacity: 0 }), animate('0.3s ease-in-out')]),
    transition(':leave', [animate('0.3s ease-in-out', style({ width: 0, opacity: 0 }))])
  ])
];
