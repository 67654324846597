import { UserModel } from '@shared/models';

export class EventActionModel {
  cancelledBy: UserModel;
  cancelledOn: string;
  eventId: string;
  id: string;
  reopenedBy: UserModel;
  reopenedOn: string;
}
