import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { PageableModel, ProductLineModel } from '@shared/models';
import { HttpHelperService } from './internal/http-helper.service';
import { AuthenticationService } from './auth.service';
import { ImageModel } from '@shared/models/shared/image.model';
import { ProductLineParams } from '@shared/models/build-models';
import { Observable, of, catchError, map, tap } from 'rxjs';
import { ApiService } from '@shared/classes/api-service';
import { TState } from '@shared/type/index.type';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class ProductsService extends ApiService {
  constructor() {
    super();
  }

  deactivate(productId: string, state: TState): Observable<void> {
    return this.put<void>(`products/${productId}/state?state=${state}`, {});
  }

  deleteAttachment(id: string, imageId: string): Observable<boolean> {
    return this.delete<void>(`products/${id}/attachment?productAttachmentId=${imageId}`).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.imageWasDeleted');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  deleteImage(id: string, imageId: string): Observable<void> {
    return this.delete(`products/${id}/image?productImageId=${imageId}`).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.fileWasDeleted')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getProduct(id: string, redirectToNotFoundPage: boolean = false): Observable<ProductLineModel> {
    return this.get<ProductLineModel>(`products/${id}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        HttpHelperService.errorHandler(error, null, redirectToNotFoundPage);
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getPageable(params: ProductLineParams): Observable<PageableModel<ProductLineModel>> {
    return this.get<PageableModel<ProductLineModel>>(`products/pageable`, HttpHelperService.addResponseTypeJSON(params));
  }

  getProductAttachments(productId: string): Observable<ImageModel[]> {
    return this.get<ImageModel[]>(`products/${productId}/attachments`).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.loadAttachments');
        return of([]);
      })
    );
  }

  getProductImages(productId: string): Observable<ImageModel[]> {
    return this.get<ImageModel[]>(`products/${productId}/images`).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.loadImages');
        return of(null);
      })
    );
  }

  createProduct(body: ProductLineModel): Observable<string> {
    return this.post<string>(`products`, body).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.createProductLine', error.message);
        return of(null);
      })
    );
  }

  unAssignProductLine(catalogId: string, productLineIds: string): Observable<string> {
    return this.post<string>(`catalogs/${catalogId}/products/unassign`, productLineIds).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  updateProduct(id: string, body: ProductLineModel): Observable<void> {
    return this.put<void>(`products/${id}`, body).pipe(
      tap(() => this.alertsService.showSuccess('shared.alerts.successMessages.saved')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  putAttachmentUrl(inventoryId: string, imageUrl: string): Observable<ImageModel> {
    return this.put<ImageModel>(`products/${inventoryId}/attachment/url`, imageUrl);
  }

  putImageUrl(inventoryId: string, imageUrl: string): Observable<ImageModel> {
    return this.put<ImageModel>(`products/${inventoryId}/image/url`, imageUrl);
  }

  async uploadAttachment(inventoryId: string, formData: FormData): Promise<boolean> {
    const token: string = AuthenticationService.getToken();

    const res = await window.fetch(`${environment.apiUrlCore}products/${inventoryId}/attachment`, {
      method: 'PUT',
      body: formData,
      headers: { Authorization: `Bearer ${token}` }
    });
    if (res.status >= 400 && res.status < 600) {
      this.alertsService.showError('shared.alerts.errorMessages.loadAttachments');
      return false;
    } else {
      Promise.resolve(res).then();
      return true;
    }
  }

  async uploadImage(inventoryId: string, formData: FormData): Promise<boolean> {
    const token: string = AuthenticationService.getToken();

    const res = await window.fetch(`${environment.apiUrlCore}products/${inventoryId}/image`, {
      method: 'PUT',
      body: formData,
      headers: { Authorization: `Bearer ${token}` }
    });
    if (res.status >= 400 && res.status < 600) {
      this.alertsService.showError('shared.alerts.errorMessages.loadImages');
      return false;
    } else {
      Promise.resolve(res).then();
      return true;
    }
  }
}
