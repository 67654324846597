export const isValidFile = (file: File): boolean => {
  let imageName: string;
  try {
    imageName = file.name.toLocaleLowerCase();
  } catch (e) {
    return false;
  }
  return (
    imageName.endsWith('.bmp') ||
    imageName.endsWith('.gif') ||
    imageName.endsWith('.jpg') ||
    imageName.endsWith('.jpeg') ||
    imageName.endsWith('.png') ||
    imageName.endsWith('.webp')
  );
};
