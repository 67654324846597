import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OfflineStatusService } from '@services/offline/offline-status.service';
import { EventModel } from '@shared/models';
import { OfflineService } from '@services/offline/offline.service';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { IBottomSheetItem } from '@shared/interfaces/bottom-sheet';

@Component({
  selector: 'app-mobile-offline-progress-modal',
  templateUrl: './mobile-offline-progress-modal.component.html',
  styleUrls: ['./mobile-offline-progress-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileOfflineProgressModalComponent {
  readonly offlineEvents$: BehaviorSubject<EventModel[]> = this.offlineService.selectedOfflineEvents$;
  readonly loadedPercentage$: BehaviorSubject<number> = this.offlineStatusService.loadedPercentage$;

  constructor(
    private offlineService: OfflineService,
    private bottomSheetRef: MatBottomSheetRef<MobileOfflineProgressModalComponent>,
    private offlineStatusService: OfflineStatusService,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: { type: 'START_PRELOADING' | 'SYNCING' }
  ) {}

  confirm() {
    this.offlineService.offlineEventsWasSet();
  }

  cancel(result?: IBottomSheetItem): void {
    this.bottomSheetRef.dismiss(result);
    this.offlineService.cancelOfflineMode();
  }
}
