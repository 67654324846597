import { PermissionModel } from '@shared/models';
import { MergeModels } from '../merge-models';
import { ECompanyTypes } from '@shared/enum';

export class ErrorRole {
  model: PermissionModel = {
    config: {
      companyType: ECompanyTypes.ERROR,
      roleName: 'ERROR',
      validRole: false,
      permissionsWasSet: true
    }
  };

  constructor() {
    this.model = MergeModels(this.model);
  }
}
