import { IFilter } from '@shared/interfaces';
import { DefaultQueryParams } from '@shared/helpers/default-query-params';
import { TFacilityGeographicalType, TState } from '@shared/type/index.type';
import { ClearRequest } from '@shared/utils/filter-object-null-properties';

export class FacilitiesPageableParams {
  facilityPricingSheetId?: string;
  geographicalType?: TFacilityGeographicalType;
  name?: string;
  page: number;
  size: number;
  sort: string[];
  state?: TState;
  userId?: string;
  hasFacilityPrice?: boolean;
  hasParLevel?: boolean;
  representativeId?: string;

  constructor(params: IFilter) {
    return FacilitiesPageableParams.build(params);
  }

  private static build(params: IFilter): FacilitiesPageableParams {
    const queryParams: FacilitiesPageableParams = DefaultQueryParams(params);

    queryParams.state = params?.state ?? 'ACTIVE';
    queryParams.name = params.search;
    queryParams.representativeId = params.representativeId;
    queryParams.userId = params.custodyId;
    queryParams.hasFacilityPrice = params.hasFacilityPrice;
    queryParams.facilityPricingSheetId = params.facilityPricingSheetId;
    queryParams.geographicalType = params.geographicalType;

    if (params.hasOwnProperty('hasParLevel')) {
      queryParams.hasParLevel = params.hasParLevel;
    }

    return ClearRequest(queryParams);
  }
}
