import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EventModel, UserModel } from '@shared/models';
import { TranslationsKeys } from '@shared/type/i18n.type';
import { IFilter } from '@shared/interfaces';

@Component({
  selector: 'app-events-autocomplete-with-filters',
  templateUrl: './events-autocomplete-with-filters.component.html',
  styleUrls: ['./events-autocomplete-with-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventsAutocompleteWithFiltersComponent {
  @Input() representative: UserModel = null;
  @Input() initialValue: EventModel = new EventModel();
  @Input() disabled: boolean = false;
  @Input() showClearIcon: boolean = false;
  @Input() showOpenInNewIcon: boolean = false;
  @Input() label: TranslationsKeys = 'shared.labels.event';
  @Input() filterParams: IFilter = {};
  @Input() displayFilterByRepresentative: boolean = false;

  @Output() setEventEmitter = new EventEmitter<EventModel>();

  eventNumber: number = null;
  datetimeFrom: Date = null;
  datetimeTo: Date = null;

  getFilterParams(): IFilter {
    const params: IFilter = {
      eventNumber: String(this.eventNumber || ''),
      datetimeFrom: this.datetimeFrom?.toISOString(),
      datetimeTo: this.datetimeTo?.toISOString()
    };

    if (this.representative) {
      params.custodyId = this.representative?.id || 'ORGANIZATION';
    }

    return { ...this.filterParams, ...params };
  }
}
