import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ISubNav, IUserOrganizationDto } from '@shared/interfaces';
import { BehaviorSubject, take } from 'rxjs';
import { ModalNotificationsComponent } from 'src/app/features/notifications/partials/modals/modal-notifications/modal-notifications.component';
import { inOutAnimation } from '@shared/utils/animations/in-out-animation';
import { NavbarRoutes } from '@shared/utils/navbar-routes';
import { CreateSmallEntityConfig } from '@constants';
import { OrganizationSwitcherComponent } from '@shared/components/form-elements/organization-switcher/organization-switcher.component';
import { UsersService } from '@services/users.service';
import { TColorThemes } from '@shared/type/index.type';
import { GetLabelURL, GetShortLogoURL } from '@shared/utils/theme-based-urls';
import { PermissionService } from '@services/internal/permission.service';
import { TrackByLink } from '@shared/utils/form-elements/track-by';

@Component({
  selector: 'app-desktop-header',
  templateUrl: './desktop-header.component.html',
  styleUrls: ['./desktop-header.component.scss'],
  animations: inOutAnimation,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesktopHeaderComponent {
  @Input() toggleSidebar: boolean = false;
  @Input() set hideSubNav(data: boolean) {
    if (data) {
      this.hideSubNavOptions();
    }
  }
  @Input() totalElements: number = 0;
  @Input() userName: string = '';
  @Input() organizationName: string = '';
  @Input() nameInitials: string = '';
  @Input() showAuditState: boolean = false;

  @Output() logoutEmitter = new EventEmitter<void>();
  @Output() getNotificationsEmitter = new EventEmitter<void>();
  @Output() toggleSidebarEmitter = new EventEmitter<boolean>();
  @Output() openAIFeaturesListModalEmitter = new EventEmitter<void>();

  showDirectoryRoutes: boolean = false;
  showInventoryRoutes: boolean = false;
  showMovementRoutes: boolean = false;
  readonly organizations$: BehaviorSubject<IUserOrganizationDto[]> = UsersService.organizations$;
  readonly colorTheme$: BehaviorSubject<TColorThemes> = UsersService.colorTheme$;
  readonly directoryRoutes: ISubNav[] = NavbarRoutes.directory;
  readonly inventoryRoutes: ISubNav[] = NavbarRoutes.inventory;
  readonly movementRoutes: ISubNav[] = NavbarRoutes.movements;
  readonly getShortLogoURL: string = GetShortLogoURL();
  readonly getLabelURL: string = GetLabelURL();
  readonly trackBy = TrackByLink;
  readonly isDemoModeOnly: boolean = UsersService.getUser().organization.isDemoModeOnly;
  readonly isCustodianOnly: boolean = UsersService.getUser().isCustodianOnly;

  constructor(
    public permissionService: PermissionService,
    private router: Router,
    private dialog: MatDialog
  ) {
    if (this.isActive(['/directory'])) {
      this.showDirectoryRoutes = true;
    } else if (this.isActive(['/inventory/'])) {
      this.showInventoryRoutes = true;
    } else if (this.isActive(['/movement'])) {
      this.showMovementRoutes = true;
    }
  }

  isActive = (urls: string[]): boolean => urls.some((url: string) => this.router.url.includes(url));

  hideSubNavOptions(): void {
    this.showDirectoryRoutes = false;
    this.showInventoryRoutes = false;
    this.showMovementRoutes = false;
  }

  showModalNotifications(): void {
    const dialogConfig: MatDialogConfig = { ...CreateSmallEntityConfig };
    const dialogRef = this.dialog.open(ModalNotificationsComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.getNotificationsEmitter.emit();
      });
  }

  openOrgSwitcherModal(): void {
    const dialogConfig: MatDialogConfig = { ...CreateSmallEntityConfig };
    dialogConfig.height = '275px';
    dialogConfig.data = this.organizations$.value;
    this.dialog.open(OrganizationSwitcherComponent, dialogConfig);
  }
}
