<app-create-entity-modal
  [title]="'physicians.createPhysicianTitle' | language"
  [showCreateButton]="stepperRef?.selectedIndex === 2"
  [showPrevStepButton]="stepperRef?.selectedIndex > 0 && stepperRef?.selectedIndex <= 2"
  [showNextStepButton]="stepperRef?.selectedIndex < 2"
  [disableNextStepButton]="formName?.invalid || (stepperRef?.selectedIndex === 1 && formContacts?.invalid)"
  [disableCreateButton]="!formsValid || (loading$ | async) === true"
  [confirmationRequired]="!redirect"
  [showCloseConfirmation]="formName?.touched"
  (prevStepEmitter)="stepperRef.previous()"
  (nextStepEmitter)="stepperRef.next()"
  (createEmitter)="!redirect ? submitWithoutRedirect() : submitWithSubscription()"
>
  <mat-stepper #stepper [linear]="true" [disableRipple]="true">
    <ng-template matStepperIcon="edit">
      <mat-icon>check</mat-icon>
    </ng-template>
    <mat-step [label]="'physicians.nameLabel' | language" [stepControl]="formName">
      <app-form-name
        [createEntityFlow]="true"
        [tags]="tags"
        (formNameValueChangeEmitter)="formName = $event; checkValidation()"
        (addTagsEmitter)="addTags($event)"
        (removeTagsEmitter)="removeTags($event)"
      ></app-form-name>
    </mat-step>
    <mat-step [label]="'physicians.contactInformationLabel' | language" [stepControl]="formContacts">
      <app-form-contacts
        [createEntityFlow]="true"
        (formNameValueChangeEmitter)="formContacts = $event; checkValidation()"
      ></app-form-contacts>
    </mat-step>
    <mat-step [label]="'physicians.assignmentsLabel' | language" [stepControl]="formAssignments">
      <app-form-assignments
        [createEntityFlow]="true"
        [searching]="searching"
        [selectedFacilities]="selectedFacilities"
        [selectedProcedures]="selectedProcedures"
        [selectedProducts]="selectedProducts"
        [removable]="true"
        (formNameValueChangeEmitter)="formAssignments = $event; checkValidation()"
        (selectItemEmitter)="selectItemEmitter($event)"
        (removeItemEmitter)="removeItemEmitter($event)"
      ></app-form-assignments>
    </mat-step>
  </mat-stepper>
</app-create-entity-modal>
