import { Injectable } from '@angular/core';
import { LanguageService } from '@services/internal/language.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalTooltipComponent } from '@shared/components/form-elements/ui-kit/tooltip-kit/modal-tooltip/modal-tooltip.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Dialog } from '@capacitor/dialog';

@Injectable({
  providedIn: 'root'
})
export class UserPromptsService {
  constructor(private dialog: MatDialog) {}

  async syncChangesPrompt(_hours: number): Promise<boolean> {
    this.dialog.closeAll();
    this.dialog.closeAll();
    const { value } = await Dialog.confirm({
      title: LanguageService.instant('offline.syncQuestionPromptTitle'),
      message: LanguageService.instant('offline.offlineHoursPromptDescription')
    });
    return value;
  }

  async clearChangesPrompt(): Promise<boolean> {
    this.dialog.closeAll();
    const { value } = await Dialog.confirm({
      title: LanguageService.instant('offline.clearChangesPromptTitle'),
      message: LanguageService.instant('offline.clearChangesPromptDescription')
    });
    return value;
  }

  async syncCellularNetworkPrompt(): Promise<boolean> {
    this.dialog.closeAll();
    const { value } = await Dialog.confirm({
      title: LanguageService.instant('offline.syncChangesCellularPromptTitle'),
      message: ''
    });
    return value;
  }

  async syncChangesQuestionPrompt(): Promise<boolean> {
    this.dialog.closeAll();
    const { value } = await Dialog.confirm({
      title: LanguageService.instant('offline.syncQuestionPromptTitle'),
      message: LanguageService.instant('offline.syncQuestionPromptDescription')
    });
    return value;
  }

  async offlineTimeOverPrompt(): Promise<void> {
    this.dialog.closeAll();
    await Dialog.alert({
      title: LanguageService.instant('offline.offlineTimeOverInfoModal'),
      message: ''
    });
  }

  selectEventsInfo(): void {
    this.dialog.open(ModalTooltipComponent, {
      width: '270px',
      height: 'auto',
      hasBackdrop: true,
      panelClass: 'tooltipModalWindow',
      scrollStrategy: new NoopScrollStrategy(),
      data: LanguageService.instant('offline.selectEventsForOfflineInfoModal')
    });
  }

  async notEnoughMemoryInfo(): Promise<void> {
    await Dialog.alert({
      title: LanguageService.instant('offline.notEnoughMemoryInfoModalTitle'),
      message: LanguageService.instant('offline.notEnoughMemoryInfoModalDescription')
    });
  }

  async offlineModeUsingCellularPrompt(): Promise<boolean> {
    this.dialog.closeAll();
    const { value } = await Dialog.confirm({
      title: LanguageService.instant('offline.offlineModeCellular'),
      message: LanguageService.instant('offline.offlineModeCellularDescription')
    });
    return value;
  }

  async disableOfflineModeWifiPrompt(): Promise<boolean> {
    this.dialog.closeAll();
    const { value } = await Dialog.confirm({
      title: LanguageService.instant('offline.offlineModeEnabledModal'),
      message: LanguageService.instant('offline.offlineModeWiFiConnectionDescription')
    });
    return value;
  }
}
