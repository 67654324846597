<div class="full-width" fxLayout="row" fxLayoutAlign="start center">
  <app-input-with-chips-kit
    *ngIf="multiple; else single"
    fxFlex="100%"
    [dataCy]="'regionInput'"
    [chipDataCy]="'regionChip'"
    [chipRemoveData_cy]="'regionRemoveChipButton'"
    [selectedChips]="selectedItems"
    [label]="label"
    [showAddChipButton]="true"
    [disabled]="disabled"
    [invalidInput]="invalid"
    [parseChipByProperty]="'regionName'"
    [selectable]="true"
    [forceBlurEvent]="forceBlurEvent"
    [showLinkToDetail]="showLinkButton"
    [detailPageLink]="'/directory/regions/edit/'"
    [debounceEvents]="['keyup', 'focus']"
    [debounceTime]="1000"
    [required]="required ? !selectedItems.length : false"
    [requiredView]="required ? !!selectedItems.length : false"
    [removable]="removable && !disabled"
    [autocompleteName]="autocomplete"
    (chipsRemovedEmitter)="removeChip($event.id)"
    (debounceTimeEndedEmitter)="searchRegions($event)"
  ></app-input-with-chips-kit>

  <ng-template #single>
    <app-input-kit
      fxFlex="100%"
      [required]="required"
      [label]="label"
      [dataCy]="'regionInput'"
      [initialValue]="region"
      [icons]="[showClearButton && !disabled ? 'close' : null, showLinkButton && region?.id ? 'link' : null]"
      [linkDetailPage]="'/directory/regions/edit/' + region?.id"
      [disabled]="disabled"
      [forceBlurEvent]="forceBlurEvent"
      [debounceEvents]="['keyup', 'focus']"
      [debounceTime]="1000"
      [autocompleteName]="autocomplete"
      (debounceTimeEndedEmitter)="searchRegions($event)"
      (iconClickEmitter)="doIconAction($event)"
    ></app-input-kit>
  </ng-template>

  <mat-autocomplete #autocomplete="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngIf="(loading$ | async) === true || (initialLoading$ | async) === true" class="is-loading" disabled>
      <mat-progress-spinner value="30" class="spinner" mode="indeterminate" diameter="30"></mat-progress-spinner>
    </mat-option>
    <ng-container *ngIf="(loading$ | async) === false && (initialLoading$ | async) === false">
      <mat-option data-cy="regionOption" *ngIf="!searchedData.length; else options" disabled class="connectSxAutocompleteOption">
        <span>{{ 'shared.labels.noItemsFound' | language }}</span>
      </mat-option>
      <ng-template #options>
        <cdk-virtual-scroll-viewport
          itemSize="0"
          class="virtualAutocompleteScroll"
          [style.height.px]="searchedData.length < 3 ? searchedData.length * 50 : 150"
        >
          <mat-option
            data-cy="regionOption"
            *cdkVirtualFor="let region of searchedData"
            class="connectSxAutocompleteOption"
            [value]="region"
            (click)="selectOption(region)"
          >
            <span>{{ region.regionName }}</span>
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </ng-template>
    </ng-container>
  </mat-autocomplete>
</div>
