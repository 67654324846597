<form
  class="form-group-emphasized"
  fxLayout="column"
  [ngClass]="{ 'last-child nullMargin': createEntityFlow }"
  [fxLayoutGap]="createEntityFlow ? '24px' : '16px'"
  [formGroup]="formContacts"
>
  <app-title [title]="'physicians.contactInformationLabel' | language" [type]="'h5'"></app-title>

  <div class="form-group-emphasized-3-col">
    <app-input-kit
      formControlName="primaryLocation"
      [dataCy]="'physicianPrimaryLocationInput'"
      [initialValue]="formContacts.get('primaryLocation').value"
      [label]="'physicians.streetAddressInputLabel' | language"
      [disabled]="formContacts.get('primaryLocation').disabled"
    ></app-input-kit>
    <app-input-kit
      formControlName="city"
      [required]="true"
      [dataCy]="'physicianCityInput'"
      [initialValue]="formContacts.get('city').value"
      [label]="'physicians.cityInputLabel' | language"
      [disabled]="formContacts.get('city').disabled"
    ></app-input-kit>
    <app-input-kit
      formControlName="addressState"
      [dataCy]="'physicianStateInput'"
      [initialValue]="formContacts.get('addressState').value"
      [label]="'physicians.stateInputLabel' | language"
      [required]="true"
      [disabled]="formContacts.get('addressState').disabled"
    ></app-input-kit>
    <app-input-kit
      formControlName="zipCode"
      [dataCy]="'physicianZipCodeInput'"
      [initialValue]="formContacts.get('zipCode').value"
      [label]="'physicians.zipCodeInputLabel' | language"
      [required]="true"
      [invalidInput]="formContacts.get('zipCode').invalid"
      [disabled]="formContacts.get('zipCode').disabled"
    ></app-input-kit>
    <app-input-with-select-kit
      formControlName="primaryPhone"
      [disabled]="formContacts.get('primaryPhone').disabled"
      [initialValue]="formContacts.get('primaryPhone').value"
      [label]="'physicians.primaryPhoneInputLabel' | language"
      [error]="!formContacts.get('primaryPhone').valid && formContacts.enabled ? ('shared.errors.phone' | language) : ''"
      [type]="primaryPhoneFormat"
      (selectEmitter)="formContacts.get('primaryPhone').markAsTouched(); primaryPhoneFormat = $event; checkPrimaryPhoneFormat()"
    ></app-input-with-select-kit>

    <app-input-with-select-kit
      formControlName="secondaryPhone"
      [disabled]="formContacts.get('secondaryPhone').disabled"
      [initialValue]="formContacts.get('secondaryPhone').value"
      [label]="'physicians.secondaryPhoneInputLabel' | language"
      [error]="!formContacts.get('secondaryPhone').valid && formContacts.enabled ? ('shared.errors.phone' | language) : ''"
      [type]="secondaryPhoneFormat"
      (selectEmitter)="formContacts.get('secondaryPhone').markAsTouched(); secondaryPhoneFormat = $event; checkSecondaryPhoneFormat()"
    ></app-input-with-select-kit>
    <app-input-kit
      formControlName="email"
      [dataCy]="'physicianEmailInput'"
      [initialValue]="formContacts.get('email').value"
      [label]="'physicians.emailInputLabel' | language"
      [error]="formContacts.get('email').invalid ? ('shared.errors.email' | language) : ''"
      [disabled]="formContacts.get('email').disabled"
    ></app-input-kit>
    <app-input-kit
      formControlName="npi"
      [dataCy]="'physicianNPIInput'"
      [initialValue]="formContacts.get('npi').value"
      [label]="'physicians.npiInputLabel' | language"
      [disabled]="formContacts.get('npi').disabled"
    ></app-input-kit>
    <app-input-kit
      *ngIf="currentUser.role === 'ADMIN' && currentUser.organization.isIntegrationMessagingEnabled"
      formControlName="externalId"
      [dataCy]="'physicianExternalId'"
      [initialValue]="formContacts.get('externalId').value"
      [label]="'physicians.integrationId' | language"
      [icons]="['help']"
      [tooltipText]="'shared.tooltips.externalIntegrationId'"
      [disabled]="formContacts.get('externalId').disabled"
    ></app-input-kit>
  </div>
</form>
