import { CatalogModel } from '@shared/models/features/inventory/catalog/catalog.model';
import { ProductLineModel } from '@shared/models/features/inventory/product-line/productline.model';
import { NoteModel } from '@shared/models/shared/note.model';
import { EventModel } from '@shared/models/features/events/event.model';
import { ManufacturerModel } from '@shared/models/features/directory/manufacturers/manufacturer.model';
import { TRequestKind, TState, TTransferStatus } from '@shared/type/index.type';
import { ECarrier, EInventoryRequestStatus, ENeedByStatus } from '@shared/enum';
import { PageableModel } from '@shared/models/shared/pageable-model';
import { UserModel } from '@shared/models/features/users/user.model';
import { InventoryRequestBuild } from '@shared/models/build-models';
import { InventoryModel, PreferenceCardModel, ShipToModel } from '../../../index';
import { CreatedDatetime, ModifiedDatetime, UniqueId } from '@shared/models/shared/shared-kernel';
import { InventoryPackRequestBuild } from '@shared/models/build-models/inventory/inventory-pack-request-build';

export interface IInventoryRequestClaim {
  createdDatetime: CreatedDatetime;
  modifiedDatetime: ModifiedDatetime;
  id: UniqueId;
  claimedBy: UserModel;
}

export class InventoryRequestAssignment {
  catalog: CatalogModel;
  id: string;
  inventory?: InventoryRequestBuild; // Front-end property
  createPackRequest?: InventoryPackRequestBuild; // Front-end property
  isImplantsOnly?: boolean;
  isInstrumentsOnly?: boolean;
  modifier: string;
  product: ProductLineModel;
  products?: ProductLineModel[]; // Front-end property
  quantity: number;
  received: number;
  receivedUI?: number;
  notShippedReason: string;
  isPartialShipping: boolean;
  quantityShipped: number;
  modifiedDatetime: string;
  createdDatetime: string;
}

export class TransferInInventoryRequests {
  assignedDevices?: PageableModel<InventoryModel>;
  status: TTransferStatus;
  transferId: string;
  transferNumber: number;
}

export class InventoryRequestModel {
  associatedRep: UserModel;
  carrier: keyof typeof ECarrier | '';
  completeSetsQuantity: number;
  createdBy: UserModel;
  createdDatetime: Date;
  email: string;
  event?: EventModel;
  expanded?: boolean; // Front-end property
  id: string;
  isSaturdayDelivery: boolean;
  manufacturer: ManufacturerModel;
  modifiedBy: UserModel;
  modifiedDatetime: Date;
  name: string;
  needByStatus?: keyof typeof ENeedByStatus;
  note: string;
  notes?: NoteModel[];
  notesCount: number;
  number: number;
  preferenceCard: PreferenceCardModel;
  requestType: TRequestKind;
  requester: UserModel = new UserModel();
  shipByDate: string;
  shippingAddress: ShipToModel;
  specificProductsQuantity: number;
  state: TState;
  status: keyof typeof EInventoryRequestStatus;
  trackingEnabled: true;
  trackingNumber: string;
  transferInInventoryRequests?: TransferInInventoryRequests[];
  inventoryRequestClaim: IInventoryRequestClaim;
  isClaimed: boolean;
}

export class RequestTrackingModel {
  carrier: keyof typeof ECarrier | '';
  trackingEnabled: boolean;
  trackingNumber: string = '';
}
