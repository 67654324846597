import { IFilter } from '@shared/interfaces';
import { DefaultQueryParams } from '@shared/helpers/default-query-params';
import { ClearRequest } from '@shared/utils/filter-object-null-properties';
import { TState } from '@shared/type/index.type';

export class RegionsPageableParams {
  hasRegionalManager?: boolean;
  name?: string;
  page: number;
  regionalManagerId?: string;
  size: number;
  sort: string[];
  state?: TState;

  constructor(params: IFilter) {
    return RegionsPageableParams.build(params);
  }

  private static build(params: IFilter): RegionsPageableParams {
    const queryParams: RegionsPageableParams = DefaultQueryParams(params);
    queryParams.state = params?.state ?? 'ACTIVE';
    queryParams.name = params.search;
    queryParams.regionalManagerId = params.regionalManagerId;

    if (params.hasRegionalManager !== null) {
      queryParams.hasRegionalManager = params.hasRegionalManager;
    }

    return ClearRequest(queryParams);
  }
}
