import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TIcons } from '@shared/type/icons.type';

@Component({
  selector: 'app-button-with-menu',
  templateUrl: './button-with-menu.component.html',
  styleUrls: ['./button-with-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonWithMenuComponent {
  @Input() icon: TIcons = null;
  @Input() title: string = '';
  @Input() disabled: boolean = false;
  @Input() type: 'primary' | 'secondary' | 'outline' = null;
  @Input() color: 'primary' | 'success' | 'warn' = 'primary';
  @Input() size: 'large' | 'medium' | 'small' = 'small';
  @Input() textColor: 'primary' | 'success' | 'warn' | 'white' | 'default' | 'primary_text' | 'orange' | 'blue' = null;
  @Input() fontWeight: 400 | 600 = 400;
  @Input() dataCy: string;
  @Input() iconOutline: 'filled' | 'outline' = 'outline';
  @Input() buttonType: 'button' | 'submit' = 'button';
  @Input() iconSize: 16 | 20 | 24 = 20;

  @Output() clickEmitter = new EventEmitter<Event>();

  click(event: Event): void {
    const e = event || window.event;
    e.preventDefault();
    this.clickEmitter.emit(event);
  }
}
