import { Injectable } from '@angular/core';
import { PermissionModel, UserModel } from '@shared/models';
import { UsersService } from '../users.service';
import { PermissionNamesType } from '@shared/type/permission.type';
import { TRoles } from '@shared/type/index.type';
import { PermissionsFactory } from '@guards/permissions/permissions.factory';
import { CustomPermissions, PermissionConfigModel, UserLevelPermissions } from '@shared/models/permissions/permission.model';
import { AlertsService } from '@services/internal/alerts.service';
import { AuthenticationService } from '@services/auth.service';
import { OfflinePermissions } from '@guards/permissions/offline.permissions';
import { OfflineStatusService } from '@services/offline/offline-status.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  permissionUpdated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private permissions: PermissionModel = new PermissionModel();
  private checkPermissionInterval: number;

  constructor(
    private alertsService: AlertsService,
    private auth: AuthenticationService,
    private offlineStatusService: OfflineStatusService
  ) {}

  setPermissions(user: UserModel, customPermissions: CustomPermissions = new CustomPermissions()): void {
    const permissions = new PermissionsFactory().getPermissions(user.role, user.organization.company.type);
    permissions.custom = customPermissions;

    if (this.offlineStatusService.isOffline$.value) {
      const config: PermissionConfigModel = permissions.config;
      const offlinePermissions: PermissionModel = new OfflinePermissions().model;
      offlinePermissions.config = config;
      offlinePermissions.custom.addAndDeactivateInventoriesEnabled = false;
      offlinePermissions.custom.reopenEventsEnabled = false;
      offlinePermissions.custom.requireAssociateRequestsWithEvents = false;
      this.permissions = offlinePermissions;
    } else {
      this.permissions = permissions;
    }

    this.setUserLevelPermissions(user);
    this.setOrganizationLevelPermissions(user);
    this.setReadonlyAccessPermissions(user);

    if (!permissions?.config?.validRole) {
      this.auth.logout();
      this.alertsService.showError('shared.alerts.errorMessages.unknownUserRole');
    }

    this.permissionUpdated$.next(!this.permissionUpdated$.value);
  }

  getPermissions(): PermissionModel {
    return <PermissionModel>this.permissions;
  }

  isAllowedRoute(routeUrl: string): boolean {
    return this.permissions.forbiddenRoutes.findIndex((route: string) => routeUrl.includes(route)) === -1;
  }

  isCurrentUser = (creatorId: string): boolean => UsersService.getUser().id === creatorId;

  isGranted(permissionScope: keyof PermissionModel, permissionName: PermissionNamesType): boolean {
    // @ts-ignore
    return this.permissions[permissionScope] ? !!this.permissions[permissionScope][permissionName] : false;
  }

  // Check role with current user role
  isRole = (role: TRoles): boolean => this.permissions.config.roleName === role;

  permissionsWasSet(): Promise<boolean> {
    let timesCounter: number = 0;
    return new Promise<boolean>(resolve => {
      timesCounter += 1;
      this.checkPermissionInterval = window.setInterval(() => {
        if (this.permissions?.config?.permissionsWasSet) {
          resolve(true);
          clearInterval(this.checkPermissionInterval);
        } else {
          if (timesCounter > 50) {
            resolve(false);
            clearInterval(this.checkPermissionInterval);
          }
        }
      }, 200);
    });
  }

  destroy() {
    this.permissions = new PermissionModel();
    clearInterval(this.checkPermissionInterval);
  }

  // CC-3826
  private setUserLevelPermissions(user: UserModel): void {
    this.permissions.userLevel = { ...new UserLevelPermissions(), ...user.customPermissions };

    if (!this.permissions.userLevel.manageIntegrationsAllowed) {
      this.permissions.settings.readIntegrations = false;
      this.permissions.forbiddenRoutes.push('/settings/integrations');
    }

    if (!this.permissions.userLevel.manageFacilityPricingAllowed) {
      this.permissions.directory.canUploadFacilityPrice = false;
      this.permissions.directory.managePriceSheets = false;
    }

    if (!this.permissions.userLevel.manageInventoryMovementsAllowed) {
      this.permissions.movements.createLocation = false;
      this.permissions.movements.createRequestRecipe = false;
      this.permissions.inventory.canInitiateInventoryRequest = false;
      this.permissions.inventory.canFulfillInventoryRequest = false;
      this.permissions.inventory.createInventoryTransfer = false;
      this.permissions.inventory.canManageInventoryAtRequestDetailPage = false;
      this.permissions.inventory.approveAnyTransfer = false;
      this.permissions.inventory.acceptSelfTransferOnly = false;
      this.permissions.inventory.assignEventToTransfer = false;
      this.permissions.inventory.manageInventoryRequestInProgress = false;
      this.permissions.events.submitForBillingRequestReplenishment = false;
      this.permissions.forbiddenRoutes.push('/movement/locations');
      this.permissions.forbiddenRoutes.push('/directory/preference-cards');
    }

    if (!this.permissions.userLevel.manageDirectoryItemsAllowed) {
      this.permissions.userLevel.addDirectoryItemsAllowed = false;
      this.permissions.directory.editFacility = false;
      this.permissions.directory.editManufacturer = false;
      this.permissions.directory.editPhysician = false;
      this.permissions.directory.editProcedure = false;
    }

    if (!this.permissions.userLevel.addDirectoryItemsAllowed) {
      this.permissions.directory.createFacility = false;
      this.permissions.directory.createPhysician = false;
      this.permissions.directory.createProcedure = false;
      this.permissions.directory.createManufacturer = false;
    }

    if (!this.permissions.userLevel.manageBomsAllowed) {
      this.permissions.inventory.createBOM = false;
      this.permissions.forbiddenRoutes.push('/inventory/bill-of-materials');
    }

    if (!this.permissions.userLevel.createAndInviteUserAllowed) {
      this.permissions.user.addUsersToSystem = false;
    }

    if (!this.permissions.userLevel.manageUserAllowed) {
      this.permissions.user.canDeactivateUsers = false;
      this.permissions.user.canEditShippingAddress = false;
    }

    if (!this.permissions.userLevel.viewReportsAllowed) {
      this.permissions.forbiddenRoutes.push(
        ...[
          '/statistics/total-sales',
          '/statistics/custom-sales',
          '/statistics/case',
          '/statistics/inventory-export-stock',
          '/statistics/inventory-export-all'
        ]
      );
      // CC-4961
      this.permissions.report.accessRestricted = true;
    }

    if (!this.permissions.userLevel.inventoryCreationAllowed) {
      this.permissions.inventory.createInventory = false;
      this.permissions.inventory.canCreateInventoryImport = false;
    }

    if (!this.permissions.userLevel.manageEventsAllowed) {
      this.permissions.events.createEvent = false;
      this.permissions.events.canEditEvent = false;
      this.permissions.events.canChangeEventStatus = false;
      this.permissions.events.canAddPONumber = false;
      this.permissions.events.canCloseEvent = false;
      this.permissions.events.canChangeCommissionAndInvoicePaid = false;
      this.permissions.events.canSignEvent = false;
      this.permissions.events.canMarkAsPaid = false;
      this.permissions.custom.reopenEventsEnabled = false;
      this.permissions.events.deviceUsedBulkActions = false;
      this.permissions.events.copyEvent = false;
      this.permissions.events.editPONUmber = false;
      this.permissions.inventory.markDeviceInEvent = false;
    }

    if (!this.permissions.userLevel.viewCommissionsAllowed) {
      this.permissions.user.readUserCommission = false;
    }
  }

  // CC-4300
  private setReadonlyAccessPermissions(user: UserModel): void {
    //CC-4429
    if (!user.organization.isReadOnly && !user.isCustodianOnly) {
      return;
    }

    // Config
    this.permissions.config.offlineMode = false;

    // Common
    this.permissions.common.addComment = false;

    // Movements
    this.permissions.userLevel.manageInventoryMovementsAllowed = false;
    this.permissions.movements.createLocation = false;
    this.permissions.movements.createRequestRecipe = false;
    this.permissions.inventory.canInitiateInventoryRequest = false;
    this.permissions.inventory.canFulfillInventoryRequest = false;
    this.permissions.inventory.createInventoryTransfer = false;
    this.permissions.inventory.canManageInventoryAtRequestDetailPage = false;
    this.permissions.inventory.approveAnyTransfer = false;
    this.permissions.inventory.acceptSelfTransferOnly = false;
    this.permissions.inventory.assignEventToTransfer = false;
    this.permissions.inventory.manageInventoryRequestInProgress = false;
    this.permissions.events.submitForBillingRequestReplenishment = false;
    this.permissions.forbiddenRoutes.push('/movement/locations');
    this.permissions.forbiddenRoutes.push('/directory/preference-cards');

    // Events
    this.permissions.events.createEvent = false;
    this.permissions.events.canEditEvent = false;
    this.permissions.events.canChangeEventStatus = false;
    this.permissions.events.canAddPONumber = false;
    this.permissions.events.canCloseEvent = false;
    this.permissions.events.canChangeCommissionAndInvoicePaid = false;
    this.permissions.events.canSignEvent = false;
    this.permissions.events.canMarkAsPaid = false;
    this.permissions.custom.reopenEventsEnabled = false;
    this.permissions.events.deviceUsedBulkActions = false;
    this.permissions.events.copyEvent = false;
    this.permissions.events.editPONUmber = false;
    this.permissions.userLevel.manageEventsAllowed = false;
    this.permissions.events.canExportSalesOrderFormToQuickBooks = false;
    this.permissions.events.canSendSalesOrderAsAttachment = false;
    this.permissions.events.canSendInvoiceAsAttachment = false;
    this.permissions.events.canSendQuoteAsAttachment = false;
    // CC-5084
    this.permissions.events.downloadInvoiceAndSOFInReadOnlyMode = true;

    // Inventory
    this.permissions.inventory.createInventory = false;
    this.permissions.inventory.editInventory = false;
    this.permissions.inventory.canEditInventoryAdditionalInfo = false;
    this.permissions.inventory.markDeviceInEvent = false;
    this.permissions.userLevel.invDeactivationAndStockEventCreationAllowed = false;
    this.permissions.inventory.canCreateInventoryImport = false;
    this.permissions.userLevel.inventoryCreationAllowed = false;
    this.permissions.inventory.createBOM = false;
    this.permissions.userLevel.manageBomsAllowed = false;
    this.permissions.forbiddenRoutes.push('/inventory/bill-of-materials');
    this.permissions.inventory.createAudits = false;
    this.permissions.inventory.readAudits = false;
    this.permissions.forbiddenRoutes.push('/inventory/audits');
    this.permissions.inventory.canCreateProductLine = false;
    this.permissions.inventory.canCreateCatalog = false;
    this.permissions.inventory.canEditCatalog = false;
    this.permissions.inventory.canEditProductLine = false;

    // Directory
    this.permissions.directory.createFacility = false;
    this.permissions.directory.createPhysician = false;
    this.permissions.directory.createProcedure = false;
    this.permissions.directory.createManufacturer = false;
    this.permissions.directory.canUploadFacilityPrice = false;
    this.permissions.directory.managePriceSheets = false;
    this.permissions.userLevel.addDirectoryItemsAllowed = false;
    this.permissions.userLevel.manageDirectoryItemsAllowed = false;
    this.permissions.directory.editFacility = false;
    this.permissions.directory.editManufacturer = false;
    this.permissions.directory.editPhysician = false;
    this.permissions.directory.editProcedure = false;

    // Users
    this.permissions.user.addUsersToSystem = false;
    this.permissions.user.canDeactivateUsers = false;
    this.permissions.user.canEditShippingAddress = false;
    this.permissions.user.requestAdditionalSeats = false;
    this.permissions.user.sendPOReminder = false;
    this.permissions.userLevel.manageUserAllowed = false;
    this.permissions.userLevel.createAndInviteUserAllowed = false;

    // Settings
    this.permissions.settings.readSettings = false;
    this.permissions.forbiddenRoutes.push('/settings');

    // Notifications
    this.permissions.notifications.readNotifications = false;
    this.permissions.forbiddenRoutes.push('/notifications');
  }

  private setOrganizationLevelPermissions(user: UserModel): void {
    this.permissions.inventory.readAudits = user.organization.isAuditEnabled && user.role !== 'REGIONAL_MANAGER';

    if (!this.permissions.inventory.readAudits) {
      this.permissions.forbiddenRoutes.push('/inventory/audits');
    }

    if (!user.organization.isUdiEnabled) {
      this.permissions.inventory.scanViaUDIdentify = false;

      // An exception for SALES and DISTRIBUTOR roles because they should see the Import module ONLY IF `isUdiEnabled` is true
      if (user.role === 'SALES' || user.role === 'DISTRIBUTOR') {
        this.permissions.inventory.canReadInventoryImport = false;
        this.permissions.forbiddenRoutes.push(...['/inventory/import', '/inventory/import/list', '/inventory/import/detail']);
      }
    }

    if (!user.organization.isLookerStudioEnabled) {
      this.permissions.report.readReport = false;
      this.permissions.report.readCaseReport = false;
      this.permissions.report.readCaseReport = false;
      this.permissions.report.readParLevelReport = false;
      this.permissions.report.readTransferReport = false;
      this.permissions.report.inventoryReport = false;
      this.permissions.report.readTotalSalesReport = false;
      this.permissions.report.readCustomSalesReport = false;
      this.permissions.report.usersReport = false;
      this.permissions.report.productLinesReport = false;
      this.permissions.report.catalogsReport = false;
      this.permissions.report.exportInventory = false;
      this.permissions.report.facilitiesReport = false;
      this.permissions.report.physiciansReport = false;
      this.permissions.report.inventoryRequestReport = false;
    }
  }
}
