import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@shared/classes/api-service';
import { LocationStateModel, PageableModel, RegionModel, RegionParamsModel } from '@shared/models';
import { RegionsPageableParams } from '@shared/models/build-models';
import { Observable, catchError, of, map } from 'rxjs';
import { HttpHelperService } from './internal/http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class RegionsService extends ApiService {
  constructor() {
    super();
  }

  getPageable(params: RegionsPageableParams): Observable<PageableModel<RegionModel>> {
    return this.get<PageableModel<RegionModel>>(`regions/pageable`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of(new PageableModel()))
    );
  }

  getLocationStates(id: string): Observable<LocationStateModel[]> {
    return this.get<LocationStateModel[]>(`regions/${id}/locationStates`).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of([]))
    );
  }

  deactivate(id: string): Observable<boolean> {
    return this.put<void>(`regions/${id}/deactivate`, {}).pipe(
      map(() => true),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  createRegion(params: RegionParamsModel): Observable<string> {
    return this.post<string>(`regions`, params).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getRegion(id: string, redirectToNotFoundPage: boolean = false): Observable<RegionModel> {
    return this.get<RegionModel>(`regions/${id}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        HttpHelperService.errorHandler(error, null, redirectToNotFoundPage);
        return of(null);
      })
    );
  }

  updateRegion(id: string, body: RegionParamsModel): Observable<void> {
    return this.put<void>(`regions/${id}`, body).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }
}
