import { differenceInBusinessDays, differenceInDays } from 'date-fns';

export const GetDifferenceInBusinessDays = (datetime: string): number => {
  const today: Date = new Date();
  today.setHours(12, 0, 0, 0);

  const datetimeToDate: Date = new Date(datetime);
  datetimeToDate.setHours(12, 0, 0, 0);

  return differenceInBusinessDays(today, datetimeToDate);
};

export const GetDifferenceInDays = (datetime: string): number => {
  const today: Date = new Date();
  today.setHours(12, 0, 0, 0);

  const datetimeToDate: Date = new Date(datetime);
  datetimeToDate.setHours(12, 0, 0, 0);

  return differenceInDays(datetimeToDate, today);
};
