import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { IUserSubordinationDTO, IUserSubordinationView } from '@shared/interfaces';
import { of, catchError, map } from 'rxjs';
import { ApiService } from '@shared/classes/api-service';

@Injectable({
  providedIn: 'root'
})
export class UserSubordinationService extends ApiService {
  constructor() {
    super();
  }

  deleteUserSubordination(id: string): Observable<void> {
    return this.delete<void>(`users/subordinations/${id}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getSubordinations = (userIds: string[], skipAlert: boolean = false): Observable<{ [k: string]: IUserSubordinationView[] }> =>
    this.post<{ [k: string]: IUserSubordinationView[] }>(`users/subordinations/grouped`, { userIds }).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        if (!skipAlert) {
          this.alertsService.showError(null, error.message);
        }

        return of({});
      })
    );

  addUserSubordination(params: IUserSubordinationDTO): Observable<string> {
    return this.post<string>(`users/subordinations`, params).pipe(
      map(data => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return data;
      }),
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.assignUser');
        return of(null);
      })
    );
  }
}
