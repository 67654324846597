// Since we use the Capacitor Storage we need to get rid of legacy data in localStorage
export const localStorageCleaner = (): void => {
  const legacyProperties: string[] = [
    'theme',
    'user_id',
    'id_token',
    'idleTimeout',
    'dashboard',
    'openSideBar',
    'csx.openSideBar',
    'dutSort'
  ];

  legacyProperties.forEach(prop => {
    localStorage.removeItem(prop);
  });
};
