<app-create-entity-modal
  [title]="'manufacturers.createManufacturerTitle' | language"
  [showCreateButton]="stepperRef?.selectedIndex > 0"
  [confirmationRequired]="true"
  [showNextStepButton]="stepperRef?.selectedIndex === 0"
  [disableNextStepButton]="!formsValid || (loading$ | async) === true"
  [showPrevStepButton]="stepperRef?.selectedIndex > 0"
  [disableCreateButton]="(loading$ | async) === true"
  [showCloseConfirmation]="form?.touched"
  (prevStepEmitter)="stepperRef.previous()"
  (nextStepEmitter)="stepperRef.next()"
  (createEmitter)="redirect ? submitWithSubscription() : submitWithoutRedirect()"
>
  <mat-stepper #stepper [linear]="true" [disableRipple]="true">
    <ng-template matStepperIcon="edit">
      <mat-icon>check</mat-icon>
    </ng-template>
    <mat-step [label]="'manufacturers.generalInformationLabel' | language">
      <app-manufacturer-form
        [createEntityFlow]="true"
        [searchingInGudid]="searchingInGudid$ | async"
        [showSearchManufacturer]="true"
        [manufacturer]="manufacturer"
        (formValueChangeEmitter)="form = $event; checkValidation()"
        [companiesAutocompleteSearchData]="companiesAutocompleteSearchData"
        (searchCompaniesEmitter)="searchCompanies($event)"
        (setManufacturerValuesFromSearchEmitter)="setManufacturerValuesFromSearch($event)"
      ></app-manufacturer-form>
    </mat-step>
    <mat-step [label]="'shared.labels.contacts' | language">
      <app-form-additional-contacts
        *ngFor="let contact of additionalContactsData; let i = index; last as isLast"
        [contact]="contact"
        [canEdit]="true"
        [createEntityFlow]="true"
        [showAddNewContactsButton]="isLast && i < 4"
        [lastChild]="isLast"
        [type]="'ADDITIONAL_PRIMARY'"
        (formNameValueChangeEmitter)="additionalContacts[i] = $event; checkValidation()"
        (addNewContactEmitter)="addContact()"
        (deleteAdditionalContacts)="deleteContact(i)"
      ></app-form-additional-contacts>
    </mat-step>
  </mat-stepper>
</app-create-entity-modal>
