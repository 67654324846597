import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IMobileActions } from '@shared/interfaces/mobile-actions';
import { BehaviorSubject, Observable, takeUntil } from 'rxjs';
import { DestroySubscriptions } from '@shared/classes/destroy-subscriptions';
import { NavbarRoutes } from '@shared/utils/navbar-routes';
import { isMobileDevice } from '@shared/utils/is-device';
import { NavigationEnd, Router } from '@angular/router';
import { OfflineStatusService } from '@services/offline/offline-status.service';
import { GlobalStoreService } from '@services/internal/global-store.service';

@Component({
  selector: 'app-mobile-screen-title',
  templateUrl: './mobile-screen-title.component.html',
  styleUrls: ['./mobile-screen-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileScreenTitleComponent extends DestroySubscriptions implements AfterViewInit {
  @ViewChild('statusTemplate') statusTemplate: ElementRef<HTMLSpanElement>;

  @Input() totalElements: number = 0;

  @Output() checkTotalNotifications = new EventEmitter<void>();
  @Output() menuTogglerEmitter = new EventEmitter<void>();

  title$: Observable<string> = GlobalStoreService.screenTitle$;
  statusData$: Observable<IMobileActions> = GlobalStoreService.mobileActions$;
  showMobileNavbar: boolean = false;
  maxWidth$: BehaviorSubject<string> = new BehaviorSubject<string>('calc(100vw - 128px)');
  readonly isOffline$: BehaviorSubject<boolean> = this.offlineStatusService.isOffline$;
  readonly isPreparingOffline$: BehaviorSubject<boolean> = this.offlineStatusService.preparingOffline$;

  constructor(private router: Router, private offlineStatusService: OfflineStatusService) {
    super();
    this.checkNavbarVisibility();
    if (isMobileDevice()) {
      this.router.events.pipe(takeUntil(this.subscriptions)).subscribe(val => {
        if (val instanceof NavigationEnd) {
          this.checkNavbarVisibility();
          this.calculateMaxWidth();
        }
      });
    }
  }

  ngAfterViewInit(): void {
    this.calculateMaxWidth();
    this.title$.pipe(takeUntil(this.subscriptions)).subscribe(() => {
      setTimeout(() => {
        this.calculateMaxWidth();
      });
    });
  }

  checkNavbarVisibility(): void {
    const forbiddenRoutes: string[] = NavbarRoutes.mobilePrimaryRoutes;
    this.showMobileNavbar = forbiddenRoutes.some(r => window.location.pathname === r);
  }

  calculateMaxWidth(): void {
    if (this.statusTemplate?.nativeElement.offsetWidth) {
      this.maxWidth$.next('calc(100vw - ' + (2 * (this.statusTemplate?.nativeElement.offsetWidth || 0) + 30) + 'px)');
    } else {
      this.maxWidth$.next('calc(100vw - 128px)');
    }
  }
}
