import { Injectable } from '@angular/core';
import { BillOfMaterials, BomDetails, PageableModel } from '@shared/models';
import { HttpHelperService } from './internal/http-helper.service';
import { BillOfMaterialRequestModel } from '@shared/models/request-models';
import { HttpErrorResponse } from '@angular/common/http';
import { BillOfMaterialsPageableParams } from '@shared/models/build-models';
import { Observable, of, catchError, map } from 'rxjs';
import { ApiService } from '@shared/classes/api-service';

@Injectable({
  providedIn: 'root'
})
export class BillOfMaterialsService extends ApiService {
  create(body: BillOfMaterialRequestModel): Observable<string> {
    return this.post<string>(`inventories/bom`, body).pipe(
      map((id: string) => {
        this.alertsService.showSuccess('shared.alerts.successMessages.created');
        return id;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.createBOM', error.message);
        return of(null);
      })
    );
  }

  deactivate(id: string): Observable<boolean> {
    return this.put<void>(`inventories/bom/${id}/deactivate`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.deactivated');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getBOM(bomId: string): Observable<BillOfMaterials> {
    return this.get<BillOfMaterials>(`inventories/bom/${bomId}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getDetails(bomId: string): Observable<BomDetails[]> {
    return this.get<BomDetails[]>(`inventories/bom/${bomId}/details`).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of([]))
    );
  }

  getPageable(params: BillOfMaterialsPageableParams): Observable<PageableModel<BillOfMaterials>> {
    return this.get<PageableModel<BillOfMaterials>>(`inventories/bom`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of(null))
    );
  }

  updateBOM(body: BillOfMaterialRequestModel, bomId: string): Observable<string> {
    return this.put<string>(`inventories/bom/${bomId}`, body).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.saveError');
        return of(null);
      })
    );
  }
}
