<div class="selectedInventoryItem" fxLayout.xs="column" data-cy="selectedInventories" [ngClass]="{ fullWidthView: fullWidthView }">
  <div fxLayout="column" fxLayoutGap="4px" class="selectedInventoryInfoContainer">
    <div fxLayout="row">
      <div fxFlex="90%" fxLayout.xs="column" fxLayoutGap.xs="4px">
        <div fxFlex="50%" fxLayout="column" fxLayoutGap="4px">
          <span class="inventoryValue" *ngIf="item.name" data-cy="selectedInventoryName">{{ item.name }} </span>

          <span class="inventoryLabel" *ngIf="item.custodyName">
            {{ 'shared.labels.custody' | language }}:
            <span class="inventoryValue" [ngClass]="{ colorRed: custody?.name && custody?.id !== item.custodyId }">{{
              item.custodyName
            }}</span>
          </span>

          <span class="inventoryLabel" *ngIf="!item.custodyName"
            >{{ 'shared.labels.org' | language }}: <span class="inventoryValue">{{ companyName }}</span></span
          >

          <span class="inventoryLabel">
            {{ 'shared.labels.udi' | language }}:
            <span *ngIf="item.udi; else showNA" class="inventoryValue">
              {{ item.udi }}
            </span>
          </span>

          <span class="inventoryLabel">
            {{ 'shared.labels.lot' | language }}:
            <span *ngIf="item.lotNumber; else showNA" class="inventoryValue">
              {{ item.lotNumber }}
            </span>
          </span>
        </div>
        <div fxFlex="50%" fxLayout="column" fxLayoutGap="4px">
          <span class="inventoryLabel">
            {{ 'shared.labels.serial' | language }}:
            <span *ngIf="item.serialNumber; else showNA" class="inventoryValue">
              {{ item.serialNumber }}
            </span>
          </span>
          <span class="inventoryLabel">
            {{ 'shared.labels.catalog' | language }}:
            <span class="inventoryValue">{{ item.referenceNumber }}</span>
          </span>
          <span *ngIf="item.inventoryType === 'DEVICE'" class="inventoryValue">
            {{
              item.parentReferenceNumber
                ? ('shared.labels.contId' | language) + ': ' + item.parentReferenceNumber
                : ('shared.statuses.loose' | language)
            }}
          </span>
          <span *ngIf="item.inventoryType === 'PACK'"
            ><span class="emphasizedGray">{{ 'shared.labels.pack' | language }}</span></span
          >
          <span *ngIf="item.expirationStatus === 'EXPIRED'" class="inventoryValue colorRed">{{
            'shared.statuses.expired' | language
          }}</span>
          <span *ngIf="item?.expiredDays !== null && item?.expiredDays > 0" class="inventoryValue colorOrange">{{
            ('shared.statuses.expiresIn_1' | language) + ' ' + item.expiredDays + ' ' + ('shared.statuses.days' | language)
          }}</span>
          <span *ngIf="item.billable === false" class="inventoryValue colorBlue">{{ 'shared.statuses.unbillable' | language }}</span>
        </div>
      </div>
      <div fxFlex="10%" fxLayout="row" fxLayoutAlign="end start">
        <app-icon
          *ngIf="!item.disabled"
          [icon]="'close'"
          [type]="'outline'"
          [color]="'warn'"
          [size]="(isMobile$ | async) === true ? 24 : 16"
          (click)="$event.stopPropagation(); removeInventory(ind)"
        ></app-icon>
      </div>
    </div>

    <div *ngIf="displayTags && tags.length" class="tagsContainer" fxLayout="row wrap">
      <span *ngFor="let tag of tags" class="emphasizedGray">{{ tag }}</span>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="inventoryCountContainer">
    <div>
      <ng-container *ngIf="permissionService.isRole('ADMIN')">
        <span
          class="inventoryValue"
          *ngIf="(item.inventoryType === 'DEVICE' || item.inventoryType === 'PACK') && (recipient?.id || recipientOrg)"
        >
          <span *ngIf="item.transferParentInventory; else showAssignToCont" data-cy="assignFutureContainerName">
            <div fxLayout="row" fxLayoutGap="8px">
              <span>{{ item.transferParentInventory.name }}</span>
              <app-icon [icon]="'edit'" [type]="'outline'" [size]="16" [matMenuTriggerFor]="chooseContainer"></app-icon>
            </div>
          </span>
        </span>
      </ng-container>
    </div>

    <app-select-items-count
      data-cy="inventorySelectedItem"
      [selectedCount]="item.selectedCount"
      [totalCount]="item.count"
      [disabled]="item.disabled"
      [ind]="ind"
      (changeCount)="selectedCountEmitter.emit($event)"
    ></app-select-items-count>
  </div>
</div>

<ng-template #showNA><span class="inventoryValue">-</span></ng-template>
<ng-template #showAssignToCont
  ><span
    class="colorPrimary"
    [matMenuTriggerFor]="chooseContainer"
    [ngClass]="{ disabled: !recipient && !recipientOrg }"
    data-cy="assignFutureContainerButton"
    >{{ 'transfers.assignToContainer' | language }}</span
  ></ng-template
>

<mat-menu #chooseContainer="matMenu" xPosition="before" yPosition="below" backdropClass="mobileStyle" class="minWidthMatMenu">
  <ng-template matMenuContent>
    <app-assign-transferred-inventory-to-container
      [selectedContainer]="item.transferParentInventory"
      [recipient]="recipient"
      (closeMatMenuEmitter)="closeMatMenu()"
      (chooseContainerEmitter)="chooseTransferContainerEmitter.emit($event)"
    ></app-assign-transferred-inventory-to-container>
  </ng-template>
</mat-menu>
