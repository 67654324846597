import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatSortHeaderModel, NotificationModel, PageableModel } from '@shared/models';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ManagerSearchParamsService } from '@shared/helpers/managaer-search-params/manager-search-params.service';
import { SearchParamsShorterObject } from '@shared/utils/http/search-params-shorter';
import { EExpirationStatusWithDays } from '@shared/enum';
import { ScrollTo } from '@shared/utils/scroll-to';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IFilter } from '@shared/interfaces';
import { FindLinkInString } from '@shared/utils/find-link-in-string';
import { GetStatisticLink } from '../../utils/get-statistic-route';
import { ResponsiveTableLayout } from '@shared/classes/responsive-table-layout';
import { addDays, subDays } from 'date-fns';
import { UsersService } from '@services/users.service';

@Component({
  selector: 'app-notifications-table',
  templateUrl: './notifications-table.component.html',
  styleUrls: ['./notifications-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsTableComponent extends ResponsiveTableLayout<NotificationModel> {
  @Input() set notificationData(data: PageableModel<NotificationModel>) {
    this.setData(data);
  }
  @Input() matSortHeaderInFilter: MatSortHeaderModel = new MatSortHeaderModel();
  @Input() loading: boolean = false;
  @Input() selectedView: 'NOTIFICATIONS' | 'MENTIONS' | 'EXPORTS' = 'NOTIFICATIONS';

  readonly replaceStatistics = NotificationsTableComponent.replaceStatisticsMessage;
  readonly isStatistic = NotificationsTableComponent.isStatistics;
  readonly columnsToDisplay: string[] = ['subject', 'message', 'createdBy', 'modifiedDatetime', 'entityType'];
  readonly isAdmin: boolean = UsersService.getUser().role === 'ADMIN';

  constructor(
    private managerSearchParamsService: ManagerSearchParamsService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  openStatisticLink(message: string): void {
    this.router.navigate([GetStatisticLink(message)]);
  }

  static replaceStatisticsMessage(type: string, message: string): string {
    if (type === 'STATISTICS') {
      if (message.includes('TOTAL_SALES_V2')) {
        return message.replace('TOTAL_SALES_V2', 'Total Sales Report');
      } else if (message.includes('CUSTOM_SALES_V2')) {
        return message.replace('CUSTOM_SALES_V2', 'Custom Sales Report');
      } else if (message.includes('CASE_REPORTS_V2')) {
        return message.replace('CASE_REPORTS_V2', 'Case Report');
      } else if (message.includes('FACILITY_PAR')) {
        return message.replace('FACILITY_PAR', 'Facility Par Levels Report');
      } else if (message.includes('CONTAINER_TURN')) {
        return message.replace('CONTAINER_TURN', 'Container Turn Report');
      } else if (message.includes('TRANSFER')) {
        return message.replace('TRANSFER', 'Transfer Report');
      } else {
        return message;
      }
    } else {
      return message;
    }
  }

  static isStatistics(type: string, message: string): boolean {
    return (
      type === 'STATISTICS' &&
      (message.includes('TOTAL_SALES_V2') ||
        message.includes('CASE_REPORTS_V2') ||
        message.includes('CUSTOM_SALES_V2') ||
        message.includes('FACILITY_PAR') ||
        message.includes('CONTAINER_TURN') ||
        message.includes('TRANSFER'))
    );
  }

  downloadFile(message: string): void {
    window.open(FindLinkInString(message), '_self');
  }

  getMentionedPage(notification: NotificationModel): string {
    if (notification.entityType === 'PHYSICIAN') {
      return '/directory/physicians/edit/' + notification.entityId;
    } else if (notification.entityType === 'TRANSFER') {
      return '/movement/transfers/edit/' + notification.entityId;
    } else if (notification.entityType === 'EVENT') {
      return '/events/edit/' + notification.entityId;
    } else if (notification.entityType === 'REQUEST') {
      return '/movement/requests/edit/' + notification.entityId;
    } else if (notification.entityType === 'INVENTORY') {
      return '/inventory/inventories/edit/' + notification.entityId;
    } else if (notification.entityType === 'AUDIT_INVENTORY') {
      return '/inventory/audits/edit/' + notification.entityId;
    } else {
      return '';
    }
  }

  getPageable(pagination: PageEvent, sort?: Sort): void {
    this.managerSearchParamsService.getPageable(pagination, sort);
    if (!this.isMobile$.value) {
      ScrollTo('.connectSxTable thead');
    } else {
      this.refreshData = false;
    }
  }

  defineInspectionDue(message: string): void {
    const params: IFilter = {};
    if (message.includes('next 7 days')) {
      params.inspectionDaysFrom = 7;
      params.inspectionDaysTo = 7;
    } else {
      params.inspectionDays = 'OVERDUE';
    }
    this.router.navigate(['/inventory/inventories/list'], { queryParams: SearchParamsShorterObject(params) });
  }

  defineDueBackStatus(message: string): void {
    const dueBackStatus = message.includes('in 24 hours') ? 'IN_24_HOURS' : message.includes('in 48 hours') ? 'IN_48_HOURS' : 'OVERDUE';
    this.router.navigate(['/inventory/inventories/list'], { queryParams: SearchParamsShorterObject({ dueBackStatus }) }).then();
  }

  defineExpirationStatus(message: string): void {
    const expirationStatus: keyof typeof EExpirationStatusWithDays = message.includes('30 days')
      ? 'WITHIN_30'
      : message.includes('31-60')
        ? '31-60'
        : 'EXPIRED';
    this.router.navigate(['/inventory/inventories/list'], { queryParams: SearchParamsShorterObject({ expirationDays: expirationStatus }) });
  }

  defineReservationDate(message: string, datetime: string): void {
    let datetimeFrom: string;
    let datetimeTo: string;

    if (message.includes('in 24 hours')) {
      datetimeFrom = subDays(new Date(datetime), 1).toISOString();
      datetimeTo = datetime;
    } else {
      datetimeFrom = datetime;
      datetimeTo = addDays(new Date(datetime), 2).toISOString();
    }

    this.router.navigate(['/inventory/inventories/list'], {
      queryParams: SearchParamsShorterObject({
        itemType: 'CONTAINER',
        showReservedContainers: true,
        eventDatetimeFrom: datetimeFrom,
        eventDatetimeTo: datetimeTo
      })
    });
  }

  bypassSecurityTrustHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  setData(data: PageableModel<NotificationModel>): void {
    if (!data) {
      return;
    }
    this.size = data.size;
    this.totalPages = data.totalPages;
    this.totalElements = data.totalElements;
    this.pageNumber = data.pageable.pageNumber;
    this.dataSource.data = this.isMobile$.value && !this.refreshData ? [...this.dataSource.data, ...data.content] : data.content;
    this.refreshData = true;
  }

  navigateToDueBackExtension(): void {
    this.router.navigate(['/inventory/inventories/list'], { queryParams: SearchParamsShorterObject({ isExtensionRequested: true }) });
  }
}
