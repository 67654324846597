<div class="full-width" fxLayout="row" fxLayoutAlign="start center">
  <app-input-with-chips-kit
    *ngIf="multiple; else single"
    class="full-width"
    [dataCy]="'productInput'"
    [chipDataCy]="'productChip'"
    [chipRemoveData_cy]="'productRemoveChipButton'"
    [selectedChips]="selectedChips"
    [showAddChipButton]="true"
    [label]="'shared.labels.productAutocomplete' | language"
    [disabled]="disabled"
    [invalidInput]="invalid"
    [selectable]="true"
    [forceBlurEvent]="forceBlurEvent"
    [icons]="[showOnlyAssignedOption && !disabled ? 'filter' : null]"
    [matMenuName]="chooseItems"
    [skeleton]="skeleton"
    [debounceEvents]="['keyup', 'focus']"
    [required]="!selectedChips.length && required"
    [requiredView]="!!selectedChips.length && required"
    [debounceTime]="1000"
    [removable]="removable"
    [showLinkToDetail]="showLinkToDetail"
    [detailPageLink]="permissionService.isGranted('common', 'visitDetailPageAssignedEntity') ? '/inventory/products/edit/' : null"
    [autocompleteName]="productAutocomplete"
    (chipsRemovedEmitter)="removeChip($event.id)"
    (debounceTimeEndedEmitter)="searchProduct($event)"
  ></app-input-with-chips-kit>

  <ng-template #single>
    <app-input-kit
      class="full-width"
      [required]="required"
      [invalidInput]="invalid"
      [label]="label"
      [dataCy]="'productInput'"
      [initialValue]="product"
      [filterView]="filterView"
      [forceBlurEvent]="forceBlurEvent"
      [skeleton]="skeleton"
      [icons]="[showClearIcon && !disabled ? 'close' : null, showOnlyAssignedOption && !disabled ? 'filter' : null]"
      [disabled]="disabled"
      [debounceEvents]="['keyup', 'focus']"
      [debounceTime]="1000"
      [autocompleteName]="productAutocomplete"
      [matMenuName]="chooseItems"
      (iconClickEmitter)="doIconAction($event)"
      (valueChangedEmitter)="valueChangedEmitter.emit($event)"
      (debounceTimeEndedEmitter)="searchProduct($event)"
    ></app-input-kit>
  </ng-template>

  <mat-menu #chooseItems="matMenu" xPosition="before" yPosition="above">
    <ng-template matMenuContent>
      <div class="productLineMenuContainer" (click)="$event.stopPropagation()">
        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
          <app-checkbox-kit
            [disabled]="disabled"
            [initialValue]="onlyAssignedProducts"
            [title]="
              permissionService.isGranted('inventory', 'filterProductLinesByAssignedToCurrentUser')
                ? ('shared.components.onlyAssignedToMe' | language)
                : userType === 'REPRESENTATIVE'
                  ? ('shared.components.onlyAssignedToSelectedRepresentative' | language)
                  : ('shared.components.onlyAssignedToSelectedCustody' | language)
            "
            (checkEmitter)="onlyAssignedProducts = $event"
          ></app-checkbox-kit>

          <app-tooltip-kit [title]="'shared.tooltips.displayedProducts'" [position]="'after'"></app-tooltip-kit>
        </div>
      </div>
    </ng-template>
  </mat-menu>

  <mat-autocomplete #productAutocomplete="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngIf="(loading$ | async) === true || (initialLoading$ | async) === true" class="is-loading" disabled>
      <mat-progress-spinner value="30" class="spinner" mode="indeterminate" diameter="30"></mat-progress-spinner>
    </mat-option>

    <ng-container *ngIf="(loading$ | async) === false && (initialLoading$ | async) === false">
      <mat-option *ngIf="!(searchedData$ | async).length; else options" disabled class="connectSxAutocompleteOption">
        <span>{{ 'shared.labels.noItemsFound' | language }}</span></mat-option
      >
      <ng-template #options>
        <cdk-virtual-scroll-viewport
          itemSize="0"
          class="virtualAutocompleteScroll"
          [style.height.px]="(searchedData$ | async).length < 3 ? (searchedData$ | async).length * 50 : 150"
        >
          <mat-option
            *cdkVirtualFor="let product of searchedData$ | async"
            data-cy="productOption"
            class="connectSxAutocompleteOption"
            [value]="product"
            (click)="selectOption(product)"
          >
            <p>{{ product.name }}</p>
            <small *ngIf="includeProductTypes && product.modifier">{{ 'shared.filter.type' | language }}: {{ product.modifier }}</small>
            <small>{{ 'shared.components.description' | language }}: {{ product.description || '-' }}</small>
            <small *ngIf="displayManufacturer">{{ 'shared.components.manufacturer' | language }}: {{ product.manufacturer.name }}</small>
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </ng-template>
    </ng-container>

    <mat-option
      *ngIf="
        (loading$ | async) === false &&
        (initialLoading$ | async) === false &&
        showAddNewButton &&
        permissionService.isGranted('inventory', 'canCreateProductLine')
      "
      data-cy="addNewOption"
      class="connectSxAutocompleteOption addNewOption"
      (click)="addProductLine()"
    >
      <app-icon [type]="'outline'" [size]="20" [color]="'primary'" [icon]="'plus'"></app-icon>
      <span class="addNewLabel">{{ 'shared.labels.addNew' | language }}</span>
    </mat-option>
  </mat-autocomplete>
</div>
