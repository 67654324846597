/** @Returns the browser name, type and version, and sets body classes
 *  https://gist.github.com/lyquix-owner/34a3ed98b990c3c61151d30de2f42702
 */
export interface IBrowser {
  browserName: string;
  browserVersion: string;
}

export const GetBrowser = (): IBrowser => {
  const ua = navigator.userAgent;
  let browser: IBrowser;

  // helper functions to deal with common regex
  const getFirstMatch = (regex: RegExp) => {
    const match = ua.match(regex);
    return (match && match.length > 1 && match[1]) || '';
  };

  const getSecondMatch = (regex: RegExp) => {
    const match = ua.match(regex);
    return (match && match.length > 1 && match[2]) || '';
  };

  // start detecting
  if (/opera|opr/i.test(ua)) {
    browser = {
      browserName: 'Opera',
      browserVersion: getFirstMatch(/version\/(\d+(\.\d+)?)/i) || getFirstMatch(/(?:opera|opr)[\s\/](\d+(\.\d+)?)/i)
    };
  } else if (/msie|trident/i.test(ua)) {
    browser = {
      browserName: 'Internet Explorer',
      browserVersion: getFirstMatch(/(?:msie |rv:)(\d+(\.\d+)?)/i)
    };
  } else if (/chrome.+? edge/i.test(ua)) {
    browser = {
      browserName: 'Microsoft Edge',
      browserVersion: getFirstMatch(/edge\/(\d+(\.\d+)?)/i)
    };
  } else if (/chrome|crios|crmo/i.test(ua)) {
    browser = {
      browserName: 'Google Chrome',
      browserVersion: getFirstMatch(/(?:chrome|crios|crmo)\/(\d+(\.\d+)?)/i)
    };
  } else if (/firefox/i.test(ua)) {
    browser = {
      browserName: 'Firefox',
      browserVersion: getFirstMatch(/(?:firefox)[ \/](\d+(\.\d+)?)/i)
    };
  } else if (!/like android/i.test(ua) && /android/i.test(ua)) {
    browser = {
      browserName: 'Android',
      browserVersion: getFirstMatch(/version\/(\d+(\.\d+)?)/i)
    };
  } else if (/safari/i.test(ua)) {
    browser = {
      browserName: 'Safari',
      browserVersion: getFirstMatch(/version\/(\d+(\.\d+)?)/i)
    };
  } else {
    browser = {
      browserName: getFirstMatch(/^(.*)\/(.*) /),
      browserVersion: getSecondMatch(/^(.*)\/(.*) /)
    };
  }
  return browser;
};
