import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Optional, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ManufacturerService } from '@services/manufacturer.service';
import { AlertsService } from '@services/internal/alerts.service';
import { CanAutoSaveDirective } from '@guards/autosave/can-auto-save';
import { ContactModel, ManufacturerModel } from '@shared/models';
import { BehaviorSubject, finalize, Observable, take } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScrollTo } from '@shared/utils/scroll-to';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-create-manufacturer',
  templateUrl: './create-manufacturer.component.html',
  styleUrls: ['./create-manufacturer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateManufacturerComponent extends CanAutoSaveDirective {
  @ViewChild('stepper') stepperRef: MatStepper;

  companiesAutocompleteSearchData: any[] = [];
  form: UntypedFormGroup;
  formsValid: boolean;
  manufacturer: ManufacturerModel = new ManufacturerModel();
  searchingInGudid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  additionalContacts: UntypedFormGroup[] = [];
  additionalContactsData: ContactModel[] = [new ContactModel()];
  formsTouched$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    public router: Router,
    public manufacturerService: ManufacturerService,
    public alertsService: AlertsService,
    public ref: ChangeDetectorRef,
    @Optional() public dialogRef: MatDialogRef<CreateManufacturerComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public redirect: boolean
  ) {
    super();
  }

  autoSave(): void {
    this.submit();
  }

  autoSaveFormsCheckValid(): UntypedFormGroup[] {
    const forms: UntypedFormGroup[] = [this.form];

    if (this.additionalContacts.length) {
      this.additionalContacts.forEach(f => forms.push(f));
    }

    return forms;
  }

  checkValidation(): void {
    setTimeout(() => {
      const additionalContactsValid: boolean = this.additionalContacts.every(form => form.valid);
      const additionalContactsTouched: boolean = this.additionalContacts.some(form => form.touched);

      this.formsValid = this.form.valid && additionalContactsValid;
      this.formsTouched$.next(this.form?.touched || additionalContactsTouched);
      this.ref.markForCheck();
    }, 50);
  }

  searchCompanies(searchText: string): void {
    this.searchingInGudid$.next(true);

    const params = {
      name: searchText,
      size: 20
    };

    this.manufacturerService
      .searchManufacturesInGudid(params)
      .pipe(
        take(1),
        finalize(() => this.searchingInGudid$.next(false))
      )
      .subscribe(r => (this.companiesAutocompleteSearchData = r.content));
  }

  setManufacturerValuesFromSearch(comp: ManufacturerModel): void {
    this.manufacturer = comp;
    this.ref.markForCheck();
  }

  submit(redirectToDetailPage: boolean = true): Observable<any> {
    this.loading$.next(true);

    return new Observable(observer => {
      const requestData: ManufacturerModel = this.form.value;

      requestData.type = 'MANUFACTURER';
      requestData.state = 'ACTIVE';
      requestData.additionalContacts = this.additionalContacts.map(form => ({ ...form.value }));

      this.manufacturerService
        .createManufacturer(requestData)
        .pipe(
          take(1),
          finalize(() => this.loading$.next(false))
        )
        .subscribe((id: string) => {
          if (!id) {
            return;
          }

          this.form.reset();

          if (redirectToDetailPage) {
            this.router.navigate([`/directory/manufacturers/edit/${id}`]);
          }

          observer.next(id);
          this.dialogRef.close(id);
        });
    });
  }

  submitWithSubscription(): void {
    this.submit().pipe(take(1)).subscribe();
  }

  submitWithoutRedirect(): void {
    this.submit(false)
      .pipe(take(1))
      .subscribe((id: string) => {
        this.dialogRef.close(id);
      });
  }

  addContact(): void {
    this.additionalContactsData.push(new ContactModel());
    ScrollTo('#addNewContactButton');
  }

  deleteContact(index: number): void {
    this.additionalContactsData.splice(index, 1);
    this.additionalContacts.splice(index, 1);
    this.additionalContactsData = [...[], ...this.additionalContactsData];
    this.ref.markForCheck();
  }
}
