import { IFilter } from '@shared/interfaces';
import { DefaultQueryParams } from '@shared/helpers/default-query-params';
import { TState } from '@shared/type/index.type';
import { ClearRequest } from '@shared/utils/filter-object-null-properties';

export class ProcedurePageableParams {
  page: number;
  physicianIds?: string[];
  searchText?: string;
  representativeId?: string;
  size: number;
  sort: string[];
  state?: TState;

  constructor(params: IFilter) {
    return ProcedurePageableParams.build(params);
  }

  private static build(params: IFilter): ProcedurePageableParams {
    const queryParams: ProcedurePageableParams = DefaultQueryParams(params);
    queryParams.state = params?.state ?? 'ACTIVE';
    queryParams.searchText = params.search;
    queryParams.physicianIds = params.physicianIds;
    queryParams.representativeId = params.representativeId;
    return ClearRequest(queryParams);
  }
}
