import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GlobalStoreService } from '@services/internal/global-store.service';
import { UsersService } from '@services/users.service';
import { OfflineStatusService } from '@services/offline/offline-status.service';

@Component({
  selector: 'app-custom-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrls: ['./custom-pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomPaginationComponent {
  @Input({ alias: 'pageNumber', required: true }) set pageNumberData(currentPage: number) {
    this.currentPageNumber = currentPage;
    this.updatePortionValues();
  }
  @Input({ required: true }) set totalPages(data: number) {
    if (data) {
      this.totalPagesNumber = data;
      this.updatePortionValues();
    }
  }
  @Input() disabled: boolean = false;
  @Input() totalElements: number = null;

  @Output() pageChangedEmitter = new EventEmitter<number>();

  /** max 8 items in visible pagination */
  pagesArray: (number | string)[] = [];
  currentPageNumber: number = 1;
  totalPagesNumber: number = 1;
  readonly isMobile$: BehaviorSubject<boolean> = GlobalStoreService.isMobile$;
  readonly defaultMobilePagination$: BehaviorSubject<number> = UsersService.defaultPagination;
  readonly isOffline$: BehaviorSubject<boolean> = this.offlineStatusService.isOffline$;

  constructor(private offlineStatusService: OfflineStatusService) {}

  pageChanged(page: number | string): void {
    if (typeof page === 'number') {
      const p: number = page - 1;
      this.pageChangedEmitter.emit(p);
    }
  }

  updatePortionValues(): void {
    const currentPage: number = this.currentPageNumber || 0;
    const currentPageUI: number = currentPage + 1;
    if (this.totalPagesNumber < 8) {
      /** Fill array 1-7 */
      this.pagesArray = Array(this.totalPagesNumber)
        .fill(1)
        .map((x, y) => x + y);
      return;
    }

    /** Should be only 7 items*/
    /** Page 1 always visible*/
    const pages: Array<number | string> = [1];

    /** Page 2 can be number or ...*/
    const page2: number | string = currentPageUI <= 4 ? 2 : '...';
    pages.push(page2);

    /** Page 3 */
    const page3: number =
      currentPageUI < 5 ? 3 : currentPageUI + 3 >= this.totalPagesNumber ? this.totalPagesNumber - 4 : currentPageUI - 1;
    pages.push(page3);

    /** Page 4 */
    const page4: number = currentPageUI < 4 ? 4 : currentPageUI + 2 >= this.totalPagesNumber ? this.totalPagesNumber - 3 : currentPageUI;
    pages.push(page4);

    /** Page 5 */
    const page5: number =
      this.totalPagesNumber - 2 === currentPageUI
        ? currentPageUI
        : currentPageUI < 4
        ? 5
        : currentPageUI + 1 >= this.totalPagesNumber
        ? this.totalPagesNumber - 2
        : currentPageUI + 1;
    pages.push(page5);

    /** Page 6 can be number or ... */
    const page6: number | string =
      this.totalPagesNumber - 1 === currentPageUI
        ? currentPageUI
        : currentPageUI + 3 >= this.totalPagesNumber
        ? this.totalPagesNumber - 1
        : '...';
    pages.push(page6);

    /** Page 7 always should be number, total pages*/
    const page7: number = this.totalPagesNumber;
    pages.push(page7);

    this.pagesArray = pages;
  }

  disablePaginationItem(page: number | string): boolean {
    /** Disable if it's not a link */
    if (page === '...') {
      return true;
      /** Disable next link */
    }
    if (page === this.currentPageNumber + 1) {
      return true;
    }
    /** Disable link of more than 5 items in pagination */
    if (this.totalPagesNumber < 5) {
      return false;
    }
    /** Disable last item in pagination, enable only if pre last page selected */
    return page === this.totalPagesNumber && !(page === this.totalPagesNumber && this.currentPageNumber === this.totalPagesNumber - 2);
  }
}
