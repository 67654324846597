import { IInventoryBuildParams } from '@shared/interfaces';
import { TItemType, TState } from '@shared/type/index.type';
import { InventoryModel, UserModel } from '@shared/models';
import { UntypedFormGroup } from '@angular/forms';
import { EConsignmentStatus, EHoldStatus, EInventoryDeviceType } from '@shared/enum';
import { UsersService } from '@services/users.service';

export class InventoryRequestBuild {
  barcode: string;
  billable: boolean;
  billOfMaterialId: string;
  catalogId: string;
  consignmentStatus: keyof typeof EConsignmentStatus;
  costOfGoods: string;
  custodyId: string;
  deviceId: string;
  dueBackDate: Date | string | null;
  expirationDate: Date | string | null;
  holdStatus: keyof typeof EHoldStatus;
  inventoryType: TItemType;
  lotNumber: string;
  name: string;
  notes: string;
  parentInventoryId: string;
  price: number | string;
  quantity: number;
  reusable: boolean;
  serialNumber: string;
  state: TState;
  tags: string[];
  udi: string;
  locationId: string;
  inventoryDeviceType: keyof typeof EInventoryDeviceType;
  manufacturerDate: string;

  static create({
    properties,
    details,
    udiGroup,
    tagsData,
    permittedCustodian,
    parentInvForm
  }: IInventoryBuildParams): InventoryRequestBuild {
    const tags: string[] = tagsData.map(tag => tag.tagText);
    const parentInv = details.value.inventoryType === 'DEVICE' ? details.value.parentInventoryId?.id : null;
    const quantity = details.value.inventoryType === 'CONTAINER' || details.value.quantity === '' ? 1 : details.value.quantity;

    const dueBackDate: string | Date | null = properties.get('dueBackDate')?.value
      ? new Date(new Date(properties.get('dueBackDate').value).setHours(12)).toISOString()
      : null;
    const expirationDate: string | Date | null = udiGroup.get('expirationDate')?.value
      ? new Date(new Date(udiGroup.get('expirationDate').value).setHours(12)).toISOString()
      : null;

    return {
      barcode: udiGroup.get('barcode').value,
      billOfMaterialId: parentInvForm.get('billOfMaterialId').value?.id,
      billable: properties.get('billable').value,
      catalogId: details.value.catalogId.id,
      consignmentStatus: properties.get('consignmentStatus').value !== '' ? properties.get('consignmentStatus').value : null,
      custodyId: details.value?.representativeId?.id ?? permittedCustodian?.id,
      deviceId: udiGroup.get('deviceId').value,
      dueBackDate,
      expirationDate,
      inventoryType: details.get('inventoryType').value,
      lotNumber: udiGroup.get('lotNumber').value,
      name: details.get('name').value,
      parentInventoryId: parentInv,
      price: properties.get('price').value,
      quantity,
      reusable: properties.get('reusable').value,
      serialNumber: udiGroup.get('serialNumber').value,
      state: 'ACTIVE',
      tags,
      udi: udiGroup.get('udi').value,
      costOfGoods: properties.get('costOfGoods').value,
      notes: parentInvForm.get('notes').value,
      holdStatus: properties.get('holdStatus')?.value || null,
      locationId: properties.get('locationId')?.value?.id || null,
      inventoryDeviceType: properties.get('inventoryDeviceType')?.value || null,
      manufacturerDate: udiGroup.get('manufacturerDate')?.value || null
    };
  }

  static save({ properties, udiGroup, tagsData, inventoryData, parentInvForm }: IInventoryBuildParams): InventoryRequestBuild {
    const tags: string[] = tagsData.map(tag => tag.tagText);
    const currentUser: UserModel = UsersService.getUser();
    let dueBackDate: string | Date = null;
    let expirationDate: string | Date = null;

    if (properties.get('dueBackDate')?.value) {
      dueBackDate = new Date(new Date(properties.get('dueBackDate').value).setHours(12)).toISOString();
    }

    if (udiGroup.get('expirationDate')?.value) {
      expirationDate = new Date(new Date(udiGroup.get('expirationDate').value).setHours(12)).toISOString();
    }

    return {
      barcode: udiGroup.get('barcode').value,
      billOfMaterialId: parentInvForm.get('billOfMaterialId').value?.id,
      billable: properties.get('billable').value,
      catalogId:
        currentUser.customPermissions?.editContainerCatalogNumberAllowed && inventoryData.inventoryType === 'CONTAINER'
          ? parentInvForm.get('catalogId').value.id
          : inventoryData.catalog.id,
      consignmentStatus: properties.get('consignmentStatus').value !== '' ? properties.get('consignmentStatus').value : null,
      custodyId: inventoryData.custody ? inventoryData.custody.id : null,
      deviceId: udiGroup.get('deviceId').value,
      dueBackDate,
      expirationDate,
      inventoryType: inventoryData.inventoryType,
      lotNumber: udiGroup.get('lotNumber').value,
      name: parentInvForm.get('name').value,
      parentInventoryId: parentInvForm.get('parentInventoryId')?.value?.id || null,
      price: properties.get('price').value,
      quantity: 1,
      reusable: properties.get('reusable').value,
      serialNumber: udiGroup.get('serialNumber').value,
      state: inventoryData.state,
      tags,
      udi: udiGroup.get('udi').value,
      costOfGoods: properties.get('costOfGoods').value,
      notes: parentInvForm.get('notes').value,
      holdStatus: properties.get('holdStatus').value,
      locationId: properties.get('locationId')?.value?.id || null,
      inventoryDeviceType: properties.get('inventoryDeviceType')?.value || null,
      manufacturerDate: udiGroup.get('manufacturerDate')?.value || null
    };
  }

  static updateInventory(inventory: InventoryModel): InventoryRequestBuild {
    return {
      barcode: inventory.barcode,
      billOfMaterialId: inventory?.billOfMaterial?.id,
      billable: inventory.billable,
      catalogId: inventory.catalog.id,
      consignmentStatus: inventory.consignmentStatus,
      custodyId: inventory.custody ? inventory.custody.id : null,
      deviceId: inventory.deviceId,
      dueBackDate: inventory.dueBackDate,
      expirationDate: inventory.expirationDate,
      inventoryType: inventory.inventoryType,
      lotNumber: inventory.lotNumber,
      name: inventory.name,
      notes: inventory.notes,
      parentInventoryId: inventory.parentInventory ? inventory.parentInventory.id : null,
      price: inventory.price,
      quantity: 1,
      reusable: inventory.reusable,
      serialNumber: inventory.serialNumber,
      tags: inventory.tags || [],
      udi: inventory.udi,
      costOfGoods: inventory.costOfGoods,
      holdStatus: inventory.holdStatus,
      locationId: inventory.location?.id || null,
      state: inventory.state,
      inventoryDeviceType: inventory.inventoryDeviceType,
      manufacturerDate: inventory.manufacturerDate
    };
  }

  static createUsingLimitData(addInventoryForm: UntypedFormGroup, custody: UserModel): InventoryRequestBuild {
    let expirationDate: string | Date = null;
    if (addInventoryForm.get('expirationDate')?.value) {
      expirationDate = new Date(new Date(addInventoryForm.get('expirationDate').value).setHours(12)).toISOString();
    }
    return {
      barcode: '',
      billOfMaterialId: null,
      billable: addInventoryForm.get('billable').value,
      catalogId: addInventoryForm.get('catalogId').value.id,
      consignmentStatus: null,
      custodyId: addInventoryForm.get('custodyId').value.id || custody?.id || null,
      deviceId: addInventoryForm.get('deviceId').value || '',
      dueBackDate: null,
      expirationDate,
      inventoryType: 'DEVICE',
      lotNumber: addInventoryForm.get('lotNumber').value || '',
      name: addInventoryForm.get('name').value,
      parentInventoryId: null,
      price: addInventoryForm.get('price').value || '',
      quantity: addInventoryForm.get('quantity').value,
      reusable: false,
      serialNumber: addInventoryForm.get('serialNumber').value || '',
      state: 'ACTIVE',
      tags: [],
      udi: addInventoryForm.get('udi').value || '',
      costOfGoods: addInventoryForm.get('costOfGoods').value || '',
      notes: '',
      holdStatus: 'READY_FOR_SALE',
      locationId: null,
      inventoryDeviceType: null,
      manufacturerDate: null
    };
  }
}
