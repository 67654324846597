import { IFilter } from '@shared/interfaces';
import { DefaultQueryParams } from '@shared/helpers/default-query-params';
import { ClearRequest } from '@shared/utils/filter-object-null-properties';
import { DateTimeString } from '@shared/models/shared/shared-kernel';
import { EAuditStatus } from '@shared/enum/audit-status';

export class AuditsPageableParams {
  page: number;
  size: number;
  sort: string[];
  status?: keyof typeof EAuditStatus;
  dateAssigned?: DateTimeString;
  dueDate?: DateTimeString;
  discrepancy?: boolean;
  statuses?: (keyof typeof EAuditStatus)[];

  constructor(params: IFilter) {
    return AuditsPageableParams.build(params);
  }

  private static build(params: IFilter): AuditsPageableParams {
    const queryParams: AuditsPageableParams = DefaultQueryParams(params);

    return ClearRequest(queryParams);
  }
}
