import { IFilter } from '@shared/interfaces';
import { DefaultQueryParams } from '@shared/helpers/default-query-params';
import { ClearRequest } from '@shared/utils/filter-object-null-properties';
import { TState } from '@shared/type/index.type';

export class LocationsPageableParams {
  catalogId?: string;
  containerId?: string;
  lotSerialNumber?: string;
  page?: number;
  searchText?: string;
  size?: number;
  sort?: string[];
  state?: TState;
  userId?: string;

  constructor(params: IFilter) {
    return LocationsPageableParams.build(params);
  }

  private static build(params: IFilter): LocationsPageableParams {
    const queryParams: LocationsPageableParams = DefaultQueryParams(params);

    queryParams.state = params?.state ?? 'ACTIVE';
    queryParams.searchText = params.search;
    queryParams.catalogId = params.catalogId;
    queryParams.lotSerialNumber = params.lotSerialNumber;
    queryParams.containerId = params.containerId;
    queryParams.userId = params.userId;

    return ClearRequest(queryParams);
  }
}
