import { ISubNav, IUserOrganizationDto } from '@shared/interfaces';
import { IExpiredStatistics } from '@shared/interfaces/dashboard/expired-statistics';
import { TransferInInventoryRequests } from '@shared/models';
import { EventVolumesModel } from '@shared/models/features/dashboard/dashboard.model';

export const TrackById = (_index: number, el: { id: string }) => el.id;

export const TrackByDay = (_index: number, item: EventVolumesModel) => item.day;

export const TrackByOrgId = (_index: number, item: IUserOrganizationDto) => item.organizationId;

export const TrackByUserId = (_index: number, item: IExpiredStatistics) => item.userId;

export const TrackByTransferId = (_index: number, item: TransferInInventoryRequests) => item.transferId;

export const TrackByLink = (_index: number, item: ISubNav) => item.link;
