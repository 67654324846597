import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SimilarInventoryInfo, UserModel } from '@shared/models';
import { GetDifferenceInDays } from '@shared/utils/date/get-diff-days';

@Component({
  selector: 'app-inventory-option-short-model',
  templateUrl: './inventory-option-short-model.component.html',
  styleUrls: ['./inventory-option-short-model.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('expandCollapse', [
      state(
        'open',
        style({
          height: '*'
        })
      ),
      state(
        'close',
        style({
          height: '0px',
          opacity: '0'
        })
      ),
      transition('open <=> close', animate(350))
    ])
  ]
})
export class InventoryOptionShortModelComponent implements OnChanges {
  @Input() companyName: string = '';
  @Input() index: number = 0;
  @Input('invent') set inventory(data: SimilarInventoryInfo) {
    this.getExpirationDays(data);
    this.invent = { ...data };
    this.tags = this.getInventoryTags(data.tags);
  }
  @Input() backgroundTransparent: boolean = false;
  @Input() showItemsCount: boolean = false;
  @Input() fullWidthView: boolean = false;
  @Input() displayTags: boolean = false;
  @Input() readonly: boolean = false;
  @Input() custody: UserModel = new UserModel();
  @Input() disableAnimation: boolean = false;

  @Output() pushDeviceEmitter = new EventEmitter<{ index: number; inventory: SimilarInventoryInfo; quantity?: number }>();

  openAnimation: 'open' | 'close' = 'open';
  quantity: number = 1;
  tags: string[] = [];
  invent: SimilarInventoryInfo;

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.invent?.currentValue) {
      this.openAnimation = 'open';
    }
  }

  getInventoryTags(value: string): string[] {
    if (!value) {
      return [];
    }

    return JSON.parse(value);
  }

  pushDevice(inventory: SimilarInventoryInfo): void {
    if (!this.disableAnimation) {
      this.openAnimation = 'close';
    }

    setTimeout(() => {
      this.pushDeviceEmitter.emit({ inventory, index: this.index, quantity: this.quantity });
    }, 300);
  }

  private getExpirationDays(data: SimilarInventoryInfo): void {
    if ((data.expirationStatus !== 'EXPIRING_SOON' && data.expirationStatus !== 'UNEXPIRED') || !data.expirationDate) {
      return;
    }
    const difference: number = GetDifferenceInDays(data.expirationDate);
    // CC-3889
    if (difference <= 60) {
      data.expiredDays = difference;
    }
  }
}
