export enum ECurrency {
  USD = '$',
  EUR = '€',
  JPY = '¥',
  GBP = '£',
  AUD = '$',
  CAD = '$',
  CHF = 'CHF',
  CNH = '¥',
  NZD = '$NZ'
}
