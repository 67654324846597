import { ImageModel } from '@shared/models';

export const ShortenFileUrl = (urls: ImageModel[] = []): ImageModel[] => {
  const result: ImageModel[] = urls;
  result.forEach((attachment: ImageModel) => (attachment.urlName = ShortenSingleFileUrl(attachment.url)));
  return result;
};

export const ShortenSingleFileUrl = (url: string): string => {
  try {
    const { hostname, pathname } = new URL(url);
    const lastIndex: number = pathname.lastIndexOf('/');
    const lastPathName: string = pathname.substring(lastIndex, pathname.length);
    return hostname + lastPathName;
  } catch (e) {
    return url;
  }
};
