export enum ECleanlinessCheck {
  FAIL = 'shared.enums.checkIn.fail',
  PASS = 'shared.enums.checkIn.pass'
}

export enum EVisualInspection {
  FAIL = 'shared.enums.checkIn.fail',
  PASS = 'shared.enums.checkIn.pass'
}

export enum EReconciledToBOM {
  FAIL = 'shared.enums.checkIn.fail',
  PASS = 'shared.enums.checkIn.pass',
  EXCEPTION = 'shared.enums.checkIn.exception'
}

export enum EIFUAttached {
  YES = 'shared.enums.checkIn.yes',
  NO = 'shared.enums.checkIn.no',
  NA = 'shared.enums.checkIn.na'
}

export enum ESetFlag {
  GREEN = 'shared.enums.checkIn.green',
  YELLOW = 'shared.enums.checkIn.yellow',
  RED = 'shared.enums.checkIn.red'
}
