import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { InventoryImportJob, InventoryImportLogs, PageableModel } from '@shared/models';
import { HttpHelperService } from '@services/internal/http-helper.service';
import { AuthenticationService } from '@services/auth.service';
import { ICreateImport, IDefaultParams } from '@shared/interfaces';
import { Observable, of, catchError, map } from 'rxjs';
import { ApiService } from '@shared/classes/api-service';
import { InventoryImportPageable } from '@shared/models/build-models/pageable/inventory-import-pageable';
import { LanguageService } from '@services/internal/language.service';

@Injectable({
  providedIn: 'root'
})
export class ImportService extends ApiService {
  constructor() {
    super();
  }

  deleteImport(id: string): Observable<boolean> {
    return this.put<InventoryImportJob>(`inventories/import/jobs/${id}`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.importDeletingStarted');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(false);
      })
    );
  }

  getImport(id: string): Observable<InventoryImportJob> {
    return this.get<InventoryImportJob>(`inventories/import/jobs/${id}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getLogs(id: string, params: IDefaultParams): Observable<PageableModel<InventoryImportLogs>> {
    return this.get<PageableModel<InventoryImportLogs>>(
      `inventories/import/jobs/${id}/logs`,
      HttpHelperService.addResponseTypeJSON(params)
    ).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getPageable(params: InventoryImportPageable): Observable<PageableModel<InventoryImportJob>> {
    return this.get<PageableModel<InventoryImportJob>>(`inventories/import/jobs`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  updateImport(params: ICreateImport, formData: FormData): Promise<void> {
    const param: string = HttpHelperService.getQueryParams(params);
    const token: string = AuthenticationService.getToken();

    return fetch(this.apiUrlCore + `inventories/import?${param}`, {
      method: 'PUT',
      body: formData,
      headers: { Authorization: `Bearer ${token}` }
    }).then(
      res => {
        if (res.status >= 400 && res.status < 600) {
          this.alertsService.showError('shared.alerts.errorMessages.parseCSV', null, 15000);
          throw new Error(LanguageService.instant('shared.alerts.errorMessages.errorParseCSV'));
          return;
        }
        this.alertsService.showSuccess('shared.alerts.successMessages.importStarted');
        Promise.resolve(res.json()).then();
      },
      err => {
        this.alertsService.showError('shared.alerts.errorMessages.parseCSV', err.message, 15000);
        return null;
      }
    );
  }
}
