import { Injectable } from '@angular/core';
import { IFilter } from '@shared/interfaces';
import {
  BillOfMaterialsPageableParams,
  CatalogsPageableParams,
  DeviceUsageTemplatesPageableParams,
  EventPageableParamsBuilder,
  FacilitiesPageableParams,
  InventoryPageableParams,
  LocationsPageableParams,
  ManufacturersPageableParams,
  NotificationsPageableParams,
  PhysiciansPageableParams,
  PriceSheetPageableParams,
  ProcedurePageableParams,
  ProductLineParams,
  RecipesPageableParams,
  RegionsPageableParams,
  RequestPageableSearchParams,
  TransferPageableParams
} from '@shared/models/build-models';
import { EPageablePageType } from '@shared/enum';
import { UsersPageableParams } from '@shared/models/build-models/pageable/users-pageable-params';
import { InventoryImportPageable } from '@shared/models/build-models/pageable/inventory-import-pageable';
import { BehaviorSubject } from 'rxjs';
import { AuditsPageableParams } from '@shared/models/build-models/pageable/audits-pageable-params';
import { BasePageableParams } from '@shared/models/build-models/pageable/base-pageable-params';

@Injectable({ providedIn: 'root' })
export class SearchParamsRequestBuilder {
  BOMPageable: BehaviorSubject<BillOfMaterialsPageableParams> = new BehaviorSubject(new BillOfMaterialsPageableParams({}));
  catalogsPageable: BehaviorSubject<CatalogsPageableParams> = new BehaviorSubject(new CatalogsPageableParams({}));
  eventPageable: BehaviorSubject<EventPageableParamsBuilder> = new BehaviorSubject(new EventPageableParamsBuilder({}));
  facilitiesPageable: BehaviorSubject<FacilitiesPageableParams> = new BehaviorSubject(new FacilitiesPageableParams({}));
  inventoryPageable: BehaviorSubject<InventoryPageableParams> = new BehaviorSubject(new InventoryPageableParams({}));
  manufacturerPageable: BehaviorSubject<ManufacturersPageableParams> = new BehaviorSubject(new ManufacturersPageableParams({}));
  notificationsPageableParams: BehaviorSubject<NotificationsPageableParams> = new BehaviorSubject(new NotificationsPageableParams({}));
  physiciansPageable: BehaviorSubject<PhysiciansPageableParams> = new BehaviorSubject(new PhysiciansPageableParams({}));
  procedurePageable: BehaviorSubject<ProcedurePageableParams> = new BehaviorSubject(new ProcedurePageableParams({}));
  productLinePageable: BehaviorSubject<ProductLineParams> = new BehaviorSubject(new ProductLineParams({}));
  requestPageable: BehaviorSubject<RequestPageableSearchParams> = new BehaviorSubject(new RequestPageableSearchParams({}));
  transferPageable: BehaviorSubject<TransferPageableParams> = new BehaviorSubject(new TransferPageableParams({}));
  locationsPageable: BehaviorSubject<LocationsPageableParams> = new BehaviorSubject(new LocationsPageableParams({}));
  deviceUsageTemplatePageable: BehaviorSubject<DeviceUsageTemplatesPageableParams> = new BehaviorSubject(
    new DeviceUsageTemplatesPageableParams({})
  );
  recipesPageable: BehaviorSubject<RecipesPageableParams> = new BehaviorSubject(new RecipesPageableParams({}));
  usersPageable: BehaviorSubject<UsersPageableParams> = new BehaviorSubject(new UsersPageableParams({}));
  inventoryImportPageable: BehaviorSubject<InventoryImportPageable> = new BehaviorSubject(new InventoryImportPageable({}));
  regionsPageable: BehaviorSubject<RegionsPageableParams> = new BehaviorSubject(new RegionsPageableParams({}));
  priceSheetsPageable: BehaviorSubject<PriceSheetPageableParams> = new BehaviorSubject(new PriceSheetPageableParams({}));
  auditsPageable: BehaviorSubject<AuditsPageableParams> = new BehaviorSubject(new AuditsPageableParams({}));
  basePageable: BehaviorSubject<BasePageableParams> = new BehaviorSubject(new BasePageableParams({}));

  setComponentParams(type: EPageablePageType, params: IFilter): void {
    if (type === EPageablePageType.inventoryPageable) {
      this.inventoryPageable.next(new InventoryPageableParams(params));
    } else if (type === EPageablePageType.eventPageable) {
      this.eventPageable.next(new EventPageableParamsBuilder(params));
    } else if (type === EPageablePageType.requestPageable) {
      this.requestPageable.next(new RequestPageableSearchParams(params));
    } else if (type === EPageablePageType.transferPageable) {
      this.transferPageable.next(new TransferPageableParams(params));
    } else if (type === EPageablePageType.productLinePageable) {
      this.productLinePageable.next(new ProductLineParams(params));
    } else if (type === EPageablePageType.billOfMaterialsPageable) {
      this.BOMPageable.next(new BillOfMaterialsPageableParams(params));
    } else if (type === EPageablePageType.catalogPageable) {
      this.catalogsPageable.next(new CatalogsPageableParams(params));
    } else if (type === EPageablePageType.facilitiesPageable) {
      this.facilitiesPageable.next(new FacilitiesPageableParams(params));
    } else if (type === EPageablePageType.physiciansPageable) {
      this.physiciansPageable.next(new PhysiciansPageableParams(params));
    } else if (type === EPageablePageType.proceduresPageable) {
      this.procedurePageable.next(new ProcedurePageableParams(params));
    } else if (type === EPageablePageType.manufacturersPageable) {
      this.manufacturerPageable.next(new ManufacturersPageableParams(params));
    } else if (type === EPageablePageType.notificationsPageable) {
      this.notificationsPageableParams.next(new NotificationsPageableParams(params));
    } else if (type === EPageablePageType.locationsPageable) {
      this.locationsPageable.next(new LocationsPageableParams(params));
    } else if (type === EPageablePageType.deviceUsageTemplate) {
      this.deviceUsageTemplatePageable.next(new DeviceUsageTemplatesPageableParams(params));
    } else if (type === EPageablePageType.requestRecipes) {
      this.recipesPageable.next(new RecipesPageableParams(params));
    } else if (type === EPageablePageType.users) {
      this.usersPageable.next(new UsersPageableParams(params));
    } else if (type === EPageablePageType.inventoryImport) {
      this.inventoryImportPageable.next(new InventoryImportPageable(params));
    } else if (type === EPageablePageType.regionsPageable) {
      this.regionsPageable.next(new RegionsPageableParams(params));
    } else if (type === EPageablePageType.priceSheetsPageable) {
      this.priceSheetsPageable.next(new PriceSheetPageableParams(params));
    } else if (type === EPageablePageType.auditsPageable) {
      this.auditsPageable.next(new AuditsPageableParams(params));
    } else if (type === EPageablePageType.basePageable) {
      this.basePageable.next(new BasePageableParams(params));
    }
  }
}
