import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleApiService {
  constructor(private httpClient: HttpClient) {}

  loadGoogleMapsAPI(): Observable<boolean> {
    if (window.google?.maps) {
      return of(true);
    }
    // jsonp requests throw an error if there are header in request
    return this.httpClient
      .jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyDs5BtQrxaysGyEiKsSU8F5HzKJySMToBw&libraries=places', 'callback')
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }
}
