<mat-checkbox
  class="checkboxKit"
  color="primary"
  [ngClass]="{ checkboxWithoutLabel: !title, wrapTitle: wrapTitle }"
  [disableRipple]="true"
  [class.disabled]="disabled"
  [attr.data-cy]="dataCy"
  [indeterminate]="indeterminate"
  [(ngModel)]="value"
  [ngModelOptions]="{ standalone: true }"
  (ngModelChange)="this.checkEmitter.emit($event)"
  (change)="checkForcedValue($event)"
>
  {{ title }}
</mat-checkbox>
