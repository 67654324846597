<app-default-modal
  [size]="'small'"
  [title]="data?.title ?? 'events.detail.selectManufacturerLabel' | language"
  [disableSubmitButton]="!manufacturerId.length && !selectAll"
  [buttonTitle]="'shared.labels.submit' | language"
  (clickEmitter)="submit()"
>
  <div fxLayout="column" fxLayoutGap="16px" fxFlex="100%">
    <span *ngIf="data.type === 'BOM'; else eventCaption" class="manufacturerHint">{{
      'boms.moreThanOneManufacturerCaption' | language
    }}</span>
    <ng-template #eventCaption>
      <span class="manufacturerHint">{{ 'events.detail.moreThanOneManufacturerCaption' | language }}</span>
    </ng-template>

    <app-checkbox-kit
      *ngIf="data?.allowSelectAll"
      class="subtitleContainer"
      [dataCy]="'printForAllManufacturersCheckbox'"
      [initialValue]="selectAll"
      [title]="'events.detail.printForAllManufacturersLabel' | language"
      (checkEmitter)="selectAll = $event; manufacturerId = ''"
    ></app-checkbox-kit>

    <app-select-kit
      [initialValue]="manufacturerId"
      [label]="'events.detail.manufacturerLabel' | language"
      [required]="true"
      [selectData]="manufacturersToShow$ | async"
      [parseValueByProperty]="'id'"
      [parseKeyByProperty]="'name'"
      [dataCy]="'manufacturerSelect'"
      [optionDataCy]="'manufacturerOption'"
      [disabled]="selectAll"
      (selectEmitter)="manufacturerId = $event"
    ></app-select-kit>
  </div>
</app-default-modal>
