import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MessagingNotificationModel, MessagingNotificationParams, NotificationModel, PageableModel } from '@shared/models';
import { HttpHelperService } from './internal/http-helper.service';
import { NotificationsPageableParams } from '@shared/models/build-models';
import { BehaviorSubject, Observable, of, catchError } from 'rxjs';
import { ApiService } from '@shared/classes/api-service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService extends ApiService {
  static checkReportJobNotification$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  static checkImportJobNotification$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  static checkExportJobNotification$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    super();
  }

  getPageable(params: NotificationsPageableParams): Observable<PageableModel<NotificationModel>> {
    return this.get<PageableModel<NotificationModel>>(`alerts/pageable`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of(new PageableModel()))
    );
  }

  markAsRead(body: string[]): Observable<void> {
    return this.post<void>(`alerts/markAsRead`, body);
  }

  markMessagingAlertAsRead(id: string): Observable<void> {
    return this.post<void>(`alerts/entity/${id}/mark-as-read`, {});
  }

  getMessagingNotifications(params: MessagingNotificationParams): Observable<MessagingNotificationModel[]> {
    return this.get<MessagingNotificationModel[]>(`alerts/alert-notifications`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of([]))
    );
  }
}
