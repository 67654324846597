import { EColorThemes } from '@shared/enum';
import { LocalStorage } from '@services/internal/localstorage.service';
import { TColorThemes } from '@shared/type/index.type';
import { UsersService } from '@services/users.service';

export const setCustomTheme = (color: TColorThemes): void => {
  const colorTheme: TColorThemes = color ? (color === 'GRAY' ? 'DARK' : color) : 'LIGHT';
  const bodyClasses: DOMTokenList = document.body.classList;
  const eColors: string[] = Object.keys(EColorThemes);
  const index: number = Object.values(bodyClasses).findIndex(themeClass => eColors.find(theme => theme === themeClass));
  if (index !== -1) {
    bodyClasses.remove(bodyClasses[index]);
    bodyClasses.add(colorTheme);
  }
  UsersService.colorTheme$.next(colorTheme);
  LocalStorage.setItem('theme', colorTheme);
};
