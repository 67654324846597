export const ScrollTo = (tag: string = null, timeout: number = 0): void => {
  setTimeout(() => {
    if (tag) {
      document.querySelector(tag)?.scrollIntoView({ behavior: 'smooth' });
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }, timeout);
};
