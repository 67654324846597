<div fxLayout="row" [fxLayoutAlign]="textAlign === 'center' ? 'center center' : 'start center'" fxLayoutGap="8px">
  <span
    #titleContainer
    [attr.data-cy]="dataCy"
    class="connectSxTitle"
    [ngClass]="
      type +
      ' ' +
      color +
      (textAlign === 'center' ? ' text-center' : '') +
      (oneLineView ? ' oneLineView' : '') +
      (capitalize ? ' textCapitalize' : '')
    "
    >{{ title }}<span *ngIf="required" class="colorRed">*</span>
  </span>
  <app-tooltip-kit *ngIf="isEllipsisActive(titleContainer)" [position]="'below'" [size]="24" [isHtml]="true">{{ title }}</app-tooltip-kit>
  <app-string-skeleton
    *ngIf="!title && type === 'h4' && showLoaderOnEmpty"
    fxFlex="500px"
    [defaultBackground]="true"
    [theme]="{ height: '29px', 'border-radius': '8px' }"
  />
</div>
