import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { isDevice } from '@shared/utils/is-device';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-tooltip-on-ellipsis',
  templateUrl: './tooltip-on-ellipsis.component.html',
  styleUrls: ['./tooltip-on-ellipsis.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TooltipOnEllipsisComponent {
  @ViewChild('contentContainer') contentContainer: ElementRef;

  @Input() position: TooltipPosition = 'above';

  showTooltip$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private ref: ChangeDetectorRef) {}

  /** Detection '...' in the title */
  isEllipsisActive(): void {
    if (isDevice('MOBILE') || isDevice('TABLET') || !this.contentContainer?.nativeElement) {
      return;
    }
    /**
     * ChangeDetection ignore changes inside ng-content,
     * to resolve it, check if the value has changed and this.ref.detectChanges()
     */
    const prevValue = this.showTooltip$.value;
    this.showTooltip$.next(this.contentContainer.nativeElement.offsetWidth < this.contentContainer.nativeElement.scrollWidth);

    if (prevValue !== this.showTooltip$.value) {
      this.ref.detectChanges();
    }
  }
}
