export class PageableModel<T> {
  content: T[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    offset: number;
    paged: boolean;
    pageNumber: number;
    pageSize: number;
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    unpaged: boolean;
  };
  size: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  totalElements: number;
  totalPages: number;

  constructor() {
    this.content = [];
    this.pageable = {
      offset: null,
      paged: null,
      pageNumber: null,
      pageSize: null,
      sort: {
        empty: null,
        sorted: null,
        unsorted: null
      },
      unpaged: null
    };
    this.sort = {
      empty: null,
      sorted: null,
      unsorted: null
    };
  }
}
