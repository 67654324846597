import { UserModel } from '@shared/models';
import { TState } from '@shared/type/index.type';

export class SalesTarget {
  createdBy: UserModel;
  createdDatetime: Date | string;
  firstQuarter: number;
  fourthQuarter: number;
  id: string;
  modifiedBy: UserModel;
  modifiedDatetime: Date | string;
  sales: UserModel;
  secondQuarter: number;
  state: TState;
  thirdQuarter: number;
  year: number;
}
