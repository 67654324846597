import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  /** Firebase configuration */
  // private static readonly firebaseConfig: any = {
  //   apiKey: 'AIzaSyCvzjAP8LIFtsHt90YXMEEElFoXNOtjjYg',
  //   authDomain: 'connectsx-production.firebaseapp.com',
  //   databaseURL: 'https://connectsx-production.firebaseio.com',
  //   projectId: 'connectsx-production',
  //   storageBucket: 'connectsx-production.appspot.com',
  //   messagingSenderId: '764280099480',
  //   appId: '1:764280099480:web:b347b0480ae7994b86b6ed',
  //   measurementId: 'G-Z5LTH9GQGH'
  // };
  //
  // globalErrorHandlerService: GlobalErrorHandlerService = inject(GlobalErrorHandlerService);
  // router: Router = inject(Router);

  //TODO test reset/init in analytics, probably we do not need reset analytics
  static reset(): void {
    // FirebaseAnalytics.reset();
  }

  //TODO Capacitor analytic plugins throws an error on build
  async initGA(): Promise<void> {
    //const capacitorPlatform = GlobalStoreService.getPlatform();
    // Added try/catch just in case to prevent errors in the app and report them to slack
    // try {
    //   await FirebaseAnalytics.initializeFirebase(AnalyticsService.firebaseConfig);
    //
    //   await FirebaseAnalytics.setUserId({ userId: UsersService.getUser().id });
    //
    //   await FirebaseAnalytics.setUserProperty({ name: 'capacitorPlatform', value: capacitorPlatform });
    //
    //   this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
    //     FirebaseAnalytics.logEvent({
    //       name: 'page_view',
    //       params: {
    //         page_path: event.urlAfterRedirects
    //       }
    //     });
    //
    //     if (capacitorPlatform !== 'web') {
    //       FirebaseAnalytics.setScreenName({
    //         screenName: GlobalStoreService.screenTitle$.value
    //       });
    //     }
    //   });
    // } catch (e) {
    //   console.error('Error AnalyticsService', e);
    //   this.globalErrorHandlerService.reportFrontEndInternalErrorsToSlack(e, {
    //     params: '',
    //     body: 'Error Google analytics AnalyticsService'
    //   });
    // }
  }
}
