<div>
  <app-input-kit
    fxFlex="100%"
    class="full-width"
    [required]="required"
    [initialValue]="transfer"
    [label]="label"
    [dataCy]="'transferInput'"
    [debounceEvents]="['keyup', 'focus']"
    [debounceTime]="1000"
    [icons]="[showClearIcon ? 'close' : null]"
    [filterView]="filterView"
    [forceBlurEvent]="forceBlurEvent"
    [autocompleteName]="transfersAutocomplete"
    (debounceTimeEndedEmitter)="searchTransfers($event)"
    (iconClickEmitter)="doIconAction($event)"
  ></app-input-kit>

  <mat-autocomplete #transfersAutocomplete="matAutocomplete" fxFlexAlign="center" [displayWith]="displayFn">
    <mat-option *ngIf="(loading$ | async) === true || (initialLoading$ | async) === true" class="is-loading" disabled>
      <mat-progress-spinner value="30" class="spinner" mode="indeterminate" diameter="30"></mat-progress-spinner>
    </mat-option>
    <ng-container *ngIf="(loading$ | async) === false && (initialLoading$ | async) === false">
      <mat-option *ngIf="!(searchedData$ | async).length; else options" disabled class="connectSxAutocompleteOption">
        <span>{{ 'shared.labels.noItemsFound' | language }}</span>
      </mat-option>

      <ng-template #options>
        <cdk-virtual-scroll-viewport
          itemSize="0"
          class="virtualAutocompleteScroll"
          [style.height.px]="(searchedData$ | async).length < 3 ? (searchedData$ | async).length * 50 : 150"
        >
          <mat-option
            *cdkVirtualFor="let transfer of searchedData$ | async"
            data-cy="transferOption"
            class="connectSxAutocompleteOption"
            [value]="transfer.id"
            (click)="selectOption(transfer)"
          >
            <span>{{ '#' + transfer.number + ', ' + transfer.name }}</span>
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </ng-template>
    </ng-container>
  </mat-autocomplete>
</div>
