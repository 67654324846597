/**
 * Encryption of the string before setting to the LocalStorage
 *
 * @description Function encrypt the string as a string
 * @param str String for encryption
 * @param separator Portion count
 * @returns Encrypted string as string
 */
export const encryptionString = (str: string, separator: number = 9): string => {
  if (!str.length) {
    return null;
  }
  const splittedArray: string[] = [];

  // Calculating the integer count of the items in each chunk
  const itemsPerChunk = Math.trunc(str.length / separator);

  // Slicing token to the chunks
  for (let i = 0; i < str.length; i += itemsPerChunk) {
    splittedArray.push(str.substr(i, itemsPerChunk));
  }

  // Encryption algorithm with replacing '-', '.', '{' and '}' symbols
  const encryptedString = splittedArray
    .map((value, index) => {
      if (index % 2 === 0) {
        return value.split('').reverse().join('');
      } else {
        return value;
      }
    })
    .join('')
    .replace(/-/g, '2aS9')
    .replace(/{/g, 'vKCtx')
    .replace(/}/g, 'kVxtC')
    .replace(/\./g, 'd9A]b~s');

  return encryptedString;
};

/**
 * Decrypting a string from LocalStorage and returning as a default string
 *
 * @description Function decrypt the string as a default string
 * @param encryptedString String for decryption
 * @param separator Portion count
 * @returns Decrypted string as default string
 */
export const decryptionString = (encryptedString: string, separator: number = 9): string => {
  if (!encryptedString) {
    return null;
  }
  const splittedArray: string[] = [];
  // Reverse replecement '-', '.', '{' and '}' symbols
  const decryptedString = encryptedString
    .replace(/2aS9/g, '-')
    .replace(/vKCtx/g, '{')
    .replace(/kVxtC/g, '}')
    .replace(/d9A]b~s/g, '.');

  // Calculating the integer count of the items in each chunk
  const itemsPerChunk = Math.trunc(decryptedString.length / separator);

  if (itemsPerChunk < 1) {
    return null;
  }

  // Slicing token to the chunks
  for (let i = 0; i < decryptedString.length; i += itemsPerChunk) {
    splittedArray.push(decryptedString.substr(i, itemsPerChunk));
  }

  // Decryption algorithm
  const decryptedStr = splittedArray
    .map((value, index) => {
      if (index % 2 === 0) {
        return value.split('').reverse().join('');
      } else {
        return value;
      }
    })
    .join('');

  return decryptedStr;
};
