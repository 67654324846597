import { Injectable } from '@angular/core';
import { MatDateFormats, NativeDateAdapter } from '@angular/material/core';
import { UsersService } from '@services/users.service';
import { format } from 'date-fns';

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date): string {
    switch (UsersService.dateFormat) {
      case 'DD_MM_YYYY':
        return format(date, `d MMM, yyyy`);
      case 'MM_DD_YYYY':
        return format(date, `MMM d, yyyy`);
      case 'YYYY_MM_DD':
        return format(date, `yyyy MMM d`);
      default:
        return format(date, `d MMM, yyyy`);
    }
  }
}

@Injectable()
export class AppShortDateAdapter extends NativeDateAdapter {
  format(date: Date): string {
    switch (UsersService.dateFormat) {
      case 'DD_MM_YYYY':
        return format(date, `MMM yyyy`);
      case 'MM_DD_YYYY':
        return format(date, `MMM yyyy`);
      case 'YYYY_MM_DD':
        return format(date, `yyyy MMM`);
      default:
        return format(date, `MMM yyyy`);
    }
  }
}

export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: UsersService.dateFormat
  },
  display: {
    dateInput: UsersService.dateFormat,
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' }
  }
};

export const APP_DATE_SHORT_FORMATS: MatDateFormats = {
  parse: {
    dateInput: UsersService.dateFormat === 'YYYY_MM_DD' ? 'yyyy MMM' : 'MMM yyyy'
  },
  display: {
    dateInput: UsersService.dateFormat === 'YYYY_MM_DD' ? 'yyyy MMM' : 'MMM yyyy',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' }
  }
};
