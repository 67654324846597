export enum EEventTypes {
  'CASE' = 'shared.enums.eventType.case',
  'SAMPLE' = 'shared.enums.eventType.sample',
  'LOAN' = 'shared.enums.eventType.loan',
  'DAMAGE' = 'shared.enums.eventType.damage',
  'RETURN' = 'shared.enums.eventType.return',
  'TRIAL' = 'shared.enums.eventType.trial',
  'OTHER' = 'shared.enums.eventType.other',
  'STOCKING_ORDER' = 'shared.enums.eventType.stockingOrder',
  'STOCK_ADJUSTMENT' = 'shared.enums.eventType.stockAdjustment'
}
