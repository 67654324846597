import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslationsKeys } from '@shared/type/i18n.type';

@Component({
  selector: 'app-textarea-kit',
  templateUrl: './textarea-kit.component.html',
  styleUrls: ['./textarea-kit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaKitComponent),
      multi: true
    }
  ]
})
export class TextareaKitComponent implements ControlValueAccessor {
  @Input() placeholder: string = '';
  @Input() label: string = 'Label';
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() dataCy: string = '';
  @Input() hint: TranslationsKeys;
  @Input() error: string = '';
  @Input() maxLength: number = 1000;
  @Input() minLength: number = 0;
  @Input() set initialValue(data: any) {
    if (data) {
      this.value = data;
      this.updateValue(data);
    } else {
      this.value = null;
      this.updateValue(null);
    }
  }
  @Input() hintPosition: 'after' | 'before' | 'above' | 'below' | 'left' | 'right' = 'left';

  @Output() valueChangedEmitter = new EventEmitter<any>();

  focused: boolean = false;
  value: string = '';

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  updateValue(value: any) {
    if (value !== undefined && this.value !== value) {
      if (typeof value === 'string') {
        value = value.trim();
      }
      this.valueChangedEmitter.emit(value);
      this.value = value;
      this.onChange(value);
      this.onTouch();
    }
  }
}
