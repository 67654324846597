import { PermissionModel } from '@shared/models';
import { ManufacturerCompanyTypeFactory } from './company-types-factories/manufacturer.company.type.factory';
import { ErrorRole } from './roles/error.role';
import { DistributorCompanyTypeFactory } from './company-types-factories/distributor.company.type.factory';
import { ECompanyTypes } from '@shared/enum';
import { TRoles } from '@shared/type/index.type';

export class PermissionsFactory {
  constructor() {}

  getPermissions(role: TRoles, companyType: string): PermissionModel {
    switch (companyType) {
      case ECompanyTypes.MANUFACTURER:
        return new ManufacturerCompanyTypeFactory().createRoles(role);
      case ECompanyTypes.DISTRIBUTOR:
        return new DistributorCompanyTypeFactory().createRoles(role);
      default:
        return new ErrorRole().model;
    }
  }
}
