import { ManufacturerModel } from '@shared/models/features/directory/manufacturers/manufacturer.model';
import { UserModel } from '@shared/models';
import { DateTimeString } from '@shared/models/shared/shared-kernel';

export class PONumberModel {
  id: string;
  manufacturer: ManufacturerModel;
  poDate: string;
  poNumber: string;
  createdBy: UserModel;
  createdDatetime: DateTimeString;
  modifiedDatetime: DateTimeString;
}
