export class SalesTargetActual {
  firstQuarter: number;
  fourthQuarter: number;
  secondQuarter: number;
  thirdQuarter: number;
  // front-end only
  firstQuarterAchieved?: number;
  fourthQuarterAchieved?: number;
  secondQuarterAchieved?: number;
  thirdQuarterAchieved?: number;
}
