import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { TIcons } from '@shared/type/icons.type';
import { startOfMonth } from 'date-fns';
import { isDevice } from '@shared/utils/is-device';

@Component({
  selector: 'app-datepicker-field-kit',
  templateUrl: './datepicker-field-kit.component.html',
  styleUrls: ['./datepicker-field-kit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DatepickerFieldKitComponent {
  @Input() initialDate = new UntypedFormControl(startOfMonth(new Date()));
  @Input() minDate: Date = null;
  @Input() label: string = '';
  @Input() filterView: boolean = false;
  @Input() clearIcon: boolean = false;
  @Input() set disabled(data: boolean) {
    if (data) {
      this.initialDate.disable();
    } else {
      this.initialDate.enable();
    }
    this._disabled = data;
  }
  @Input() set initialValue(data: Date | string) {
    this.initialDate.setValue(data);
  }
  @Input() icon: TIcons = null;
  @Input() resetValueOnClear: boolean = true;
  @Input() inputDataCy: string = '';
  @Input() togglerDataCy: string = '';
  @Input() required: boolean = false;
  @Input() tooltip: string = '';

  @Output() dateEmitter = new EventEmitter<MatDatepickerInputEvent<any, any>>();
  @Output() clearEmitter = new EventEmitter<void>();

  touchDatePicker = isDevice('MOBILE') || isDevice('TABLET');
  _disabled: boolean = false;

  clearInput(input: HTMLInputElement): void {
    if (this.resetValueOnClear) {
      input.value = '';
      this.initialValue = '';
    }

    this.clearEmitter.emit();
  }
}
