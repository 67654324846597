import { ProcedureModel, PreferenceCardAssignment, UserModel } from '@shared/models';
import { TState } from '@shared/type/index.type';

export class PreferenceCardModel {
  additionalPreferences: string;
  createdBy: UserModel;
  createdDatetime: string;
  id: string;
  looseItemQuantity: number;
  modifiedBy: UserModel;
  modifiedDatetime: string;
  name: string;
  note: string;
  procedure: ProcedureModel;
  productQuantity: number;
  requestRecipeAssignments: PreferenceCardAssignment[];
  state: TState;

  constructor() {
    this.requestRecipeAssignments = [];
  }
}
