import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LanguageService } from '@services/internal/language.service';
import { EPhoneFormat } from '@shared/enum';
import { TranslationsKeys } from '@shared/type/i18n.type';
import { TPhoneFormat } from '@shared/type/index.type';
import { IsNumbers } from '@shared/utils/phone/check-phone-format';

@Component({
  selector: 'app-input-with-select-kit',
  templateUrl: './input-with-select-kit.component.html',
  styleUrls: ['./input-with-select-kit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputWithSelectKitComponent),
      multi: true
    }
  ]
})
export class InputWithSelectKitComponent implements ControlValueAccessor {
  @Input() label: string = LanguageService.instant('shared.labels.phone');
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() hint: string = '';
  @Input() error: string = '';
  @Input() tooltip: TranslationsKeys = 'shared.tooltips.numbersOnly';
  @Input() background: boolean = false;
  @Input() set initialValue(data: string) {
    if (data) {
      /** From integration with another service, letters can be inside a phone field, if letter exists,
       * then disable phone mask to allow user edit this field (form validation will throw an error and disallow save)
       */
      this.showMask = IsNumbers(data);
      this.value = data;
    }
  }
  @Input('type') set typeData(data: TPhoneFormat) {
    if (!data) {
      return;
    }

    this.type = data;
    this.mask = data === 'INTERNATIONAL' ? this.maskInternationalFormat : this.maskUSFormat;
    this.prefix = data === 'INTERNATIONAL' ? '+' : '';
  }

  @Output() selectEmitter = new EventEmitter<TPhoneFormat>();
  @Output() valueChangedEmitter = new EventEmitter<string>();
  @Output() valid = new EventEmitter<boolean>();

  value: string = '';
  focused: boolean = false;
  type: TPhoneFormat = 'US';
  showMask: boolean = true;
  mask: string = null;
  prefix: string = '';

  readonly ePhoneFormat: Record<string, TranslationsKeys> = EPhoneFormat;
  readonly maskUSFormat: string = '(000) 000-0000';
  readonly maskInternationalFormat: string = '00 0 0000-0000-0000';

  updateSelectValue(value: TPhoneFormat) {
    this.selectEmitter.emit(value);
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  updateValue(value: string, valid?: boolean) {
    if (value !== undefined && this.value !== value) {
      this.valueChangedEmitter.emit(value);
      this.valid.emit(valid);
      this.onChange(value);
      this.onTouch();
    }
  }
}
