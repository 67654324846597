class ManufacturerName {
  id: string;
  name: string;
  poNumber: string;
  required?: boolean;
}

export class PoNumberUpdateResponse {
  billableTotal: number;
  datetime: string;
  devices: string[];
  eventName: string;
  facility: string;
  salesRep: string;
  surgeon: string;
  organizationEmail: string;
  totalQuantity: number;
  manufacturers: ManufacturerName[];
}
